import { getAllDisplayLanguages, IDisplayLanguage } from "service/http/localization"
import { Dispatch } from "react"

import { ERequestState } from "state/reducers/RequestTypes"

enum EActions {
    Get = "displayLanguages/get",
    Add = "displayLanguages/add",
    Update = "displayLanguages/update"
}

interface IDefaultState {
    state: ERequestState
    entities: IDisplayLanguage[]
}

interface GetAction {
    type: EActions.Get
    payload: IDefaultState
}

interface AddAction {
    type: EActions.Add
    payload: IDisplayLanguage
}

interface UpdateAction {
    type: EActions.Update
    payload: IDisplayLanguage
}

type TDisplayLanguagesAction = GetAction | AddAction | UpdateAction

const defaultState: IDefaultState = {
    state: ERequestState.Loading,
    entities: []
}

export const fetchDisplayLanguages = () => async (dispatch: Dispatch<GetAction>) => {
    dispatch({
        type: EActions.Get,
        payload: {
            state: ERequestState.Loading,
            entities: []
        }
    })

    try {
        const response = await getAllDisplayLanguages()

        return dispatch({
            type: EActions.Get,
            payload: {
                state: ERequestState.Succeeded,
                entities: response
            }
        })
    } catch(e) {
        return dispatch({
            type: EActions.Get,
            payload: {
                state: ERequestState.Failed,
                entities: []
            }
        })
    }
}

export const addDisplayLanguages = (displayLang: IDisplayLanguage) => (dispatch: Dispatch<AddAction>) => {
    dispatch({
        type: EActions.Add,
        payload: displayLang
    })
}

export const updateDisplayLanguages = (displayLanguage: IDisplayLanguage) => (dispatch: Dispatch<UpdateAction>) => {
    dispatch({
        type: EActions.Update,
        payload: displayLanguage
    })
}

const DisplayLanguagesReducer = (state = defaultState, action: TDisplayLanguagesAction): IDefaultState => {
    switch(action.type){
        case EActions.Get:
            return action.payload

        case EActions.Add:
            return {
                ...state,
                entities: [ ...state.entities, action.payload ]
            }

        case EActions.Update:
            return {
                ...state,
                entities: state.entities.map((entry) => {
                    if (entry.isoCode === action.payload.isoCode)
                        return action.payload

                    return entry
                })
            }

        default:
            return state
    }
}

export default DisplayLanguagesReducer
