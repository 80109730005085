import { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import Icon from "@mdi/react"
import { mdiArrowLeft } from "@mdi/js"

import * as api from "service/http/api"
import routes from "routes/routes"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { handleAxiosError } from "service/service"
import { noResponseReceivedNotification } from "utils/notifications"

import Loading from "components/common/Loading/Loading"
import Details from "components/AdminDashboard/Managers/Page/Details"
import Account from "components/AdminDashboard/Managers/Page/Account"

export interface IManagerPageHistoryState {
    from?: {
        pathname: string
        search: string
    }
}

export interface IManagerPageParams {
    managerId: string
}

const Page: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "modal", "managers" ])
    const { managerId } = useParams<IManagerPageParams>()
    const history = useHistory<IManagerPageHistoryState>()

    const setModalOptions = useConfirmModal()

    // region state
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ manager, setManager ] = useState<api.managers.IManager>({} as api.managers.IManager)
    const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false)
    // endregion

    // region mount
    useEffect(() => {
        const fetch = async () => {
            try {
                const manager = await api.managers.getManager(managerId)
                setManager(manager)
            } catch (error) {
                handleAxiosError(
                    error,
                    (e) => {
                        noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                        history.replace(routes.doctors)
                    }, (e) => {
                        // no connection or smth
                        noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                    }, (e) => {
                        // this my bad
                        // console.log('Error', e)
                    })
            }
        }

        fetch().then(() => setIsLoading(false))

        // eslint-disable-next-line -- irrelevant dependencies
    }, [])
    // endregion

    // region handlers
    const handleGoBack = () => {
        const goBack = () => {
            const state = history.location.state

            if (state && state.from && state.from.pathname === routes.managers)
                history.goBack()
            else
                history.push(routes.managers)
        }

        if (isFormDirty) {
            setModalOptions({
                isOpen: true,
                modalProps: {
                    title: t("modal:discardChanges.title"),
                    content: t("modal:discardChanges.message"),
                    onConfirm: () => {
                        setModalOptions({ isOpen: false })
                        goBack()
                    },
                    onCancel: () => {
                        setModalOptions({ isOpen: false })
                    }
                }
            })
        } else {
            goBack()
        }
    }

    const updateManagerDetails = async (entry: api.managers.IUpdateManagerParams) => {
        const response = await api.managers.updateManager(managerId, entry)

        setManager(response)
    }

    const updateManagerAccountState = async (state: api.accounts.EAccountState) => {
        await api.accounts.updateAccountState(managerId, {
            state: state
        })

        setManager({
            ...manager,
            accountState: state
        })
    }
    // endregion

    // region render
    if (isLoading)
        return <Loading />

    return <>
        <div className="container">

            {/*back button*/}
            <div className="columns">
                <div className="column">
                    <button
                        className="button is-circular"
                        onClick={ handleGoBack }
                    >
                        <span className="icon is-small">
                            <Icon path={ mdiArrowLeft } size={ 1 } />
                        </span>
                    </button>
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    {/*details*/}
                    <div className="card">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("managers:page.titles.details") }
                            </div>
                            <Details
                                type="existing"
                                details={ manager }
                                onUpdate={ updateManagerDetails }
                                setFormIsDirty={ setIsFormDirty }
                            />
                        </div>
                    </div>
                </div>

                <div className="column">
                    {/*account*/}
                    <div className="card">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("managers:page.titles.account") }
                            </div>
                            <Account
                                accountState={ manager.accountState }
                                onStateChange={ updateManagerAccountState }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    // endregion
}

export default Page
