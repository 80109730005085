import { ChangeEvent, FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiWeb } from "@mdi/js"
import _ from "lodash"

import "style/AgathaLabs.scss"
import languages from "utils/languages"

const displayLanguages = [ "en", "ro" ]

const ChangeDisplayLanguage: FunctionComponent = (props) => {
    const { i18n } = useTranslation()

    const [ selectedLang, setSelectedLang ] = useState<string>(i18n.resolvedLanguage)

    const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.target.value).then()
        setSelectedLang(event.target.value)
    }

    return <>
        <div className="control is-narrow has-icons-left">
            <div className="select">
                <select name="lang" value={ selectedLang } onChange={ handleLanguageChange }>
                    {
                        displayLanguages.map((value) => {
                            return (
                                <option key={ value } value={ value }>
                                    { _.upperFirst(languages[value].native) }
                                </option>
                            )
                        })
                    }
                </select>
            </div>
            <div className="icon is-small is-left">
                <Icon path={ mdiWeb } size={ 1 } color="#6c0daf"/>
            </div>
        </div>
    </>
}

export default ChangeDisplayLanguage
