import { FunctionComponent, useEffect, useState } from "react"
import Icon from "@mdi/react"
import { mdiAccountPlus } from "@mdi/js"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as api from "service/http/api"
import { noResponseReceivedNotification } from "utils/notifications"
import { handleAxiosError } from "service/service"
import usePagination from "hooks/usePagination"

import Loading from "components/common/Loading/Loading"
import PageSizeSelect from "components/common/Pagination/PageSizeSelect"
import Pagination from "components/common/Pagination/Pagination"
import ManagersListRow from "components/AdminDashboard/Managers/List/ListRow"
import Add from "components/AdminDashboard/Managers/List/Add"

const List: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "managers" ])

    // pagination
    const location = useLocation()
    // eslint-disable-next-line
    const [ currentPage, currentPageSize, currentPageCount, currentFilters, changePage, handlePagination ] = usePagination(api.managers.getManagersList)

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ managers, setManagers ] = useState<api.managers.IManager[]>([])
    const [ isAddingManager, setIsAddingManager ] = useState<boolean>(false)

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)

            try {
                const response = await handlePagination()

                if (response)
                    setManagers(response)
            } catch (error) {
                // clear the table
                setManagers([])

                handleAxiosError(error, (e) => {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // if it were a 401 error the user would be redirected
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // Something happened in setting up the request that triggered an Error
                    console.log(error);
                })
            }
        }

        fetch().then(() => {
            setIsLoading(false)
        })

        // t is not relevant as a dependency
        // eslint-disable-next-line
    }, [ location.search ])

    return <>
        <h1 className="title"> { t("managers:table.title") } </h1>
        <h2 className="subtitle"> { t("managers:table.subtitle") } </h2>

        <div className="is-flex is-justify-content-flex-end mb-2">
            <PageSizeSelect
                currentPageSize={ currentPageSize }
                onChangePage={ (pageSize) => {
                    changePage({
                        pageSize: pageSize
                    })
                } }
                isLoading={ isLoading }
            />
        </div>

        <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
                <thead>
                <tr>
                    <th>#</th>
                    <th>{ t("managers:table.headers.name") }</th>
                    <th>{ t("managers:table.headers.email") }</th>
                    <th>{ t("managers:table.headers.accountState") }</th>

                    <th>
                        <div className="buttons is-centered">
                            <button
                                className="button is-secondary is-circular"
                                title={ t("managers:table.buttons.add.title") }
                                onClick={ () => { setIsAddingManager(true) } }
                            >
                                <span className="icon is-small">
                                    <Icon path={ mdiAccountPlus } size={ 1 } />
                                </span>
                            </button>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    (() => {
                        if (isLoading)
                            return <>
                                <tr>
                                    <td colSpan={ 5 }>
                                        <Loading/>
                                    </td>
                                </tr>
                            </>

                        if (!managers.length)
                            return <>
                                <tr>
                                    <td colSpan={ 5 }>
                                        { t("managers:table.noManagersFoundMessage") }
                                    </td>
                                </tr>
                            </>

                        return managers.map((manager, index) => {
                            return (
                                <ManagersListRow
                                    key={ manager.id }
                                    tableRowIndex={ ((currentPage - 1) * currentPageSize) + index + 1 }
                                    manager={ manager }
                                />
                            )
                        })
                    })()
                }
                </tbody>
            </table>
        </div>
        {
            managers.length > 0 && !isLoading
            && <Pagination
                currentPage={ currentPage }
                lastPage={ currentPageCount }
                onPageChange={ (page) => {
                    changePage({
                        page: page
                    })
                } }
            />
        }
        {
            isAddingManager
            && <Add onClose={ () => { setIsAddingManager(false) } }/>
        }
    </>
}

export default List
