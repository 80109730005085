const primary = "#6c0daf"
const secondary = "#009e86ff"
const danger = "#f14668"
const info = "#3e8ed0"
const warning = "#ffe08a"

export {
    primary,
    secondary,
    danger,
    info,
    warning
}
