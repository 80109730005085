import { FunctionComponent, useEffect, useState } from "react"
import Icon from "@mdi/react"
import { mdiArrowLeft } from "@mdi/js"

import * as api from "service/http/api"
import routes from "routes/routes"
import { handleAxiosError } from "service/service"
import { noResponseReceivedNotification } from "utils/notifications"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"

import Loading from "components/common/Loading/Loading"
import Details from "components/AdminDashboard/Clients/Page/Details"
import Account from "components/AdminDashboard/Clients/Page/Account"
import Authentications from "components/AdminDashboard/Clients/Page/Authentications"
import PaymentMethods from "components/AdminDashboard/Clients/Page/PaymentMethods/PaymentMethods"

export interface IClientPageHistoryState {
    from?: {
        pathname: string
        search: string
    }
}

export interface IClientPageParams {
    clientId: string
}

const Page: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "modal", "clients" ])
    const { clientId } = useParams<IClientPageParams>()
    const history = useHistory<IClientPageHistoryState>()

    // region state
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ client, setClient ] = useState<api.clients.IClient>({} as api.clients.IClient)
    // endregion

    // region mount
    useEffect(() => {
        const fetch = async () => {
            try {
                const client = await api.clients.getClient(clientId)
                setClient(client)
            } catch (error) {
                handleAxiosError(
                    error,
                    (e) => {
                        noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                        history.replace(routes.clients)
                    }, (e) => {
                        // no connection or smth
                        noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                    }, (e) => {
                        // this my bad
                        // console.log('Error', e)
                    })
            }
        }

        fetch().then(() => setIsLoading(false))

        // irrelevant dependencies
        // eslint-disable-next-line
    }, [])
    // endregion

    // region handlers
    const handleGoBack = () => {
        const state = history.location.state

        if (state && state.from && state.from.pathname === routes.clients)
            history.goBack()
        else
            history.push(routes.clients)
    }

    // region account
    const updateClientAccountState = async (state: api.accounts.EAccountState) => {
        await api.accounts.updateAccountState(clientId, {
            state: state
        })

        setClient({
            ...client,
            accountState: state
        })
    }

    const updateClientAccountTestingState = async (isTestingAccount: boolean) => {
        await api.clients.updateClientAccountTestingState(clientId, {
            isTestAccount: isTestingAccount
        })

        setClient({
            ...client,
            isTestingAccount: isTestingAccount
        })
    }
    // endregion
    // endregion handlers

    // region render
    if (isLoading)
        return <Loading />

    return <>
        <div className="container">

            {/*back button*/}
            <div className="columns">
                <div className="column">
                    <button
                        className="button is-circular"
                        onClick={ handleGoBack }
                    >
                        <span className="icon is-small">
                            <Icon path={ mdiArrowLeft } size={ 1 } />
                        </span>
                    </button>
                </div>
            </div>

            {/*content*/}
            <div className="columns">

                {/*account & details*/}
                <div className="column">
                    {/*details*/}
                    <div className="card">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("clients:view.details.title") }
                            </div>
                            <Details details={ client } />
                        </div>
                    </div>

                    {/*account*/}
                    <div className="card mt-5">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("clients:view.account.title") }
                            </div>
                            <Account
                                client={ client }
                                onClientAccountStateChange={ updateClientAccountState }
                                onClientAccountTestingStateChange={ updateClientAccountTestingState }
                            />
                        </div>
                    </div>
                </div>

                {/*payment and authentication methods*/}
                <div className="column">
                    {/*authentication*/}
                    <div className="card">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("clients:view.authentication.title") }
                            </div>
                            <Authentications
                                auth={ client.authenticationMethods }
                            />
                        </div>
                    </div>

                    {/*payment*/}
                    <div className="card mt-5">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("clients:view.paymentMethods.title") }
                            </div>
                            <PaymentMethods
                                paymentMethods={ client.clientPaymentMethods }
                            />
                        </div>
                    </div>
                </div>
            </div> {/*.columns*/}
        </div> {/*.container*/}
    </>
    // endregion
}

export default Page
