import { mdiPlus } from "@mdi/js"
import Icon from "@mdi/react"
import { FieldProps } from "formik"
import React, { FunctionComponent, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import * as api from "service/http/api"

interface Props {
    fieldProps: FieldProps<string[]>
    onAddCallback: (value: string) => void
    onRemoveCallback: (index: number) => void
    hasCampaignEnded: boolean
}

const DoctorSelect: FunctionComponent<Props> = (props) => {
    // eslint-disable-next-line
    const { t } = useTranslation([ "campaigns", "common" ])

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ doctors, setDoctors ] = useState<api.doctors.IDoctorDropDownListProfile[]>([])
    const [ input, setInput ] = useState<string>("none")
    const [ isInputValid, setIsInputValid ] = useState<boolean>(false)

    // fetch doctors for dropdown
    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await api.doctors.getDoctorDropDownList()
                setDoctors(response)
            } catch (e) {

            }
        }

        fetch().then(() => setIsLoading(false))
    }, [])

    // validate input
    useEffect(() => {
        const valid = input !== "none" && props.fieldProps.field.value.indexOf(input) === -1
        setIsInputValid(valid)

        // irrelevant dependency
        // eslint-disable-next-line
    }, [ input ])

    const addElement = useCallback(() => {
        if (isInputValid) {
            props.onAddCallback(input)
            setInput("none")
        }
        // irrelevant dependency
        // eslint-disable-next-line
    }, [ input, isInputValid ])

    return <>
        <div className="field">
            <label htmlFor="" className="label">
                { t("campaigns:page.details.form.doctors.label") }
            </label>
            {
                !props.hasCampaignEnded
                && <>
                    <div className="field has-addons">
                        <div className="control is-expanded">
                            <div className={ `select is-fullwidth ${ isLoading ? "is-loading" : "" }` }>
                                <select
                                    className="input"
                                    name="doctors"
                                    value={ input }
                                    disabled={ isLoading }
                                    onKeyDown={ e => {
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            addElement()
                                        }
                                    } }
                                    onChange={ e => setInput(e.target.value) }
                                    onBlur={ props.fieldProps.field.onBlur }
                                >
                                    <option value="none">{ t("common:form.selectADoctorDropdownEntry") }</option>
                                    {
                                        doctors.map((it, index) => {
                                            if (props.fieldProps.field.value.indexOf(it.id) !== -1) return null

                                            return <option key={ index } value={ it.id }>
                                                { `${it.fullName} (${it.primaryEmail})` }
                                            </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="control">
                            <button
                                className="button is-primary"
                                type="button"
                                title={ t("campaigns:page.details.form.buttons.addDoctorTitle") }
                                disabled={ !isInputValid || isLoading }
                                onClick={ addElement }
                            >
                    <span className="icon is-small">
                        <Icon path={ mdiPlus } size={ 1 } />
                    </span>
                            </button>
                        </div>
                    </div>
                    {/*show error message here if needed*/}
                </>
            }
        </div>

        {
            props.fieldProps.field.value.length > 0 && !isLoading
            && <>
                <div className="field is-grouped is-grouped-multiline">
                    {
                        props.fieldProps.field.value.map((it, index) => {
                            const doctor = doctors.find(d => d.id === it)

                                return (
                                    <div key={ `selected-${it}` } className="control">
                                        <span className="tag is-primary">
                                            <figure className="image is-16x16 mr-2">
                                                <img
                                                    className="is-rounded"
                                                    src={ doctor!.profilePicture }
                                                    alt={ doctor!.fullName }
                                                />
                                            </figure>
                                            { doctor!.fullName }
                                            {
                                                !props.hasCampaignEnded
                                                && <>
                                                    <button
                                                        title={ t("campaigns:page.details.form.buttons.removeDoctorTitle") }
                                                        type="button"
                                                        className="delete is-small"
                                                        onClick={ () => props.onRemoveCallback(index) }
                                                    />
                                                </>
                                            }
                                       </span>
                                    </div>
                                )
                        })
                    }
                </div>
            </>
        }
    </>
}

export default DoctorSelect
