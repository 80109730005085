import React, { FunctionComponent } from "react"
import { IDisplayLanguage, updateDisplayLanguageAvailability } from "service/http/localization"
import { useTranslation } from "react-i18next"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { notify } from "utils/notifications"
import languages from "utils/languages"

interface Props {
    info: IDisplayLanguage
    onUpdate: (available: boolean) => void
    onClose: () => void
}

const ChangeAvailablity: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "common", "localization", "modal", "notification" ])

    const setModalOptions = useConfirmModal()

    const handMakeAvailable = () => {
        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.changeLanguageAvailability.title"),
                content: t("modal:confirm.changeLanguageAvailability.message", {
                    language: languages[props.info.isoCode][i18n.resolvedLanguage]
                }),
                onConfirm: async () => {
                    setModalOptions({ isOpen: false })

                    try {
                        await updateDisplayLanguageAvailability(props.info.isoCode, true)

                        props.onUpdate(true)
                        props.onClose()

                        notify(
                            "success",
                            t("notification:onDisplayLanguageAvailabilityChange.success.title"),
                            t("notification:onDisplayLanguageAvailabilityChange.success.message", {
                                language: languages[props.info.isoCode][i18n.resolvedLanguage]
                            })
                        )
                    } catch (e) {
                        notify(
                            "danger",
                            t("notification:onDisplayLanguageAvailabilityChange.fail.title"),
                            t("notification:onDisplayLanguageAvailabilityChange.fail.message", {
                                language: languages[props.info.isoCode][i18n.resolvedLanguage]
                            })
                        )
                    }
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })
    }

    return <>
        {/*<div className="field">*/}
        {/*    <label className="label">{ t("localization:edit.form.available.label") }</label>*/}
        {/*    <div className="control is-expanded">*/}
        {/*        <div className="select is-fullwidth">*/}
        {/*            <select*/}
        {/*                value={ value }*/}
        {/*                onChange={ handleChange }*/}
        {/*            >*/}
        {/*                <option value={ 0 }>unavailable</option>*/}
        {/*                <option value={ 1 }>available</option>*/}
        {/*            </select>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <div className="field is-grouped is-grouped-centered">
            <button className="button is-primary" onClick={ handMakeAvailable }>
                { t("localization:edit.form.buttons.makeAvailable")}
            </button>
        </div>

        {/* form actions */}
        <div className="field is-grouped is-justify-content-space-between">
            <button className="button" type="button" onClick={ () => { props.onClose() } }>{ t("localization:edit.form.buttons.close") }</button>
        </div>
    </>
}

export default ChangeAvailablity
