import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiContentSave } from "@mdi/js"
import _ from "lodash"

import * as api from "service/http/api"
import { notify } from "utils/notifications"
import { enumToNumberValuesArray } from "utils/helpers"
import { EPermissionRoles, hasPermissions } from "utils/auth"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"

interface Props {
    doctor: api.doctors.IDoctor
    onStateChange: (newState: api.accounts.EAccountState) => Promise<void>
    onPageVisibilityChange: (newPageVisibility: api.doctors.EDoctorPageVisibility) => Promise<void>
    onUpdateManager: (params: api.doctors.TUpdateDoctorsManagersParams) => Promise<void>
    onPasswordReset: () => Promise<void>
}

const Account: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "notification", "modal", "doctors", "common" ])
    const setModalOptions = useConfirmModal()

    // region state
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ isUpdating, setIsUpdating ] = useState<boolean>(false)
    const [ managers, setManagers ] = useState<api.managers.IManager[]>([])

    // fields
    const [ managersPayoutPercentage, setManagersPayoutPercentage ] = useState<number>(props.doctor.managersPayoutPercentage ?? 0)
    // endregion

    // mount
    useEffect(() => {
        const fetch = async () => {
            const response = await api.managers.getAllManagers()
            setManagers(response.results)
        }

        fetch().then(() => setIsLoading(false))
    }, [])

    // region handlers
    const handleStateChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        // for some reason e.target.value is undefined when onConfirm() is called
        // not sure why, so we define it here instead
        const newState = parseInt(e.target.value, 10)

        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.doctorAccountStateChange.title"),
                content: t("modal:confirm.doctorAccountStateChange.message", { name: `${props.doctor.givenName} ${props.doctor.familyName}` }),
                onConfirm: async () => {
                    setModalOptions({ isOpen: false })
                    setIsUpdating(true)

                    try {
                        await props.onStateChange(newState)

                        notify(
                            "success",
                            t("notification:onDoctorAccountStateChange.success.title"),
                            t("notification:onDoctorAccountStateChange.success.message")
                        )
                    } catch(e) {
                        notify(
                            "danger",
                            t("notification:onDoctorAccountStateChange.fail.title"),
                            t("notification:onDoctorAccountStateChange.fail.message")
                        )
                    }

                    setIsUpdating(false)
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })
    }

    const handlePageVisibilityChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        // for some reason e.target.value is undefined when onConfirm() is called
        // not sure why, so we define it here instead
        const newState = parseInt(e.target.value, 10)

        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.doctorPageVisibilityChange.title"),
                content: t("modal:confirm.doctorPageVisibilityChange.message", { name: `${props.doctor.givenName} ${props.doctor.familyName}` }),
                onConfirm: async () => {
                    setModalOptions({ isOpen: false })
                    setIsUpdating(true)

                    try {
                        await props.onPageVisibilityChange(newState)

                        notify(
                            "success",
                            t("notification:onDoctorPageVisibilityChange.success.title"),
                            t("notification:onDoctorPageVisibilityChange.success.message")
                        )
                    } catch(e) {
                        notify(
                            "danger",
                            t("notification:onDoctorPageVisibilityChange.fail.title"),
                            t("notification:onDoctorPageVisibilityChange.fail.message")
                        )
                    }

                    setIsUpdating(false)
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })
    }

    const handleManagerChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        // for some reason e.target.value is undefined when onConfirm() is called
        // not sure why, so we define it here instead
        const newManager = e.target.value !== "0" ? e.target.value : null

        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.doctorManagerChange.title"),
                content: t("modal:confirm.doctorManagerChange.message", { name: `${props.doctor.givenName} ${props.doctor.familyName}` }),
                onConfirm: async () => {
                    setModalOptions({ isOpen: false })
                    setIsUpdating(true)

                    try {
                        let params: api.doctors.TUpdateDoctorsManagersParams

                        if (newManager)
                            params = {
                                managerId: newManager
                            }
                        else
                            params = {
                                removeManager: true
                            }

                        await props.onUpdateManager(params)

                        notify(
                            "success",
                            t("notification:onDoctorManagerChange.success.title"),
                            t("notification:onDoctorManagerChange.success.message")
                        )
                    } catch(e) {
                        notify(
                            "danger",
                            t("notification:onDoctorManagerChange.fail.title"),
                            t("notification:onDoctorManagerChange.fail.message")
                        )
                    }

                    setIsUpdating(false)
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })
    }

    const handleResetPassword = async () => {
        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.doctorPasswordReset.title"),
                content: t("modal:confirm.doctorPasswordReset.message", { name: `${props.doctor.givenName} ${props.doctor.familyName}`}),
                onConfirm: async () => {
                    setModalOptions({ isOpen: false })
                    setIsUpdating(true)

                    try {
                        await props.onPasswordReset()

                        notify(
                            "success",
                            t("notification:onDoctorPasswordReset.success.title"),
                            t("notification:onDoctorPasswordReset.success.message")
                        )
                    } catch(e) {
                        notify(
                            "danger",
                            t("notification:onDoctorPasswordReset.fail.message"),
                            t("notification:onDoctorPasswordReset.fail.message")
                        )
                    }

                    setIsUpdating(false)
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })
    }

    const handleManagersPayoutPercentageChange = async () => {
        setIsUpdating(true)

        try {
            await props.onUpdateManager({
                managersPayoutPercentage: managersPayoutPercentage
            })

            notify(
                "success",
                t("notification:onDoctorManagersPayoutPercentageChange.success.title"),
                t("notification:onDoctorManagersPayoutPercentageChange.success.message")
            )
        } catch(e) {
            notify(
                "danger",
                t("notification:onDoctorManagersPayoutPercentageChange.fail.title"),
                t("notification:onDoctorManagersPayoutPercentageChange.fail.message")
            )
        }

        setIsUpdating(false)
    }

    // endregion

    // region render
    return <>
        {/*account state*/}
        <div className="field">
            <div className="control is-expanded">
                <label className="label">{ t("doctors:edit.account.form.accountState.label") }</label>
                <div className={ "select is-fullwidth " + (isUpdating ? "is-loading" : "")}>
                    <select
                        name="accountState"
                        disabled={ isUpdating }
                        value={ props.doctor.accountState }
                        onChange={ handleStateChange }
                    >
                        {
                            enumToNumberValuesArray(api.accounts.EAccountState).map((value) => {
                                return (
                                    <option
                                        key={ value }
                                        value={ value }
                                    >
                                        { t("common:accountState.state", { context: value }) }
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
        </div>

        {/*managers shouldn't edit this*/}
        {
            hasPermissions(EPermissionRoles.Admin)
            && <>
                {/*page visibility*/}
                <div className="field">
                    <div className="control is-expanded">
                        <label className="label">{ t("doctors:edit.account.form.pageVisibility.label") }</label>
                        <div className={ "select is-fullwidth " + (isUpdating ? "is-loading" : "") }>
                            <select
                                name="pageVisibility"
                                disabled={ isUpdating }
                                value={ props.doctor.pageVisibility }
                                onChange={ handlePageVisibilityChange }
                            >
                                {
                                    enumToNumberValuesArray(api.doctors.EDoctorPageVisibility).map((value) => {
                                        return (
                                            <option
                                                key={ value }
                                                value={ value }
                                            >
                                                { t("common:doctorPageVisibilityType.type", { context: value }) }
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>

                {/*assigned manager*/}
                <div className="field">
                    <div className="control is-expanded">
                        <label className="label">{ t("doctors:edit.account.form.changeManager.label") }</label>
                        <div className={ "select is-fullwidth " + ( isLoading || isUpdating ? "is-loading" : "") }>
                            <select
                                name="managerId"
                                disabled={ isLoading || isUpdating }
                                value={ props.doctor.managerId ?? 0 }
                                onChange={ handleManagerChange }
                            >
                                <option key={ -1 } value={ 0 }>
                                    { t("doctors:edit.account.form.changeManager.none") }
                                </option>

                                {
                                    managers.length > 0
                                    && managers.map((entry) => {
                                        return <option
                                            key={ entry.id }
                                            value={ entry.id }
                                        >
                                            { `${entry.name} (${entry.primaryEmail})` }
                                        </option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </>
        }

        {/*managers payout percentage*/}
        {
            props.doctor.managerId
            && <>
                <div className="field">
                    <label className="label">
                        { t("doctors:edit.account.form.managersPayoutPercentage.label") }
                    </label>

                    <div className="field is-horizontal">
                        <div className="field-body">
                            {/*input*/}
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        className="input"
                                        type="number"
                                        step={ 0.01 }
                                        value={ managersPayoutPercentage }
                                        onChange={ (e) => { setManagersPayoutPercentage(_.toNumber(e.target.value)) } }
                                    />
                                </div>
                            </div>

                            {/*save button*/}
                            <div className="field is-narrow">
                                <div className="control">
                                    <button
                                        className={ `button is-primary is-circular ${ isUpdating ? "is-loading" : "" }` }
                                        title={ t("doctors:edit.account.form.managersPayoutPercentage.button.title") }
                                        disabled={ isUpdating || managersPayoutPercentage === props.doctor.managersPayoutPercentage }
                                        onClick={ handleManagersPayoutPercentageChange }
                                    >
                                <span className="icon is-small">
                                    <Icon path={ mdiContentSave } size={ 1 } />
                                </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <span className="help">
                        { t("doctors:edit.account.form.managersPayoutPercentage.helpMessage") }
                    </span>
                </div>
            </>
        }

        {/*password reset*/}
        <div className="field">
            <div className="control">
                <label className="label">{ t("doctors:edit.account.form.resetPassword.label") }</label>
                <button
                    className={ `button is-primary ${isUpdating ? "is-loading" : ""}` }
                    disabled={ isUpdating }
                    onClick={ handleResetPassword }
                >
                    { t("doctors:edit.account.form.resetPassword.buttonText") }
                </button>
            </div>
        </div>
    </>
    // endregion
}

export default Account
