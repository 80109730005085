import { ERequestState } from "state/reducers/RequestTypes"
import { Dispatch } from "react"

import { getDoctorSpecialties, IDoctorSpecialty } from "service/http/doctors"

enum EActions {
    Get = "doctorSpecialties/get",
    Add = "doctorSpecialties/add",
    Update = "doctorSpecialties/update"
}

interface IDefaultState {
    state: ERequestState,
    entities: IDoctorSpecialty[]
}

interface GetAction {
    type: EActions.Get
    payload: IDefaultState
}

interface AddAction {
    type: EActions.Add
    payload: IDoctorSpecialty
}

interface UpdateAction {
    type: EActions.Update
    payload: IDoctorSpecialty
}

type TDoctorSpecialtiesAction = GetAction | AddAction | UpdateAction

const defaultState: IDefaultState = {
    state: ERequestState.Loading,
    entities: []
}

export const fetchDoctorSpecialties = () => async (dispatch: Dispatch<GetAction>) => {
    dispatch({
        type: EActions.Get,
        payload: {
            state: ERequestState.Loading,
            entities: []
        }
    })

    try {
        const response = await getDoctorSpecialties()

        return dispatch({
            type: EActions.Get,
            payload: {
                state: ERequestState.Succeeded,
                entities: response
            }
        })
    } catch(e) {
        return dispatch({
            type: EActions.Get,
            payload: {
                state: ERequestState.Failed,
                entities: []
            }
        })
    }
}

export const addDoctorSpecialty = (specialty: IDoctorSpecialty) => async (dispatch: Dispatch<AddAction>) => {
    dispatch({
        type: EActions.Add,
        payload: specialty
    })
}

export const updateDoctorSpecialty = (specialty: IDoctorSpecialty) => async (dispatch: Dispatch<UpdateAction>) => {
    dispatch({
        type: EActions.Update,
        payload: specialty
    })
}

const DoctorSpecialtyReducer = (state = defaultState, action: TDoctorSpecialtiesAction): IDefaultState => {
    switch(action.type) {
        case EActions.Get:
            return action.payload

        case EActions.Add:
            return {
                ...state,
                entities: [
                    ...state.entities,
                    action.payload
                ]
            }

        case EActions.Update:
            return {
                ...state,
                entities: state.entities.map((entry) => {
                    if (entry.id === action.payload.id)
                        return action.payload

                    return entry
                })
            }

        default:
            return state
    }
}

export default DoctorSpecialtyReducer
