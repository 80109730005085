import { DateTime } from "luxon"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import Icon from "@mdi/react"
import { mdiEye } from "@mdi/js"
import { useTranslation } from "react-i18next"

import { IReport } from "service/http/reports"
import routes from "routes/routes"

interface Props {
    tableRowIndex: number
    info: IReport
    onView: () => void
}

const ReportsTableRow: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common" ])
    return <>
        <tr>
            <td>{ props.tableRowIndex }</td>
            <td>
                <Link
                    to={ {
                        pathname: routes.clients,
                        search: `?ids=${props.info.client.id}`
                    } }
                >
                    { `${props.info.client.givenName} ${props.info.client.familyName}` }
                </Link>
            </td>
            <td>
                <Link
                    to={{
                        pathname: routes.instantCalls,
                        search: `?ids=${props.info.instantCallId}`
                    } }
                >
                    { props.info.instantCallId }
                </Link>
            </td>
            <td>
                { t("common:reportState.state", { context: props.info.reportState }) }
            </td>
            <td>{ t("common:date.dateTimeMedWithSeconds", { date: DateTime.fromISO(props.info.createdAt).toLocal() }) }</td>
            <td>
                <div className="buttons is-centered">
                    <button
                        className="button is-circular"
                        type="button"
                        onClick={ props.onView }
                    >
                        <span className="icon is-small">
                            <Icon path={ mdiEye } size={ 1 } />
                        </span>
                    </button>
                </div>
            </td>
        </tr>
    </>
}

export default ReportsTableRow
