import { mdiContentCopy, mdiHelpCircle, mdiVideo, mdiWeatherNight, mdiWeatherSunny } from "@mdi/js"
import Icon from "@mdi/react"
import { rawTimeZones, timeZonesNames } from "@vvo/tzdb"
import LanguageSelect from "components/AdminDashboard/Doctors/Page/Details/LanguageSelect/LanguageSelect"
import CheckBoxSwitchField from "components/common/Form/CheckBoxSwitchField"

import FormikDirtyListener from "components/common/Form/FormikDirtyListener"
import ImageFileInputField from "components/common/Form/ImageFileInputField"
import ImagePreview from "components/common/Form/ImagePreview"
import InputField from "components/common/Form/InputField"
import InputFieldWithAddons from "components/common/Form/InputFieldWithAddons"
import SelectField from "components/common/Form/SelectField"
import TextAreaField from "components/common/Form/TextAreaField"
import Loading from "components/common/Loading/Loading"
import { Field, FieldArray, FieldProps, Form, Formik, FormikHelpers, FormikProps } from "formik"
import _ from "lodash"
import { DateTime } from "luxon"
import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { SUPPORTED_IMAGE_FORMATS } from "service/config/config"

import * as api from "service/http/api"
import { handleAxiosError } from "service/service"
import { ERequestState } from "state/reducers/RequestTypes"
import { TRootStore } from "state/Store"
import {
    isValidDate,
    isValidImageFile,
    stringArrayToString,
    timeStringToUtcIsoString,
    UtcIsoStringToTimeString,
    UtcIsoStringToYearString,
    yearStringToUtcISOString
} from "utils/helpers"
import { notify } from "utils/notifications"
import * as yup from "yup"
import { primary } from "../../../../../assets/colors"

export interface IFormFields {
    [key: string]: any
    givenName: string
    familyName: string
    primaryEmail: string
    phoneNumber: string
    // profilePictureRequired: boolean // needed to make the file upload required
    profilePicture: File | null
    ratePerMinuteInUnit: number
    videoRatePerMinuteInUnit: number
    payoutPercentage: number
    outOfScheduleRatePerMinuteInUnit: number
    acceptsOutsideOfSchedule: boolean
    acceptsFreeFirstCalls: boolean
    isResident: boolean
    primarySpecialty: number
    firstYearOfMedicalExperience: string
    onlineConsultationsOverCountApproximation: number
    scheduleBegin: string
    scheduleEnd: string
    scheduleTimeZone: string
    prettyUrlName?: string
    spokenLanguages: string[]
    internalNotes?: string
}

interface NewProps {
    type: "new"
    details?: never
    onCreate: (details: api.doctors.ICreateDoctorParams) => Promise<void>
    onUpdate?: never
    onClose: () => void
}

interface ExistingProps {
    type: "existing"
    details: api.doctors.IDoctor
    onCreate?: never
    onUpdate: (details: api.doctors.IUpdateDoctorParams) => Promise<void>
    onClose?: never
}

type Props = (NewProps | ExistingProps) & {
    setFormIsDirty: (value: boolean) => void
}

const agathaHealthDoctorsUrl = "https://agatha.health/doctors/"

const Details: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "common", "doctors", "notification" ])

    const displayLanguages = useSelector((state: TRootStore) => state.displayLanguages)
    const doctorSpecialties = useSelector((state: TRootStore) => state.doctorSpecialties)

    const [ profilePicKey, setProfilePicKey ] = useState<number>(Date.now())
    const [ emailAlreadyInUse, setEmailAlreadyInUse ] = useState<string | null>(null)

    const [ initialValues, setInitialValues ] = useState<IFormFields>({
        // profilePictureRequired:                     props.type === "new",
        givenName:                                  props.type === "existing" ? props.details.givenName                                 : "",
        familyName:                                 props.type === "existing" ? props.details.familyName                                : "",
        primaryEmail:                               props.type === "existing" ? props.details.primaryEmail                              : "",
        phoneNumber:                                props.type === "existing" ? props.details.phoneNumber                               : "",
        profilePicture:                             null,
        ratePerMinuteInUnit:                        props.type === "existing" ? props.details.ratePerMinuteInUnit                       : 1,
        videoRatePerMinuteInUnit:                   props.type === "existing" ? props.details.videoRatePerMinuteInUnit                  : .25,
        outOfScheduleRatePerMinuteInUnit:           props.type === "existing" ? props.details.outOfScheduleRatePerMinuteInUnit          : 4,
        payoutPercentage:                           props.type === "existing" ? props.details.payoutPercentage                          : 50,
        acceptsOutsideOfSchedule:                   props.type === "existing" ? props.details.acceptsOutsideOfSchedule                  : true,
        acceptsFreeFirstCalls:                      props.type === "existing" ? props.details.acceptsFreeFirstCalls                     : true,
        isResident:                                 props.type === "existing" ? props.details.isResident                                : false,
        primarySpecialty:                           props.type === "existing" ? props.details.primarySpecialty                          : doctorSpecialties.entities[0].id,
        firstYearOfMedicalExperience:               props.type === "existing" ? props.details.firstYearOfMedicalExperience              : DateTime.now().toUTC().toISO(),
        onlineConsultationsOverCountApproximation:  props.type === "existing" ? props.details.onlineConsultationsOverCountApproximation : 500,
        scheduleBegin:                              props.type === "existing" ? props.details.scheduleBegin                             : DateTime.now().set({ hour: 8, minute: 0 }).toUTC().toISO(),
        scheduleEnd:                                props.type === "existing" ? props.details.scheduleBegin                             : DateTime.now().set({ hour: 20, minute: 0 }).toUTC().toISO(),
        scheduleTimeZone:                           props.type === "existing" ? props.details.scheduleTimeZone                          : Intl.DateTimeFormat().resolvedOptions().timeZone,
        spokenLanguages:                            props.type === "existing" ? props.details.spokenLanguages                           : [ "en", "ro" ],
        prettyUrlName:                              props.type === "existing" ? props.details.prettyUrlName ?? ""                       : "",
        internalNotes:                              props.type === "existing" ? props.details.internalNotes ?? ""                       : "",
    })

    const validationSchemaRef = useRef(
        yup.object<IFormFields>().shape({
            givenName: yup
                .string()
                .trim()
                .required(t("common:form.fieldIsRequired")),
            familyName: yup
                .string()
                .trim()
                .required(t("common:form.fieldIsRequired")),
            primaryEmail: yup
                .string()
                .trim()
                .email(t("common:form.invalidEmail"))
                .test('isEmailAlreadyInUse', t("common:form.emailInUse"), (value) => {
                    if (value)
                        return !(emailAlreadyInUse && emailAlreadyInUse === value);

                    return true
                })
                .required(t("common:form.fieldIsRequired")),
            phoneNumber: yup
                .string()
                .trim()
                .required(t("common:form.fieldIsRequired")),
            // profilePictureRequired: yup.boolean(),
            profilePicture: yup
                .mixed<File>()
                .nullable()
                // .when([ "profilePictureRequired" ], {
                //     is: true,
                //     then: yup.mixed().required(t("common:form.fieldIsRequired"))
                // })
                .test("isFileTypeValid", t("common:form.file.image.error"),
                    value => isValidImageFile(value)
                ),
            ratePerMinuteInUnit: yup
                .number()
                .required(t("common:form.fieldIsRequired")),
            outOfScheduleRatePerMinuteInUnit: yup
                .number()
                .required(t("common:form.fieldIsRequired")),
            videoRatePerMinuteInUnit: yup
                .number()
                .required(t("common:form.fieldIsRequired")),
            payoutPercentage: yup
                .number()
                .min(0, t("common:form.invalidRange", { min: 0, max: 100 }))
                .max(100, t("common:form.invalidRange", { min: 0, max: 100 }))
                .required(t("common:form.fieldIsRequired")),
            acceptsOutsideOfSchedule: yup
                .boolean()
                .required(t("common:form.fieldIsRequired")),
            acceptsFreeFirstCalls: yup
                .boolean()
                .required(t("common:form.fieldIsRequired")),
            isResident: yup
                .boolean()
                .required(t("common:form.fieldIsRequired")),
            primarySpecialty: yup
                .number()
                .required(t("common:form.fieldIsRequired")),
            firstYearOfMedicalExperience: yup
                .string()
                .trim()
                .test("stringIsYear", t("common:form.yearIsInvalid"), isValidDate)
                .required(t("common:form.fieldIsRequired")),
            onlineConsultationsOverCountApproximation: yup
                .number()
                .min(0)
                .required(t("common:form.fieldIsRequired")),
            scheduleBegin: yup
                .string()
                .test("stringIsTime", t("common:form.timeIsInvalid"), isValidDate)
                .required(t("common:form.fieldIsRequired")),
            scheduleEnd: yup
                .string()
                .test("stringIsTime", t("common:form.timeIsInvalid"), isValidDate)
                .required(t("common:form.fieldIsRequired")),
            scheduleTimeZone: yup
                .string()
                .oneOf(timeZonesNames)
                .required(t("common:form.fieldIsRequired")),
            spokenLanguages: yup
                .array(yup.string())
                .test("arrayIsEmpty", t("doctors:edit.details.form.spokenLanguages.error.fieldIsRequired"), (value) => {
                    return !!value && value.length > 0
                })
                .required(t("common:form.fieldIsRequired")),
            prettyUrlName: yup
                .string()
                .trim()
                .min(3, t("common:form.minLength", { length: 3 }))
                .matches(/^[A-Za-z0-9_.-]*$/, t("doctors:edit.details.form.prettyUrlName.error.invalidCharacters"))
                .notRequired(),
            internalNotes: yup
                .string()
                .trim()
                .notRequired()
        })
    )

    // update initial values on details updated successfully
    useEffect(() => {
        if (props.type === "existing")
            setInitialValues({
                // profilePictureRequired:                     false,
                givenName:                                  props.details.givenName,
                familyName:                                 props.details.familyName,
                primaryEmail:                               props.details.primaryEmail,
                phoneNumber:                                props.details.phoneNumber,
                profilePicture:                             null,
                ratePerMinuteInUnit:                        props.details.ratePerMinuteInUnit,
                videoRatePerMinuteInUnit:                   props.details.videoRatePerMinuteInUnit,
                outOfScheduleRatePerMinuteInUnit:           props.details.outOfScheduleRatePerMinuteInUnit,
                payoutPercentage:                           props.details.payoutPercentage,
                acceptsOutsideOfSchedule:                   props.details.acceptsOutsideOfSchedule,
                acceptsFreeFirstCalls:                      props.details.acceptsFreeFirstCalls,
                isResident:                                 props.details.isResident,
                primarySpecialty:                           props.details.primarySpecialty,
                firstYearOfMedicalExperience:               props.details.firstYearOfMedicalExperience,
                onlineConsultationsOverCountApproximation:  props.details.onlineConsultationsOverCountApproximation,
                scheduleBegin:                              props.details.scheduleBegin,
                scheduleEnd:                                props.details.scheduleEnd,
                scheduleTimeZone:                           props.details.scheduleTimeZone,
                spokenLanguages:                            props.details.spokenLanguages,
                prettyUrlName:                              props.details.prettyUrlName ?? "",
                internalNotes:                              props.details.internalNotes ?? "",
            })

        // props.type never changes
        // eslint-disable-next-line
    }, [ props.details ])

    const handleSubmit = async (values: any, formikHelpers: FormikHelpers<IFormFields>) => {
        values = _.omit<IFormFields>(values, "profilePictureRequired")
        values = validationSchemaRef.current.cast(values) // applying yup transformations

        if (props.type === "new") {
            try {
                values = _.omit(values, values.profilePicture === null ? "profilePicture" : "")
                setEmailAlreadyInUse(null)

                await props.onCreate(values)

                notify(
                    "success",
                    t("notification:onDoctorCreate.success.title"),
                    t("notification:onDoctorCreate.success.message", {
                        name: `${values.givenName} ${values.familyName}`
                    })
                )
            } catch (error) {
                handleAxiosError(error, (e) => {
                    if ((e.response?.data as { errors?: { primaryEmail?: string } }).errors?.primaryEmail) {
                        setEmailAlreadyInUse(values.primaryEmail)
                        formikHelpers.setFieldError("primaryEmail", t("common:form.emailInUse"))
                    }
                    else
                        notify(
                            "danger",
                            t("notification:onDoctorCreate.fail.title"),
                            t("notification:onDoctorCreate.fail.message")
                        )
                }, (e) => {
                    notify(
                        "danger",
                        t("notification:onDoctorCreate.fail.title"),
                        t("notification:onDoctorCreate.fail.message")
                    )
                }, (e) => {
                    // my bad
                })
            }
        }
        else {
            let params: api.doctors.IUpdateDoctorParams = {}

            // assign only the modified fields
            Object.keys(_.omit(values)).forEach((key) => {
                if (values[key as keyof IFormFields] !== initialValues[key as keyof IFormFields])
                    params[key] = values[key]
            })

            try {
                await props.onUpdate(params)

                // in case the only updates were some whitespaces
                // the values are not actually updated, and we remove them like this
                formikHelpers.resetForm()

                // reset file input
                setProfilePicKey(Date.now())

                // notify
                notify(
                    "success",
                    t("notification:onDoctorUpdate.success.title"),
                    t("notification:onDoctorUpdate.success.message", {
                        name: `${values.givenName} ${values.familyName}`
                    })
                )
            } catch (e) {
                if (e.response?.data.errors.prettyUrlName) {
                    formikHelpers.setFieldError("prettyUrlName", t("doctors:edit.details.form.prettyUrlName.error.urlAlreadyInUse"))
                    return
                }

                notify(
                    "danger",
                    t("notification:onDoctorUpdate.fail.title"),
                    t("notification:onDoctorUpdate.fail.message", {
                        name: `${values.givenName} ${values.familyName}`
                    })
                )
            }

        }
    }

    const handleCopyPrettyUrlNameToClipboard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(`${agathaHealthDoctorsUrl}${text}`)
            notify("success", "", t("notification:onPrettyUrlCopiedToClipboard.message"))
        }
        catch (e) {
            // well
        }
    }

    const formatTimeZoneName = (rawFormat: string, name: string) => {
        // the raw format looks like this
        // rawFormat: "-08:00 Pacific Time - Los Angeles, San Diego, San Jose, San Francisco"

        const timeOffset = rawFormat.split(" ")
        const location = name.replaceAll('_', ' ').split('/')

        return `${location[1]}, ${location[0]} (GMT ${timeOffset[0]})`
    }

    if (doctorSpecialties.state === ERequestState.Loading || displayLanguages.state === ERequestState.Loading)
        return <Loading />

    return <>
        <Formik
            enableReinitialize={ true }
            initialValues={ initialValues }
            validationSchema={ validationSchemaRef.current }
            onSubmit={ handleSubmit }
        >
            {
                ((formikProps: FormikProps<IFormFields>) => {
                    return <>
                        <Form>
                            <FormikDirtyListener onChange={ props.setFormIsDirty } />

                            {/*image preview*/}
                            <div className="field">
                                <div className="control">
                                    {
                                        (() => {
                                            if (!!formikProps.values.profilePicture && !formikProps.errors.profilePicture)
                                                return <>
                                                    <ImagePreview
                                                        placeholderType="account"
                                                        file={ formikProps.values.profilePicture }
                                                        imgAlt="profile-profilePicture"
                                                        size="128x128"
                                                        shape="circle"
                                                    />
                                                </>

                                            return <>
                                                <ImagePreview
                                                    placeholderType="account"
                                                    url={ props.type === "existing" ? props.details.profilePicture : "" }
                                                    imgAlt="profile-profilePicture"
                                                    size="128x128"
                                                    shape="circle"
                                                />
                                            </>
                                        })()
                                    }
                                </div>
                            </div>

                            {/*upload profile pic*/}
                            <ImageFileInputField
                                resetKey={ profilePicKey } // used for resetting the input
                                label={ t("common:form.file.image.labels.profilePicture") }
                                name="profilePicture"
                                helpMessage={ t("common:form.file.image.help", { fileTypes: stringArrayToString(SUPPORTED_IMAGE_FORMATS) }) }
                                crop={ true }
                                cropAspectRatio={ 1 }
                                onSaveFile={ (file) => formikProps.setFieldValue("profilePicture", file) }
                                onRemoveFile={ () => {
                                    setProfilePicKey(Date.now())
                                    formikProps.setFieldValue("profilePicture", null)
                                } }
                            />

                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <InputField
                                        label={ t("doctors:edit.details.form.givenName.label") }
                                        name="givenName"
                                        placeholder={ t("doctors:edit.details.form.givenName.placeholder") }
                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                            formikProps.handleChange(e)

                                            if (props.type === "new" && !formikProps.touched.prettyUrlName) {
                                                let familyName = formikProps.values.familyName.replaceAll(" ", "")
                                                formikProps.setFieldValue("prettyUrlName", `${e.target.value}${familyName}`, true)
                                            }
                                        } }
                                    />

                                    <InputField
                                        label={ t("doctors:edit.details.form.familyName.label") }
                                        name="familyName"
                                        placeholder={ t("doctors:edit.details.form.familyName.placeholder") }
                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                            formikProps.handleChange(e)

                                            if (props.type === "new" && !formikProps.touched.prettyUrlName) {
                                                let givenName = formikProps.values.givenName.replaceAll(" ", "")
                                                formikProps.setFieldValue("prettyUrlName", `${ givenName }${ e.target.value }`, true)
                                            }
                                        } }
                                    />
                                </div>
                            </div>

                            <InputField
                                label={ t("doctors:edit.details.form.primaryEmail.label") }
                                name="primaryEmail"
                                placeholder={ t("doctors:edit.details.form.primaryEmail.placeholder") }
                                readOnly={ props.type === "existing" }
                            />

                            <InputFieldWithAddons
                                label={ t("doctors:edit.details.form.prettyUrlName.label") }
                                name="prettyUrlName"
                                fieldSize="expanded"
                                beforeAddons={
                                    <button className="button is-static">
                                        <strong>{ agathaHealthDoctorsUrl }</strong>
                                    </button>
                                }
                                afterAddons={
                                    <button
                                        className="button is-primary"
                                        type="button"
                                        disabled={ formikProps.errors.prettyUrlName !== undefined || (formikProps.values.prettyUrlName === undefined || formikProps.values.prettyUrlName === "") }
                                        onClick={ () => handleCopyPrettyUrlNameToClipboard(formikProps.values.prettyUrlName!) }
                                    >
                                        <span className="icon is-small">
                                            <Icon path={ mdiContentCopy } size={ 1 } />
                                        </span>
                                    </button>
                                }
                            />

                            <InputField
                                label={
                                    <span className="icon-text">
                                        <span>{ t("doctors:edit.details.form.phoneNumber.label") }</span>
                                        <span className="icon" title={ t("doctors:edit.details.form.phoneNumber.tooltip") }>
                                            <Icon path={ mdiHelpCircle } size={ .75 } color={ primary } />
                                        </span>
                                    </span>
                                }
                                name="phoneNumber"
                                placeholder={ t("doctors:edit.details.form.phoneNumber.placeholder") }
                            />

                            <SelectField
                                label={
                                    <span className="icon-text">
                                        <span>{ t("doctors:edit.details.form.primarySpecialty.label") }</span>
                                        <span className="icon" title={ t("doctors:edit.details.form.primarySpecialty.tooltip") }>
                                            <Icon path={ mdiHelpCircle } size={ .75 } color={ primary } />
                                        </span>
                                    </span>
                                }
                                fieldSize="expanded"
                                name="primarySpecialty"
                                onChange={ (e: ChangeEvent<HTMLSelectElement>) => {
                                    formikProps.setFieldValue("primarySpecialty", _.toNumber(e.target.value))
                                } }
                            >
                                {
                                    doctorSpecialties.entities.map((specialty) => {
                                        return <option key={ specialty.id } value={ specialty.id }>
                                            { specialty.translations[i18n.resolvedLanguage] ?? specialty.translations["en"] }
                                        </option>
                                    })
                                }
                            </SelectField>

                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <InputField
                                        label={
                                            <span className="icon-text">
                                                <span>{ t("doctors:edit.details.form.firstYearOfMedicalExperience.label") }</span>
                                                <span className="icon" title={ t("doctors:edit.details.form.firstYearOfMedicalExperience.tooltip") }>
                                                    <Icon path={ mdiHelpCircle } size={ .75 } color={ primary } />
                                                </span>
                                            </span>
                                        }
                                        name="firstYearOfMedicalExperience"
                                        placeholder={ t("doctors:edit.details.form.firstYearOfMedicalExperience.placeholder") }
                                        type="number"
                                        value={ UtcIsoStringToYearString(formikProps.values.firstYearOfMedicalExperience) }
                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                            formikProps.setFieldValue("firstYearOfMedicalExperience", yearStringToUtcISOString(e.target.value))
                                        } }
                                        min="1900"
                                        max={ DateTime.now().toLocal().year }
                                    />

                                    <InputField
                                        label={
                                            <span className="icon-text">
                                                <span>{ t("doctors:edit.details.form.onlineConsultationsOverCountApproximation.label") }</span>
                                                <span className="icon" title={ t("doctors:edit.details.form.onlineConsultationsOverCountApproximation.tooltip") }>
                                                    <Icon path={ mdiHelpCircle } size={ .75 } color={ primary } />
                                                </span>
                                            </span>
                                        }
                                        name="onlineConsultationsOverCountApproximation"
                                        type="number"
                                        min="0"
                                    />
                                </div>
                            </div>

                            <div className="field">
                                <div className="field is-horizontal">
                                    <div className="field-body">
                                        <InputField
                                            label={
                                                <span className="icon-text">
                                                    <span className="icon ml-2">
                                                        <Icon path={ mdiWeatherSunny } size={ 0.75 }/>
                                                    </span>
                                                    <span>{ t("doctors:edit.details.form.ratePerMinuteInUnit.label") }</span>
                                                    <span className="icon" title={ t("doctors:edit.details.form.ratePerMinuteInUnit.tooltip") }>
                                                        <Icon path={ mdiHelpCircle } size={ .75 } color={ primary } />
                                                    </span>
                                                </span>
                                            }
                                            name="ratePerMinuteInUnit"
                                            type="number"
                                            placeholder={ t("doctors:edit.details.form.ratePerMinuteInUnit.placeholder") }
                                            min="0"
                                            step="0.01"
                                        />

                                        <InputField
                                            label={
                                                <span className="icon-text">
                                                    <span className="icon ml-2">
                                                        <Icon path={ mdiWeatherNight } size={ 0.75 }/>
                                                    </span>
                                                    <span>{ t("doctors:edit.details.form.ratePerMinuteInUnit.label") }</span>
                                                    <span className="icon" title={ t("doctors:edit.details.form.ratePerMinuteInUnit.outOfSchedule.tooltip") }>
                                                        <Icon path={ mdiHelpCircle } size={ .75 } color={ primary } />
                                                    </span>
                                                </span>
                                            }
                                            name="outOfScheduleRatePerMinuteInUnit"
                                            type="number"
                                            placeholder={ t("doctors:edit.details.form.ratePerMinuteInUnit.placeholder") }
                                            min="0"
                                            step="0.01"
                                        />

                                        <InputField
                                            label={
                                                <span className="icon-text">
                                                    <span className="icon">
                                                        <Icon path={ mdiVideo } size={ 0.75 }/>
                                                    </span>
                                                    <span>{ t("doctors:edit.details.form.ratePerMinuteInUnit.label") }</span>
                                                    <span className="icon" title={ t("doctors:edit.details.form.ratePerMinuteInUnit.video.tooltip") }>
                                                        <Icon path={ mdiHelpCircle } size={ .75 } color={ primary } />
                                                    </span>
                                                </span>
                                            }
                                            name="videoRatePerMinuteInUnit"
                                            type="number"
                                            placeholder={ t("doctors:edit.details.form.ratePerMinuteInUnit.placeholder") }
                                            min="0"
                                            step="0.01"
                                        />

                                        <InputFieldWithAddons
                                            label={
                                                <span className="icon-text">
                                                    <span>{ t("doctors:edit.details.form.payoutPercentage.label") }</span>
                                                    <span className="icon" title={ t("doctors:edit.details.form.payoutPercentage.tooltip") }>
                                                        <Icon path={ mdiHelpCircle } size={ .75 } color={ primary } />
                                                    </span>
                                                </span>
                                            }
                                            name="payoutPercentage"
                                            type="number"
                                            min="0"
                                            max="100"
                                            step="0.01"
                                            afterAddons={
                                                <button className="button is-static">
                                                    <strong>%</strong>
                                                </button>
                                            }
                                        />

                                        {/* currency field */}
                                        {/*<InputField*/}
                                        {/*    label={ t("doctors:edit.details.form.currency.label") }*/}
                                        {/*    helpMessage={*/}
                                        {/*        <Trans ns="doctors" i18nKey="edit.details.form.currency.help">*/}
                                        {/*            Pentru o lista cu toate codurile ISO-4217, accesati <a href="https://en.wikipedia.org/wiki/ISO_4217#Active_codes" rel="noreferrer" target="_blank"> acest link</a>*/}
                                        {/*        </Trans>*/}
                                        {/*    }*/}
                                        {/*    name="rateCurrency"*/}
                                        {/*    placeholder={ t("doctors:edit.details.form.currency.placeholder") }*/}
                                        {/*    onChange={ (e: ChangeEvent<HTMLInputElement>) => {*/}
                                        {/*        // limit length to 3 characters*/}
                                        {/*        if (e.target.value.length <= 3) {*/}
                                        {/*            formikProps.setFieldValue("rateCurrency", e.target.value.toUpperCase())*/}
                                        {/*        }*/}
                                        {/*    } }*/}
                                        {/*/>*/}

                                    </div> {/* field-body */}
                                </div> {/* field is-horizontal */}
                                <p className="help" > { t("doctors:edit.details.form.ratePerMinuteInUnit.helpMessage") } </p>
                            </div> {/* field */}

                            <div className="field">
                                <div className="field is-horizontal">
                                    <div className="field-body">
                                        <InputField
                                            label={ t("doctors:edit.details.form.scheduleBegin.label") }
                                            name="scheduleBegin"
                                            type="time"
                                            value={ UtcIsoStringToTimeString(formikProps.values.scheduleBegin, formikProps.values.scheduleTimeZone) }
                                            onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                                formikProps.setFieldValue("scheduleBegin", timeStringToUtcIsoString(e.target.value, formikProps.values.scheduleTimeZone))
                                            } }
                                        />
                                        <InputField
                                            label={ t("doctors:edit.details.form.scheduleEnd.label") }
                                            name="scheduleEnd"
                                            type="time"
                                            value={ UtcIsoStringToTimeString(formikProps.values.scheduleEnd, formikProps.values.scheduleTimeZone) }
                                            onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                                formikProps.setFieldValue("scheduleEnd", timeStringToUtcIsoString(e.target.value, formikProps.values.scheduleTimeZone))
                                            } }
                                        />
                                    </div>
                                </div>
                                <p className="help" >{ t("doctors:edit.details.form.scheduleTimeZone.help", { timeZone: formikProps.values.scheduleTimeZone }) }</p>
                            </div>

                            <SelectField
                                label={ t("doctors:edit.details.form.scheduleTimeZone.label") }
                                fieldSize="expanded"
                                name="scheduleTimeZone"
                            >
                                {
                                    rawTimeZones.map((entry) => {
                                        return <option key={ entry.name } value={ entry.name }>{ formatTimeZoneName(entry.rawFormat, entry.name) }</option>
                                    })
                                }
                            </SelectField>

                            <CheckBoxSwitchField
                                label={ t("doctors:edit.details.form.acceptsOutsideOfSchedule.label") }
                                name="acceptsOutsideOfSchedule"
                            />

                            <CheckBoxSwitchField
                                label={ t("doctors:edit.details.form.acceptsFreeFirstCall.label") }
                                name="acceptsFreeFirstCalls"
                            />

                            <CheckBoxSwitchField
                                label={ t("doctors:edit.details.form.isResident.label") }
                                name="isResident"
                            />

                            {/*@ts-ignore: I guess types are not updated for react@18+*/}
                            <FieldArray name="spokenLanguages">
                                {
                                    ((helpers) => {
                                        return <>
                                            <Field name="spokenLanguages" >
                                                {
                                                    ((fieldProps: FieldProps<string[]>) => {
                                                        return <>
                                                            <LanguageSelect
                                                                fieldProps={ fieldProps }
                                                                onAddCallback={ (value) => {
                                                                    helpers.push(value)
                                                                } }
                                                                onRemoveCallback={ (index) => {
                                                                    helpers.remove(index)
                                                                } }
                                                            />
                                                        </>
                                                    })
                                                }
                                            </Field>
                                        </>
                                    })
                                }
                            </FieldArray>

                            <TextAreaField
                                name="internalNotes"
                                placeholder={ t("doctors:edit.details.form.internalNotes.placeholder") }
                                label={ t("doctors:edit.details.form.internalNotes.label") }
                            />

                            {/* form actions */}
                            <div className={ `field is-grouped ${ props.type === "new" ? "is-justify-content-space-between" : "is-justify-content-end"}` }>
                                {
                                    props.type === "new"
                                    && <>
                                        <button
                                            className="button"
                                            type="button"
                                            onClick={ props.onClose }
                                        >
                                            { t("doctors:edit.details.form.buttons.close") }
                                        </button>
                                    </>
                                }
                                <button
                                    className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                    type="submit"
                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                >{ t("doctors:edit.details.form.buttons.submit") }</button>
                            </div>

                        </Form>
                    </>
                })
            }
        </Formik>
    </>
}

export default Details
