import React, { FunctionComponent, useEffect, useState } from "react"
import { Link, useLocation, matchPath, useHistory } from "react-router-dom"
import { mdiArrowCollapseLeft, mdiContentCopy, mdiLogout, mdiMenu, mdiShare, mdiTranslate } from "@mdi/js"
import Icon from "@mdi/react"
import { useTranslation } from "react-i18next"
import { logout } from "service/http/accounts"
import { primary } from "assets/colors"
import ChangeDisplayLanguage from "components/common/Navbar/ChangeDisplayLanguage"
import AgathaLogo from "assets/agatha_logo_purple.svg"
import "components/common/Navbar/Navbar.scss"
import JWTTokenStorageInstance from "../../../service/JWTTokenStorage"
import { notify } from "../../../utils/notifications"

export interface INavbarLink {
    path: string
    title: string
    disabled: boolean
    hasMissingTranslations?: boolean
    links?: INavbarLink[]
}

export interface INavbarEntry {
    path?: string
    title: string
    icon: string
    disabled: boolean
    hasMissingTranslations?: boolean
    links?: INavbarLink[]
}

interface Props {
    entries: INavbarEntry[]
}

const Navbar: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "notification" ])

    const [ isMenuOpen, setIsMenuOpen ] = useState(false);
    const [ isMobile, setIsMobile ] = useState<boolean>(window.innerWidth < 1024)
    const [ showInviteDoctorModal, setShowInviteDoctorModal ] = useState<boolean>(false)
    const [ doctorInviteLink ] = useState<string>(`https://agatha.health/join?from=${JWTTokenStorageInstance.getDecodedAccessToken()?.accountId}`)

    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        window.addEventListener("resize", updateMedia)

        return () => window.removeEventListener("resize", updateMedia)
    }, [])

    const updateMedia = () => {
        setIsMobile(window.innerWidth < 1024)
    }

    const handleCopyDoctorInviteLink = async (url: string) => {
        try {
            await navigator.clipboard.writeText(url)
            notify("success", "", t("notification:onDoctorInviteLinkCopied.message"))
        }
        catch (e) {
            // well
        }
    }

    const doctorInviteModal = () => showInviteDoctorModal && (
        <div className="modal is-active">
            <div className="modal-background" onClick={ () => setShowInviteDoctorModal(false) } />
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">
                            { t("common:navbar.inviteDoctor.modal.title") }
                        </p>

                        <p className="content has-text-centered">
                            { t("common:navbar.inviteDoctor.modal.message") }
                        </p>

                        <div className="field has-addons has-addons-centered">
                            <p className="control is-expanded">
                                <input type="text" className="input" value={ doctorInviteLink } readOnly/>
                            </p>
                            <p className="control">
                                <button
                                    className="button is-primary"
                                    onClick={ () => {
                                        setShowInviteDoctorModal(false)
                                        handleCopyDoctorInviteLink(doctorInviteLink)
                                    }}
                                >
                                    <span className="icon">
                                        <Icon path={ mdiContentCopy } />
                                    </span>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const generateSubLinks = (subLinks: INavbarLink[], level: number = 1) => {
        if (level < 6 && subLinks.find(it => !it.disabled)) {
            return (
                <ul key={ level } className={ level === 1 ? "menu-list" : "" }>
                    {
                        subLinks.map((entry) => {
                            if (entry.disabled) return null

                            return (
                                <React.Fragment key={ entry.path }>
                                    <li>
                                        <Link className={ !!matchPath(location.pathname, { path: entry.path, exact: true }) ? "is-active" : "" } to={ entry.path }>
                                            {
                                                !entry.hasMissingTranslations
                                                ? entry.title
                                                : <>
                                                    <span className="icon-text">
                                                        <span>{ entry.title }</span>
                                                        <span className="icon is-danger">
                                                            <Icon path={ mdiTranslate } size={ 0.75 } />
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                        </Link>
                                        {
                                            entry.links &&
                                            generateSubLinks(entry.links, ++level)
                                        }
                                    </li>
                                </React.Fragment>
                            )
                        })
                    }
                </ul>
            )
        }
    }

    if (!isMobile && !isMenuOpen)
        return <>
            <div className="collapsed-navbar">
                <div className="menu-toggle">
                    <button
                        className="button is-circular"
                        onClick={ () => setIsMenuOpen(true) }
                    >
                        <span className="icon is-small">
                            <Icon path={ mdiMenu } color={ primary } size={ 1.2 }/>
                        </span>
                    </button>
                </div>
            </div>
        </>

    return <>
        {/*invite doctor modal*/}
        { doctorInviteModal() }

        <div className="navbarWrapper">
            <div className="navbarArea">
                <div className="navbarLogoArea">
                    {
                        !isMobile
                        && <>
                            <span
                                className="menu-toggle is-clickable"
                                onClick={ () => setIsMenuOpen(false) }
                            >
                                <Icon path={ mdiArrowCollapseLeft  } color={ primary } size={ 1.2 }/>
                            </span>
                        </>
                    }
                    <img className="logo-white" src={ AgathaLogo } alt="Agatha Logo" />
                </div>


                <div className="columns">
                    <div className="column mx-4">

                        {/*invite doctor*/}
                        <span className="is-flex is-justify-content-start mb-2">
                            <button
                                className="button is-primary is-success"
                                onClick={ () => setShowInviteDoctorModal(true) }
                            >
                                <span className="icon-text">
                                    <span className="icon is-small mr-2">
                                        <Icon path={ mdiShare } />
                                    </span>
                                    { t("common:navbar.inviteDoctor.button") }
                                </span>
                            </button>
                        </span>

                        {/*change language and logout*/}
                        <span className="is-flex is-justify-content-space-between">
                            <ChangeDisplayLanguage />
                            <div className="buttons">
                                <button className="button is-danger" onClick={ () => { logout(history) } }>
                                    <span className="icon-text">
                                        <span className="icon is-small mr-2">
                                            <Icon path={ mdiLogout } />
                                        </span>
                                        { t("common:navbar.logoutButton") }
                                    </span>
                                </button>
                            </div>
                        </span>
                    </div>
                </div>

                <div className="hamburgerMenuIcon is-clickable" onClick={ () => { setIsMenuOpen(!isMenuOpen) } }>
                    <Icon path={ mdiMenu }
                          size={ 1.5 }
                          color={ primary }
                    />
                </div>

                <div className={ `navbarLinksArea ${isMenuOpen ? "navbarLinksVisible" : "navbarLinksHidden"}` }>
                    <aside className="menu">
                        {
                            props.entries.map((entry, index) => {
                                if (entry.disabled) return null

                                // if it has a path it means that it should be a button
                                if (entry.path)
                                    return (
                                        <ul key={ index } className="menu-list">
                                            <li>
                                                <Link className={ !!matchPath(location.pathname, { path: entry.path, exact: true }) ? "is-active" : "" } to={ entry.path }>
                                            <span className="icon-text">
                                                <span className="icon">
                                                    <Icon path={ entry.icon } size={ 1 } />
                                                </span>
                                                <span>{ entry.title }</span>
                                                {
                                                    entry.hasMissingTranslations
                                                    && <>
                                                        <span className="icon is-danger">
                                                            <Icon path={ mdiTranslate } size={ 0.75 } />
                                                        </span>
                                                    </>
                                                }
                                            </span>
                                                </Link>
                                                {
                                                    entry.links &&
                                                    generateSubLinks(entry.links)
                                                }
                                            </li>
                                        </ul>
                                    )

                                // no path means it's a label
                                return (
                                    <React.Fragment key={ index }>
                                        <p className="menu-label">
                                            <span className="icon-text">
                                                <span className="icon">
                                                    <Icon path={ entry.icon } size={ 0.75 } />
                                                </span>
                                                { entry.title }
                                            </span>
                                        </p>
                                        {
                                            entry.links
                                            && generateSubLinks(entry.links, 1)
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </aside>
                </div>
            </div>
        </div>
    </>
}

export default Navbar
