import { FunctionComponent } from "react"
import { IReport } from "service/http/reports"
import { useTranslation } from "react-i18next"
import { notify } from "utils/notifications"

interface Props {
    info: IReport
}

const Contact: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "reports", "notification" ])

    const handleCopyToClipboard = async (value: string, type: "phone" | "email") => {
        try {
            await navigator.clipboard.writeText(value)

            notify(
                "success",
                t("notification:onCopiedToClipboard.success.title"),
                t("notification:onCopiedToClipboard.success.message", {
                    value: type === "phone"
                           ? t("notification:onCopiedToClipboard.types.phoneNumber")
                           : t("notification:onCopiedToClipboard.types.email")
                })
            )
        } catch(e) {
            alert(value)
        }
    }

    return <>
        {
            props.info.phoneNumber
            && (
                <div className="field">
                    <div className="control">
                        <label className="label">{ t("reports:view.contact.labels.phoneNumber") }</label>
                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <input className="input" readOnly={ true } value={ props.info.phoneNumber } />
                            </div>
                            <div className="control">
                                <button
                                    className="button"
                                    onClick={ () => {
                                        handleCopyToClipboard(props.info.phoneNumber!, "phone").then()
                                    } }
                                >
                                    { t("reports:view.contact.buttons.copy") }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        <div className="field">
            <div className="control">
                <label className="label">{ t("reports:view.contact.labels.email") }</label>
                <div className="field has-addons">
                    <div className="control is-expanded">
                        <input className="input" readOnly={ true } value={ props.info.client.email } />
                    </div>
                    <div className="control">
                        <button
                            className="button"
                            onClick={ () => {
                                handleCopyToClipboard(props.info.client.email, "email").then()
                            } }
                        >
                            { t("reports:view.contact.buttons.copy") }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Contact
