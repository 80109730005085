import { ChangeEvent, FunctionComponent, useRef, useState } from "react"
import { Form, Formik } from "formik"
import { DateTime } from "luxon"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { isValidDate } from "utils/helpers"
import InputField from "components/common/Form/InputField"
import * as api from "service/http/api"
import zipcelx, {  ZipCelXConfig, ZipCelXRow } from "zipcelx"

interface IFormFields {
    from: string
    to: string
    [key: string]: any
}

const DoctorRevenueSummary: FunctionComponent = () => {
    const { t } = useTranslation([ "stats", "common" ])

    const [ initialValues ] = useState<IFormFields>({
        from: DateTime.now().toLocal().toISO(),
        to: DateTime.now().plus({ days: 1 }).toLocal().toISO()
    })

    const validationSchemaRef = useRef(
        yup.object<IFormFields>().shape({
            from: yup
                .string()
                .test("stringIsDate", t("common:form.dateIsInvalid"), isValidDate)
                .required(t("common:form.fieldIsRequired")),
            to: yup
                .string()
                .test("stringIsDate", t("common:form.dateIsInvalid"), isValidDate)
                .test("afterStartDate", t("stats:reportGeneration.doctorRevenueSummary.form.to.error"), (value, context) => {
                    if (value && context.parent.startsAt) {
                        const start = DateTime.fromISO(context.parent.startsAt)
                        const end = DateTime.fromISO(value)

                        return end > start
                    }

                    return true
                })
                .required(t("common:form.fieldIsRequired"))
        })
    )


    const handleSubmit = async (values: IFormFields) => {
        try {
            let from = DateTime.fromISO(values.from)
            let to = DateTime.fromISO(values.to)
            const response = await api.stats.getDoctorRevenueSummary(from.toUTC().toISO(), to.toUTC().toISO())
            // generate that xlsx
            const config: ZipCelXConfig = {
                filename: `Revenue Summary ${from.toLocal().toFormat("yyyy-MM-dd")} ${to.toLocal().toFormat("yyyy-MM-dd")}`,
                sheet: {
                    data: [
                        [
                            {
                                value: "Doctor",
                                type: "string"
                            },
                            {
                                value: "Distribuitor",
                                type: "string"
                            },
                            {
                                value: "Apeluri",
                                type: "string"
                            },
                            {
                                value: "Venituri totale",
                                type: "string"
                            },
                            {
                                value: "Taxe Stripe",
                                type: "string"
                            },
                            {
                                value: "Venituri doctor",
                                type: "string"
                            },
                            {
                                value: "Venituri manager",
                                type: "string"
                            },
                            {
                                value: "Profit Agatha",
                                type: "string"
                            },
                        ],
                        ...response.map(it => [
                            {
                                value: `${it.doctor.name} (${it.doctor.primaryEmail})`,
                                type: 'string'
                            },
                            {
                                value: it.manager ? `${it.manager.name} (${it.manager.primaryEmail})` : "-",
                                type: "string"
                            },
                            {
                                value: it.totalCalls,
                                type: "number"
                            },
                            {
                                value: it.totalRevenue,
                                type: "number"
                            },
                            {
                                value: it.stripeFee,
                                type: "number"
                            },
                            {
                                value: it.doctorRevenue,
                                type: "number"
                            },
                            {
                                value: it.managerRevenue,
                                type: "number"
                            },
                            {
                                value: it.agathaProfit,
                                type: "number"
                            }
                        ] as ZipCelXRow),
                        // empty row
                        [],
                        // totals
                        [
                            {
                                value: "Doctori",
                                type: "string"
                            },
                            {
                                value: "Distribuitori",
                                type: "string"
                            },
                            {
                                value: "Apeluri",
                                type: "string"
                            },
                            {
                                value: "Venituri totale",
                                type: "string"
                            },
                            {
                                value: "Taxe totale Stripe",
                                type: "string"
                            },
                            {
                                value: "Venituri totale doctori",
                                type: "string"
                            },
                            {
                                value: "Venituri totale distribuitori",
                                type: "string"
                            },
                            {
                                value: "Profit total Agatha",
                                type: "string"
                            },
                        ],
                        [
                            {
                                value: response.length,
                                type: 'string'
                            },
                            {
                                value: response.filter(it => it.manager !== null).length,
                                type: "string"
                            },
                            {
                                value: response.reduce((acc, entry) => acc + entry.totalCalls, 0),
                                type: "number"
                            },
                            {
                                value: response.reduce((acc, entry) => acc + entry.totalRevenue, 0),
                                type: "number"
                            },
                            {
                                value: response.reduce((acc, entry) => acc + entry.stripeFee, 0),
                                type: "number"
                            },
                            {
                                value: response.reduce((acc, entry) => acc + entry.doctorRevenue, 0),
                                type: "number"
                            },
                            {
                                value: response.reduce((acc, entry) => acc + entry.managerRevenue, 0),
                                type: "number"
                            },
                            {
                                value: response.reduce((acc, entry) => acc + entry.agathaProfit, 0),
                                type: "number"
                            }
                        ]
                    ]
                }
            }

            await zipcelx(config)
        }
        catch (e) {
            // ok
        }
    }

    return <>
        <p className="title is-5">{ t("stats:reportGeneration.doctorRevenueSummary.title") }</p>

        <Formik
            initialValues={ initialValues }
            validationSchema={ validationSchemaRef.current }
            onSubmit={ handleSubmit }
        >
            {
                ((formikProps) => <>
                        <Form>
                            <div className="field is-horizontal">
                                <div className="field-body is-flex is-align-items-end">
                                    <InputField
                                        label={ t("stats:reportGeneration.doctorRevenueSummary.form.from.label") }
                                        name="from"
                                        type="date"
                                        value={ DateTime.fromISO(formikProps.values.from).toFormat("yyyy-MM-dd") }
                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                            const newDate = DateTime.fromFormat(e.target.value, "yyyy-MM-dd").toLocal().toISO()
                                            formikProps.setFieldValue("from", newDate)
                                        } }
                                    />

                                    <InputField
                                        label={ t("stats:reportGeneration.doctorRevenueSummary.form.to.label") }
                                        name="to"
                                        type="date"
                                        min={ DateTime.fromISO(formikProps.values.from).toFormat("yyyy-MM-dd") }
                                        value={ DateTime.fromISO(formikProps.values.to).toFormat("yyyy-MM-dd") }
                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                            const newDate = DateTime.fromFormat(e.target.value, "yyyy-MM-dd").toLocal().toISO()
                                            formikProps.setFieldValue("to", newDate)
                                        } }
                                    />

                                    <button className="button is-primary">
                                        { t("stats:reportGeneration.doctorRevenueSummary.form.generateButton") }
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </>
                )
            }
        </Formik>
    </>
}

export default DoctorRevenueSummary
