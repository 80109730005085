import { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { mdiFilter } from "@mdi/js"
import Icon from "@mdi/react"
import { Form, Formik, FormikProps } from "formik"
import * as yup from "yup"
import _ from "lodash"

import SelectField from "components/common/Form/SelectField"
import * as api from "service/http/api"
import { enumToNumberValuesArray } from "utils/helpers"

interface IFormField {
    orderBy: api.campaigns.ECampaignFilterOrderBy
}

interface Props {
    filters: api.campaigns.ICampaignFilters
    onUpdate: (filters: api.campaigns.ICampaignFilters) => void
}

const Filters: FunctionComponent<Props> = (props) => {
    //eslint-disable-next-line
    const { t } = useTranslation([ "common", "campaigns" ])

    const [ isOpen, setIsOpen ] = useState<boolean>(false)
    const [ initialValues, setInitialValues ] = useState<IFormField>({
        orderBy: props.filters.orderBy
    })

    useEffect(() => {
        // in case the filters are updated manually in the url
        setInitialValues({
            orderBy: props.filters.orderBy
        })
    }, [ props.filters ])

    const handleSubmit = (values: IFormField) => {
        props.onUpdate({ orderBy: values.orderBy })
        setIsOpen(false)
    }

    return <>
        <div className={ `dropdown is-right ${isOpen ? 'is-active' : ""}` }>
            <div className="dropdown-trigger">
                <div className="buttons is-centered">
                    <button
                        className="button is-circular"
                        onClick={ () => { setIsOpen(!isOpen) } }
                    >
                    <span className="icon is-small">
                        <Icon path={ mdiFilter } size={ 1 } />
                    </span>
                    </button>
                </div>
            </div>
            <div className="dropdown-menu filters" role="menu">
                <div className="dropdown-content">
                    <Formik
                        enableReinitialize={ true }
                        initialValues={ initialValues }
                        validationSchema={
                            yup.object().shape({
                                orderBy: yup
                                    .number()
                                    .required()
                            })
                        }
                        onSubmit={ handleSubmit }
                    >
                        {
                            ((formikProps: FormikProps<IFormField>) => {
                                return <>
                                    <Form>
                                        <div className="dropdown-item">
                                            <SelectField
                                                label={ t("campaigns:list.filters.orderBy.label") }
                                                name="orderBy"
                                                fieldSize="expanded"
                                                onChange={ (e: ChangeEvent<HTMLSelectElement>) => formikProps.setFieldValue("orderBy", _.toNumber(e.target.value)) }
                                            >
                                                {
                                                    enumToNumberValuesArray(api.campaigns.ECampaignFilterOrderBy).map(value => {
                                                        return <option key={ value } value={ value }>
                                                            { t("common:campaignFilterOrderBy.type", { context: value }) }
                                                        </option>
                                                    })
                                                }
                                            </SelectField>
                                        </div> {/* dropdown-item */}

                                        <div className="dropdown-item">
                                            <div className="field is-grouped is-justify-content-end">
                                                <button
                                                    className="button is-primary"
                                                    type="submit"
                                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                                >
                                                    { t("campaigns:list.filters.buttons.apply") }
                                                </button>
                                            </div>
                                        </div> {/* dropdown-item */}
                                    </Form>
                                </>
                            })
                        }
                    </Formik>
                </div> {/* dropdown-content */}
            </div> {/* dropdown-menu */}
        </div> {/* dropdown */}
    </>
}

export default Filters
