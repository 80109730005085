import React, { FunctionComponent, useEffect, useState } from "react"
import { placeholderImage, placeholderProfilePic } from "assets/asstes"
import { isValidImageUrl } from "utils/helpers"

interface FileProps {
    file: File
    url?: never
}

interface UrlProps {
    file?: never
    url: string
}

type Props = (FileProps | UrlProps) & {
    placeholderType: "image" | "account"
    imgAlt: string
    shape: "square" | "circle"
    size: "32x32" | "64x64" | "128x128" | "16by9" | "auto"
    maxHeight?: number,
    maxWidth?: number
}

const placeholders = {
    account: placeholderProfilePic,
    image: placeholderImage
}

const ImagePreview: FunctionComponent<Props> = (props ) => {
    const [ isUrlValid, setIsUrlValid ] = useState<boolean>(false)
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ imageUrl, setImageUrl ] = useState<string>()

    useEffect(() => {
        // release the object
        if (imageUrl) URL.revokeObjectURL(imageUrl)

        if (props.file) {
            setImageUrl(URL.createObjectURL(props.file))
            setIsUrlValid(true)
        } else if (props.url) {
            setIsLoading(true)

            const validate = async () => {
                const isValid = await isValidImageUrl(props.url)

                setImageUrl(props.url)
                setIsUrlValid(isValid)
            }

            validate().then(() => {
                setIsLoading(false)
            })
        } else {
            setIsUrlValid(false)
        }

        return () => {
            // release the object
            if (imageUrl) URL.revokeObjectURL(imageUrl)
        }

        // we don't care about imgUrl here
        // eslint-disable-next-line
    }, [ props.url, props.file ])

    const sizeClass = () => {
        switch (props.size) {
            case "16by9":
                return "is-16by9 is-fullwidth"

            case "auto":
                return "is-fullheight"

            case null:
                return "is-128x128"

            default:
                return `is-${props.size}`
        }
    }

    return<>
        <div className="is-flex is-justify-content-center">
            <figure className={ `image no-padding-top ${sizeClass()}` }>
                {
                    !isLoading
                    && <img
                        className={ props.shape === "circle" ? "is-rounded" : "" }
                        src={
                            isUrlValid
                            ? imageUrl
                            : placeholders[props.placeholderType]
                        }
                        alt={ props.imgAlt }
                        style={{
                            maxHeight: props.maxHeight,
                            maxWidth: props.maxWidth,
                            objectFit: "cover"
                        }}
                    />
                }
            </figure>
        </div>
    </>
}

export default ImagePreview
