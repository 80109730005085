// getList
import { IPagedRequestParams, IPagedRequestResponse } from "service/http/pagination"
import axios from "axios"
import { EAccountState } from "service/http/accounts"

const basePath = "/administration/managers"

export interface IManager {
    id: string
    name: string
    primaryEmail: string
    phoneNumber: string
    accountState: EAccountState
    creatorsPayoutPercentage?: number
}

interface IManagerFilters {}

// list
interface IGetManagersListResponse extends IPagedRequestResponse<IManager> {}

export const getManagersList = async (params: IPagedRequestParams<IManagerFilters>): Promise<IGetManagersListResponse> => {
    const response = await axios.get<IGetManagersListResponse>(`${basePath}`, {
        params: params
    })
    return response.data
}

// all
export interface TGetAllManagerResponse extends IPagedRequestResponse<IManager> {}

export const getAllManagers = async (): Promise<TGetAllManagerResponse> => {
    const response = await axios.get<TGetAllManagerResponse>(`${basePath}?valid-accounts-only=true`)
    return response.data
}

// get
interface IGetManagerResponse extends IManager {}

export const getManager = async (managerId: string): Promise<IGetManagerResponse> => {
    const response = await axios.get<IGetManagerResponse>(`${basePath}/${managerId}`)
    return response.data
}

// create
export interface ICreateManagerParams extends Omit<IManager, "id" | "accountState"> {}

interface ICreateManagerResponse {
    id: string
}

export const createManager = async (params: ICreateManagerParams): Promise<ICreateManagerResponse> => {
    const response = await axios.post<ICreateManagerResponse>(`${basePath}`, params)
    return response.data
}

// update
export interface IUpdateManagerParams extends Partial<Omit<IManager, "id" | "accountState">> {}

interface IUpdateManagerResponse extends IManager {}

export const updateManager = async (managerId: string, params: IUpdateManagerParams): Promise<IUpdateManagerResponse> => {
    const response = await axios.patch<IUpdateManagerResponse>(`${basePath}/${managerId}`, params)
    return response.data
}
