import { FunctionComponent } from "react"
import { mdiCheck, mdiClose, mdiPencil } from "@mdi/js"
import Icon from "@mdi/react"
import { useTranslation } from "react-i18next"

import { IDisplayLanguage } from "service/http/localization"
import languages from "utils/languages"

interface Props {
    tableRowIndex: number
    info: IDisplayLanguage
    onEdit: () => void
}

const LocalizationTableRow: FunctionComponent<Props> = (props) => {
    const { i18n } = useTranslation()

    return <>
        <tr>
            <td>{ props.tableRowIndex }</td>
            <td>{ languages[props.info.isoCode][i18n.resolvedLanguage] }</td>
            <td>
                <Icon
                    path={ props.info.available ? mdiCheck : mdiClose }
                    color={ props.info.available ? "green" : "red" }
                    size={ 1 }
                />
            </td>
            <td>
                {
                    !props.info.available
                    && <div className="buttons is-centered">
                        <button className="button is-circular" onClick={ props.onEdit }>
                            <span className="icon is-small">
                                <Icon path={ mdiPencil } size={ 1 } />
                            </span>
                        </button>
                    </div>
                }
            </td>
        </tr>
    </>
}

export default LocalizationTableRow
