import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from "formik"
import * as yup from "yup"
import _ from "lodash"

import languages from "utils/languages"
import * as api from "service/http/api"
import { isValidImageFile, stringArrayToString } from "utils/helpers"
import { notify } from "utils/notifications"
import { useSelector } from "react-redux"
import { TRootStore } from "state/Store"
import { ERequestState } from "state/reducers/RequestTypes"
import { SUPPORTED_IMAGE_FORMATS } from "service/config/config"

import FormikDirtyListener from "components/common/Form/FormikDirtyListener"
import InputField from "components/common/Form/InputField"
import ImagePreview from "components/common/Form/ImagePreview"
import Loading from "components/common/Loading/Loading"
import ImageFileInputField from "components/common/Form/ImageFileInputField"

import "components/AdminDashboard/Doctors/Specialties/Manage/Form.scss"

interface IFormFields {
    icon: File | null
    translations: Record<string, string>
    [key: string]: any
}

interface NewProps {
    type: "new"
    info?: never
    onCreate: (specialty: api.doctors.ICreateDoctorSpecialtyResponse) => void
    onUpdate?: never
}

interface ExistingProps {
    type: "existing"
    info: api.doctors.IDoctorSpecialty
    onCreate?: never
    onUpdate: (specialty: api.doctors.IUpdateDoctorSpecialtyResponse) => void
}

type Props = (NewProps | ExistingProps) & {
    setFormIsDirty: (value: boolean) => void
    onClose: (checkDirty?: boolean) => void
}

const Form: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "common", "doctors", "notification" ])

    const displayLanguages = useSelector((state: TRootStore) => state.displayLanguages)

    const [ specialtyPicKey, setSpecialtyPicKey ] = useState<number>(Date.now())

    const [ initialValues, setInitialValues ] = useState<IFormFields>({
        icon: null,
        translations: props.type === "existing" ? props.info.translations : {} as Record<string, string>
    })

    const validationSchemaRef = useRef(yup.object<IFormFields>().shape({
        icon: yup
            .mixed()
            .test("isFileTypeValid", t("common:form.file.image.error"),
                value => isValidImageFile(value)
            ),
        translations: yup.lazy(obj =>
            yup.object(
                _.mapValues(obj, (value, key) => {
                    // available
                    const isAvailable = displayLanguages.entities.find((v) => v.isoCode === key)?.available
                    if (isAvailable)
                        return yup.string().trim().required(t("common:form.fieldIsRequired"))

                    // unavailable ones
                    return yup.string().trim().notRequired()
                })
            )
        )
    }))

    // update initial values on details updated successfully
    useEffect(() => {
        if (props.type === "existing")
            setInitialValues({
                icon: null,
                translations: props.info.translations
            })

        // props.type is not relevant as a dependency
        // eslint-disable-next-line
    }, [ props.info ])

    const handleSubmit = async (values: IFormFields, formikHelpers: FormikHelpers<IFormFields>) => {
        const specialtyName = values.translations[i18n.resolvedLanguage] ?? values.translations["en"]
        values = validationSchemaRef.current.cast(values) as IFormFields

        if (props.type === "new") {
            try {
                const response = await api.doctors.createDoctorSpecialty({
                    ...values,
                    icon: values.icon ?? undefined
                })

                props.onCreate(response)

                // close modal
                props.onClose(false)

                notify(
                    "success",
                    t("notification:onDoctorSpecialtyCreate.success.title"),
                    t("notification:onDoctorSpecialtyCreate.success.message", {
                        specialty: specialtyName
                    })
                )
            } catch (e) {
                notify(
                    "danger",
                    t("notification:onDoctorSpecialtyCreate.fail.title"),
                    t("notification:onDoctorSpecialtyCreate.fail.message", {
                        specialty: specialtyName
                    })
                )
            }
        } else {
            let params: api.doctors.IUpdateDoctorSpecialtyParams = {}

            Object.keys(values).forEach((key) => {
                if (values[key] !== initialValues[key]) {
                    params[key] = values[key]!
                }
            })

            try {
                const response = await api.doctors.updateDoctorSpecialty(props.info.id, params)

                // update the doctors table
                props.onUpdate(response)

                // close modal
                props.onClose(false)

                // notify
                notify(
                    "success",
                    t("notification:onDoctorSpecialtyUpdate.success.title"),
                    t("notification:onDoctorSpecialtyUpdate.success.message", {
                        specialty: specialtyName
                    })
                )
            } catch (e) {
                notify(
                    "danger",
                    t("notification:onDoctorSpecialtyUpdate.fail.title"),
                    t("notification:onDoctorSpecialtyUpdate.fail.message", {
                        specialty: specialtyName
                    })
                )
            }
        }
    }

    if (displayLanguages.state === ERequestState.Loading)
        return <Loading />

    return <>
        <Formik
            enableReinitialize={ true }
            initialValues={ initialValues }
            validationSchema={ validationSchemaRef.current }
            onSubmit={ handleSubmit }
        >
            {
                ((formikProps: FormikProps<IFormFields>) => {
                    return <>
                        <FormikForm>
                            <FormikDirtyListener onChange={ props.setFormIsDirty } />

                            <div className="field">
                                <div className="control">
                                    {
                                        (() => {
                                            if (!!formikProps.values.icon && !formikProps.errors.icon)
                                                return <>
                                                    <ImagePreview
                                                        placeholderType="image"
                                                        file={ formikProps.values.icon }
                                                        imgAlt="profile-profilePicture"
                                                        size="128x128"
                                                        shape="circle"
                                                    />
                                                </>

                                            return <>
                                                <ImagePreview
                                                    placeholderType="image"
                                                    url={ props.type === "existing" ? props.info.icon ?? "" : "" }
                                                    imgAlt="profile-profilePicture"
                                                    size="128x128"
                                                    shape="circle"
                                                />
                                            </>
                                        })()
                                    }
                                </div>
                            </div>

                            {/*upload profile pic*/}
                            <ImageFileInputField
                                resetKey={ specialtyPicKey } // used for resetting the input
                                label={ t("common:form.file.image.labels.specialtyIcon") }
                                name="icon"
                                helpMessage={ t("common:form.file.image.help", { fileTypes: stringArrayToString(SUPPORTED_IMAGE_FORMATS) }) }
                                crop={ true }
                                cropAspectRatio={ 1 }
                                onSaveFile={ (file) => formikProps.setFieldValue("icon", file) }
                                onRemoveFile={ () => {
                                    setSpecialtyPicKey(Date.now())
                                    formikProps.setFieldValue("icon", null)
                                } }
                            />

                            <hr />

                            <p className="subtitle">{ t("doctors:specialties.edit.sections.translations") }</p>

                            <div className="translationsContainer">
                                {
                                    displayLanguages.entities.map((language) => {
                                        return (
                                            <div key={ language.isoCode } className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">
                                                        { languages[language.isoCode][i18n.resolvedLanguage] }
                                                    </label>
                                                </div>
                                                <div className="field-body">
                                                    <InputField
                                                        name={ `translations[${language.isoCode}]` }
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <hr />

                            {/* form actions */}
                            <div className="field is-grouped is-justify-content-space-between">
                                <button className="button" type="button" onClick={ () => { props.onClose() } }>{ t("doctors:edit.details.form.buttons.close") }</button>
                                <button
                                    className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                    type="submit"
                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                >{ t("doctors:edit.details.form.buttons.submit") }</button>
                            </div>
                        </FormikForm>
                    </>
                })
            }
        </Formik>
    </>
}

export default Form
