import { FunctionComponent } from "react"

import { isAuthenticated } from "utils/auth"
import { Redirect } from "react-router-dom"

interface Props {
    authRequired: boolean
    redirectTo: string
}

const Authenticated: FunctionComponent<Props> = (props) => {
    if (props.authRequired) {
        if (isAuthenticated())
            return <>
                { props.children }
            </>

        return <Redirect to={ props.redirectTo } />
    }

    if (!isAuthenticated())
        return <>
            { props.children }
        </>

    return <Redirect to={ props.redirectTo } />
}

export default Authenticated
