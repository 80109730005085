import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

interface Props {
    currentPageSize: number
    onChangePage: (pageSize: number) => void
    isLoading: boolean
}

const pageSizes = [ 10, 25, 50, 100 ]

const PageSizeSelect: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common" ])

    return <>
        <div className="field">
            <label className="label">{ t("common:pageSize") }</label>
            <div className="control">
                <div className={ "select " + (props.isLoading ? "is-loading" : "") }>
                    <select
                        name="pageSize"
                        value={ props.currentPageSize }
                        disabled={ props.isLoading }
                        onChange={ (e) => {
                            props.onChangePage(parseInt(e.target.value, 10))
                        } }
                    >
                        {
                            pageSizes.map((value) => {
                                return <option key={ value } value={ value }>{ value }</option>
                            })
                        }
                    </select>
                </div>
            </div>
        </div>
    </>
}

export default PageSizeSelect
