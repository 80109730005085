import { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import Icon from "@mdi/react"
import { mdiFilter } from "@mdi/js"
import { useTranslation } from "react-i18next"
import { Form, Formik, FormikProps } from "formik"
import * as yup from "yup"
import _ from "lodash"

import { EMarketingNotificationState, INotificationFilters } from "service/http/notifications"
import CheckBoxSwitchField from "components/common/Form/CheckBoxSwitchField"
import SelectField from "components/common/Form/SelectField"
import { enumToNumberValuesArray } from "utils/helpers"

interface IFormField {
    showState: boolean
    state: EMarketingNotificationState
}

interface Props {
    filters: INotificationFilters
    onUpdate: (filters: INotificationFilters) => void
    onClear: () => void
}

const Filters: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "notification" ])

    const [ isOpen, setIsOpen ] = useState<boolean>(false)
    const [ initialValues, setInitialValues ] = useState<IFormField>({
        showState: !!props.filters.state,
        state: props.filters.state ?? EMarketingNotificationState.ToBeDelivered
    })

    useEffect(() => {
        // in case the filters are updated manually in the url
        setInitialValues({
            showState: !!props.filters.state,
            state: props.filters.state ?? EMarketingNotificationState.ToBeDelivered
        })
    }, [ props.filters ])

    const handleSubmit = (values: IFormField) => {
        let filters: INotificationFilters = {}

        // @NOTE: we can do this for multiple filters in the future
        if (values.showState) {
            filters.state = values.state
        }

        props.onUpdate(filters)
        setIsOpen(false)
    }

    const handleClear = () => {
        props.onClear()
        setIsOpen(false)
    }

    return <>
        <div className={ `dropdown is-right ${isOpen ? 'is-active' : ""}` }>
            <div className="dropdown-trigger">
                <div className="buttons is-centered">
                    <button
                        className="button is-circular"
                        onClick={ () => { setIsOpen(!isOpen) } }
                    >
                    <span className="icon is-small">
                        <Icon path={ mdiFilter } size={ 1 } />
                    </span>
                    </button>
                </div>
            </div>
            <div className="dropdown-menu filters" role="menu">
                <div className="dropdown-content">
                    <Formik
                        enableReinitialize={ true }
                        initialValues={ initialValues }
                        validationSchema={
                            yup.object().shape({
                                showState: yup.boolean(),
                                state: yup
                                    .number()
                                    .when("showState", {
                                        is: true,
                                        then: yup.number().required(t("common:form.fieldIsRequired"))
                                    })
                            })
                        }
                        onSubmit={ handleSubmit }
                    >
                        {
                            ((formikProps: FormikProps<IFormField>) => {
                                return <>
                                    <Form>
                                        <div className="dropdown-item">
                                            <CheckBoxSwitchField
                                                label={ t("notification:page.table.filters.state.label") }
                                                name="showState"
                                            />
                                            {
                                                formikProps.values.showState
                                                && <SelectField
                                                    name="state"
                                                    fieldSize="expanded"
                                                    onChange={ (e: ChangeEvent<HTMLSelectElement>) => {
                                                        formikProps.setFieldValue("state", _.toNumber(e.target.value))
                                                    } }
                                                >
                                                    {
                                                        enumToNumberValuesArray(EMarketingNotificationState).map((entry, index) => {
                                                            return <option key={ index } value={ entry }>
                                                                { t("common:marketingNotificationState.state", { context: entry }) }
                                                            </option>
                                                        })
                                                    }
                                                </SelectField>
                                            }

                                        </div> {/* dropdown-item */}
                                        <div className="dropdown-item">
                                            <div className="field is-grouped is-justify-content-space-between">
                                                <button
                                                    className="button"
                                                    type="button"
                                                    onClick={ handleClear }
                                                >
                                                    { t("notification:page.table.filters.buttons.clear") }
                                                </button>
                                                <button
                                                    className="button is-primary"
                                                    type="submit"
                                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                                >
                                                    { t("notification:page.table.filters.buttons.apply") }
                                                </button>
                                            </div>
                                        </div> {/* dropdown-item */}
                                    </Form>
                                </>
                            })
                        }
                    </Formik>
                </div> {/* dropdown-content */}
            </div> {/* dropdown-menu */}
        </div> {/* dropdown */}
    </>
}

export default Filters
