import { mdiClose } from "@mdi/js"
import Icon from "@mdi/react"
import { FunctionComponent, useEffect, useState } from "react"
import "./SearchBar.scss"

interface Props {
    placeholder: string
    searchQuery?: string
    onSubmit: (searchQuery?: string) => void
}

const SearchBar: FunctionComponent<Props> = (props) => {
    const [ value, setValue ] = useState<string>(props.searchQuery ?? "")
    const [ isDisabled, setIsDisabled ] = useState<boolean>(props.searchQuery ? (props.searchQuery.length < 3) : false)

    useEffect(() => {
        setIsDisabled(value.length < 3)
    }, [ value ])

    const handleClear = () => {
        setValue("")
        props.onSubmit() // no arg clears the searchQuery
    }

    return <>
        <div className="field has-addons mb-0">
            <div className={ `search-bar control ${ value.length > 0 ? "has-icons-right" : ""}` }>
                <input
                    className="input"
                    type="text"
                    placeholder={ props.placeholder }
                    value={ value }
                    onChange={ (e) => setValue(e.target.value) }
                />
                {
                    value.length > 0
                    && (
                        <span
                            className="icon is-small is-right is-clickable"
                            onClick={ handleClear }
                        >
                            <Icon path={ mdiClose } size={ 1.2 } />
                        </span>
                    )
                }
            </div>
            <div className="control">
                <button
                    className="button is-primary"
                    disabled={ isDisabled }
                    onClick={ () => props.onSubmit(value) }
                >
                    Search
                </button>
            </div>
        </div>
    </>
}

export default SearchBar
