import axios from "axios"

const basePath = "administration/documents"

export interface AdminDocument {
    fileKey: string
    fileName: string
    fileSize: number
}

type TGetDocumentsResponse = AdminDocument[]

export const getDocuments = async (): Promise<TGetDocumentsResponse> => {
    const response = await axios.get<TGetDocumentsResponse>(`${basePath}`)
    return response.data
}

export const getDocument = async (fileKey: string) => {
    const response = await axios.get<Blob>(`${basePath}/download`, {
        params: { fileKey: fileKey },
        responseType: "blob"
    })
    return response.data
}
