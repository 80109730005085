import { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { mdiPlus } from "@mdi/js"
import Icon from "@mdi/react"

// components
import Add from "components/AdminDashboard/Campaigns/List/Add"
import Filters from "components/AdminDashboard/Campaigns/List/Filters"
import ListRow from "components/AdminDashboard/Campaigns/List/ListRow"
import Loading from "components/common/Loading/Loading"
import PageSizeSelect from "components/common/Pagination/PageSizeSelect"
import Pagination from "components/common/Pagination/Pagination"

// lib
import usePagination from "hooks/usePagination"
import * as api from "service/http/api"
import { ECampaignFilterOrderBy } from "service/http/campaigns"
import { handleAxiosError } from "service/service"
import { noResponseReceivedNotification } from "utils/notifications"

const List: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "campaigns" ])
    const location = useLocation()

    // pagination
    const [
        currentPage,
        currentPageSize,
        currentPageCount,
        currentFilters,
        changePage,
        handlePagination
    ] = usePagination(api.campaigns.getCampaignsPage, { orderBy: ECampaignFilterOrderBy.DESCENDING_ACTIVE_FIRST } as api.campaigns.ICampaignFilters)

    // state
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ campaigns, setCampaigns ] = useState<api.campaigns.ICampaign[]>([])
    const [ isAddingCampaign, setIsAddingCampaign ] = useState<boolean>(false)

    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await handlePagination()
                if (response)
                    setCampaigns(response)
            } catch (error) {
                handleAxiosError(error, (e) => {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // if it were a 401 error the user would be redirected
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                })
            }
        }

        fetch().then(() => setIsLoading(false))
        // dependency irrelevant
        // eslint-disable-next-line
    }, [ location.search ])

    return <>
        <h1 className="title">{ t("campaigns:list.title") }</h1>
        <h2 className="subtitle">{ t("campaigns:list.subtitle") }</h2>

        <div className="field is-grouped is-grouped-right">
            <div className="control is-flex is-align-items-end">
                <Filters
                    filters={ currentFilters }
                    onUpdate={ (filters) => changePage({ filters: filters }) }
                />
            </div>
                <PageSizeSelect
                    currentPageSize={ currentPageSize }
                    onChangePage={ (pageSize) => {
                        changePage({
                            pageSize: pageSize
                        })
                    } }
                    isLoading={ isLoading }
                />
        </div>

        <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{ t("campaigns:list.table.headers.name") }</th>
                        <th>{ t("campaigns:list.table.headers.createdAt") }</th>
                        <th>{ t("campaigns:list.table.headers.startsAt") }</th>
                        <th>{ t("campaigns:list.table.headers.endsAt") }</th>
                        <th>{ t("campaigns:list.table.headers.createdBy") }</th>
                        <th>{ t("campaigns:list.table.headers.defaultMaxUsage") }</th>
                        <th>{ t("campaigns:list.table.headers.batchesCount") }</th>
                        <th>
                            <div className="buttons is-centered">
                                <button
                                    className="button is-secondary is-circular"
                                    title={ t("campaigns:list.table.buttons.add.title") }
                                    onClick={ () => setIsAddingCampaign(true) }
                                >
                                <span className="icon is-small">
                                    <Icon path={ mdiPlus } />
                                </span>
                                </button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                {
                    (() => {
                        if (isLoading)
                            return <tr>
                                <td colSpan={ 9 }>
                                    <Loading />
                                </td>
                            </tr>


                        if (!campaigns.length)
                            return <tr>
                                <td colSpan={ 9 }>{ t("campaigns:list.table.noCampaignsFound") }</td>
                            </tr>


                        return campaigns.map((campaign, index) => {
                            return (
                                <ListRow
                                    key={ index }
                                    tableRowIndex={ ((currentPage - 1) * currentPageSize) + index }
                                    campaign={ campaign }
                                />
                            )
                        })
                    })()
                }
                </tbody>
            </table>
        </div>
        {
            campaigns.length > 0 && !isLoading
            && <Pagination
                currentPage={ currentPage }
                lastPage={ currentPageCount }
                onPageChange={ (page) => {
                    changePage({
                        page: page
                    })
                } }
            />
        }
        {
            isAddingCampaign
            && <Add onClose={ () => setIsAddingCampaign(false) } />
        }
    </>
}

export default List
