import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

interface Props {
    currentPage: number
    lastPage: number
    onPageChange: (page: number) => void
}

const Pagination: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common" ])

    return <>
        <nav className="pagination is-centered mt-4 mb-4 mx-0" role="navigation" aria-label="pagination">
            <button
                className={ "pagination-previous " + (props.currentPage !== 1 ? "is-clickable" : "")  }
                type="button"
                disabled={ props.currentPage === 1 }
                onClick={ () => { props.onPageChange(props.currentPage - 1) } }
            >
                { t("common:pagination.prevButton") }
            </button>
            <button
                className={ "pagination-next " + (props.currentPage < props.lastPage ? "is-clickable" : "") }
                type="button"
                disabled={ props.currentPage >= props.lastPage }
                onClick={ () => { props.onPageChange(props.currentPage + 1) } }
            >
                { t("common:pagination.nextButton") }
            </button>
            <ul className="pagination-list">
                {/*show first page*/}
                {
                    props.currentPage > 2
                    && <li>
                        <button
                            className="pagination-link is-clickable"
                            type="button"
                            onClick={ () => { props.onPageChange(1) } }
                        >1</button>
                    </li>
                }

                {/*show dots between current page and first one*/}
                {
                    props.currentPage > 3
                    && <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                    </li>
                }

                {/*show current page and the adjacent ones*/}
                {
                    props.currentPage > 1
                    && <li>
                        <button
                            className="pagination-link is-clickable"
                            type="button"
                            onClick={ () => props.onPageChange(props.currentPage - 1) }
                        >{ props.currentPage - 1 }</button>
                    </li>
                }
                <li>
                    <button
                        className="pagination-link is-current"
                    >{ props.currentPage }</button>
                </li>
                {
                    props.currentPage < props.lastPage - 1
                    && <li>
                        <button
                            className="pagination-link is-clickable"
                            type="button"
                            onClick={ () => { props.onPageChange(props.currentPage + 1) } }
                        >{ props.currentPage + 1 }</button>
                    </li>
                }

                {/*show dots between current page and last one*/}
                {
                    props.lastPage - props.currentPage >= 3
                    && <li>
                        <span className="pagination-ellipsis">&hellip;</span>
                    </li>
                }

                {/*show last page*/}
                {
                    props.currentPage < props.lastPage
                    && <li>
                        <button
                            className="pagination-link is-clickable"
                            type="button"
                            onClick={ () => { props.onPageChange(props.lastPage) } }
                        >{ props.lastPage }</button>
                    </li>
                }
            </ul>
        </nav>
    </>
}

export default Pagination
