import { combineReducers } from "redux"
import DisplayLanguagesReducer from "state/reducers/DisplayLanguagesReducer"
import DoctorSpecialtiesReducer from "state/reducers/DoctorSpecialtiesReducer"
import LocalizationStatusReducer from "state/reducers/LocalizationStatusReducer"

const RootReducer = combineReducers({
    displayLanguages: DisplayLanguagesReducer,
    doctorSpecialties: DoctorSpecialtiesReducer,
    localizationStatus: LocalizationStatusReducer
})

export default RootReducer
