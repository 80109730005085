import { FunctionComponent } from "react"

const Loading: FunctionComponent = () => {
    return <>
        <div className="columns is-gapless is-vcentered is-fullheight">
            <div className="column"/>
            <div className="column is-half is-offset-one-quarter">
                <progress className="progress is-primary is-small is-half" max="100" >Loading</progress>
            </div>
            <div className="column"/>
        </div>
    </>
}

export default Loading
