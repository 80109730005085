import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from "react"
import { Formik, Form as FormikForm, FieldArray, FormikProps, ArrayHelpers, FormikHelpers } from "formik"
import * as yup from "yup"
import Icon from "@mdi/react"
import { mdiDelete, mdiPlus, mdiTranslate } from "@mdi/js"
import { useTranslation } from "react-i18next"

import { enumToNumberValuesArray } from "utils/helpers"
import * as api from "service/http/api"
import { notify } from "utils/notifications"
import DefaultPresentationText from "components/AdminDashboard/Doctors/Page/Presentation/DefaultPresentationText"
import UniqueKeys from "utils/classes/UniqueKeys"

import FormikDirtyListener from "components/common/Form/FormikDirtyListener"
import InputField from "components/common/Form/InputField"
import SelectField from "components/common/Form/SelectField"

import "./Form.scss"
import MarkdownEditor from "../../../../common/Form/MarkdownEditor/MarkdownEditor"

interface IFormFields {
    [key:string]: any
    description: string,
    careerHistory: api.doctors.IPresentationCareerHistoryEntry[]
}

interface NewEntryProps {
    type: "new"
    presentation?: never // new entry doesn't need this
    onCreate: (presentation: api.doctors.ICreatePresentationParams) => Promise<void>
    onUpdate?: never
}

interface ExistingEntryProps {
    type: "existing"
    presentation: api.doctors.IDoctorPresentation // existing entry does need this
    onCreate?: never
    onUpdate: (presentation: api.doctors.IUpdatePresentationParams) => void
}

type Props = (NewEntryProps | ExistingEntryProps) & {
    language: string
    englishPresentation: api.doctors.IDoctorPresentation | undefined
    setFormIsDirty: (value: boolean) => void
}

let careerHistoryPointKeys = new UniqueKeys()

const Form: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "notification", "doctors" ])

    const [ initialValues, setInitialValues ] = useState<IFormFields>({
        description: props.type === "existing" ? props.presentation.description : DefaultPresentationText[props.language] ?? DefaultPresentationText.en,
        careerHistory: props.type === "existing" ? props.presentation.careerHistory : [],
    })

    const validationSchemaRef = useRef(yup.object<IFormFields>().shape({
        description: yup
            .string()
            .trim()
            .test(
                "isExampleText",
                t("doctors:edit.presentation.form.description.errors.placeholderText"),
                value => !!value && !value.includes("### EXAMPLE ###")
            )
            .required(t("common:form.fieldIsRequired")),
        careerHistory: yup
            .array<api.doctors.IPresentationCareerHistoryEntry>(
                yup.object().shape({
                    type: yup
                        .number()
                        .oneOf(enumToNumberValuesArray(api.doctors.EPresentationCareerPointType), t("common:form.fieldIsInvalid"))
                        .required(t("common:form.fieldIsRequired")),
                    description: yup
                        .string()
                        .trim()
                        .required(t("common:form.fieldIsRequired"))
                })
            )
            .min(1)
            .required()
    }))

    // update initial values after a successful update
    useEffect(() => {
        if (props.type === "existing")
            setInitialValues({
                description: props.presentation.description,
                careerHistory: props.presentation.careerHistory
            })
        else if (props.language)
            setInitialValues({
                description: DefaultPresentationText[props.language] ?? DefaultPresentationText.en,
                careerHistory: []
            })

        // eslint-disable-next-line -- only props.presentation is relevant
    }, [ props.presentation, props.language ])

    const handleSubmit = async (values: IFormFields, formikHelpers: FormikHelpers<IFormFields>) => {
        values = validationSchemaRef.current.cast(values) as IFormFields

        if (props.type === "new") {
            try {
                await props.onCreate(values)

                // notify
                notify(
                    "success",
                    t("notification:onDoctorPresentationCreate.success.title"),
                    t("notification:onDoctorPresentationCreate.success.message")
                )
            } catch (e) {
                notify(
                    "danger",
                    t("notification:onDoctorPresentationCreate.fail.title"),
                    t("notification:onDoctorPresentationCreate.success.message")
                )
            }
        } else {
            let params: api.doctors.IUpdatePresentationParams = {}

            // assign only the modified fields
            Object.keys(values).forEach((key) => {
                if (values[key] !== initialValues[key])
                    params[key] = values[key]
            })

            try {
                await props.onUpdate(params)

                // in case the only updates were some whitespaces
                // the values are not actually updated and we remove them like this
                formikHelpers.resetForm()

                // notify
                notify(
                    "success",
                    t("notification:onDoctorPresentationUpdate.success.title"),
                    t("notification:onDoctorPresentationUpdate.success.message")
                )
            } catch (e) {
                notify(
                    "danger",
                    t("notification:onDoctorPresentationUpdate.fail.title"),
                    t("notification:onDoctorPresentationUpdate.success.message")
                )
            }
        }
    }

    const googleTranslateUrl = () => {
        const url = new URLSearchParams()
        url.append("sl", "en")
        url.append("tl", props.language)
        url.append("text", props.englishPresentation!.description)

        return `https://translate.google.com/?${url.toString()}`
    }

    return <>
        <Formik
            enableReinitialize={ true }
            initialValues={ initialValues }
            validationSchema={ validationSchemaRef.current }
            onSubmit={ handleSubmit }
        >
            {
                ((formikProps: FormikProps<IFormFields>) => {
                    return <>
                        <FormikForm className="presentation-form">
                            <FormikDirtyListener onChange={ props.setFormIsDirty }/>

                            {/*description*/}
                            <div className="description-wrapper mb-3">
                                <MarkdownEditor
                                    label={ t("doctors:edit.presentation.form.description.label") }
                                    name="description"
                                    placeholder={ t("doctors:edit.presentation.form.description.placeholder") }
                                />

                                {/*google translate button*/}
                                {
                                    props.language !== "en" && props.englishPresentation?.description && props.englishPresentation.description !== ""
                                    && <>
                                        <a
                                            className="translate-button button is-primary is-circular is-small"
                                            title={ t("doctors:edit.presentation.form.description.translateButtonHint") }
                                            href={ googleTranslateUrl() }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span className="icon is-small">
                                                <Icon path={ mdiTranslate } size={ 1 } />
                                            </span>
                                        </a>
                                    </>
                                }
                            </div>

                            {/*career history*/}
                            {/*@ts-ignore: I guess types are not updated for react@18+*/}
                            <FieldArray name="careerHistory">
                                {
                                    (helpers: ArrayHelpers) => {
                                        return <>
                                            {/* add career entry point */}
                                            <div className="field">
                                                <label className="label">{ t("doctors:edit.presentation.form.careerHistory.label") }</label>
                                                <button
                                                    className="button is-circular is-secondary"
                                                    type="button"
                                                    title={ t("doctors:edit.presentation.form.careerHistory.buttons.add.title") }
                                                    onClick={ () => {
                                                        careerHistoryPointKeys.addNewEntry()
                                                        helpers.push( {
                                                            type: api.doctors.EPresentationCareerPointType.Education,
                                                            description: ""
                                                        })
                                                    } }
                                                >
                                                <span className="icon is-small">
                                                    <Icon path={ mdiPlus } size={ 1 } />
                                                </span>
                                                </button>
                                            </div>
                                            {
                                                formikProps.values.careerHistory.map((careerHistoryPoint, index) => {
                                                    return (
                                                        <div key={ careerHistoryPointKeys.getKey(index) }
                                                             className="field is-horizontal">
                                                            <div className="field-body">

                                                                <SelectField
                                                                    name={ `careerHistory[${ index }].type` }
                                                                    fieldSize="narrow"
                                                                    onChange={ (event: ChangeEvent<HTMLSelectElement>) => {
                                                                        formikProps.setFieldValue(`careerHistory[${ index }].type`, parseInt(event.target.value, 10))
                                                                    }}
                                                                >
                                                                    {
                                                                        enumToNumberValuesArray(api.doctors.EPresentationCareerPointType).map((value) => {
                                                                            return <option key={ value } value={ value }>{ t("common:doctorPresentationCareerPointType.type", { context: value }) }</option>
                                                                        })
                                                                    }
                                                                </SelectField>

                                                                <InputField
                                                                    name={ `careerHistory[${ index }].description` }
                                                                    fieldSize="expanded"
                                                                    placeholder={ t("doctors:edit.presentation.form.careerHistory.descriptionPlaceholder") }
                                                                />

                                                                {/* delete entry */ }
                                                                <div className="field is-narrow">
                                                                    <div className="control">
                                                                        <button
                                                                            className="button is-danger is-circular"
                                                                            type="button"
                                                                            title={ t("doctors:edit.presentation.form.careerHistory.buttons.delete.title") }
                                                                            onClick={ () => {
                                                                                helpers.remove(index)
                                                                                careerHistoryPointKeys.remove(index)
                                                                            } }
                                                                        >
                                                                     <span className="icon is-small">
                                                                         <Icon path={ mdiDelete } size={ 1 }/>
                                                                     </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                }
                            </FieldArray>

                            {/*form actions*/}
                            <div className="field is-grouped is-justify-content-end">
                                <button
                                    className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                    type="submit"
                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                >
                                    {
                                        props.type === "new"
                                        ? t("doctors:edit.presentation.form.buttons.submitCreate")
                                        : t("doctors:edit.presentation.form.buttons.submit")
                                    }
                                </button>
                            </div>
                        </FormikForm>
                    </>
                })
            }
        </Formik>
    </>
}

export default Form
