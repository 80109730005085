import { Store } from "react-notifications-component"

type ReactNotificationType = "danger" | "success" | "info" | "default" | "warning"

export const notify = (type: ReactNotificationType, title: string, message: string) => {
    Store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true
        }
    })
}

export const noResponseReceivedNotification = (title: string, message: string) => {
    notify("danger", title, message)
}
