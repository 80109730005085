import { FunctionComponent, Suspense, useState } from "react"
import { useHistory } from "react-router-dom"
import { Formik, FormikProps, Field, FieldProps, Form, FormikHelpers } from "formik"
import * as yup from "yup"
import Icon from "@mdi/react"
import { mdiEye, mdiEyeOff } from "@mdi/js"
import { useTranslation } from "react-i18next"

import * as api from "service/http/api"
import JWTTokenStorageInstance from "service/JWTTokenStorage"
import { getFingerprint } from "service/fingerprint"
import { noResponseReceivedNotification } from "utils/notifications"

import InputField from "components/common/Form/InputField"
import Loading from "components/common/Loading/Loading"

import AgathaLogo from "assets/agatha_logo_purple.svg"
import "./Login.scss"

interface ILoginFields {
    loginState: ILoginState
    email: string,
    password: string
}

enum ILoginState {
    InputEmail,
    InputPassword
}

const Login: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "login", "common" ])
    const history = useHistory()

    const [ initialValues ] = useState<ILoginFields>({
        loginState: ILoginState.InputEmail,
        email: "",
        password: ""
    })
    const [ isPasswordVisible, setIsPasswordVisible ] = useState<boolean>(false)
    const [ hasLoginError, setHasLoginError ] = useState<boolean>(false)
    const [ email, setEmail ] = useState<string | null>(null)

    const handleLogin = async (values: ILoginFields, formikHelpers: FormikHelpers<ILoginFields>) => {
        setHasLoginError(false)

        if (values.loginState === ILoginState.InputEmail) {
            // trim the fields
            values.email = values.email.trim()

            try {
                await api.accounts.requestLogin({
                    email: values.email,
                    adminLogin: true
                })

                setEmail(values.email)
                formikHelpers.setFieldValue("loginState", ILoginState.InputPassword)
            } catch(error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    // this means the email is invalid
                    setHasLoginError(true)
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            }
        } else {
            try {
                const response = await api.accounts.login({
                    email: email!,
                    password: values.password,
                    deviceId: await getFingerprint(),
                    adminLogin: true
                })

                // this means the refresh token was also set in the httpOnly cookie
                JWTTokenStorageInstance.setAccessToken(response.accessToken)
                JWTTokenStorageInstance.setRefreshToken(response.refreshToken)

                // redirect to the dashboard
                history.replace("/admin/")
            } catch(error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                    // this means the password is invalid
                    setHasLoginError(true)
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            }
        }
    }

    return <>
        <Suspense fallback={ <Loading /> }>
            <div className="columns is-vcentered">
                <div className="column is-fullheight is-hidden-touch"/>
                <div className="column is-narrow">
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={
                            yup.object().shape({
                                email: yup.string()
                                    .email(t("common:form.invalidEmail"))
                                    .trim()
                                    .required(t("common:form.fieldIsRequired")),
                                password: yup.string()
                                    .when("loginState", {
                                        is: (loginState: ILoginState) => loginState === ILoginState.InputPassword,
                                        then: yup.string().required("common:form.fieldIsRequired")
                                    })
                            })
                        }
                        onSubmit={ handleLogin }
                    >
                        {
                            ((formikProps: FormikProps<ILoginFields>) => {
                                return <>
                                    <Form>
                                        <div className="box login-form">
                                            <div className="is-flex is-justify-content-center">
                                                <figure className="image">
                                                    <img className="logo" src={ AgathaLogo } alt="AgathaLabs" />
                                                </figure>
                                            </div>

                                            <h2 className="title has-text-centered is-3"> { t("login:title") } </h2>

                                            {
                                                hasLoginError
                                                && <div className="notification is-danger">
                                                    <button
                                                        className="delete"
                                                        onClick={ () => { setHasLoginError(false) } }
                                                    />
                                                    {
                                                        formikProps.values.loginState === ILoginState.InputEmail
                                                        ? t("login:error.email")
                                                        : t("login:error.password")
                                                    }
                                                </div>
                                            }

                                            <InputField
                                                label={ t("login:form.email.label") }
                                                name="email"
                                                placeholder={ t("login:form.email.placeholder") }
                                                isStatic={ formikProps.values.loginState === ILoginState.InputPassword }
                                            />

                                            {
                                                formikProps.values.loginState === ILoginState.InputPassword
                                                && <>
                                                    <Field name="password">
                                                        {
                                                            ((fieldProps: FieldProps<string>) => {
                                                                return <>
                                                                    <div className="field">
                                                                        <label className="label">Password</label>
                                                                        <div className="control has-icons-right">
                                                                            <input
                                                                                className="input"
                                                                                type={ isPasswordVisible ? "text" : "password" }
                                                                                { ...fieldProps.field }
                                                                            />
                                                                            <span
                                                                                className={ `icon is-small is-right show-password-icon is-clickable ${isPasswordVisible ? "is-password-visible" : ""}` }
                                                                                onClick={ () => setIsPasswordVisible(!isPasswordVisible) }
                                                                            >
                                                                                <Icon path={ isPasswordVisible ? mdiEyeOff : mdiEye } size={ 1.2 }/>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            })
                                                        }
                                                    </Field>
                                                </>
                                            }

                                            <div className={ `is-flex ${ formikProps.values.loginState === ILoginState.InputEmail ? "is-justify-content-flex-end" : "is-justify-content-space-between" }` }>
                                                {
                                                    formikProps.values.loginState === ILoginState.InputPassword
                                                    && <>
                                                        <button
                                                            className="button"
                                                            type="button"
                                                            disabled={ formikProps.isSubmitting }
                                                            onClick={ () => {
                                                                setEmail(null)
                                                                setHasLoginError(false)
                                                                formikProps.setFieldValue("password", "")
                                                                formikProps.setFieldValue("loginState", ILoginState.InputEmail)
                                                            } }
                                                        >
                                                            { t("login:form.cancelButton") }
                                                        </button>
                                                    </>
                                                }

                                                <button
                                                    className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                                    type="submit"
                                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                                >
                                                    {
                                                        formikProps.values.loginState === ILoginState.InputEmail
                                                        ? t("login:form.loginButton.next")
                                                        : t("login:form.loginButton.login")
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            })
                        }
                    </Formik>
                </div>
                <div className="column is-fullheight is-hidden-touch"/>
            </div>
        </Suspense>
    </>
}

export default Login
