import React, { ChangeEvent, FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"

import * as api from "service/http/api"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { enumToNumberValuesArray } from "utils/helpers"
import { notify } from "utils/notifications"

interface Props {
    client: api.clients.IClient
    onClientAccountStateChange: (newState: api.accounts.EAccountState) => void
    onClientAccountTestingStateChange: (newState: boolean) => void
}

const Account: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "notification", "modal", "clients", "common" ])
    const setModalOptions = useConfirmModal()

    const [ isUpdating, setIsUpdating ] = useState<boolean>(false)

    const handleAccountStateChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        // for some reason e.target.value is undefined when onConfirm() is called
        // not sure why, so we define it here instead
        const newState = parseInt(e.target.value, 10)

        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.clientAccountStateChange.title"),
                content: t("modal:confirm.clientAccountStateChange.message", { name: `${props.client.givenName} ${props.client.familyName}` }),
                onConfirm: async () => {
                    setModalOptions({ isOpen: false })
                    setIsUpdating(true)

                    try {
                        await props.onClientAccountStateChange(newState)

                        notify(
                            "success",
                            t("notification:onClientAccountStateChange.success.title"),
                            t("notification:onClientAccountStateChange.success.message",{
                                name: `${props.client.givenName} ${props.client.familyName}`
                            })
                        )
                    } catch(e) {
                        notify(
                            "danger",
                            t("notification:onClientAccountStateChange.fail.title"),
                            t("notification:onClientAccountStateChange.fail.message", {
                                name: `${props.client.givenName} ${props.client.familyName}`
                            })
                        )
                    }

                    setIsUpdating(false)
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })

    }

    const handleAccountTestingStateChange = async (e: ChangeEvent<HTMLInputElement>) => {
        // for some reason e.target.value is undefined when onConfirm() is called
        // not sure why, so we define it here instead
        const newState = e.target.checked

        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.clientAccountTestingStateChange.title"),
                content: t("modal:confirm.clientAccountTestingStateChange.message", { name: `${props.client.givenName} ${props.client.familyName}` }),
                onConfirm: async () => {
                    setModalOptions({ isOpen: false })
                    setIsUpdating(true)

                    try {
                        // update the table info
                        await props.onClientAccountTestingStateChange(newState)

                        notify(
                            "success",
                            t("notification:onClientAccountTestingStateChange.success.title"),
                            t("notification:onClientAccountTestingStateChange.success.message",{
                                name: `${props.client.givenName} ${props.client.familyName}`
                            })
                        )
                    } catch(e) {
                        notify(
                            "danger",
                            t("notification:onClientAccountTestingStateChange.fail.title"),
                            t("notification:onClientAccountTestingStateChange.fail.message", {
                                name: `${props.client.givenName} ${props.client.familyName}`
                            })
                        )
                    }

                    setIsUpdating(false)
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })

    }

    return <>
        {/*account state*/}
        <div className="field">
            <div className="control is-expanded">
                <label className="label">{ t("clients:view.account.form.accountState.label") }</label>
                <div className={ "select is-fullwidth " + (isUpdating ? "is-loading" : "")}>
                    <select
                        name="accountState"
                        disabled={ isUpdating }
                        value={ props.client.accountState }
                        onChange={ handleAccountStateChange }
                    >
                        {
                            enumToNumberValuesArray(api.accounts.EAccountState).map((value) => {
                                return (
                                    <option
                                        key={ value }
                                        value={ value }
                                    >
                                        { t("common:accountState.state", { context: value }) }
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
        </div> {/* field */}
        <div className="field">
            <div className="control">
                <div className="switch">
                    <input
                        className="switch__input"
                        id="isTestingAccount"
                        name="isTestingAccount"
                        type="checkbox"
                        checked={ props.client.isTestingAccount }
                        onChange={ handleAccountTestingStateChange }
                    />
                    <label htmlFor="isTestingAccount" className="switch__label label">
                        { t("clients:view.account.form.accountTestingState.label") }
                    </label>
                </div>
                <p className="help">
                    { t("clients:view.account.form.accountTestingState.help") }
                </p>
            </div>
        </div>
    </>
}

export default Account
