import { RouteComponentProps } from 'react-router-dom';
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import JWTTokenStorageInstance from "./JWTTokenStorage"

import { logout } from "service/http/accounts"
import { decamelizeKeys } from "humps"

export const fileUploadAxiosConfig: AxiosRequestConfig = {
    headers: {
        "Content-Type": "multipart/form-data"
    }
}

// filekeys can include the key of a nested file
// i.e. "entries[0][file]"
export const objToFormData = (obj: any, fileKeys: string | string[]) => {
    let formData = new FormData()

    const createFormData = (obj: any, subKeyStr = '') => {
        for(let i in obj){
            let value          = obj[i]
            let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i

            if ((Array.isArray(fileKeys) && fileKeys.includes(subKeyStr))
                || subKeyStrTrans === fileKeys
            ) {
                formData.append(subKeyStrTrans, value, (value as File).name)
                continue
            }

            if (Array.isArray(value)) // array is also an object so we check this first
                formData.append(subKeyStrTrans, JSON.stringify(value))
            else if(typeof(value) === 'object')
                createFormData(value, subKeyStrTrans)
            else if(typeof(value) === 'string')
                formData.append(subKeyStrTrans, value)
            else
                formData.append(subKeyStrTrans, value.toString())
        }
    }

    createFormData(obj)

    return formData
}

export const setUpAxios = (history: RouteComponentProps["history"]) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_HOSTNAME! + process.env.REACT_APP_API_BASE_URL

    // setup JWT interceptors
    axios.interceptors.request.use(async (config) => {
        if (JWTTokenStorageInstance.getAccessToken()) {

            // check JWT token for expiration
            const decoded = JWTTokenStorageInstance.getDecodedAccessToken()

            // our tokens have the exp in s, while Date.now() is in ms
            if (decoded === null || Date.now() / 1000 >= decoded.exp!) {
                await JWTTokenStorageInstance.refreshTokens()
            }

            config.headers["Authorization"] = "Bearer " + JWTTokenStorageInstance.getAccessToken()
        }

        if (config.params)
            config.params = decamelizeKeys(config.params, { separator: "-" })

        return config;
    }, (error) => {
        throw error
    })

    axios.interceptors.response.use( (response) => {
        // Return a successful response back to the calling service
        return response;
    }, async (error) => {
        // The refresh endpoint threw an error, so we log out the user
        if (error?.config?.url === JWTTokenStorageInstance.getLastUsedRefreshEndpoint()) {
            // if (!error.config.url.includes("logins"))
            await logout(history)
            return
        }

        // Return any error which is not due to authentication back to the calling service
        if (error?.response?.status !== 401) {
            throw error
        }

        // Try request again with new token
        try {
            await JWTTokenStorageInstance.refreshTokens()

            // New request with new token
            const config = error.config;

            config.headers = {
                "Authorization": "Bearer " + JWTTokenStorageInstance.getAccessToken(),
            }

            return await axios.request(config)
        } catch(e) {
            throw e
        }
    });
}

export function isAxiosError(error: any): error is AxiosError {
    return ('isAxiosError' in error && error.isAxiosError)
}

export function handleAxiosError(error: Error, responseErrorCallback: (e: AxiosError) => void, requestErrorCallback: (e: AxiosError) => void, otherErrorCallback: (e: any) => void): void {
    if (isAxiosError(error)) {
        if (error.response)
            return responseErrorCallback(error)

        if (error.request)
            return requestErrorCallback(error)
    }

    return otherErrorCallback(error)
}
