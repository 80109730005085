import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup.
let fpPromise: Promise<Agent>

export const initFingerprint = () => {
    fpPromise = FingerprintJS.load()
}

export const getFingerprint = async (): Promise<string> => {
    const fp = await fpPromise
    const result = await fp.get()

    return result.visitorId
}
