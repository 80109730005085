import { RouteComponentProps } from 'react-router-dom';
import axios from "axios"
import JWTTokenStorageInstance from "../JWTTokenStorage"

const basePath = `/accounts`

export enum EAccountState {
    Valid,
    Suspended
}

enum EAccountRole {
    Client,
    Doctor,
    Admin,
    Manager
}

export interface IBasicAccountInfo {
    id: string
    givenName: string
    familyName: string
    picture: string
}

// login / logout
export interface IRequestLoginParams {
    email: string
    adminLogin: boolean
}

export interface IRequestLoginResponse {
    id: string
}

export const requestLogin = async (params: IRequestLoginParams): Promise<IRequestLoginResponse> => {
    const response = await axios.post<IRequestLoginResponse>(`${basePath}/logins/login-request`, params)
    return response.data
}

export interface ILoginParams {
    email: string
    password: string
    deviceId: string
    adminLogin: boolean
}

interface ILoginResponse {
    id: string
    refreshToken: string
    accessToken: string
    role: EAccountRole
    justCreated: boolean
}

export const login = async (params: ILoginParams): Promise<ILoginResponse> => {
    const response = await axios.post<ILoginResponse>(`${basePath}/logins/email`, params)
    return response.data
}

export const logout = async (history: RouteComponentProps["history"] | null = null) => {
    try {
        await axios.delete(`${basePath}/logins/current`).then()
    } catch(e) {
        // doesn't really matter if the request fails
        // ig that means the refreshToken is expired or smth
    }

    // clear the tokens
    JWTTokenStorageInstance.clearAccessToken()
    JWTTokenStorageInstance.clearRefreshToken()

    // redirect to the login page
    if (history)
        history.push(process.env.REACT_APP_UNAUTHORIZED_REDIRECT_TARGET!)
}

// refresh tokens
interface IRefreshAuthTokenResponse {
    accessToken: string
    refreshToken: string
}

export const getRefreshEndpoint = () => `${basePath}/logins/${JWTTokenStorageInstance.getRefreshToken()}`

export const refreshAuthToken = async (): Promise<IRefreshAuthTokenResponse> => {
    const response = await axios.patch<IRefreshAuthTokenResponse>(`${getRefreshEndpoint()}`)
    return response.data
}

// update account state
interface IUpdateAccountStateParams {
    state: EAccountState
}

interface IUpdateAccountStateResponse {}

export const updateAccountState = async (accountId: string, params: IUpdateAccountStateParams): Promise<IUpdateAccountStateResponse> => {
    const response = await axios.patch<IUpdateAccountStateParams>(`${basePath}/${accountId}/account-state`, params)
    return response.data
}
