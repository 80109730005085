import { DateTime } from "luxon"
import { FunctionComponent, useState } from "react"
import Icon from "@mdi/react"
import { mdiCheck, mdiClose, mdiEye } from "@mdi/js"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { IClientPaymentMethod } from "service/http/clients"
import RawData from "components/AdminDashboard/Clients/Page/PaymentMethods/RawData"

interface Props {
    paymentMethods: IClientPaymentMethod[]
}

const PaymentMethods: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "clients" ])

    const [ isViewingRawDataOf, setIsViewingRawDataOf ] = useState<number | null>(null)

    return <>
        <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
            <thead>
                <tr>
                    <th>{ t("clients:view.paymentMethods.table.headers.cardBrand") }</th>
                    <th>{ t("clients:view.paymentMethods.table.headers.lastFourDigits") }</th>
                    <th>{ t("clients:view.paymentMethods.table.headers.expirationDate") }</th>
                    <th>{ t("clients:view.paymentMethods.table.headers.isPreferred") }</th>
                    <th>{ t("clients:view.paymentMethods.table.headers.isValid") }</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
            {
                (() => {
                    if (props.paymentMethods.length === 0)
                        return (
                            <tr>
                                <td colSpan={ 6 }>
                                    { t("clients:view.paymentMethods.table.noPaymentMethodSet") }
                                </td>
                            </tr>
                        )

                    return props.paymentMethods.map((entry, index) => {
                        const expDate = DateTime.now().toLocal()
                        expDate.set({ year: entry.expirationYear, month: entry.expirationMonth })

                        return (
                            <tr key={ entry.id }>
                                <td>{ _.upperFirst(entry.cardBrand) }</td>
                                <td>{ entry.last4Digits }</td>
                                <td>{ t("common:date.dateShortWithoutDay", { date: expDate }) }</td>
                                <td>
                                    <Icon
                                        path={ entry.isPrimary ? mdiCheck : mdiClose }
                                        color={ entry.isPrimary ? "green" : "red" }
                                        size={ 1 }
                                    />
                                </td>
                                <td>
                                    <Icon
                                        path={ entry.isValid ? mdiCheck : mdiClose }
                                        color={ entry.isValid ? "green" : "red" }
                                        size={ 1 }
                                    />
                                </td>
                                <th>
                                    <div className="buttons is-centered">
                                        <button
                                            className="button is-circular"
                                            type="button"
                                            onClick={ () => { setIsViewingRawDataOf(index) } }
                                        >
                                            <span className="icon is-small">
                                                <Icon path={ mdiEye } size={ 1 } />
                                            </span>
                                        </button>
                                    </div>
                                </th>
                            </tr>
                        )
                    })
                })()
            }
            </tbody>
        </table>
        {
            isViewingRawDataOf !== null
            && <RawData
                json={ props.paymentMethods![isViewingRawDataOf] }
                onClose={ () => { setIsViewingRawDataOf(null) } }
            />
        }
    </>
}

export default PaymentMethods
