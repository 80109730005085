import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import { FieldHookConfig } from "formik"

import FileInputField from "components/common/Form/FileInputField"
import ImageCrop from "components/common/ImageCrop/ImageCrop"
import { isValidImageFile } from "utils/helpers"

interface OtherProps {
    resetKey: number
    label: string
    fieldSize?: "narrow" | "expanded"
    helpMessage?: string | JSX.Element
    crop?: boolean
    cropAspectRatio?: number
    onSaveFile: (file: File) => void
    onRemoveFile: () => void
}

/** @Note:
 * In order for this component to work you need to assign a key for this component
 * this key should be a random string and onRemoveFile should just generate a new key
 * thus triggering the rerender of the component and clearing the selected file
 * For yup validation, this field should be mixed() and nullable() so whenever we remove the file,
 * we should change the value of the field to null
 * TL;DR onRemove should:
 * 1. refresh the key used for this component
 * 2. set the field value to null
 **/
const ImageFileInputField: FunctionComponent<OtherProps & FieldHookConfig<string>> = ({ label, fieldSize = "normal", helpMessage, crop = false, cropAspectRatio = 1, ...props }) => {
    const [ uploadedFile, setUploadedFile ] = useState<File | null>(null)
    const [ finishedCrop, setFinishedCrop ] = useState<boolean>(false)

    // used for updating from parent
    useEffect(() => {
        if (uploadedFile)
            handleRemoveFile()

        // eslint-disable-next-line -- not a relevant dependency
    }, [ props.resetKey ])

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.currentTarget.files || e.currentTarget.files.length === 0) return

        const file = e.currentTarget.files[0]

        if (crop && isValidImageFile(file)) {
            setFinishedCrop(false)
            setUploadedFile(file)
        }
        else
            props.onSaveFile(e.currentTarget.files[0])
    }

    const handleRemoveFile = () => {
        setUploadedFile(null)
        props.onRemoveFile()
    }

    return <>
        <FileInputField
            key={ props.resetKey }
            label={ label }
            name={ props.name }
            helpMessage={ helpMessage }
            onChange={ handleFileUpload }
            onRemoveFile={ handleRemoveFile }
        />

        {
            crop && cropAspectRatio && uploadedFile && !finishedCrop
            && <ImageCrop
                image={ uploadedFile }
                aspect={ cropAspectRatio }
                onComplete={ (file) => {
                    setFinishedCrop(true)
                    props.onSaveFile(file)
                } }
                onCancel={ handleRemoveFile }
            />
        }
    </>
}

export default ImageFileInputField
