import axios from "axios"

import { IPagedRequestParams, IPagedRequestResponse } from "service/http/pagination"
import { IBasicAccountInfo } from "service/http/accounts"

const basePath = "/administration/instant-calls"

// CALLS

export enum EPaymentStatus
{
    Pending,
    Failed,
    Successful,
    NotRequired,
    Reverted,
    FreeCall,
    SpecialAccessCampaignCall
}

export enum EEndOfCallRationale {
    DoctorOutsideOfSchedule = 0,
    DoctorInAnotherCall = 1,
    DoctorNotLoggedIn = 2,
    DoctorClosedCall = 3,
    PatientClosedCall = 4,
    DoctorDisconnected = 5,
    PatientDisconnected = 6,
    TwilioError = 7,
    DoctorRefusedCall = 8,
}

export interface IInstantCallDetails {
    id: string
    doctor: IBasicAccountInfo
    client: IBasicAccountInfo
    ratePerMinuteInUnit: number
    videoRatePerMinuteInUnit: number
    doctorPayoutPercentage: number
    paymentStatus: boolean
    createdAt: string
    endOfCallRationale: EEndOfCallRationale
    startedAt?: string
    videoStartedAt?: string
    respondedAt?: string
    endedAt?: string
    score?: number
    doctorRejected: boolean
    scoreReceived: boolean
    callEnded: boolean
    costInUnit: number
    voiceDurationInSeconds: number
    videoDurationInSeconds: number
    messages: boolean
    doctorReceivedNotification: boolean
}

export interface IInstantCallFilters {
    ids?: string[]
    month?: number
    year?: number
    [key: string]: number | string[] | undefined
}

// get InstantCalls Page
interface IGetInstantCallsPageResponse extends IPagedRequestResponse<IInstantCallDetails> {}

export const getInstantCallsPage = async (params: IPagedRequestParams<IInstantCallFilters>): Promise<IGetInstantCallsPageResponse> => {
    const response = await axios.get<IGetInstantCallsPageResponse>(`${basePath}`, {
        params: params
    })

    return response.data
}

// MESSAGE

export interface IInstantCallMessage {
    id: number,
    timeStamp: string,
    text: string,
    senderId: string
}

// get Instant Call Messages
interface IGetInstantCallMessages extends IInstantCallMessage {}

export const getInstantCallMessages = async (instantCallId: string): Promise<IGetInstantCallMessages[]> => {
    const response = await axios.get<IGetInstantCallMessages[]>(`${basePath}/${instantCallId}/messages`)
    return response.data
}
