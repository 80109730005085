import JWTTokenStorageInstance from "service/JWTTokenStorage"

export const isAuthenticated = (): boolean => {
    return !!(JWTTokenStorageInstance.getAccessToken() || JWTTokenStorageInstance.getRefreshToken())
}

// Permissions
export enum EPermissionRoles {
    None,
    Admin,
    Manager,
}

export const hasPermissions = (roles: EPermissionRoles | EPermissionRoles[]): boolean => {
    const decoded = JWTTokenStorageInstance.getDecodedAccessToken()

    if (decoded === null)
        return false

    const checkPermissionForRole = (role: EPermissionRoles) => {
        switch (role) {
            case EPermissionRoles.Admin:
                return decoded.role === "Admin"

            case EPermissionRoles.Manager:
                return decoded.role === "Manager"

            default: return false
        }
    }

    if (Array.isArray(roles)) {
        let hasPermissions = false

        for (const role in roles) {
            hasPermissions = hasPermissions || checkPermissionForRole(roles[role])
        }

        return hasPermissions
    }

    return checkPermissionForRole(roles as EPermissionRoles)
}
