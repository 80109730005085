import { FunctionComponent } from "react"

// components
import DoctorsStats from "components/AdminDashboard/Stats/Doctors/DoctorsStats"

const Stats: FunctionComponent = () => {
    return <>
        <DoctorsStats />
    </>
}

export default Stats
