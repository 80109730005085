import axios from "axios"

const basePath = "/administration/localization"

// Display Languages
const displayLanguagesPath = `${basePath}/display-languages`

export interface IDisplayLanguage {
    isoCode: string
    available: boolean
}

// get all
interface IGetDisplayLanguagesResponse extends IDisplayLanguage {}

export const getAllDisplayLanguages = async (): Promise<IGetDisplayLanguagesResponse[]> => {
    const response = await axios.get<IGetDisplayLanguagesResponse[]>(`${displayLanguagesPath}`)
    return response.data
}

// post
interface ICreateDisplayLanguageParams {
    languageIsoCode: string
}

interface ICreateDisplayLanguageResponse {}

export const createDisplayLanguage = async (params: ICreateDisplayLanguageParams): Promise<ICreateDisplayLanguageResponse> => {
    const response = await axios.post<ICreateDisplayLanguageResponse>(`${displayLanguagesPath}`, params)
    return response.data
}

// patch
interface IUpdateDisplayLanguageAvailabilityResponse extends IDisplayLanguage {}

export const updateDisplayLanguageAvailability = async (language: string, available: boolean): Promise<IUpdateDisplayLanguageAvailabilityResponse> => {
    const response = await axios.patch<IUpdateDisplayLanguageAvailabilityResponse>(`${displayLanguagesPath}/${language}`, {
        available: available
    })
    return response.data
}

// Status
export interface ILocalizationStatus {
    doctors: boolean
    specialties: boolean
}

export const getLocalizationStatus = async (): Promise<ILocalizationStatus> => {
    const response = await axios.get<ILocalizationStatus>(`${basePath}/status`)
    return response.data
}
