import axios from "axios"

import { IPagedRequestParams, IPagedRequestResponse } from "service/http/pagination"

const basePath = '/administration/notifications'

export enum EMarketingNotificationState {
    ToBeDelivered,
    NotToBeDelivered,
    Delivered
}

export interface INotificationFields {
    id: string
    title: string
    message: string
    icon: string
    sendSchedule: string
    // pageToRedirectTo: string
    marketingNotificationState: EMarketingNotificationState
}

export interface INotification extends INotificationFields {
    [key: string]: number | string | boolean
}

export enum NotificationOrdering {
    ASC,
    DESC,
    SCHEDULE_ASC,
    SCHEDULE_DESC,
}

export interface INotificationFilters {
    state?: EMarketingNotificationState
}

// get MarketingNotifications Page
export interface IGetNotificationsPageResponse extends IPagedRequestResponse<INotification> {}

export const getNotificationsPage = async (params: IPagedRequestParams<INotificationFilters>): Promise<IGetNotificationsPageResponse> => {
    const response = await axios.get<IGetNotificationsPageResponse>(`${basePath}`, {
        params: params
    })

    return response.data
}

// create notification
export interface ICreateNotificationParams extends Omit<INotificationFields, "id" | "marketingNotificationState"> {}

interface ICreateNotificationResponse {
    id: string
}

export const createNotification = async (params: ICreateNotificationParams): Promise<ICreateNotificationResponse> => {
    const response = await axios.post<ICreateNotificationResponse>(`${basePath}`, params)
    return response.data
}

// update notification
export interface IUpdateNotificationParams extends Partial<Omit<INotificationFields, "id" | "marketingNotificationState">> {
    [key: string]: string | EMarketingNotificationState | undefined
}

interface IUpdateNotificationResponse {}

export const updateNotification = async (notificationId: string, params: IUpdateNotificationParams): Promise<IUpdateNotificationResponse> => {
    const response = await axios.patch<IUpdateNotificationResponse>(`${basePath}/${notificationId}`, params)
    return response.data
}

// delete notification
export const deleteNotification = async (notificationId: string) => {
    await axios.delete(`${basePath}/${notificationId}`)
}

