import React, { FunctionComponent } from "react"
import { Link, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiPencil, mdiWeatherSunny, mdiWeatherNight, mdiPhone, mdiTranslate, mdiVideo } from "@mdi/js"

import routes from "routes/routes"
import * as api from "service/http/api"
import { IDoctorPageHistoryState } from "components/AdminDashboard/Doctors/Page/Page"

import ImagePreview from "components/common/Form/ImagePreview"

interface Props {
    tableRowIndex: number
    doctor: api.doctors.IDoctor
}

const ListRow: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "notification" ])
    const history = useHistory()

    return <>
        <tr>
            <td>
                {
                    !(props.doctor.hasMissingTranslations || props.doctor.hasMissingMediaContentTranslations)
                    ? props.tableRowIndex
                    : <>
                        <span className="icon is-danger">
                            <Icon path={ mdiTranslate } size={ 0.75 } />
                        </span>
                    </>
                }
            </td>
            <td>
                <div className="is-flex is-justify-content-center">
                    <ImagePreview
                        placeholderType="account"
                        url={ props.doctor.profilePicture }
                        imgAlt="profile-picture"
                        size="32x32"
                        shape="circle"
                    />
                </div>
            </td>
            <td>{ `${props.doctor.givenName} ${ props.doctor.familyName }` }</td>
            <td>{ props.doctor.primaryEmail }</td>
            <td>
                <span className="icon-text" style={ { whiteSpace: "nowrap" } }>
                    <span className="icon mr-2">
                        <Icon path={ mdiWeatherSunny } size={ 0.75 } />
                    </span>
                    { `${props.doctor.ratePerMinuteInUnit.toFixed(2)} EUR` }
                </span>
                <br/>
                <span className="icon-text" style={ { whiteSpace: "nowrap" } }>
                    <span className="icon mr-2">
                        <Icon path={ mdiWeatherNight } size={ 0.75 } />
                    </span>
                    { `${props.doctor.outOfScheduleRatePerMinuteInUnit.toFixed(2)} EUR` }
                </span>
                <br/>
                <span className="icon-text" style={ { whiteSpace: "nowrap" } }>
                    <span className="icon mr-2">
                        <Icon path={ mdiVideo } size={ 0.75 } />
                    </span>
                    { `${props.doctor.videoRatePerMinuteInUnit.toFixed(2)} EUR` }
                </span>
            </td>
            <td>{ props.doctor.payoutPercentage }</td>
            <td>{ t("common:accountState.state", { context: props.doctor.accountState }) }</td>
            <td>{ t("common:doctorPageVisibilityType.type", { context: props.doctor.pageVisibility }) }</td>
            <td>
                <div className="buttons is-centered">
                    {/*edit*/}
                    <Link
                        to={ {
                            pathname: routes.doctorPage(props.doctor.id),
                            state: {
                                from: {
                                    pathname: history.location.pathname,
                                    search: history.location.search
                                }
                            } as IDoctorPageHistoryState
                        } }
                    >
                        <button className="button is-circular" type="button">
                        <span className="icon is-small">
                            <Icon path={ mdiPencil } size={ 1 } />
                        </span>
                        </button>
                    </Link>

                    {/* instant calls*/}
                    <Link
                        to={ {
                            pathname: routes.instantCalls,
                            search: `?&doctor-id=${props.doctor.id}`
                        } }
                    >
                        <button className="button is-circular" type="button">
                            <span className="icon is-small">
                                <Icon path={ mdiPhone } size={ 1 } />
                            </span>
                        </button>
                    </Link>
                </div>
            </td>
        </tr>
    </>
}

export default ListRow
