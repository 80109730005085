import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import _ from "lodash"
import { camelizeKeys, decamelizeKeys } from "humps"
import * as queryString from "query-string"

import useQuery from "hooks/useQuery"
import { IPagedRequestParams, IPagedRequestResponse } from "service/http/pagination"

const usePagination = <TResponseResults extends unknown, TFilters extends { [key: string]: any }>(fetchFunc: (params: IPagedRequestParams<TFilters>) => Promise<IPagedRequestResponse<TResponseResults>>, defaultFilters: TFilters | null = null) => {
    const query = useQuery()
    const history = useHistory()
    const location = useLocation()

    const parseFilters = () => {
        let filters = {} as TFilters

        let queryParams = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true }) as TFilters
        queryParams = _.omit(queryParams, ["page", "page-size"]) as TFilters
        filters = camelizeKeys(queryParams) as TFilters

        if (defaultFilters && Object.keys(filters).length === 0)
            filters = defaultFilters

        return filters
    }

    const [ currentPage, setCurrentPage ] = useState<number>(1)
    const [ currentPageSize, setCurrentPageSize ] = useState<number>(_.toNumber(localStorage.getItem("lastPageSize") ?? "25"))
    const [ currentPageCount, setCurrentPageCount ] = useState<number>(0)
    const [ currentFilters, setCurrentFilters ] = useState<TFilters>(parseFilters())

    // replace should be used when redirecting inside this hook in order
    // to avoid creating and infinite loop when using the back button of the browser
    const changePage = (params: Partial<IPagedRequestParams<TFilters>>, type: ("replace" | "push") = "push") => {
        const page = params.page ?? currentPage
        const pageSize = params.pageSize ?? currentPageSize
        const filters = params.filters ?? currentFilters

        setCurrentPage(page)
        setCurrentPageSize(pageSize)
        setCurrentFilters(filters)

        localStorage.setItem("lastPageSize", pageSize.toString())

        const newQuery = new URLSearchParams()

        newQuery.append("page", page.toString())
        newQuery.append("page-size", pageSize.toString())

        // append filters
        let dashCaseFilters = decamelizeKeys(
            _.cloneDeep(filters),
            { separator: "-" }
        ) as { [key: string]: any }

        Object.keys(dashCaseFilters).forEach((key) => {
            if (dashCaseFilters[key])
                newQuery.append(key, dashCaseFilters[key])
        })

        switch (type) {
            case "push": {
                history.push({
                    pathname: location.pathname,
                    search: newQuery.toString()
                })

                break
            }

            case "replace": {
                history.replace({
                    pathname: location.pathname,
                    search: newQuery.toString()
                })

                break
            }
        }
    }

    const fetchPage = async () => {
        let filters = parseFilters()

        // update state from query
        setCurrentPage(_.toNumber(query.get("page")!))
        setCurrentPageSize(_.toNumber(query.get("page-size")!))
        setCurrentFilters(filters)

        const response = await fetchFunc({
            page: _.toNumber(query.get("page")!),
            pageSize: _.toNumber(query.get("page-size")!),
            ...filters
        })

        if (response.results.length === 0 && currentPage !== 1) {
            changePage({
                page: 1,
                pageSize: _.toNumber(query.get("page-size")),
                filters: filters
            }, "replace")
            return
        }

        setCurrentPageCount(response.pageCount)

        return response.results
    }

    const handlePagination = async (): Promise<TResponseResults[] | void> => {
        const queryParams = camelizeKeys(queryString.parse(location.search, { parseBooleans: true, parseNumbers: true }))

        // query is valid if it has page, pageSize and all the default filters (which are basically required)
        const isQueryValid = Object.keys(queryParams).some((val, index, array) => {
            return !!(val === "page"
                || val === "pageSize"
                || (defaultFilters && Object.keys(defaultFilters).indexOf(val) !== -1));
        })

        if (isQueryValid) {
            return await fetchPage()
        } else {
            // get the filters first before redirecting
            let filters = parseFilters()

            // default page and page-size
            changePage({
                page: 1,
                pageSize: currentPageSize ?? localStorage.getItem("lastPageSize"),
                filters: filters
            }, "replace")
        }
    }

    return [ currentPage, currentPageSize, currentPageCount, currentFilters, changePage, handlePagination ] as const
}

export default usePagination
