import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import { IDisplayLanguage } from "service/http/localization"
import AddDisplayLanguageForm from "components/AdminDashboard/Localization/Manage/AddDisplayLanguageForm"
import ChangeAvailablity from "components/AdminDashboard/Localization/Manage/ChangeAvailablity"

interface NewProps {
    type: "add"
    existingLanguages: IDisplayLanguage[]
    info?: never
    onCreate: (language: string) => void
    onUpdate?: never
}

interface ExistingProps {
    type: "edit"
    info: IDisplayLanguage
    onCreate?: never
    onUpdate: (available: boolean) => void
}

type Props = (NewProps | ExistingProps) & {
    onClose: () => void
}

const Manage: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "modal", "localization" ])

    return <>
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">
                            { props.type === "edit" ? t("localization:edit.title") : t("localization:add.title") }
                        </p>
                        {
                            (() => {
                                if (props.type === "add")
                                    return <AddDisplayLanguageForm
                                        existingLanguages={ props.existingLanguages }
                                        onCreate={ props.onCreate }
                                        onClose={ props.onClose }
                                    />

                                return <ChangeAvailablity
                                    info={ props.info }
                                    onUpdate={ props.onUpdate }
                                    onClose={ props.onClose }
                                />
                            })()
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Manage
