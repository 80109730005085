import DoctorSelect from "components/AdminDashboard/Campaigns/Page/Details/DoctorSelect"
import FormikDirtyListener from "components/common/Form/FormikDirtyListener"
import InputField from "components/common/Form/InputField"
import { Field, FieldArray, FieldProps, Form, Formik, FormikProps } from "formik"
import { DateTime } from "luxon"
import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { isValidDate } from "utils/helpers"
import { notify } from "utils/notifications"
import * as yup from "yup"

import * as api from "service/http/api"

interface IFromFields {
    name: string
    startsAt: string
    endsAt: string
    defaultMaxUsagesCount: number
    doctors: string[]
    [key: string]: any
}

interface NewProps {
    type: "new"
    details?: never
    onCreate: (entry: api.campaigns.ICreateCampaignParams) => void
    onUpdate?: never
    onClose: () => void
    hasCampaignEnded?: false
}

interface ExistingProps {
    type: "existing"
    details: api.campaigns.ICampaign
    onCreate?: never
    onUpdate: (entry: api.campaigns.IUpdateCampaignParams) => void
    onClose?: never
    hasCampaignEnded: boolean
}

type Props = (NewProps | ExistingProps) & {
    setIsFormDirty: (value: boolean) => void
}

const Details: FunctionComponent<Props> = ({ hasCampaignEnded = false, ...props }) => {
    const { t } = useTranslation([ "common", "campaigns", "notification" ])

    const [ initialValues, setInitialValues ] = useState<IFromFields>({
        name:                   props.type === "existing" ? props.details.name                                              : "",
        startsAt:               props.type === "existing" ? props.details.startsAt                                          : DateTime.now().toUTC().toISO(),
        endsAt:                 props.type === "existing" ? props.details.endsAt                                            : DateTime.now().toUTC().toISO(),
        defaultMaxUsagesCount:  props.type === "existing" ? props.details.defaultMaxUsagesCount                              : 1,
        doctors:                props.type === "existing" ? props.details.campaignDoctors.map(it => it) : []
    })

    const validationSchemaRef = useRef(
        yup.object<IFromFields>().shape({
            name: yup
                .string()
                .trim()
                .required(t("common:form.fieldIsRequired")),
            startsAt: yup
                .string()
                .test("stringIsTime", t("common:form.timeIsInvalid"), isValidDate)
                .test("beforeEndDate", t("campaigns:page.details.form.startsAt.error"), (startsAt) => {
                    if (props.type === "new" && startsAt) {
                        const start = DateTime.fromISO(startsAt)
                        const now = DateTime.now().toUTC()

                        return start > now
                    }

                    return true
                })
                .required(t("common:form.fieldIsRequired")),
            endsAt: yup
                .string()
                .test("stringIsTime", t("common:form.timeIsInvalid"), isValidDate)
                .test("afterStartDate", t("campaigns:page.details.form.endsAt.error"), (endsAt, context) => {
                    if (endsAt && context.parent.startsAt) {
                        const start = DateTime.fromISO(context.parent.startsAt)
                        const end = DateTime.fromISO(endsAt)

                        return end > start
                    }

                    return true
                })
                .required(t("common:form.fieldIsRequired")),
            defaultMaxUsagesCount: yup
                .number()
                .min(1, t("common:form.numberMustBePositive"))
                .required(t("common:form.fieldIsRequired")),
            doctors: yup
                .array(yup.string())
                .test("arrayIsEmpty", "doctors is empty", it => !!it && it.length > 0)
                .required(t("common:form.fieldIsRequired"))
        })
    )

    // update form when props change
    useEffect(() => {
        if (props.type === "existing")
            setInitialValues({
                name: props.details.name,
                startsAt: props.details.startsAt,
                endsAt: props.details.endsAt,
                defaultMaxUsagesCount: props.details.defaultMaxUsagesCount,
                doctors: props.details.campaignDoctors.map(it => it)
            })

        // irrelevant dependency
        // eslint-disable-next-line
    }, [ props.details ])

    const handleSubmit = useCallback(async (values: IFromFields)=> {
        if (props.type === "new") {
            try {
                await props.onCreate(values)

                notify(
                    "success",
                    t("notification:onCampaignCreate.success.title"),
                    t("notification:onCampaignCreate.success.message", { name: values.name })
                )
            } catch (e) {
                notify(
                    "danger",
                    t("notification:onCampaignCreate.fail.title"),
                    t("notification:onCampaignCreate.fail.message")
                )
            }
        } else {
            try {
                let entry: api.campaigns.IUpdateCampaignParams = {}

                Object.keys(values).forEach(key => {
                    if (values[key as keyof IFromFields] !== initialValues[key as keyof IFromFields])
                        entry[key] = values[key]
                })

                await props.onUpdate(entry)

                notify(
                    "success",
                    t("notification:onCampaignUpdate.success.title"),
                    t("notification:onCampaignUpdate.success.message", { name: values.name })
                )
            } catch (e) {
                notify(
                    "danger",
                    t("notification:onCampaignUpdate.fail.title"),
                    t("notification:onCampaignUpdate.fail.message",{ name: values.name })
                )
            }
        }

        // irrelevant dependency
        // eslint-disable-next-line
    }, [])

    return <>
        <Formik
            enableReinitialize={ true }
            initialValues={ initialValues }
            validationSchema={ validationSchemaRef.current }
            onSubmit={ handleSubmit }
        >
            {
                ((formikProps: FormikProps<IFromFields>) => {
                    return <>
                        <Form>
                            <FormikDirtyListener onChange={ props.setIsFormDirty } />

                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <InputField
                                        label={ t("campaigns:page.details.form.name.label") }
                                        name="name"
                                        fieldSize="expanded"
                                        isReadOnly={ hasCampaignEnded }
                                    />

                                    <InputField
                                        label={ t("campaigns:page.details.form.defaultMaxUsageCount.label") }
                                        name="defaultMaxUsagesCount"
                                        type="number"
                                        min={ 1 }
                                        step={ 1 }
                                        isReadOnly={ hasCampaignEnded }
                                    />
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-body">
                                    {/*starts at*/}
                                    <div className="field">
                                        <label className="label">{ t("campaigns:page.details.form.startsAt.label") }</label>
                                        <div className="control">
                                            <div className="field has-addons">

                                                {/*date*/}
                                                <div className="control is-expanded">
                                                    <Field
                                                        className={ `input ${ formikProps.touched.startsAt && formikProps.errors.startsAt ? "is-danger" : ""  }` }
                                                        name="startsAt"
                                                        type="date"
                                                        readOnly={ hasCampaignEnded }
                                                        disabled={ !hasCampaignEnded && props.type === "existing" && DateTime.fromISO(formikProps.values.startsAt) < DateTime.now().toUTC() }
                                                        min={ DateTime.now().toLocal().toFormat("yyyy-MM-dd") }
                                                        value={ DateTime.fromISO(formikProps.values.startsAt).toLocal().toFormat("yyyy-MM-dd") }
                                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                                            const date = DateTime.fromFormat(e.target.value, "yyyy-MM-dd")
                                                            const newVal = DateTime.fromISO(formikProps.values.startsAt).toLocal().set({ year: date.year, month: date.month, day: date.day })
                                                            formikProps.setFieldValue("startsAt", newVal.toUTC().toISO())
                                                        } }
                                                    />
                                                </div>

                                                {/*time*/}
                                                <div className="control">
                                                    <Field
                                                        className={ `input ${ formikProps.touched.startsAt && formikProps.errors.startsAt ? "is-danger" : "" }` }
                                                        name="startsAt"
                                                        type="time"
                                                        readOnly={ hasCampaignEnded }
                                                        disabled={ !hasCampaignEnded && props.type === "existing" && DateTime.fromISO(formikProps.values.startsAt) < DateTime.now().toUTC() }
                                                        value={ DateTime.fromISO(formikProps.values.startsAt).toLocal().toFormat("HH:mm") }
                                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                                            const time = DateTime.fromFormat(e.target.value, "HH:mm")
                                                            const newVal = DateTime.fromISO(formikProps.values.startsAt).toLocal().set({ hour: time.hour, minute: time.minute })
                                                            formikProps.setFieldValue("startsAt", newVal.toUTC().toISO())
                                                        } }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            formikProps.touched.startsAt && formikProps.errors.startsAt
                                            && <p className="help is-danger">
                                                { formikProps.errors.startsAt }
                                            </p>
                                        }
                                    </div>

                                    {/*ends at*/}
                                    <div className="field">
                                        <label className="label">{ t("campaigns:page.details.form.endsAt.label") }</label>
                                        <div className="control">
                                            <div className="field has-addons">

                                                {/*date*/}
                                                <div className="control is-expanded">
                                                    <Field
                                                        className={ `input ${ formikProps.touched.endsAt && formikProps.errors.endsAt ? "is-danger" : "" }` }
                                                        name="endsAt"
                                                        type="date"
                                                        readOnly={ hasCampaignEnded }
                                                        min={ DateTime.fromISO(formikProps.values.startsAt).toLocal().toFormat("yyyy-MM-dd") }
                                                        value={ DateTime.fromISO(formikProps.values.endsAt).toLocal().toFormat("yyyy-MM-dd") }
                                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                                            const date = DateTime.fromFormat(e.target.value, "yyyy-MM-dd")
                                                            const newVal = DateTime.fromISO(formikProps.values.endsAt).toLocal().set({ year: date.year, month: date.month, day: date.day })
                                                            formikProps.setFieldValue("endsAt", newVal.toUTC().toISO())
                                                        } }
                                                    />
                                                </div>

                                                {/*time*/}
                                                <div className="control">
                                                    <Field
                                                        className={ `input ${ formikProps.touched.endsAt && formikProps.errors.endsAt ? "is-danger" : "" }` }
                                                        name="endsAt"
                                                        type="time"
                                                        readOnly={ hasCampaignEnded }
                                                        value={ DateTime.fromISO(formikProps.values.endsAt).toLocal().toFormat("HH:mm") }
                                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                                            const time = DateTime.fromFormat(e.target.value, "HH:mm")
                                                            const newVal = DateTime.fromISO(formikProps.values.endsAt).toLocal().set({ hour: time.hour, minute: time.minute })
                                                            formikProps.setFieldValue("endsAt", newVal.toUTC().toISO())
                                                        } }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            formikProps.touched.endsAt && formikProps.errors.endsAt
                                            && <p className="help is-danger">
                                                { formikProps.errors.endsAt }
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/*@ts-ignore: I guess types are not updated for react@18+*/}
                            <FieldArray name="doctors">
                                {
                                    ((helpers) => {
                                        return <>
                                            <Field name="doctors">
                                                {
                                                    ((fieldProps: FieldProps<string[]>) => {
                                                        return <>
                                                            <DoctorSelect
                                                                fieldProps={ fieldProps }
                                                                onAddCallback={ value => helpers.push(value) }
                                                                onRemoveCallback={ index => helpers.remove(index) }
                                                                hasCampaignEnded={ hasCampaignEnded }
                                                            />
                                                        </>
                                                    })
                                                }
                                            </Field>
                                        </>
                                    })
                                }
                            </FieldArray>

                            {/* form actions */}
                            <div className={ `field is-grouped ${ props.type === "new" ? "is-justify-content-space-between" : "is-justify-content-end"}` }>
                                {
                                    props.type === "new"
                                    && <>
                                        <button
                                            className="button"
                                            type="button"
                                            onClick={ props.onClose }
                                        >
                                            { t("campaigns:page.details.form.buttons.close") }
                                        </button>
                                    </>
                                }
                                {
                                    !hasCampaignEnded
                                    && <>
                                        <button
                                            className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                            type="submit"
                                            disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                        >
                                            { t("campaigns:page.details.form.buttons.submit") }
                                        </button>
                                    </>
                                }
                            </div>
                        </Form>
                    </>
                })
            }
        </Formik>
    </>
}

export default Details
