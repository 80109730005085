import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import * as yup from "yup"
import _ from "lodash"

import * as api from "service/http/api"
import { notify } from "utils/notifications"
import { handleAxiosError } from "service/service"
import FormikDirtyListener from "components/common/Form/FormikDirtyListener"
import InputField from "components/common/Form/InputField"
import Icon from "@mdi/react"
import { mdiClose, mdiPencil } from "@mdi/js"
import { EPermissionRoles, hasPermissions } from "utils/auth"

export interface IFormFields {
    [key: string]: any
    primaryEmail: string
    name: string
    phoneNumber: string
    isCreatorsPayoutPercentageRequired: boolean // used to make creatorsPayoutPercentage required
    creatorsPayoutPercentage: number
}

interface NewProps {
    type: "new"
    details?: never
    onCreate: (details: api.managers.ICreateManagerParams) => Promise<void>
    onUpdate?: never
    onClose: () => void
}

interface ExistingProps {
    type: "existing"
    details: api.managers.IManager
    onCreate?: never
    onUpdate: (details: api.managers.IUpdateManagerParams) => Promise<void>
    onClose?: never
}

type Props = (NewProps | ExistingProps) & {
    setFormIsDirty: (value: boolean) => void
}

const Details: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "notification", "modal", "managers", "common" ])

    const [ initialValues, setInitialValues ] = useState<IFormFields>({
        primaryEmail:                       props.type === "existing" ? props.details.primaryEmail : "",
        name:                               props.type === "existing" ? props.details.name : "",
        phoneNumber:                        props.type === "existing" ? props.details.phoneNumber : "",
        isCreatorsPayoutPercentageRequired: hasPermissions(EPermissionRoles.Manager),
        creatorsPayoutPercentage:           props.type === "existing" ? props.details.creatorsPayoutPercentage ?? 0 : 0
    })

    const validationSchemaRef = useRef(
        yup.object<IFormFields>().shape({
            isCreatorsPayoutPercentageRequired: yup
                .boolean(),
            name: yup
                .string()
                .trim()
                .required(t("common:form.fieldIsRequired")),
            primaryEmail: yup
                .string()
                .trim()
                .email(t("common:form.invalidEmail"))
                .test('isEmailAlreadyInUse', t("common:form.emailInUse"), (value) => {
                    if (value)
                        return !(emailAlreadyInUse && emailAlreadyInUse === value);

                    return true
                })
                .required(t("common:form.fieldIsRequired")),
            phoneNumber: yup
                .string()
                .trim()
                .required(t("common:form.fieldIsRequired")),
            creatorsPayoutPercentage: yup
                .number()
                .min(0, t("common:form.invalidRange", { min: 0, max: 25 }))
                .max(25, t("common:form.invalidRange", { min: 0, max: 25 }))
                .when("isCreatorsPayoutPercentageRequired", {
                    is: true,
                    then: yup
                        .number()
                        .required(t("common:form.fieldIsRequired"))
                })
        })
    )

    const [ emailAlreadyInUse, setEmailAlreadyInUse ] = useState<string | null>(null)
    const [ allowEmailChange, setAllowEmailChange ] = useState<boolean>(false)

    useEffect(() => {
        if (props.type === "existing") {
            setInitialValues({
                primaryEmail:                       props.details.primaryEmail,
                name:                               props.details.name,
                phoneNumber:                        props.details.phoneNumber,
                isCreatorsPayoutPercentageRequired: hasPermissions(EPermissionRoles.Manager),
                creatorsPayoutPercentage:           props.details.creatorsPayoutPercentage ?? 0
            })
        }

        // eslint-disable-next-line -- props.type never changes
    }, [ props.details ])

    const handleSubmit = async (values: IFormFields, formikHelpers: FormikHelpers<IFormFields>) => {
        values = validationSchemaRef.current.cast(_.omit(values, "isCreatorsPayoutPercentageRequired")) as IFormFields

        if (props.type === "new") {
            try {
                setEmailAlreadyInUse(null)

                await props.onCreate(values)

                notify(
                    "success",
                    t("notification:onManagerCreate.success.title"),
                    t("notification:onManagerCreate.success.message", {
                        name: `${values.name}`
                    })
                )
            } catch (error) {
                handleAxiosError(error, (e) => {
                    if (e.response?.status === 422) {
                        setEmailAlreadyInUse(values.primaryEmail)
                        formikHelpers.setFieldError("primaryEmail", t("common:form.emailInUse"))
                    }
                    else
                        notify(
                            "danger",
                            t("notification:onManagerCreate.fail.title"),
                            t("notification:onManagerCreate.fail.message")
                        )
                }, (e) => {
                    notify(
                        "danger",
                        t("notification:onManagerCreate.fail.title"),
                        t("notification:onManagerCreate.fail.message")
                    )
                }, (e) => {
                    // my bad
                })
            }
        } else {
            let params: api.managers.IUpdateManagerParams = {}

            Object.keys(values).forEach((key) => {
                if (values[key as keyof IFormFields] !== initialValues[key as keyof IFormFields])
                    params[key as keyof api.managers.IUpdateManagerParams] = values[key as keyof IFormFields]
            })

            try {
                await props.onUpdate(params)

                // in case the only updates were some whitespaces
                // the values are not actually updated, so we remove them like this
                formikHelpers.resetForm()
                setAllowEmailChange(false)

                // notify
                notify(
                    "success",
                    t("notification:onManagerUpdate.success.title"),
                    t("notification:onManagerUpdate.success.message", {
                        name: `${values.name}`
                    })
                )
            } catch (e) {
                notify(
                    "danger",
                    t("notification:onManagerUpdate.fail.title"),
                    t("notification:onManagerUpdate.fail.message", {
                        name: `${values.name}`
                    })
                )
            }
        }
    }

    return <>
        <Formik
            enableReinitialize={ true }
            initialValues={ initialValues }
            validationSchema={ validationSchemaRef.current }
            onSubmit={ handleSubmit }
        >
            {
                ((formikProps: FormikProps<IFormFields>) => {
                    return <>
                        <Form>
                            <FormikDirtyListener onChange={ props.setFormIsDirty } />

                            <InputField
                                label={ t("managers:page.details.form.name.label") }
                                name="name"
                                placeholder={ t("managers:page.details.form.name.placeholder") }
                            />

                            <InputField
                                label={ t("managers:page.details.form.phoneNumber.label") }
                                name="phoneNumber"
                            />

                            {
                                hasPermissions(EPermissionRoles.Manager)
                                && <>
                                    <InputField
                                        label={ t("managers:page.details.form.creatorsPayoutPercentage.label") }
                                        name="creatorsPayoutPercentage"
                                        helpMessage={ t("managers:page.details.form.creatorsPayoutPercentage.help") }
                                        type="number"
                                        min="0"
                                        max="25"
                                        step="0.01"
                                    />
                                </>
                            }

                            <div className="field">
                                <label className="label">
                                    { t("managers:page.details.form.primaryEmail.label") }
                                </label>
                                <div className="field is-horizontal">
                                    <div className="field-body">
                                        {
                                            props.type === "existing"
                                            && <>
                                                {/*enable/disable e-mail input*/}
                                                <div className="field is-narrow">
                                                    <button
                                                        className="button is-circular"
                                                        title={
                                                            allowEmailChange
                                                            ? t("managers:page.details.form.buttons.closeEditEmail.title")
                                                            : t("managers:page.details.form.buttons.editEmail.title")
                                                        }
                                                        type="button"
                                                        onClick={ () => {
                                                            const next = !allowEmailChange

                                                            setAllowEmailChange(next)

                                                            if (!next)
                                                                formikProps.setFieldValue("primaryEmail", initialValues.primaryEmail)

                                                        } }
                                                    >
                                                        <span className="icon is-small">
                                                            <Icon path={ allowEmailChange ? mdiClose : mdiPencil } size={ 1 }/>
                                                        </span>
                                                    </button>
                                                </div>
                                            </>
                                        }

                                        <InputField
                                            disabled={ props.type === "existing" && !allowEmailChange }
                                            name="primaryEmail"
                                            placeholder={ t("managers:page.details.form.primaryEmail.placeholder") }
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* form actions */}
                            <div className={ `field is-grouped ${ props.type === "new" ? "is-justify-content-space-between" : "is-justify-content-end"}` }>
                                {
                                    props.type === "new"
                                    && <>
                                        <button
                                            className="button"
                                            type="button"
                                            onClick={ props.onClose }
                                        >
                                            { t("managers:page.details.form.buttons.close") }
                                        </button>
                                    </>
                                }
                                <button
                                    className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                    type="submit"
                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                >
                                    { t("managers:page.details.form.buttons.submit") }
                                </button>
                            </div>

                        </Form>
                    </>
                })
            }
        </Formik>
    </>
}

export default Details
