import { FunctionComponent } from "react"
import { CookieConsent } from "react-cookie-consent"
import "./CookieConsentBar.scss"
import { useTranslation } from "react-i18next"

const CookieConsentBar: FunctionComponent = () => {
    const { t } = useTranslation([ "common" ])

    return (
        <div className="cookie-consent-bar">
            <CookieConsent
                disableButtonStyles
                location="bottom"
                containerClasses="cookie-consent-bar-container is-align-items-center"
                contentClasses="content"
                buttonClasses="button is-primary is-light m-4"
                buttonText={ t("common:cookieConsent.buttons.ok") }
                flipButtons
                enableDeclineButton
                declineButtonClasses="button is-primary is-light m-4"
                declineButtonText={ t("common:cookieConsent.buttons.rejectNonEssential") }
            >
                { t("common:cookieConsent.message") }
            </CookieConsent>
        </div>
    )
}

export default CookieConsentBar
