import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react"
import Cropper, { Area, MediaSize, Point } from "react-easy-crop"
import _ from "lodash"
import getCroppedImg from "components/common/ImageCrop/cropUtils"

interface Props {
    image: File
    aspect: number // 1 for square
    onComplete: (file: File) => void
    onCancel: () => void
}

// crop container
const CONTAINER_HEIGHT = 600
const CONTAINER_WIDTH = 600
const CROPPER_PADDING = 100

// zoom
const ZOOM_STEP = 0.1
const MIN_ZOOM = 0.5
const MAX_ZOOM = 5

// rotation
const ROTATION_STEP = 5
const MIN_ROTATION = -180
const MAX_ROTATION = 180

const ImageCrop: FunctionComponent<Props> = (props) => {
    const [ imgUrl, setImgUrl ] = useState<string | undefined>(undefined)
    const [ crop, setCrop ] = useState<Point>({ x: 0, y: 0 })
    const [ pixelCrop, setPixelCrop ] = useState<Area>()
    const [ zoom, setZoom ] = useState<number>(1)
    const [ rotation, setRotation ] = useState<number>(0)

    useEffect(() => {
        if (imgUrl)
            URL.revokeObjectURL(imgUrl)

        if (props.image)
            setImgUrl(URL.createObjectURL(props.image))

        return () => {
            if (imgUrl)
                URL.revokeObjectURL(imgUrl)
        }

        // eslint-disable-next-line -- imgUrl is not relevant
    }, [ props.image ])

    const getCropSize = () => {
        const width = CONTAINER_WIDTH - CROPPER_PADDING
        const height = CONTAINER_HEIGHT - CROPPER_PADDING

        return {
            width : props.aspect > 0
                    ? width
                    : width / props.aspect,
            height: props.aspect < 0
                    ? height
                    : height / props.aspect
        }
    }

    const handleZoom = (e: ChangeEvent<HTMLInputElement>) => {
        const value = _.parseInt(e.target.value) / 100;

        if (value < MIN_ZOOM) setZoom(MIN_ZOOM)
        else if (value > MAX_ZOOM) setZoom(MAX_ZOOM)
        else setZoom(value)
    }

    const handleRotation = (e: ChangeEvent<HTMLInputElement>) => {
        const value = _.parseInt(e.target.value);

        if (value < MIN_ROTATION) setRotation(MIN_ROTATION)
        else if(value > MAX_ROTATION) setRotation(MAX_ROTATION)
        else setRotation(value)
    }

    const onMediaLoaded = (mediaSize: MediaSize) => {
        const cropSize = getCropSize()

        let zoom = cropSize.width > cropSize.height
                   ? cropSize.width / mediaSize.width
                   : cropSize.height / mediaSize.height

        setZoom(zoom)
    }

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setPixelCrop(croppedAreaPixels)
    }, [])

    const onComplete = async () => {
        const blob = await getCroppedImg(imgUrl!, pixelCrop!, rotation)

        if (blob)
            props.onComplete(new File([blob], props.image.name, { type: props.image.type }))
    }

    return <>
        <div className="modal is-active">
            <div className="modal-background"/>
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <div className="field" style={
                            {
                                position: "relative",
                                height: `${CONTAINER_HEIGHT}px`,
                            }
                        }>
                            <Cropper
                                image={ imgUrl }
                                aspect={ props.aspect }
                                rotation={ rotation }
                                crop={ crop }
                                cropSize={ getCropSize() }
                                onCropChange={ setCrop }
                                onCropComplete={ onCropComplete }
                                zoom={ zoom }
                                onZoomChange={ setZoom }
                                onMediaLoaded={ onMediaLoaded }
                                restrictPosition={ false }
                            />
                        </div>

                        {/*zoom*/}
                        <div className="field">
                            <label className="label">ZOOM</label>
                            <div className="control is-expanded">
                                <input
                                    className="slider is-primary is-circle is-fullwidth"
                                    name="zoom"
                                    type="range"
                                    value={ zoom * 100 }
                                    step={ ZOOM_STEP * 100 }
                                    min={ MIN_ZOOM * 100 }
                                    max={ MAX_ZOOM * 100 }
                                    onChange={ handleZoom }
                                />
                            </div>
                        </div>

                        {/*rotation*/}
                        <div className="field">
                            <label className="label">ROTATION</label>
                            <div className="control">
                                <input
                                    className="slider is-primary is-circle is-fullwidth"
                                    name="rotation"
                                    type="range"
                                    value={ rotation }
                                    step={ ROTATION_STEP }
                                    min={ MIN_ROTATION }
                                    max={ MAX_ROTATION }
                                    onChange={ handleRotation }
                                />
                            </div>
                        </div>

                        {/*buttons*/}
                        <div className="buttons is-justify-content-space-between">
                            <button
                                className="button"
                                type="button"
                                onClick={ props.onCancel }
                            >
                                CANCEL
                            </button>

                            <button
                                className="button is-primary"
                                type="button"
                                onClick={ onComplete }
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ImageCrop
