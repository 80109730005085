import { FunctionComponent } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { ReactNotifications } from "react-notifications-component"

import routes from "routes/routes"
import { WithConfirmModal } from "components/common/ConfirmModal/ConfirmModal"
import AdminDashboard from "./AdminDashboard/AdminDashboard"
import { EPermissionRoles } from "utils/auth"

import "react-notifications-component/dist/theme.css"
import Authenticated from "components/common/Routes/Authenticated"
import Permission from "components/common/Routes/Permission"
import Login from "components/Login/Login"
import CookieConsentBar from "./common/CookieConsentBar/CookieConsentBar"

const App: FunctionComponent = () => {
    return <>
        <ReactNotifications />
        <CookieConsentBar />
        <WithConfirmModal >
            <Switch>
                <Route exact path="/">
                    <Redirect to={ routes.admin } />
                </Route>

                {/*no auth*/}
                    <Route exact path={ routes.login } render={ () => {
                        return <>
                            <Authenticated
                                authRequired={ false }
                                redirectTo={ routes.admin }
                            >
                                <Login />
                            </Authenticated>
                        </>
                    } } />

                {/*auth*/}
                <Route path={ routes.admin } render={ () => {
                    return <>
                        <Authenticated
                            authRequired={ true }
                            redirectTo={ routes.login }
                        >
                            <Permission
                                logoutOnDenied={ true }
                                roles={ [ EPermissionRoles.Admin, EPermissionRoles.Manager ] }
                            >
                                <AdminDashboard/>
                            </Permission>
                        </Authenticated>
                    </>
                } }/>
            </Switch>
        </WithConfirmModal>
    </>
}

export default App
