import { FunctionComponent, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { mdiArrowLeft } from "@mdi/js"
import Icon from "@mdi/react"

import routes from "routes/routes"
import * as api from "service/http/api"
import { handleAxiosError } from "service/service"
import { noResponseReceivedNotification } from "utils/notifications"

import Loading from "components/common/Loading/Loading"
import Details from "components/AdminDashboard/Doctors/Page/Details/Details"
import Presentation from "components/AdminDashboard/Doctors/Page/Presentation/Presentation"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import Account from "components/AdminDashboard/Doctors/Page/Account"
import Quote from "./AdditionalDetails/Quote"
import Signature from "./AdditionalDetails/Signature"
import MediaContent from "./MediaContent/MediaContent"

export interface IDoctorPageHistoryState {
    from?: {
        pathname: string
        search: string
    }
}

export interface IDoctorPageParams {
    doctorId: string
}

const Page: FunctionComponent = () => {
    const { t, i18n } = useTranslation([ "notification", "modal", "doctors" ])
    const { doctorId } = useParams<IDoctorPageParams>()
    const history = useHistory<IDoctorPageHistoryState>()

    const setModalOptions = useConfirmModal()

    // region state
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ doctor, setDoctor ] = useState<api.doctors.IDoctor>({} as api.doctors.IDoctor)
    const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false)
    // endregion

    // region mount
    useEffect(() => {
        const fetch = async () => {
            try {
                const doctor = await api.doctors.getDoctor(doctorId)
                setDoctor(doctor)
            } catch (error) {
                handleAxiosError(
                    error,
                    (e) => {
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                    history.replace(routes.doctors)
                }, (e) => {
                    // no connection or smth
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // this my bad
                    // console.log('Error', e)
                })
            }
        }

        fetch().then(() => setIsLoading(false))

        // irrelevant dependencies
        // eslint-disable-next-line
    }, [])
    // endregion

    // region handlers
    const handleGoBack = () => {
        const goBack = () => {
            const state = history.location.state

            if (state && state.from && state.from.pathname === routes.doctors)
                history.goBack()
            else
                history.push(routes.doctors)
        }

        if (isFormDirty) {
            setModalOptions({
                isOpen: true,
                modalProps: {
                    title: t("modal:discardChanges.title"),
                    content: t("modal:discardChanges.message"),
                    onConfirm: () => {
                        setModalOptions({ isOpen: false })
                        goBack()
                    },
                    onCancel: () => {
                        setModalOptions({ isOpen: false })
                    }
                }
            })
        } else {
            goBack()
        }
    }

    // region details
    const updateDoctorDetails = async (entry: api.doctors.IUpdateDoctorParams) => {
        const response = await api.doctors.updateDoctor(doctorId, entry)

        let newState = { ...doctor }

        newState = {
            ...newState,
            ..._.omit(entry, "profilePicture") as api.doctors.IDoctor,
            profilePicture: response.pictureUrl ?? newState.profilePicture
        }

        setDoctor(newState)
    }
    // endregion

    // region additional details
    const updateDoctorAdditionalDetails = async (entry: api.doctors.IUpdateDoctorAdditionalDetailsParams) => {
        const response = await api.doctors.updateDoctorAdditionalDetails(doctorId, entry)

        const newState = {
            ...doctor,
            quote: entry.quote ?? doctor.quote,
            signature: response.signatureUrl ?? doctor.signature
        }

        setDoctor(newState)
    }
    // endregion

    // region presentation
    const createDoctorPresentation = async (entry: api.doctors.ICreatePresentationParams, displayLanguage: string) => {
        await api.doctors.createPresentation(doctorId, displayLanguage, entry)

        let newState = _.cloneDeep(doctor)

        newState.presentations = [
            ...newState.presentations,
            {
                ...entry,
                displayLanguageCode: displayLanguage,
                mediaContents: [] as api.doctors.IDoctorMediaContent[]
            } as api.doctors.IDoctorPresentation
        ]

        setDoctor(newState)
    }

    const updateDoctorPresentation = async (entry: api.doctors.IUpdatePresentationParams, displayLanguage: string) => {
        await api.doctors.updatePresentation(doctorId, displayLanguage, entry)

        let newState = _.cloneDeep(doctor)

        const presentationIndex = newState.presentations.findIndex(it => it.displayLanguageCode === displayLanguage)
        Object.keys(entry).forEach(key => {
            newState.presentations[presentationIndex][key] = entry[key]!
        })

        setDoctor(newState)
    }
    //endregion

    // region media content
    const createDoctorMediaContent = async (entry: api.doctors.TCreateMediaContentParams) => {
        const newMediaContent = await api.doctors.createMediaContent(doctorId, entry)

        let newState = _.cloneDeep(doctor)

        newState.mediaContents = [
            newMediaContent,
            ...newState.mediaContents
        ]

        setDoctor(newState)
    }

    const updateDoctorMediaContent = async (entry: api.doctors.TUpdateMediaContentParams, mediaContentId: string) => {
        const updatedMediaContent = await api.doctors.updateMediaContent(doctorId, mediaContentId, entry)

        let newState = _.cloneDeep(doctor)

        const mediaContentIndex = newState.mediaContents.findIndex(it => it.id === mediaContentId)

        newState.mediaContents[mediaContentIndex] = updatedMediaContent

        setDoctor(newState)
    }

    const deleteDoctorMediaContent = async (mediaContentId: string) => {
        await api.doctors.deleteMediaContent(doctorId, mediaContentId)

        let newState = _.cloneDeep(doctor)

        const mediaContentIndex = newState.mediaContents.findIndex(it => it.id === mediaContentId)

        newState.mediaContents.splice(mediaContentIndex, 1)

        setDoctor(newState)
    }
    //endregion

    // region account
    const updateDoctorAccountState = async (state: api.accounts.EAccountState) => {
        await api.accounts.updateAccountState(doctorId, {
            state: state
        })

        setDoctor({
            ...doctor,
            accountState: state
        })
    }

    const updateDoctorPageVisibility = async (visibility: api.doctors.EDoctorPageVisibility) => {
        await api.doctors.updateDoctor(doctorId, {
            pageVisibility: visibility
        })

        setDoctor({
            ...doctor,
            pageVisibility: visibility
        })
    }

    const updateDoctorManager = async (params: api.doctors.TUpdateDoctorsManagersParams) => {
        await api.doctors.updateDoctorManager(doctorId, params)

        let newState: api.doctors.IDoctor

        if (params.removeManager) {
            newState = {
                ...doctor,
                managerId: null,
                managersPayoutPercentage: null
            }
        } else {
            newState = {
                ...doctor,
                ...params
            } as api.doctors.IDoctor
        }

        setDoctor(newState)
    }

    const resetDoctorPassword = async () => {
        await api.doctors.resetPassword(doctorId, i18n.resolvedLanguage)
    }
    //endregion
    // endregion handlers

    // region render
    if (isLoading)
        return <Loading />

    return <>
        {/*details and account*/}
        <div className="container">

            {/*back button*/}
            <div className="columns">
                <div className="column">
                    <button
                        className="button is-circular"
                        onClick={ handleGoBack }
                    >
                        <span className="icon is-small">
                            <Icon path={ mdiArrowLeft } size={ 1 } />
                        </span>
                    </button>
                </div>
            </div>

            {/*content*/}
            <div className="columns">
                <div className="column">
                    {/*details*/}
                    <div className="card">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("doctors:page.titles.details") }
                            </div>
                            <Details
                                type="existing"
                                details={ doctor }
                                onUpdate={ updateDoctorDetails }
                                setFormIsDirty={ setIsFormDirty }
                            />
                        </div>
                    </div>

                    {/*account*/}
                    <div className="card mt-5">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("doctors:page.titles.account") }
                            </div>
                            <Account
                                doctor={ doctor }
                                onStateChange={ updateDoctorAccountState }
                                onPageVisibilityChange={ updateDoctorPageVisibility }
                                onUpdateManager={ updateDoctorManager }
                                onPasswordReset={ resetDoctorPassword }
                            />
                        </div>
                    </div>
                </div>
                {/*presentation and media contents*/}
                <div className="column">

                    {/*presentation*/}
                    <div className="card">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("doctors:page.titles.presentation") }
                            </div>
                            <Presentation
                                presentations={ doctor.presentations }
                                onCreatePresentation={ createDoctorPresentation }
                                onUpdatePresentation={ updateDoctorPresentation }
                                setFormIsDirty={ setIsFormDirty }
                            />
                        </div>
                    </div>

                    {/*presentation*/}
                    <div className="card mt-5">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("doctors:page.titles.mediaContent") }
                            </div>
                            <MediaContent
                                mediaContents={ doctor.mediaContents }
                                onCreate={ createDoctorMediaContent }
                                onUpdate={ updateDoctorMediaContent }
                                onDelete={ deleteDoctorMediaContent }
                                setFormIsDirty={ setIsFormDirty }
                            />
                        </div>
                    </div>

                    {/*quote   */}
                    <div className="card mt-5">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("doctors:page.titles.quote") }
                            </div>

                            <Quote
                                doctor={ doctor }
                                onUpdate={ updateDoctorAdditionalDetails }
                                setFormIsDirty={ setIsFormDirty }
                            />
                        </div>
                    </div>

                    {/*Signature*/}
                    <div className="card mt-5">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("doctors:page.titles.signature") }
                            </div>

                            <Signature
                                doctor={ doctor }
                                onUpdate={ updateDoctorAdditionalDetails }
                                setFormIsDirty={ setIsFormDirty }
                            />
                        </div>
                    </div>
                </div> {/*column*/}
            </div> {/*columns */}
        </div> {/*container*/}
    </>
    // endregion
}

export default Page
