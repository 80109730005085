import { mdiPencil } from "@mdi/js"
import Icon from "@mdi/react"
import { ICampaignPageHistoryState } from "components/AdminDashboard/Campaigns/Page/Page"
import { DateTime } from "luxon"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import routes from "routes/routes"

import * as api from "service/http/api"

interface Props {
    tableRowIndex: number
    campaign: api.campaigns.ICampaign
}

const ListRow: FunctionComponent<Props> = (props) => {
    //eslint-disable-next-line
    const { t, i18n } = useTranslation([ "common", "campaigns" ])
    const history = useHistory()

    return <>
        <tr>
            <td>{ props.tableRowIndex + 1 }</td>
            <td>{ props.campaign.name }</td>
            <td>{ DateTime.fromISO(props.campaign.createdAt).toLocal().toLocaleString(DateTime.DATETIME_MED, { locale: i18n.resolvedLanguage }) }</td>
            <td>{ DateTime.fromISO(props.campaign.startsAt).toLocal().toLocaleString(DateTime.DATETIME_MED, { locale: i18n.resolvedLanguage }) }</td>
            <td>{ DateTime.fromISO(props.campaign.endsAt).toLocal().toLocaleString(DateTime.DATETIME_MED, { locale: i18n.resolvedLanguage }) }</td>
            <td>{ props.campaign.createdBy.displayName }</td>
            <td>{ props.campaign.defaultMaxUsagesCount }</td>
            <td>{ props.campaign.batchesCount }</td>
            <td>
                <div className="buttons is-centered">
                    {/*edit*/}
                    <Link
                        to={ {
                            pathname: routes.campaignPage(props.campaign.id),
                            state: {
                                from: {
                                    pathname: history.location.pathname,
                                    search: history.location.search
                                }
                            } as ICampaignPageHistoryState
                        } }
                    >
                        <button
                            className="button is-circular"
                            type="button"
                            title={ t("campaigns:list.table.buttons.edit.title") }
                        >
                        <span className="icon is-small">
                            <Icon path={ mdiPencil } size={ 1 } />
                        </span>
                        </button>
                    </Link>
                </div>
            </td>
        </tr>
    </>
}

export default ListRow
