import { mdiFileDownload } from "@mdi/js"
import Icon from "@mdi/react"
import _ from "lodash"
import { DateTime } from "luxon"
import { FunctionComponent, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import zipcelx, { ZipCelXConfig } from "zipcelx"

import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import * as api from "service/http/api"
import { noResponseReceivedNotification } from "utils/notifications"

interface Props {
    codes: api.campaigns.ICampaignCode[]
    campaignName: string
    onGenerateCodes: (amount: number) => void
}

const Codes: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "common", "notification", "campaigns", "modal" ])

    const showConfirmModal = useConfirmModal()

    const [ amountToGenerate, setAmountToGenerate ] = useState<number>(1)
    const [ isGeneratingCodes, setIsGeneratingCodes ] = useState<boolean>(false)

    const renderTableRow = useCallback((index: number, code: api.campaigns.ICampaignCode): JSX.Element => {
        return (
            <tr key={ code.code }>
                <td>{ index }</td>
                <td>{ code.code }</td>
                <td>{ DateTime.fromISO(code.createdAt).toLocal().toLocaleString(DateTime.DATETIME_MED, { locale: i18n.resolvedLanguage }) }</td>
                <td>
                    {
                        code.usedAt
                            ? DateTime.fromISO(code.usedAt).toLocal().toLocaleString(DateTime.DATETIME_MED, { locale: i18n.resolvedLanguage })
                            : "-"
                    }
                </td>
                <td>{ code.callsMadeCount }</td>
                <td>
                    {
                        code.client
                            ? code.client.fullName
                            : "-"
                    }
                </td>
            </tr>
        )
    }, [ i18n.resolvedLanguage ])

    const handleGenerateCodes = useCallback(() => {
        showConfirmModal({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.campaignGenerateCodes.title"),
                content: t("modal:confirm.campaignGenerateCodes.message", { amount: amountToGenerate }),
                onConfirm: () => {
                    showConfirmModal({ isOpen: false })
                    setIsGeneratingCodes(true)

                    try {
                        props.onGenerateCodes(amountToGenerate)
                        setAmountToGenerate(1)
                    }
                    catch(e) {
                        noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                    }

                    setIsGeneratingCodes(false)
                },
                onCancel: () => {
                    showConfirmModal({ isOpen: false })
                }
            }
        })

        // props and t are not relevant dependencies
        // eslint-disable-next-line
    }, [ amountToGenerate, i18n.resolvedLanguage ])

    const handleDownloadCSV = useCallback(async () => {
        const config: ZipCelXConfig = {
            filename: `${props.campaignName}_${DateTime.now().toFormat("yyyy-MM-dd_HH:mm")}`,
            sheet: {
                data: props.codes.map(it => ([{ value: it.code, type: 'string' }]))
            }
        }

        await zipcelx(config)
    }, [ props.codes, props.campaignName ])

    return <>
        <div className="is-flex is-justify-content-space-between">
            <div className="field is-narrow is-grouped is-grouped-right">
                <div className="control">
                    <div className="field">
                        <input
                            type="number"
                            className="input"
                            disabled={ isGeneratingCodes }
                            value={ amountToGenerate }
                            min={ 1 }
                            step={ 1 }
                            onChange={ e => setAmountToGenerate(_.toNumber(e.target.value)) }
                        />
                    </div>
                </div>

                <div className="control">
                    <button
                        className={ `button is-primary ${ isGeneratingCodes ? "is-loading" : "" }` }
                        disabled={ isGeneratingCodes }
                        onClick={ handleGenerateCodes }
                    >
                        { t("campaigns:page.codes.buttons.generate") }
                    </button>
                </div>
            </div>

            <div className="buttons">
                <button
                    className="button is-primary is-circular"
                    onClick={ handleDownloadCSV }
                >
                    <span className="icon is-small">
                        <Icon path={ mdiFileDownload } size={ 1 } />
                    </span>
                </button>
            </div>
        </div>

        <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{ t("campaigns:page.codes.table.headers.code") }</th>
                        <th>{ t("campaigns:page.codes.table.headers.createdAt") }</th>
                        <th>{ t("campaigns:page.codes.table.headers.usedAt") }</th>
                        <th>{ t("campaigns:page.codes.table.headers.callsMade") }</th>
                        <th>{ t("campaigns:page.codes.table.headers.usedBy") }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (() => {
                            if (props.codes.length === 0)
                                return <>
                                    <tr>
                                        <td colSpan={ 6 }>{ t("campaigns:page.codes.table.noCodesFound") }</td>
                                    </tr>
                                </>

                            return props.codes.map((code, index) => {
                                return renderTableRow(index + 1, code)
                            })
                        })()
                    }
                </tbody>
            </table>
        </div>
    </>
}

export default Codes
