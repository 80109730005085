import { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import _ from "lodash"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"

import { handleAxiosError } from "service/service"
import { noResponseReceivedNotification, notify } from "utils/notifications"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import usePagination from "hooks/usePagination"
import {
    deleteNotification,
    EMarketingNotificationState,
    getNotificationsPage,
    ICreateNotificationParams,
    INotification,
    IUpdateNotificationParams
} from "service/http/notifications"

import Loading from "components/common/Loading/Loading"
import NotificationsTableRow from "components/AdminDashboard/MarketingNotifications/NotificationsTableRow"
import AddEdit from "components/AdminDashboard/MarketingNotifications/AddEdit"
import Pagination from "components/common/Pagination/Pagination"
import PageSizeSelect from "components/common/Pagination/PageSizeSelect"
import Filters from "components/AdminDashboard/MarketingNotifications/Filters"

const Notifications: FunctionComponent = () => {
    const { t } = useTranslation([ "common", "notification", "modal" ])

    // pagination
    const location = useLocation()
    // eslint-disable-next-line
    const [ currentPage, currentPageSize, currentPageCount, currentFilters, changePage, handlePagination ] = usePagination(getNotificationsPage)

    const setModalOptions = useConfirmModal()

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ notifications, setNotifications ] = useState<INotification[]>([])
    const [ isEditingDetailsOf, setIsEditingDetailsOf ] = useState<number | null>(null)
    const [ isCreatingNotification, setIsCreatingNotification ] = useState<boolean>(false)

    useEffect(() => {
        setIsLoading(true)

        const fetch = async () => {
            try {
                const response = await handlePagination()

                if (response)
                    setNotifications(response)
            } catch (error) {
                handleAxiosError(error, (e) => {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // if it were a 401 error the user would be redirected
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                })
            }
        }

        fetch().then(() => {
            setIsLoading(false)
        })

        // t is not a relevant dependency
        //eslint-disable-next-line
    }, [ location.search ])

    const handleAddNotification = (notification: ICreateNotificationParams, entityId: string) => {
        let newState = _.cloneDeep<INotification[]>(notifications)

        newState.push({
            ...notification,
            id: entityId,
            marketingNotificationState: EMarketingNotificationState.NotToBeDelivered
        })

        setNotifications(newState)
        setIsCreatingNotification(false)
    }

    const handleUpdateNotification = (notification: IUpdateNotificationParams) => {
        let prevState = _.cloneDeep(notifications)

        // basically, every property key that exists in details exists in any INotification
        // and we know for sure that isEditingDetailsOf is set
        // trust me
        Object.keys(notification).forEach((key) => {
            prevState[isEditingDetailsOf!][key] = notification[key]!
        })

        setNotifications(prevState)
    }

    const handleDelete = (index: number) => {
        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.notificationDelete.title"),
                content: t("modal:confirm.notificationDelete.message", { title: notifications[index].title }),
                onConfirm: async () => {
                    try {
                        await deleteNotification(notifications[index].id)

                        let newState = _.cloneDeep(notifications)

                        newState.splice(index, 1)

                        setNotifications(newState)

                        notify(
                            "success",
                            t("notification:onNotificationDelete.success.title"),
                            t("notification:onNotificationDelete.success.message", {
                                title: notifications[index].title
                            })
                        )
                    } catch (e) {
                        notify(
                            "danger",
                            t("notification:onNotificationDelete.fail.title"),
                            t("notification:onNotificationDelete.fail.message", {
                                title: notifications[index].title
                            })
                        )
                    }

                    setModalOptions({ isOpen: false })
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })
    }

    return <>
        <h1 className="title">{ t("notification:page.table.title") }</h1>
        <h2 className="subtitle">{ t("notification:page.table.subtitle") }</h2>

        <div className="field is-horizontal is-pulled-right">
            <div className="field-body">
                <div className="field is-flex is-align-items-end">
                    <Filters
                        filters={ currentFilters }
                        onUpdate={ (filters) => {
                            changePage({
                                filters: filters
                            })
                        } }
                        onClear={ () => {
                            changePage({
                                filters: {}
                            })

                        } }
                    />
                </div>
                <div className="field">
                    <PageSizeSelect
                        currentPageSize={ currentPageSize }
                        onChangePage={ (pageSize) => {
                            changePage({
                                pageSize: pageSize
                            })
                        } }
                        isLoading={ isLoading }
                    />
                </div>
            </div>
        </div>

        <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
            <thead>
            <tr>
                <th>#</th>
                <th>{ t("notification:page.table.headers.profilePicture") }</th>
                <th>{ t("notification:page.table.headers.title") }</th>
                <th>{ t("notification:page.table.headers.schedule") }</th>
                <th>{ t("notification:page.table.headers.marketingNotificationState") }</th>
                {/*<th>{ t("notification:page.table.headers.pageToRedirectTo") }</th>*/}
                <th>
                    <div className="buttons is-centered">
                        <button className="button is-secondary is-circular" onClick={ () => { setIsCreatingNotification(true) } }>
                            <span className="icon is-small">
                                <Icon path={ mdiPlus } size={ 1 } />
                            </span>
                        </button>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            {
                (() => {
                    if (isLoading)
                        return <tr>
                            <td colSpan={ 6 }>
                                <Loading />
                            </td>
                        </tr>

                    if (!notifications.length)
                        return <tr>
                                <td colSpan={ 6 }>
                                    { t("notification:page.table.noNotificationsFoundMessage") }
                                </td>
                            </tr>

                    return notifications.map((notification, index) => {
                        return (
                            <NotificationsTableRow
                                key={ notification.id }
                                notificationsTableIndex={ ((currentPage - 1) * currentPageSize) + index + 1 }
                                notificationDetails={ notification }
                                onEdit={ () => { setIsEditingDetailsOf(index) } }
                                onDelete={ () => { handleDelete(index) } }
                            />
                        )
                    })
                })()
            }
            </tbody>
        </table>
        {
            notifications.length > 0 && !isLoading
            && <Pagination
                currentPage={ currentPage }
                lastPage={ currentPageCount }
                onPageChange={ (page) => {
                    changePage({
                        page: page
                    })
                } }
            />
        }
        {
            isCreatingNotification
            && <AddEdit
                type="new"
                onCreateNotification={ handleAddNotification }
                onClose={ () => { setIsCreatingNotification(false) } }
            />
        }
        {
            isEditingDetailsOf !== null
            && <AddEdit
                type="existing"
                details={ notifications[isEditingDetailsOf] }
                onUpdateNotification={ handleUpdateNotification }
                onClose={ () => { setIsEditingDetailsOf(null) } }
            />
        }
    </>
}

export default Notifications
