import { FunctionComponent, useEffect, useState } from "react"
import YouTube from "react-youtube"

import * as api from "service/http/api"
import { getYouTubeVideoIdFromUrl } from "utils/helpers"

import "components/AdminDashboard/Doctors/Page/MediaContent/Preview/Preview.scss"

interface YtSize {
    width: number,
    height: number
}

interface Props {
    type: api.doctors.EMediaContentType
    url: string
    onClose: () => void
}

const Preview: FunctionComponent<Props> = (props) => {

    const calcYtPlayerSize = ():YtSize => {
        // 1320 because we account for the padding of at least 40px around the video
        const newWidth = window.innerWidth > 1320 ? 1280 : window.innerWidth - 40
        const newHeight = (window.innerHeight / window.innerWidth) * newWidth

        return {
            width: newWidth,
            height: newHeight
        }
    }

    // state
    const [ ytSize, setYtSize ] = useState<YtSize>(calcYtPlayerSize())

    const handleResize = () => {
        setYtSize(calcYtPlayerSize())
    }

    // resize event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)

        return () => window.removeEventListener("resize", handleResize)

        // eslint-disable-next-line -- handleResize never changes
    }, [])

    return <>
        <div className="media-content-preview modal is-active">
            <div className="modal-background" onClick={ props.onClose } />
            <div className="modal-close" onClick={ props.onClose } />
            <div className="modal-content is-flex is-justify-content-center is-align-items-center">
                {
                    (() => {
                        switch (props.type) {
                            case api.doctors.EMediaContentType.Image:
                                return <>
                                    <img src={ props.url } alt="doctor-media-content-entry" />
                                </>

                            case api.doctors.EMediaContentType.YoutubeVideo:
                            case api.doctors.EMediaContentType.AgathaTvVideo:
                                return <>
                                    <YouTube
                                        videoId={ getYouTubeVideoIdFromUrl(props.url) }
                                        opts={ {
                                            width: ytSize.width.toString(),
                                            height: ytSize.height.toString(),
                                            playerVars: {
                                                autoplay: 1
                                            }
                                        } }
                                    />
                                </>
                        }
                    })()
                }
            </div>
        </div>
    </>
}

export default Preview
