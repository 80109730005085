import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import * as api from "service/http/api"
import routes from "routes/routes"
import Details from "components/AdminDashboard/Managers/Page/Details"

interface Props {
    onClose: () => void
}

const Add: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "modal", "managers" ])
    const history = useHistory()

    const setModalOptions = useConfirmModal()

    const [ formIsDirty, setFormIsDirty ] = useState<boolean>()

    const handleClose = () => {
        if (formIsDirty) {
            setModalOptions({
                isOpen: true,
                modalProps: {
                    title: t("modal:discardChanges.title"),
                    content: t("modal:discardChanges.message"),
                    onConfirm: () => {
                        setModalOptions({ isOpen: false })
                        props.onClose()
                    },
                    onCancel: () => {
                        setModalOptions({ isOpen: false })
                    }
                }
            })
        }
        else {
            props.onClose()
        }
    }

    const handleCreate = async (entry: api.managers.ICreateManagerParams) => {
        const response = await api.managers.createManager(entry)

        history.push(routes.managerPage(response.id))
    }

    return <>
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">
                            { t("managers:add.title") }
                        </p>
                        <Details
                            type="new"
                            setFormIsDirty={ setFormIsDirty }
                            onCreate={ handleCreate }
                            onClose={ handleClose }
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Add
