const adminPath = "/admin"

const routes = {
    login: "/login",
    admin: `${adminPath}`,
    stats: `${adminPath}/stats`,
    clinics: `${adminPath}/clinics`,
    doctors: `${adminPath}/doctors`,
    doctorsSpecialties: `${adminPath}/doctors/specialties`,
    doctorPage: (doctorId?: string) => `${adminPath}/doctors/${ doctorId ?? ":doctorId" }`,
    clients: `${adminPath}/clients`,
    clientPage: (clientId?: string) => `${adminPath}/clients/${ clientId ?? ":clientId" }`,
    instantCalls: `${adminPath}/instant-calls`,
    notifications: `${adminPath}/notifications`,
    localizationLanguages: `${adminPath}/localization/languages`,
    reports: `${adminPath}/reports`,
    managers: `${adminPath}/managers`,
    managerPage: (managerId?: string) => `${adminPath}/managers/${ managerId ?? ":managerId" }`,
    campaigns: `${adminPath}/campaigns`,
    campaignPage: (campaignId?: string) => `${adminPath}/campaigns/${ campaignId ?? ":campaignId" }`,
    documents: `${adminPath}/documents`,
    documentation: `${adminPath}/documentation`
}

export default routes
