import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"

import * as api from "service/http/api"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"

import Form from "components/AdminDashboard/Doctors/Specialties/Manage/Form"

interface NewProps {
    type: "add"
    info?: never
    onCreate: (specialty: api.doctors.ICreateDoctorSpecialtyResponse) => void
    onUpdate?: never
}

interface ExistingProps {
    type: "edit"
    info: api.doctors.IDoctorSpecialty
    onCreate?: never
    onUpdate: (specialty: api.doctors.IUpdateDoctorSpecialtyResponse) => void
}

type Props = (NewProps | ExistingProps) & {
    onClose: () => void
}

const Manage: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "modal", "doctors" ])

    const setModalOptions = useConfirmModal()
    const [ formIsDirty, setFormIsDirty ] = useState<boolean>()

    const handleClose = (checkDirty: boolean = true) => {
        if (formIsDirty && checkDirty) {
            setModalOptions({
                isOpen: true,
                modalProps: {
                    title: t("modal:discardChanges.title"),
                    content: t("modal:discardChanges.message"),
                    onConfirm: () => {
                        setModalOptions({ isOpen: false })
                        props.onClose()
                    },
                    onCancel: () => {
                        setModalOptions({ isOpen: false })
                    }
                }
            })
        }
        else {
            props.onClose()
        }
    }

    return <>
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">
                            { props.type === "edit" ? t("doctors:specialties.edit.title") : t("doctors:specialties.add.title") }
                        </p>
                        {
                            (() => {
                                if (props.type === "add")
                                    return <Form
                                        type="new"
                                        onCreate={ props.onCreate }
                                        setFormIsDirty={ setFormIsDirty }
                                        onClose={ handleClose }
                                    />

                                return <Form
                                    type="existing"
                                    info={ props.info }
                                    onUpdate={ props.onUpdate }
                                    setFormIsDirty={ setFormIsDirty }
                                    onClose={ handleClose }
                                />
                            })()
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Manage
