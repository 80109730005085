import { Suspense } from "react";
import { createRoot } from "react-dom/client"
import { Router } from "react-router-dom"
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history"
import { Provider } from "react-redux"
import Store from "./state/Store"

import { setUpAxios } from "service/service"
import { initFingerprint } from "service/fingerprint"

import Loading from "components/common/Loading/Loading"
import App from './components/App'

import './style/index.scss'

// import i18n (needs to be bundled ))
import './i18n'

// init FingerpirntJS
// this should be done asap
initFingerprint()

// Sentry

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
if (process.env.NODE_ENV === "production") {
    if (process.env.REACT_APP_SENTRY_RELEASE) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DNS,
            release: process.env.REACT_APP_SENTRY_RELEASE,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
    }
}

// set up axios (middleware, baseUrl...)
const history = createBrowserHistory() // for middleware redirects
setUpAxios(history)

// when the refresh token is deleted from local storage on another tab, redirect to login all pages
window.addEventListener('storage', () => {
    if (!window.localStorage.getItem("refreshToken")) {
        history.push(process.env.REACT_APP_UNAUTHORIZED_REDIRECT_TARGET!)
    }
})

// render App
const container = document.getElementById("root") as HTMLElement
const root = createRoot(container)
root.render(
    <Suspense fallback={ <Loading /> }>
        <Router history={history}>
            <Provider store={ Store }>
                <App />
            </Provider>
        </Router>
    </Suspense>
)
