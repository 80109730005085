import React, { FunctionComponent, useState } from "react"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { useTranslation } from "react-i18next"

import { notify } from "utils/notifications"
import * as api from "service/http/api"
import UniqueKeys from "utils/classes/UniqueKeys"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"

import Form from "components/AdminDashboard/Doctors/Page/MediaContent/Form/Form"
import Preview from "components/AdminDashboard/Doctors/Page/MediaContent/Preview/Preview"
import ListEntry from "components/AdminDashboard/Doctors/Page/MediaContent/ListEntry/ListEntry"

import "components/AdminDashboard/Doctors/Page/MediaContent/Form/Form.scss"

interface Props {
    mediaContents: api.doctors.IDoctorMediaContent[]
    onCreate: (entry: api.doctors.TCreateMediaContentParams) => Promise<void>
    onUpdate: (entry: api.doctors.TUpdateMediaContentParams, mediaContentId: string) => Promise<void>
    onDelete: (mediaContentId: string) => Promise<void>
    setFormIsDirty: (value: boolean) => void
}

let mediaContentEntryKeys = new UniqueKeys()

const MediaContent: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "doctors", "modal", "notification" ])

    const setModalProps = useConfirmModal()

    const [ isCreatingMediaContentEntry, setIsCreatingMediaContentEntry ] = useState<boolean>(false)
    const [ isEditingDetailsOf, setIsEditingDetailsOf ] = useState<number | null>(null)
    const [ isShowingPreviewOf, setIsShowingPreviewOf ] = useState<number | null>(null)

    const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false)

    const handleDelete = async (id: string, index: number) => {
        setModalProps({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.doctorMediaContentDelete.title"),
                content: t("modal:confirm.doctorMediaContentDelete.title"),
                onConfirm: async () => {
                    try {
                        await props.onDelete(id)
                        mediaContentEntryKeys.remove(index)

                        notify(
                            "success",
                            t("notification:onDoctorMediaContentDeleted.success.title"),
                            t("notification:onDoctorMediaContentDeleted.success.message")
                        )
                    } catch (e) {
                        notify(
                            "danger",
                            t("notification:onDoctorMediaContentDeleted.fail.title"),
                            t("notification:onDoctorMediaContentDeleted.fail.message")
                        )
                    }

                    setModalProps({ isOpen: false })
                },
                onCancel: () => {
                    setModalProps({ isOpen: false })
                }
            }
        })
    }

    const handleCloseForm = (checkDirty = true) => {
        if (isFormDirty && checkDirty) {
            setModalProps({
                isOpen: true,
                modalProps: {
                    title: t("modal:discardChanges.title"),
                    content: t("modal:discardChanges.message"),
                    onConfirm: () => {
                        handleFormDirtyChange(false)
                        setIsEditingDetailsOf(null)
                        setIsCreatingMediaContentEntry(false)
                        setModalProps({ isOpen: false })
                    },
                    onCancel: () => {
                        setModalProps({ isOpen: false })
                    }
                }
            })
        } else {
            setIsFormDirty(false)
            setIsEditingDetailsOf(null)
            setIsCreatingMediaContentEntry(false)
        }
    }

    const handleFormDirtyChange = (value: boolean) => {
        setIsFormDirty(value)
        props.setFormIsDirty(value)
    }

    return <>
        <div className="field">
            <div className="control">
                <button
                    className="button is-circular is-secondary"
                    title={ t("doctors:edit.mediaContent.buttons.add.title") }
                    onClick={ () => {
                        setIsCreatingMediaContentEntry(true)
                    } }
                >
                    <span className="icon is-small">
                        <Icon path={ mdiPlus } />
                    </span>
                </button>
            </div>
        </div>

        <div className="field mt-4">
            {
                (() => {
                    if(props.mediaContents.length > 0)
                        return props.mediaContents.map((entry, index) => {
                            return <ListEntry
                                key={ mediaContentEntryKeys.getKey(index) }
                                mediaContent={ entry }
                                onShowPreview={ () => setIsShowingPreviewOf(index) }
                                onEdit={ () => setIsEditingDetailsOf(index) }
                                onDelete={ () => handleDelete(entry.id, index) }
                            />
                        })

                    else
                        return <span className="has-text-centered">
                    <p className="content">
                        { t("doctors:edit.mediaContent.noMediaContentFound") }
                    </p>
                </span>
                })()
            }
        </div>

        {/*pop-ups*/}
        <div className={ "modal " + (isEditingDetailsOf !== null || isCreatingMediaContentEntry ? "is-active" : "") }>
            <div className="modal-background" />
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">
                            { isCreatingMediaContentEntry && t("doctors:edit.mediaContent.form.title.add") }
                            { isEditingDetailsOf !== null && t("doctors:edit.mediaContent.form.title.edit") }
                        </p>
                        {
                            isEditingDetailsOf !== null
                            && <>
                                <Form
                                    type="existing"
                                    mediaContentEntry={ props.mediaContents[isEditingDetailsOf] }
                                    onUpdate={ (entry) => props.onUpdate(entry, props.mediaContents[isEditingDetailsOf].id) }
                                    onClose={ handleCloseForm }
                                    setIsFormDirty={ handleFormDirtyChange }
                                />
                            </>
                        }
                        {
                            isCreatingMediaContentEntry
                            && <>
                                <Form
                                    type="new"
                                    onCreate={ props.onCreate }
                                    onClose={ handleCloseForm }
                                    setIsFormDirty={ handleFormDirtyChange }
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>

        {
            isShowingPreviewOf !== null
            && <Preview
                type={ props.mediaContents[isShowingPreviewOf].type }
                url={ props.mediaContents[isShowingPreviewOf].url }
                onClose={ () => { setIsShowingPreviewOf(null) } }
            />
        }
    </>
}

export default MediaContent
