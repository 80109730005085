import { createContext, FunctionComponent, useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import "./ConfirmModal.scss"

export interface ConfirmModalProps {
    title: string
    content: string | JSX.Element
    onConfirm: () => void
    onCancel: () => void
}

const ConfirmModal: FunctionComponent = () => {
    const { t } = useTranslation("modal")

    const {
        isOpen,
        modalProps: {
            title,
            content,
            onConfirm,
            onCancel,
        },
    } = useContext(ModalStateContext)

    return <>
        <div className={ "modal confirm-modal " + (isOpen ? "is-active" : "") }>
            <div className="modal-background" />
            <div className="modal-content" >
                <div className="card">
                    <div className="card-content has-text-centered">
                        {
                            title
                            && <p className="title">
                                { title }
                            </p>
                        }
                        <p className="content">
                            { content }
                        </p>
                        <div className="field is-grouped is-justify-content-space-between">
                            <button className="button is-danger" onClick={ onCancel }>{ t("confirm.cancelButton") }</button>
                            <button className="button is-secondary" onClick={ onConfirm }>{ t("confirm.confirmButton") }</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export interface UseModalStateProps {
    isOpen: boolean
    modalProps: ConfirmModalProps
}

const useModalState = (initialState: UseModalStateProps)  => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState.isOpen)
    const [modalProps, setModalProps] = useState<ConfirmModalProps>(initialState.modalProps)

    const setModalState = (props: { isOpen: boolean, modalProps?: ConfirmModalProps}) => {
        setIsOpen(props.isOpen)

        if (props.modalProps)
            setModalProps(props.modalProps)
    }

    return [
        {
            isOpen: isOpen,
            modalProps: modalProps
        },
        setModalState
    ] as const
}

const ModalStateContext = createContext<UseModalStateProps>({} as UseModalStateProps)
const ModalUpdaterContext = createContext<(props: { isOpen: boolean, modalProps?: ConfirmModalProps}) => void>(() => {})

export const WithConfirmModal: FunctionComponent = (props) => {
    const [ modalOptions, setModalOptions ] = useModalState({
        isOpen: false,
        modalProps: {
            title: "",
            content: "",
            onConfirm: () => {},
            onCancel: () => {}
        }
    })

    return <>
        <ModalUpdaterContext.Provider value={ setModalOptions }>
            <ModalStateContext.Provider value={ modalOptions }>
                <ConfirmModal/>
                { props.children }
            </ModalStateContext.Provider>
        </ModalUpdaterContext.Provider>
    </>
}

const useConfirmModal = ()  => {
    return useContext(ModalUpdaterContext)
}

export default useConfirmModal
