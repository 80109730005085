const en = "<!-- This is an example description which you should modify -->\n" +
    "## Description\n" +
    "\n" +
    "Dr. [FULL NAME] is a primary care doctor specialized in family medicine with competence in general medicine. You can also find their office at the \\Sfanta Maria\\ Clinic in Bucharest, Romania.\n" +
    "\n" +
    "## Schedule\n" +
    "\n" +
    "- Monday to Friday, 8:00 to 12:00 and 15:00 to 20:00\n" +
    "- Less likely to answer between 12:00 and 15:00\n" +
    "\n" +
    "For more information [check here](https://www.sfm.ro)"

const ro = "<!-- Acesta este un exemplu de descriere pe care ar trebui să il modificați -->\n" +
    "## Descriere \n" +
    "\n" +
    "Dr. [FULL NAME] este medic primar specializat in medicina de familie cu competenta in medicina generala. De asemenea, puteți găsi biroul lui la Clinica \\Sfanta Maria\\ din București, România.\n" +
    "\n" +
    "## Program \n" +
    "\n" +
    "- De luni până marți, 8:00 to 12:00 and 15:00 to 20:00\n" +
    "Mai puțin probabil să răspundă între orele 12:00 și 15:00\n" +
    "\n" +
    "Pentru mai multe informații [consultați aici](https://www.sfm.ro)"

const DefaultPresentationText: Record<string, string> = {
    en: en,
    ro: ro
}

// const DefaultPresentationText: IDefaultPresentationText = {
//     en: "# Description \n" +
//         "Dr. [FULL NAME] is a primary care doctor specialized in family medicine with competence in general medicine & pediatrics. You can also find her office at the \"Sfanta Maria\" Clinic in Bucharest, Romania.\n" +
//         "\n" +
//         "Dr. [FAMILY NAME] is available to answer any questions you have and can also keep in touch with you for support and advice.\n" +
//         "\n" +
//         "She is available to take your calls exclusively through the Agatha Health App on working days - from Monday to Friday - within her scheduled hours and even outside of them.",
//     ro: "# Description \n" +
//         "Dr. [NUME COMPLET] este medic primar specializat in medicina de familie cu competenta in medicina generala si pediatrie. O puteti gasi si la cabinetul ei la Clinica „Sfanta Maria” din Bucuresti.\n" +
//         "\n" +
//         "Dr. [NUME DE FAMILIE] este disponibila pentru a va raspunde la orice intrebari si poate de asemenea sa tina legatura cu dumneavoastra pentru sprijin si sfaturi.\n" +
//         "\n" +
//         "Ea este disponibilă să vă preia apelurile exclusiv prin intermediul aplicației Agatha Health în zilele lucrătoare - de Luni până Vineri, in orele de program dar si inafara programului."
// }

export default DefaultPresentationText
