import { DateTime } from "luxon"
import React, { FunctionComponent } from "react"
import Icon from "@mdi/react"
import { mdiDelete, mdiPencil } from "@mdi/js"
import { useTranslation } from "react-i18next"

import { EMarketingNotificationState, INotification } from "service/http/notifications"
import ImagePreview from "components/common/Form/ImagePreview"

interface Props {
    notificationsTableIndex: number
    notificationDetails: INotification
    onEdit: () => void
    onDelete: () => void
}

const NotificationsTableRow: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common" ])

    return <>
        <tr>
            <td>
                <strong>{ props.notificationsTableIndex }</strong>
            </td>
            <td>
                <ImagePreview
                    url={ props.notificationDetails.icon }
                    placeholderType="image"
                    size="32x32"
                    imgAlt="notification-icon"
                    shape="circle"
                />
            </td>
            <td>{ props.notificationDetails.title }</td>
            <td>{ t("common:date.dateTimeMedWithSeconds", { date: DateTime.fromISO(props.notificationDetails.sendSchedule).toLocal() }) }</td>
            <td>{ t("common:marketingNotificationState.state", { context: props.notificationDetails.marketingNotificationState }) }</td>
            {/*<td>{ props.notificationDetails.pageToRedirectTo }</td>*/}
            <td>
                <div className="buttons is-centered">
                    {
                        props.notificationDetails.marketingNotificationState !== EMarketingNotificationState.Delivered
                        && <>
                            <button
                                className="button is-circular"
                                type="button"
                                onClick={ props.onEdit }
                            >
                                <span className="icon is-small">
                                    <Icon path={ mdiPencil } size={ 1 } />
                                </span>
                            </button>

                            <button
                                className="button is-circular is-danger"
                                type="button"
                                onClick={ props.onDelete }
                            >
                            <span className="icon is-small">
                                <Icon path={ mdiDelete } size={ 1 } />
                            </span>
                            </button>
                        </>
                    }
                </div>
            </td>
        </tr>
    </>
}

export default NotificationsTableRow
