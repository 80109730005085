import { DateTime } from "luxon"
import React, { FunctionComponent, useEffect, useState } from "react"
import { MessageList } from "react-chat-elements"
import { useTranslation } from "react-i18next"

import { getInstantCallMessages, IInstantCallMessage } from "service/http/instantCalls"
import { handleAxiosError } from "service/service"
import { IBasicAccountInfo } from "service/http/accounts"

import Loading from "components/common/Loading/Loading"

// react-chat-elements CSS
import 'react-chat-elements/dist/main.css';
import "./Messages.scss"

interface Props {
    instantCallId: string
    clientInfo: IBasicAccountInfo
    doctorInfo: IBasicAccountInfo
    onClose: () => void
}

const Messages: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "instantCalls" ])

    const [ isLoading, setIsLoading ] = useState<boolean>(true)

    const [ messages, setMessages ] = useState<IInstantCallMessage[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                await fetchMessages()
            } catch (error) {
                handleAxiosError(error, () => {

                }, () => {

                }, () => {

                })
            }
        }

        fetch().then(() => {
            setIsLoading(false)
        })

        // fetchMessages never changes
        // eslint-disable-next-line
    }, [])

    const fetchMessages = async () => {
        const response = await getInstantCallMessages(props.instantCallId)
        setMessages(response)
    }

    const generateMessageListDataSource = () => {
        return messages.map((message) => {
            return {
                position: message.senderId === props.clientInfo.id ? "left" : "right",
                title: message.senderId === props.clientInfo.id
                       ? `${props.clientInfo.givenName} ${props.clientInfo.familyName}`
                       : `${props.doctorInfo.givenName} ${props.doctorInfo.familyName}`,
                text: message.text,
                date: DateTime.fromISO(message.timeStamp).toLocal().toJSDate()
            }
        })
    }

    return  <>
        <div className="modal is-active">
            <div className="modal-background"/>
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">{ t("instantCalls:messages.title") }</p>
                        <div className="columns">
                            <div className="column">
                                {
                                    (() => {
                                        if (isLoading)
                                            return <Loading />

                                        if (!messages.length)
                                            return <p className="has-text-centered">
                                                { t("instantCalls:messages.noMessagesFound") }
                                            </p>

                                        return <MessageList
                                            className="chat-container"
                                            dataSource={ generateMessageListDataSource() }
                                        />
                                    })()
                                }
                            </div>
                        </div>
                        <div className="field is-grouped">
                            <button className="button" type="button" onClick={ () => { props.onClose() } }>
                                { t("instantCalls:messages.closeButton") }
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default Messages
