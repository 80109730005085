import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import { DateTime } from "luxon"
import Icon from "@mdi/react"
import { mdiFilter } from "@mdi/js"
import { useTranslation } from "react-i18next"
import { Form, Formik, FormikProps } from "formik"
import * as yup from "yup"
import _ from "lodash"

import * as api from "service/http/api"

import InputField from "components/common/Form/InputField"
import CheckBoxSwitchField from "components/common/Form/CheckBoxSwitchField"
import { enumToNumberValuesArray } from "utils/helpers"
import SelectField from "components/common/Form/SelectField"

const DATE_FORMAT = "yyyy-MM-dd"

interface IFormFields {
    customRange: boolean
    range: api.stats.EStatsPredefinedDateRange
    start: string
    end: string
}

interface Props {
    filters: api.stats.TStatsFilters
    onUpdate: (filters: api.stats.TStatsFilters) => void
    onClear: () => void
}

const Filters: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "stats", "common" ])

    const getInitialValues = (filters: api.stats.TStatsFilters): IFormFields => {
        const start = filters.start
                      ? DateTime.fromSeconds(filters.start).toFormat(DATE_FORMAT)
                      : DateTime.now().minus({ days: 7 }).toFormat(DATE_FORMAT)

        const end = filters.end
                    ? DateTime.fromSeconds(filters.end).toFormat(DATE_FORMAT)
                    : DateTime.now().toFormat(DATE_FORMAT)

        return {
            customRange: filters.range === null || filters.range === undefined,
            range: filters.range ?? api.stats.EStatsPredefinedDateRange.LastWeek,
            start: start,
            end: end
        }
    }

    // region state
    const [ isOpen, setIsOpen ] = useState<boolean>(false)
    const [ initialValues, setInitialValues ] = useState<IFormFields>(getInitialValues(props.filters))
    const [ maxStartDate, setMaxStartDate ] = useState<DateTime>(DateTime.now().minus({ days: 7 }))
    // endregion

    // update initial values on url change
    useEffect(() => {
        // in case the filters are updated manually in the url
        setInitialValues(getInitialValues(props.filters))
    }, [ props.filters ])

    const handleSubmit = (values: IFormFields) => {
        let filters = {} as api.stats.TStatsFilters

        // @NOTE: we can do this for multiple filters in the future
        if (values.customRange) {
            const start = DateTime.fromFormat(values.start!, DATE_FORMAT)
            const end = DateTime.fromFormat(values.end!, DATE_FORMAT)

            filters.start = start.toUTC().toSeconds()
            filters.end = end.toUTC().toSeconds()
        } else {
            filters.range = values.range
        }

        props.onUpdate(filters)
        setIsOpen(false)
    }

    const handleClear = () => {
        props.onClear()
        setIsOpen(false)
    }

    return <>
        <div className={ `dropdown is-right ${isOpen ? 'is-active' : ""}` }>
            <div className="dropdown-trigger">
                <div className="buttons is-centered">
                    <button
                        className="button is-circular"
                        onClick={ () => { setIsOpen(!isOpen) } }
                    >
                    <span className="icon is-small">
                        <Icon path={ mdiFilter } size={ 1 } />
                    </span>
                    </button>
                </div>
            </div>
            <div className="dropdown-menu filters" role="menu">
                <div className="dropdown-content">
                    <Formik
                        enableReinitialize={ true }
                        initialValues={ initialValues }
                        validationSchema={
                            yup.object().shape({
                                customRange: yup.boolean(),
                                range: yup
                                    .number()
                                    .oneOf(enumToNumberValuesArray(api.stats.EStatsPredefinedDateRange))
                                    .when("customRange", {
                                        is: false,
                                        then: yup.number().required(t("common:form.fieldIsRequired"))
                                    }),
                                start: yup
                                    .string()
                                    .when("customRange", {
                                        is: true,
                                        then: yup.string().required(t("common:form.fieldIsRequired"))
                                    }),
                                end: yup
                                    .string()
                                    .when("customRange", {
                                        is: true,
                                        then: yup.string().required(t("common:form.fieldIsRequired"))
                                    }),
                            })
                        }
                        onSubmit={ handleSubmit }
                    >
                        {
                            ((formikProps: FormikProps<IFormFields>) => {
                                return <>
                                    <Form>
                                        <div className="dropdown-item">
                                            <CheckBoxSwitchField
                                                label={ t("stats:filters.custom.label") }
                                                name="customRange"
                                            />

                                            {
                                                (() => {
                                                    if (formikProps.values.customRange)
                                                        return <>
                                                            <InputField
                                                                name="start"
                                                                label={ t("stats:filters.custom.start") }
                                                                type="date"
                                                                min="2022-01-01"
                                                                max={ maxStartDate.toFormat(DATE_FORMAT) }
                                                            />

                                                            <InputField
                                                                name="end"
                                                                label={ t("stats:filters.custom.end") }
                                                                type="date"
                                                                min="2022-02-01"
                                                                max={ DateTime.now().toFormat(DATE_FORMAT) }
                                                                onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                                                    formikProps.handleChange(e)

                                                                    // set new startMax and start values
                                                                    const start = DateTime.fromFormat(formikProps.values.start, DATE_FORMAT)
                                                                    const end = DateTime.fromFormat(e.target.value, DATE_FORMAT)

                                                                    if (start.toMillis() >= end.minus({ days: 7 }).toMillis())
                                                                        formikProps.setFieldValue("start", end.minus({ days: 7 }).toFormat(DATE_FORMAT))

                                                                    setMaxStartDate(end.minus({ days: 7 }))
                                                                } }
                                                            />
                                                        </>

                                                    return <>
                                                        <SelectField
                                                            label={ t("stats:filters.range") }
                                                            name="range"
                                                            fieldSize="expanded"
                                                            onChange={ (event: ChangeEvent<HTMLSelectElement>) => {
                                                                formikProps.setFieldValue("range", _.toNumber(event.target.value))
                                                            } }
                                                        >
                                                            {
                                                                enumToNumberValuesArray(api.stats.EStatsPredefinedDateRange).map((value) => {
                                                                    return <option key={ value } value={ value }>
                                                                        { t("common:predefinedDateRange.type", { context: value }) }
                                                                    </option>
                                                                })
                                                            }
                                                        </SelectField>
                                                    </>
                                                })()
                                            }

                                        </div> {/* dropdown-item */}
                                        <div className="dropdown-item">
                                            <div className="field is-grouped is-justify-content-space-between">
                                                <button
                                                    className="button"
                                                    type="button"
                                                    onClick={ handleClear }
                                                >
                                                    { t("stats:filters.buttons.clear") }
                                                </button>
                                                <button
                                                    className="button is-primary"
                                                    type="submit"
                                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                                >
                                                    { t("stats:filters.buttons.apply") }
                                                </button>
                                            </div>
                                        </div> {/* dropdown-item */}
                                    </Form>
                                </>
                            })
                        }
                    </Formik>
                </div> {/* dropdown-content */}
            </div> {/* dropdown-menu */}
        </div> {/* dropdown */}
    </>
}

export default Filters
