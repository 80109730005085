import { FunctionComponent } from "react"
import { Line } from "react-chartjs-2"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"

import * as api from "service/http/api"
import * as colors from "assets/colors"

interface Props {
    stats: api.stats.IGroupedByDateInstantCallStats[]
}

const Chart: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "stats" ])

    return <>
        <Line
            data={{
                labels: props.stats.map(entry => {
                    let date = DateTime.utc().set({ year: entry.year, month: entry.month ?? 1, day: entry.day ?? 1}).toLocal()

                    if (!entry.month && !entry.day)
                        return date.year

                    if (!entry.day)
                        return t("common:date.dateMedWithoutDay", { date: date })

                    return t("common:date.dateMedWithoutDayName", { date: date })
                }),
                datasets: [
                    {
                        label: t("stats:chart.instantCalls.dataSetsLabels.total"),
                        data: props.stats.map(entry => entry.stats.total),
                        fill: false,
                        backgroundColor: colors.primary,
                        borderColor: colors.primary,
                        tension: 0
                    },
                    {
                        label: t("stats:chart.instantCalls.dataSetsLabels.answered"),
                        data: props.stats.map(entry => entry.stats.answered),
                        fill: false,
                        backgroundColor: colors.secondary,
                        borderColor: colors.secondary,
                        tension: 0
                    },
                    {
                        label: t("stats:chart.instantCalls.dataSetsLabels.notAnswered"),
                        data: props.stats.map(entry => entry.stats.notAnswered),
                        fill: false,
                        backgroundColor: colors.danger,
                        borderColor: colors.danger,
                        tension: 0
                    },
                    {
                        label: t("stats:chart.instantCalls.dataSetsLabels.rejected"),
                        data: props.stats.map(entry => entry.stats.rejected),
                        fill: false,
                        backgroundColor: colors.info,
                        borderColor: colors.info,
                        tension: 0
                    },
                    {
                        label: t("stats:chart.instantCalls.dataSetsLabels.notNotified"),
                        data: props.stats.map(entry => entry.stats.notNotified),
                        fill: false,
                        backgroundColor: colors.warning,
                        borderColor: colors.warning,
                        tension: 0
                    },
                ],
            }}
            options={{
                maintainAspectRatio: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            precision: 0
                        }
                    }
                }
            }}
        />
    </>
}

export default Chart
