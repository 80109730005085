import React, { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik, FormikProps } from "formik"
import * as yup from "yup"

import { createDisplayLanguage, IDisplayLanguage } from "service/http/localization"
import { notify } from "utils/notifications"
import languages from "utils/languages"
import SelectField from "components/common/Form/SelectField"

interface IFormFields {
    isoCode: string
}

interface Props {
    existingLanguages: IDisplayLanguage[]
    onCreate: (language: string) => void
    onClose: () => void
}

const AddDisplayLanguageForm: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "common", "localization", "notification" ])

    const [ initialValues ] = useState<IFormFields>({
        isoCode: ""
    })

    const handleSubmit = async (values: IFormFields) => {
        try {
            await createDisplayLanguage({
                languageIsoCode: values.isoCode
            })

            props.onCreate(values.isoCode)

            props.onClose()

            notify(
            "success",
                t("notification:onDisplayLanguageCreate.success.title"),
                t("notification:onDisplayLanguageCreate.success.message", {
                    language: languages[values.isoCode][i18n.resolvedLanguage]
                })
            )
        } catch (error) {
            notify(
                "danger",
                t("notification:onDisplayLanguageCreate.fail.title"),
                t("notification:onDisplayLanguageCreate.fail.message", {
                    language: languages[values.isoCode][i18n.resolvedLanguage]
                })
            )
        }
    }

    return <>
        <Formik
            enableReinitialize={true}
            initialValues={ initialValues }
            validationSchema={
                yup.object().shape({
                    isoCode: yup
                        .string()
                        .test("languageAlreadyExists", t("common:form.languageAlreadyExists"), (value) => {
                            if(value) {
                                const exists = props.existingLanguages.find(entry => entry.isoCode ===  value)

                                return exists === undefined
                            }

                            return true
                        })
                        .required(t("common:form.fieldIsRequired"))
                })
            }
            onSubmit={ handleSubmit }
        >
        {
            ((formikProps: FormikProps<IFormFields>) => {
                return <>
                    <Form>
                        <SelectField
                            name="isoCode"
                            label={ t("localization:add.form.isoCode.label") }
                            fieldSize="expanded"
                        >
                            <option value={ -1 }>{ t("common:form.selectALanguageDropdownEntry") }</option>
                            {

                                Object.keys(languages).map((entry, index) => {
                                    // skip existing languages
                                    if (props.existingLanguages.find(it => it.isoCode === entry) !== undefined) return null

                                    return <option key={ index } value={ entry }>
                                        { `${ languages[entry][i18n.resolvedLanguage] } (${ languages[entry].native })` }
                                    </option>
                                })
                            }
                        </SelectField>

                        {/* form actions */}
                        <div className="field is-grouped is-justify-content-space-between">
                            <button className="button" type="button" onClick={ () => { props.onClose() } }>{ t("localization:edit.form.buttons.close") }</button>
                            <button
                                className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                type="submit"
                                disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                            >{ t("localization:add.form.buttons.submit") }</button>
                        </div>
                    </Form>
                </>
            })
        }
        </Formik>
    </>
}

export default AddDisplayLanguageForm
