import { FunctionComponent } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import routes from "routes/routes"
import { EPermissionRoles } from "utils/auth"

import Permission from "components/common/Routes/Permission"
import List from "components/AdminDashboard/Managers/List/List"
import Page from "components/AdminDashboard/Managers/Page/Page"

const Routes: FunctionComponent = () => {
    return <>
        <Switch>
            {/*list*/}
            <Route
                exact
                path={ routes.managers }
                render={ () => {
                    return <>
                        <Permission
                            logoutOnDenied={ false }
                            redirectTo={ routes.doctors }
                            roles={ [ EPermissionRoles.Admin, EPermissionRoles.Manager ] }
                        >
                            <List />
                        </Permission>
                    </>
                }}
            />

            {/*doctor page*/}
            <Route
                exact
                path={ routes.managerPage() }
                render={ () => {
                    return <>
                        <Permission
                            logoutOnDenied={ false }
                            redirectTo={ routes.doctors }
                            roles={ [ EPermissionRoles.Admin, EPermissionRoles.Manager ] }
                        >
                            <Page />
                        </Permission>
                    </>
                }}
            />

            {/*redirect*/}
            <Route render={ () => <Redirect to={ routes.doctors } /> }/>
        </Switch>
    </>
}

export default Routes
