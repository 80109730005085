import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import * as api from "service/http/api"
import { placeholderProfilePic } from "assets/asstes"
import languages from "utils/languages"

interface Props {
    details: api.clients.IClient
}

const Details: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "common", "clients" ])

    return <>
        <div className="columns">
            <div className="column is-half is-offset-one-quarter">
                <div className="media is-align-items-center">
                    <div className="media-left">
                        <figure className="image is-64x64">
                            <img className="is-rounded" src={ props.details.profilePicture || placeholderProfilePic } alt="Client Profile"/>
                        </figure>
                    </div>
                    <div className="media-content has-text-centered is-narrow">
                        <p className="title is-4">{ `${props.details.givenName} ${props.details.familyName}` }</p>
                        <p className="subtitle is-6">{ props.details.primaryEmail }</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="columns">
            <div className="column">
                <div className="has-text-centered">
                    <p className="title is-5">{ t("clients:view.details.preferredDisplayLanguage") }</p>
                    {
                        (() => {
                            if (!props.details.languagePreferences.preferredDisplayLanguage)
                                return <p className="subtitle is-6">
                                    { t("clients:view.details.noLanguage") }
                                </p>

                            return <p className="subtitle">
                                <span className="tag is-primary is-small">
                                    { languages[props.details.languagePreferences.preferredDisplayLanguage][i18n.resolvedLanguage] }
                                </span>
                            </p>
                        })()
                    }
                </div>
            </div>
        </div>

        <div className="columns">
            <div className="column">
                <p className="title is-5 has-text-centered">{ t("clients:view.details.preferredDoctorLanguages") }</p>
                    {
                        (() => {
                            if (!props.details.languagePreferences.preferredDoctorLanguages)
                                return <p className="subtitle is-6 has-text-centered">
                                    { t("clients:view.details.noLanguage") }
                                </p>

                            return <>
                                <div className="subtitle">
                                    <div className="tags are-small is-justify-content-center">
                                        {
                                            props.details.languagePreferences.preferredDoctorLanguages.map((entry) => {
                                                return <span key={ entry } className="tag is-primary">{ languages[entry][i18n.resolvedLanguage] }</span>
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        })()
                    }
            </div>
        </div>
    </>
}

export default Details

