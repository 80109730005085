import React, { FunctionComponent } from "react"

import * as api from "service/http/api"
import { getYouTubeVideoThumbnail } from "utils/helpers"

import "components/AdminDashboard/Doctors/Page/MediaContent/ListEntry/ListEntry.scss"
import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiDelete, mdiEye, mdiPencil } from "@mdi/js"
import languages from "../../../../../../utils/languages"
import ImagePreview from "../../../../../common/Form/ImagePreview"

interface Props {
    mediaContent: api.doctors.IDoctorMediaContent
    onShowPreview: () => void
    onEdit: () => void
    onDelete: () => void
}

const ListEntry: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "common", "doctors" ])

    const mediaContentDescription = () => {
        return props.mediaContent.description[i18n.resolvedLanguage]
            ?? props.mediaContent.description["en"]
            ?? ""
    }

    return <>
        <div className="media-content-list-entry box is-shadowless">
            <article className="media">
                <div className="media-left">
                    <ImagePreview
                        placeholderType="image"
                        imgAlt="doctor-media-content"
                        shape="square"
                        size="auto"
                        maxHeight={ 128 }
                        url={ props.mediaContent.type === api.doctors.EMediaContentType.Image ? props.mediaContent.url : getYouTubeVideoThumbnail(props.mediaContent.url) }
                    />
                </div>
                <div className="media-content">
                    <div className="content">
                        <strong>{ t("common:doctorMediaContentType.type", { context: props.mediaContent.type }) }</strong>
                        <p>{ mediaContentDescription() }</p>

                        <div className="columns is-gapless">
                            <div className="column is-narrow">{ t("doctors:edit.mediaContent.displayedIn") } </div>
                            <div className="column ml-2">
                                <div className="tags">
                                    {
                                        Object.keys(props.mediaContent.description).map(it => props.mediaContent.description[it]?.length > 0 && (
                                            <span className="tag is-primary is-small">{ languages[it][i18n.resolvedLanguage] }</span>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buttons is centered">
                        {/*actions*/}
                        <div className="field is-grouped is-narrow">
                            <div className="control">
                                <button
                                    className="button is-circular"
                                    title={ t("doctors:edit.mediaContent.buttons.preview.title") }
                                    onClick={ props.onShowPreview }
                                >
                                    <span className="icon is-small">
                                        <Icon path={ mdiEye } />
                                    </span>
                                </button>
                            </div>
                            <div className="control">
                                <button
                                    className="button is-circular"
                                    type="button"
                                    title={ t("doctors:edit.mediaContent.buttons.edit.title") }
                                    onClick={ props.onEdit }
                                >
                                    <span className="icon is-small">
                                        <Icon path={ mdiPencil } />
                                    </span>
                                </button>
                            </div>
                            <div className="control">
                                <button
                                    className="button is-circular is-danger"
                                    title={ t("doctors:edit.mediaContent.buttons.delete.title") }
                                    onClick={ props.onDelete }
                                >
                                    <span className="icon is-small">
                                        <Icon path={ mdiDelete } />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    </>
}

export default ListEntry
