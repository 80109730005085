import { FunctionComponent, useEffect, useState } from "react"
import Loading from "components/common/Loading/Loading"
import MarkdownPreview from "@uiw/react-markdown-preview"

const Documentation: FunctionComponent = () => {
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ text, setText ] = useState<string>("")

    useEffect(() => {
        const importDocumentation = async () => {
            fetch("/documentation/DistributorDocumentation.md")
                .then(res => res.text())
                .then(text => setText(text))
                .catch(err => console.log(err))
        }

        importDocumentation().then(() => setIsLoading(false))
    }, [])

    if (isLoading)
        return <Loading />

    return <>
        <div className="container">
            {/* for some reason react-markdown doesn't work properly, so we use this wrapper around it
            made by the guy that made the editor */}
            <MarkdownPreview
                source={ text }
                transformImageUri={ uri => `/documentation/${ uri }` }
                wrapperElement={{ "data-color-mode": "light" }}
            />
        </div>
    </>
}

export default Documentation
