import axios from "axios"
import { IPagedRequestParams, IPagedRequestResponse } from "service/http/pagination"
import { EAccountState } from "service/http/accounts"

const basePath = '/administration/clients'

export enum EClientOAuthType {
    Google,
    Facebook
}

export interface IClientEmailLogin {
    id: number
    accountId: string
    email: string
    createdAt: string
}

export interface IClientOAuthLogin {
    id: number
    accountId: string
    email: string
    createdAt: string
    oAuthId: string
    givenName: string
    familyName: string
    profilePicture: string
    oAuthType: EClientOAuthType
    rawData: string
}

export type TClientAuthenticationMethods = (IClientEmailLogin | IClientOAuthLogin)

export interface IClientPaymentMethod {
    id: string
    expirationYear: number,
    expirationMonth: number,
    cardBrand: string,
    last4Digits: string,
    isPrimary: boolean,
    isValid: boolean,
    stripePaymentMethodId: string
    RawPaymentCreationDataFromStripe: any // this is an object
}

interface IClientLanguagePreferences {
    preferredDisplayLanguage?: string
    preferredDoctorLanguages?: string[]
}

export interface IClient {
    id: string
    givenName: string
    familyName: string
    primaryEmail: string
    profilePicture: string
    accountState: EAccountState
    isTestingAccount: boolean
    languagePreferences: IClientLanguagePreferences
    clientPaymentMethods: IClientPaymentMethod[]
    authenticationMethods: TClientAuthenticationMethods[]
}

interface IClientsFilters {
    ids?: string[]
    searchQuery?: string
    [key: string]: string[] | string | undefined
}

// getClientList
interface IGetClientsPageResponse extends IPagedRequestResponse<IClient> {}

export const getClientsPage = async (params: IPagedRequestParams<IClientsFilters>): Promise<IGetClientsPageResponse> => {
    const response = await axios.get<IGetClientsPageResponse>(`${basePath}`, {
        params: params
    })
    return response.data
}

// get
interface IGetClientResponse extends IClient {}

export const getClient = async (clientId: string): Promise<IGetClientResponse> => {
    const response = await axios.get<IGetClientResponse>(`${basePath}/${clientId}`)
    return response.data
}

// updateClientAccountTestingState
interface IUpdateClientAccountTestingStateParams {
    isTestAccount: boolean
}

export const updateClientAccountTestingState = async (clientId: string, params: IUpdateClientAccountTestingStateParams): Promise<void> => {
    await axios.patch(`${basePath}/${clientId}`, params)
}
