import React, { FunctionComponent } from "react"
import { FieldHookConfig, useField, Field } from "formik"
import Icon from "@mdi/react"
import { mdiAlert } from "@mdi/js"

interface BeforeAddonsOnly {
    beforeAddons: JSX.Element | JSX.Element[]
    afterAddons?: never
}

interface AfterAddonsOnly {
    beforeAddons?: never
    afterAddons: JSX.Element | JSX.Element[]
}

interface BeforeAndAfterAddons {
    beforeAddons: JSX.Element | JSX.Element[]
    afterAddons: JSX.Element | JSX.Element[]
}

interface InputFieldProps {
    label?: string | JSX.Element
    isStatic?: boolean
    fieldSize?: "narrow" | "expanded"
    helpMessage?: string | JSX.Element
}

type OtherProps = (BeforeAddonsOnly | AfterAddonsOnly | BeforeAndAfterAddons) & InputFieldProps

const renderAddons = (addons: JSX.Element | JSX.Element[]) => {
    if (!Array.isArray(addons))
        return <>
            <div className="control">
                { addons }
            </div>
        </>

    return <>
        {
            addons.map(it => <>
                    <div className="control">
                        { it }
                    </div>
                </>
            )
        }
    </>
}

const InputFieldWithAddons: FunctionComponent<OtherProps & FieldHookConfig<string>> = ({
    beforeAddons,
    afterAddons,
    label,
    isStatic = false,
    fieldSize = "normal",
    helpMessage,
    ...props
}) => {
    const [ field, meta ] = useField(props.name)

    return <>
        <div className={ "field" + (fieldSize === "narrow" ? "is-narrow" : "") }>
            {
                label && <label className="label">{ label }</label>
            }
            <div className="control">
                <div className="field has-addons">
                    {
                        beforeAddons
                        && renderAddons(beforeAddons)
                    }
                    <div className={
                        "control "
                        + (meta.touched && meta.error ? "has-icons-right " : "")
                        + (fieldSize === "expanded" ? "is-expanded"  : "")
                    }>
                        <Field
                            className={ `input ${meta.touched && meta.error ? "is-danger" : ""} ${isStatic ? "is-static" : ""}` }
                            { ...field }
                            { ...props }
                            readOnly={ isStatic }
                        />
                        {
                            meta.touched && meta.error
                            && <span  className="icon is-small is-right">
                                <Icon path={ mdiAlert } color={ "#dbdbdb" } size={ 1.0 }/>
                            </span>
                        }
                    </div>
                    {
                        afterAddons
                        && renderAddons(afterAddons)
                    }
                </div>
            </div>
            {
                meta.touched && meta.error
                && <p className="help is-danger">{ meta.error }</p>
            }
            {
                helpMessage
                && <p className="help" > { helpMessage } </p>
            }
        </div>
    </>
}

export default InputFieldWithAddons
