import { DateTime } from "luxon"
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import * as api from "service/http/api"
import { enumToNumberValuesArray } from "utils/helpers"
import ImagePreview from "components/common/Form/ImagePreview"

interface Props {
    auth?: api.clients.TClientAuthenticationMethods[]
}

const Authentications: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "clients" ])

    const [ selectedOAuthType, setSelectedOAuthType ] = useState<api.clients.EClientOAuthType | -1>(-1)

    // set the default selected auth
    useEffect(() => {
        if (
            props.auth
            && props.auth[0].hasOwnProperty("oAuthType")
        ) {
            setSelectedOAuthType((props.auth[0] as api.clients.IClientOAuthLogin).oAuthType)
        console.log(props.auth)
        } else {
            setSelectedOAuthType(-1)
        }


        // eslint-disable-next-line
    }, [])

    const handleOAuthTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedOAuthType(_.toNumber(event.target.value))
    }

    return <>
        <div className="field is-horizontal">
            <div className="field-label is-normal">
                <label className="label">{ t("clients:view.authentication.selectType") }</label>
            </div>
            <div className="field-body">
                <div className="field">
                    <div className="control is-expanded">
                        <div className="select is-fullwidth">
                            <select
                                name="oAuthType"
                                value={ selectedOAuthType }
                                onChange={ handleOAuthTypeChange }
                            >
                                <option value={ -1 }>{ t("clients:view.authentication.typeEmail") }</option>
                                {
                                    enumToNumberValuesArray(api.clients.EClientOAuthType).map((entry) => {
                                        return <option key={ entry } value={ entry }>
                                            { t("common:oAuthType.type", { context: entry }) }
                                        </option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr/>

        {
            (() => {
                let entry = null

                if (props.auth)
                    if (selectedOAuthType === -1)
                        entry = props.auth.find(entry => !entry.hasOwnProperty("oAuthType"))
                    else
                        entry = props.auth.find(entry => (entry as api.clients.IClientOAuthLogin).oAuthType === selectedOAuthType)

                if (entry)
                    // email
                    if (selectedOAuthType === -1)
                        return <>
                            <div className="columns">
                                <div className="column">
                                    <div className="has-text-centered">
                                        <p className="title is-5">
                                            { t("clients:view.authentication.typeEmail") }
                                        </p>
                                        <p className="subtitle is-6">{ entry.email }</p>
                                    </div>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="has-text-centered">
                                        <p className="title is-5">
                                            { t("clients:view.authentication.firstConnectedAt") }
                                        </p>
                                        <p className="subtitle is-6">
                                            { t("common:date.dateTimeMedWithSeconds", { date: DateTime.fromISO(entry.createdAt).toLocal() }) }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                    else {
                        // oAuth
                        entry = entry as api.clients.IClientOAuthLogin

                        return <>
                            <div className="columns">
                                <div className="column is-half is-offset-one-quarter">
                                    <div className="media is-align-items-center">
                                        <div className="media-left">
                                            <ImagePreview
                                                placeholderType="account"
                                                url={ entry.profilePicture }
                                                imgAlt="oAuth-profile"
                                                size="64x64"
                                                shape="circle"
                                            />
                                        </div>
                                        <div className="media-content has-text-centered is-narrow">
                                            <p className="title is-4">{ `${ entry.givenName } ${ entry.familyName }` }</p>
                                            <p className="subtitle is-6">{ entry.email }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="has-text-centered">
                                        <p className="title is-5">
                                            { t("clients:view.authentication.oAuthId") }
                                        </p>
                                        <p className="subtitle is-6">{ entry.oAuthId }</p>
                                    </div>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="has-text-centered">
                                        <p className="title is-5">
                                            { t("clients:view.authentication.firstConnectedAt") }
                                        </p>
                                        <p className="subtitle is-6">
                                            { t("common:date.dateTimeMedWithSeconds", { date: DateTime.fromISO(entry.createdAt).toLocal() }) }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                return <p className="has-text-centered">
                    {
                        selectedOAuthType === -1
                        && t("clients:view.authentication.noAuthenticationForType", { type: t("clients:view.authentication.typeEmail") })
                    }
                    {
                        selectedOAuthType !== -1
                        && t("clients:view.authentication.noAuthenticationForType", { type: t("common:oAuthType.type", { context: selectedOAuthType }) })
                    }
                </p>
            })()
        }
    </>
}

export default Authentications
