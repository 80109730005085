import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"

import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import NotificationForm from "components/AdminDashboard/MarketingNotifications/NotificationForm"
import { ICreateNotificationParams, INotification, IUpdateNotificationParams } from "service/http/notifications"

interface NewProps {
    type: "new"
    details?: never
    onCreateNotification: (notification: ICreateNotificationParams, entityId: string) => void
    onUpdateNotification?: never
}

interface ExistingProps {
    type: "existing"
    details: INotification
    onCreateNotification?: never
    onUpdateNotification: (notification: IUpdateNotificationParams) => void
}

type Props = (NewProps | ExistingProps) & {
    onClose: () => void
}

const AddEdit: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "modal", "notification" ])

    const setModalOptions = useConfirmModal()

    const [ formIsDirty, setFormIsDirty ] = useState<boolean>()

    const handleClose = () => {
        if (formIsDirty) {
            setModalOptions({
                isOpen: true,
                modalProps: {
                    title: t("modal:discardChanges.title"),
                    content: t("modal:discardChanges.message"),
                    onConfirm: () => {
                        setModalOptions({ isOpen: false })
                        props.onClose()
                    },
                    onCancel: () => {
                        setModalOptions({ isOpen: false })
                    }
                }
            })
        }
        else {
            props.onClose()
        }
    }

    return <>
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">
                            { t("notification:page.add.title") }
                        </p>
                        {
                            (() => {
                               if (props.type === "new")
                                   return <NotificationForm
                                       type="new"
                                       setFormIsDirty={ setFormIsDirty }
                                       onCreateNotification={ props.onCreateNotification }
                                       onClose={ handleClose }
                                   />

                               return <NotificationForm
                                   type="existing"
                                   details={ props.details }
                                   setFormIsDirty={ setFormIsDirty }
                                   onUpdateNotification={ props.onUpdateNotification }
                                   onClose={ handleClose }
                               />
                            })()
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AddEdit
