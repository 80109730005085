import { Dispatch } from "react"

import { ERequestState } from "state/reducers/RequestTypes"
import { getLocalizationStatus } from "service/http/localization"

enum EActions {
    Get = "localizationStatus/add"
}

interface IDefaultState {
    state: ERequestState
    status: {
        doctors: boolean
        specialties: boolean
    }
}

interface GetAction {
    type: EActions.Get
    payload: IDefaultState
}

type TLocalizationStatusAction = GetAction

const defaultState: IDefaultState = {
    state: ERequestState.Loading,
    status: {
        doctors: false,
        specialties: false
    }
}

export const fetchLocalizationStatus = () => async (dispatch: Dispatch<GetAction>) => {
    dispatch({
        type: EActions.Get,
        payload: {
            state: ERequestState.Loading,
            status: defaultState.status
        }
    })

    try {
        const response = await getLocalizationStatus()

        return dispatch({
            type: EActions.Get,
            payload: {
                state: ERequestState.Succeeded,
                status: response
            }
        })
    } catch(e) {
        return dispatch({
            type: EActions.Get,
            payload: {
                state: ERequestState.Failed,
                status: defaultState.status
            }
        })
    }
}

const LocalizationStatusReducer = (state = defaultState, action: TLocalizationStatusAction): IDefaultState => {
    switch (action.type) {
        case EActions.Get:
            return action.payload

        default:
            return state
    }
}

export default LocalizationStatusReducer
