import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiAlertCircleOutline } from "@mdi/js"

import * as api from "service/http/api"
import routes from "routes/routes"

import ImagePreview from "components/common/Form/ImagePreview"
import Chart from "components/AdminDashboard/Stats/Doctors/Chart"

interface GlobalProps {
    type: "global"
    stats: api.stats.IInstantCallStats
}

interface DoctorProps {
    type: "doctor"
    stats: api.stats.IDoctorInstantCallStats
}

type Props = (GlobalProps | DoctorProps) & {
    chartTitle: string
}

const EntryCard: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "stats" ])

    return <>
        <div className="card">
            <div className="card-content">
                {
                    props.type === "global"
                    ? <>
                        <p className="title pb-4">
                            { t("stats:chart.instantCalls.card.title.global") }
                        </p>
                    </>
                    : <>
                        <div className="media">
                            <div className="media-left">
                                <ImagePreview
                                    placeholderType="account"
                                    imgAlt="doctor-profile-pic"
                                    size="64x64"
                                    shape="circle"
                                    url={ props.stats.doctor.profilePicture }
                                />
                            </div>
                            <div className="media-content">
                                <p className="title is-4">
                                    <Link
                                        to={ {
                                            pathname: routes.doctorPage(props.stats.doctor.id),
                                        } }
                                    >
                                        { `${props.stats.doctor.givenName} ${props.stats.doctor.familyName}` }
                                    </Link>
                                </p>
                                <p className="subtitle is-6">
                                    { props.stats.doctor.primaryEmail }
                                </p>
                            </div>
                        </div>
                    </>
                }

                {/*not notified alert*/}
                {
                    props.type === "doctor" && props.stats.notNotifiedToday
                    && <>
                        <div className="notification is-danger">
                            <span className="icon-text">
                                <span className="icon">
                                    <Icon path={ mdiAlertCircleOutline } size={ 1 } color={ "white" } />
                                </span>
                                <span>{ t("stats:chart.instantCalls.card.notNotifiedTodayMessage") }</span>
                            </span>
                        </div>
                    </>
                }

                {/*current month*/}

                <p className="subtitle has-text-centered">
                    { t("stats:chart.instantCalls.card.subtitle.currentMonth") }
                </p>
                <table className="table is-bordered is-fullwidth has-text-centered is-vcentered">
                    <thead>
                        <tr>
                            <th>{ t("stats:chart.instantCalls.dataSetsLabels.total") }</th>
                            <th>{ t("stats:chart.instantCalls.dataSetsLabels.answered") }</th>
                            <th>{ t("stats:chart.instantCalls.dataSetsLabels.notAnswered") }</th>
                            <th>{ t("stats:chart.instantCalls.dataSetsLabels.rejected") }</th>
                            <th>{ t("stats:chart.instantCalls.dataSetsLabels.notNotified") }</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ props.stats.currentMonth.total }</td>
                            <td>{ props.stats.currentMonth.answered }</td>
                            <td>{ props.stats.currentMonth.notAnswered }</td>
                            <td>{ props.stats.currentMonth.rejected }</td>
                            <td>{ props.stats.currentMonth.notNotified }</td>
                        </tr>
                    </tbody>
                </table>

                {/*chart*/}
                <p className="subtitle has-text-centered">
                    { props.chartTitle }
                </p>
                <Chart stats={ props.stats.entries } />

            </div>
        </div>
    </>
}

export default EntryCard
