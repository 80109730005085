import axios from "axios"

import { IPagedRequestParams, IPagedRequestResponse } from "service/http/pagination"
import { EAccountState } from "service/http/accounts"
import { fileUploadAxiosConfig, objToFormData } from "service/service"

const basePath = '/administration/doctors'

// region DOCTORS
export enum EDoctorPageVisibility {
    Private,
    Public,
}

interface IDoctorFields {
    id: string
    givenName: string
    familyName: string
    primaryEmail: string
    profilePicture: string
    phoneNumber: string
    ratePerMinuteInUnit: number
    outOfScheduleRatePerMinuteInUnit: number
    videoRatePerMinuteInUnit: number
    payoutPercentage: number
    acceptsOutsideOfSchedule: boolean
    acceptsFreeFirstCalls: boolean
    isResident: boolean
    primarySpecialty: number
    firstYearOfMedicalExperience: string
    onlineConsultationsOverCountApproximation: number
    scheduleBegin: string
    scheduleEnd: string
    scheduleTimeZone: string
    score: number
    spokenLanguages: string[]
    prettyUrlName: string | null
    accountState: EAccountState
    pageVisibility: EDoctorPageVisibility
    internalNotes: string
    quote: Record<string,string> | null
    managerId: string | null
    managersPayoutPercentage: number | null
    presentations: IDoctorPresentation[]
    mediaContents: IDoctorMediaContent[]
    hasMissingTranslations: boolean
    signature: string
}

export interface IDoctor extends IDoctorFields {
    [key: string]: number | string | string[] | boolean | EAccountState | EDoctorPageVisibility | IDoctorPresentation[] | IDoctorMediaContent[] | Record<string, string> | null
}

interface IDoctorFilters {
    ids?: number[]
    searchQuery?: string
    [key: string]: number[]  | string | undefined
}

// getAll
interface IGetDoctorProfilesResponse extends IPagedRequestResponse<IDoctor> {}

export const getDoctorProfilesPage = async (params: IPagedRequestParams<IDoctorFilters>): Promise<IGetDoctorProfilesResponse> => {
    const response = await axios.get<IGetDoctorProfilesResponse>(`${basePath}`, {
        params: params
    })
    return response.data
}

// get
export const getDoctor = async (doctorId: string): Promise<IDoctor> => {
    const response = await axios.get<IDoctor>(`${basePath}/${doctorId}`)
    return response.data
}

// create
export interface ICreateDoctorParams extends Omit<IDoctorFields, "id" | "score" | "accountState" | "pageVisibility" | "hasMissingTranslations" | "presentations" | "mediaContents" | "profilePicture" | "managerId" | "managersPayoutPercentage"> {
    profilePicture: File
    [key: string]: number | string | boolean | string[] | EAccountState | EDoctorPageVisibility | File | Record<string, string> | null
}

interface ICreateDoctorResponse {
    id: string,
}

export const createDoctor = async (params: ICreateDoctorParams): Promise<ICreateDoctorResponse> => {
    // const toFormData = params.quote
    //     ? { // we need to transform the translation obj into an array of { key => value } because dotnet minimal api
    //         ...params,
    //         quote: Object.keys(params.quote!).map(key => {
    //             return {
    //                 key: key,
    //                 value: params.quote![key]
    //             }
    //         })
    //     }
    //     : params

    const response = await axios.post<ICreateDoctorResponse>(
        `${basePath}`,
        objToFormData(params, "profilePicture"),
        fileUploadAxiosConfig
    )
    return response.data
}

// update
export interface IUpdateDoctorParams extends Partial<Omit<IDoctorFields, "id" | "score" | "accountState" | "pageVisibility" | "hasMissingTranslations" | "presentations" | "mediaContents" | "profilePicture" | "managerId" | "managersPayoutPercentage" | "quote">> {
    profilePicture?: File
    [key: string]: number | string | string[] | boolean | File | Record<string, string> | undefined | null
}

interface IUpdateDoctorResponse {
    pictureUrl?: string
}

export const updateDoctor = async (doctorId: string, params: IUpdateDoctorParams): Promise<IUpdateDoctorResponse> => {
    const response = await axios.patch<IUpdateDoctorResponse>(
        `${basePath}/${doctorId}`,
        objToFormData(params, "profilePicture"),
        fileUploadAxiosConfig
    )
    return response.data
}

// update additional details
export interface IUpdateDoctorAdditionalDetailsParams extends Partial<Pick<IDoctorFields, "quote">> {
    signature?: File
}

interface IUpdateDoctorAdditionalDetailsResponse {
    signatureUrl?: string
}

export const updateDoctorAdditionalDetails = async (doctorId: string, params: IUpdateDoctorAdditionalDetailsParams): Promise<IUpdateDoctorAdditionalDetailsResponse> => {
    const toFormData = params.quote
        ? { // we need to transform the translation obj into an array of { key => value } because dotnet minimal api
            ...params,
            quote: Object.keys(params.quote!).map(key => {
                return {
                    key: key,
                    value: params.quote![key]
                }
            })
        }
        : params

    const response = await axios.patch<IUpdateDoctorAdditionalDetailsResponse>(
        `${basePath}/${doctorId}`,
        objToFormData(toFormData, "signature"),
        fileUploadAxiosConfig
    )
    return response.data
}

// request reset password
export const resetPassword = async (doctorId: string, language: string) => {
    await axios.post(`${basePath}/${doctorId}/password-reset?lang=${language}`, {})
}

//update assigned manager
interface IUpdateDoctorManagerParamsRemove {
    removeManager: true
    managerId?: never
    managersPayoutPercentage?: never
}

interface IUpdateDoctorManagerParamsUpdate {
    removeManager?: never
    managerId?: string
    managersPayoutPercentage?: number
}

export type TUpdateDoctorsManagersParams = (IUpdateDoctorManagerParamsUpdate | IUpdateDoctorManagerParamsRemove)

export const updateDoctorManager = async (doctorId: string, params: TUpdateDoctorsManagersParams) => {
    await axios.patch(`${basePath}/${doctorId}/manager`, params)
}
// endregion

// region PRESENTATION
export enum EPresentationCareerPointType {
    Education,
    Clinic,
    Hospital,
    Award
}

export interface IPresentationCareerHistoryEntry {
    type: EPresentationCareerPointType
    description: string
    [key:string]: EPresentationCareerPointType | string
}

export interface IDoctorPresentation {
    displayLanguageCode: string
    description: string
    careerHistory: IPresentationCareerHistoryEntry[]
    mediaContents: IDoctorMediaContent[]
    [key: string]: string | IPresentationCareerHistoryEntry[] | IDoctorMediaContent[]
}

// get
export const getPresentation = async (doctorId: string, displayLanguage: string): Promise<IDoctorPresentation> => {
    const response =  await axios.get<IDoctorPresentation>(`${basePath}/${doctorId}/presentations`, {
        params: {
            displayLanguage: displayLanguage
        }
    })
    return response.data
}

// get all
type TGetAllPresentationsResponse = IDoctorPresentation[]
export const getAllPresentations = async (doctorId: string): Promise<TGetAllPresentationsResponse> => {
    const response = await axios.get<TGetAllPresentationsResponse>(`${basePath}/${doctorId}/presentations/all`)
    return response.data
}

// create
export interface ICreatePresentationParams extends Omit<IDoctorPresentation, "displayLanguageCode" | "mediaContents"> {}

interface ICreatePresentationResponse {}

export const createPresentation = async (doctorId: string, displayLanguage: string, params: ICreatePresentationParams): Promise<ICreatePresentationResponse> => {
    const response = await axios.post<ICreatePresentationResponse>(`${basePath}/${doctorId}/presentations`, params, {
        params: {
            displayLanguage: displayLanguage
        }
    })
    return response.data
}

// update
export interface IUpdatePresentationParams extends Partial<Omit<IDoctorPresentation, "displayLanguageCode" | "mediaContents">> {}

interface IUpdatePresentationResponse {}

export const updatePresentation = async (doctorId: string, displayLanguage: string, params: IUpdatePresentationParams): Promise<IUpdatePresentationResponse> => {
    const response = await axios.patch<IUpdatePresentationResponse>(`${basePath}/${doctorId}/presentations/${displayLanguage}`, params)
    return response.data
}
// endregion PRESENTATION

// region MEDIA CONTENT
export enum EMediaContentType{
    Image,
    YoutubeVideo,
    AgathaTvVideo
}

export interface IDoctorMediaContent {
    id: string
    doctorId: string
    url: string
    description: Record<string, string>
    type: EMediaContentType
    [key: string]: number | Record<string, string> | string | EMediaContentType
}

interface IImageMediaContentRequest {
    type: EMediaContentType.Image
    url?: never
    image: File
}

interface IVideoMediaContentRequest {
    type: EMediaContentType.YoutubeVideo | EMediaContentType.AgathaTvVideo
    url: string
    image?: never
}

export type TMediaContentRequest = (IImageMediaContentRequest | IVideoMediaContentRequest) & {
    description: Record<string, string>
}

// create
export type TCreateMediaContentParams = TMediaContentRequest
interface ICreateMediaContentResponse extends IDoctorMediaContent {}

export const createMediaContent = async (doctorId: string, params: TCreateMediaContentParams): Promise<ICreateMediaContentResponse> => {
    const response = await axios.postForm<ICreateMediaContentResponse>(
        `${basePath}/${doctorId}/media-content`,
        {
            ...params,
            description: Object.keys(params.description).map(it => JSON.stringify({
                language: it,
                text: params.description[it]
            }))
        }
    )

    return response.data
}

// update
export type TUpdateMediaContentParams = Partial<TMediaContentRequest> & {
    [key: string]: any
}
interface IUpdateMediaContentResponse extends IDoctorMediaContent {}

export const updateMediaContent = async (doctorId: string, mediaContentId: string, params: TUpdateMediaContentParams): Promise<IUpdateMediaContentResponse> => {
    const response = await axios.patchForm<IUpdateMediaContentResponse>(
        `${basePath}/${doctorId}/media-content/${mediaContentId}`,
        {
            ...params,
            ...(params.description && {
                description: Object.keys(params.description).map(it => JSON.stringify({
                    language: it,
                    text: params.description![it]
                }))
            })
        }
    )

    return response.data
}

// delete
interface IDeleteMediaContentResponse {}

export const deleteMediaContent = async (doctorId: string, mediaContentId: string): Promise<IDeleteMediaContentResponse> => {
    const response = await axios.delete<IDeleteMediaContentResponse>(`${basePath}/${doctorId}/media-content/${mediaContentId}`)
    return response.data
}

// endregion

// region SPECIALTY
const specialtiesPath = `${basePath}/specialties`

export interface IDoctorSpecialtyFields {
    id: number
    icon?: string
    translations: Record<string, string>
}

export interface IDoctorSpecialty extends IDoctorSpecialtyFields {
    [key: string]: number | string | Record<string, string> | undefined
}

// get
interface IGetDoctorSpecialtiesResponse extends IDoctorSpecialty {}

export const getDoctorSpecialties = async (): Promise<IGetDoctorSpecialtiesResponse[]> => {
    const response = await axios.get<IGetDoctorSpecialtiesResponse[]>(`${specialtiesPath}`)
    return response.data
}

// create
interface ICreateDoctorSpecialtyParams extends Omit<IDoctorSpecialtyFields, "id" | "icon"> {
    icon?: File
}

export interface ICreateDoctorSpecialtyResponse extends IDoctorSpecialty {}

export const createDoctorSpecialty = async (params: ICreateDoctorSpecialtyParams): Promise<ICreateDoctorSpecialtyResponse> => {
    const response = await axios.postForm<ICreateDoctorSpecialtyResponse>(`${specialtiesPath}`, {
        ...params,
        translations: Object.keys(params.translations).map(key => JSON.stringify({
            language: key,
            text: params.translations[key]
        }))
    })
    return response.data
}

// update
export interface IUpdateDoctorSpecialtyParams extends Partial<Omit<IDoctorSpecialtyFields, "id" | "icon">> {
    icon?: File
    [key: string]: string | Record<string, string> | File | undefined
}

export interface IUpdateDoctorSpecialtyResponse extends IDoctorSpecialty {}

export const updateDoctorSpecialty = async (specialtyId: number, params: IUpdateDoctorSpecialtyParams): Promise<IUpdateDoctorSpecialtyResponse> => {
    const response = await axios.patchForm<IUpdateDoctorSpecialtyResponse>(`${specialtiesPath}/${specialtyId}`, {
        ...params,
        ...(params.translations && {
            translations: Object.keys(params.translations).map(key => JSON.stringify({
                language: key,
                text: params.translations![key]
            }))
        })
    })
    return response.data
}
// endregion

// get doctors drop down list
export interface IDoctorDropDownListProfile {
    id: string
    fullName: string
    primaryEmail: string
    profilePicture: string
}

type IGetDoctorDropDownListResponse = IDoctorDropDownListProfile[]

export const getDoctorDropDownList = async (): Promise<IGetDoctorDropDownListResponse> => {
    const response = await axios.get<IGetDoctorDropDownListResponse>(`${basePath}/drop-down-list`)
    return response.data
}
