import React, { ChangeEvent, FunctionComponent, useState, useEffect } from "react"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { FieldProps } from "formik"
import { useTranslation } from "react-i18next"

import "./LanguageSelect.scss"
import languages from "utils/languages"

interface Props {
    fieldProps: FieldProps<string[]>
    onAddCallback: (value: string) => void
    onRemoveCallback: (index: number) => void
}

const LanguageSelect: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "doctors", "common" ])

    const [ input, setInput ] = useState<string>("none")
    const [ isInputValid, setIsInputValid ] = useState<boolean>(false)

    const addElement = () => {
        if (isInputValid) {
            props.onAddCallback(input as string)
            setInput("none")
        }
    }

    const removeElement = (index: number) => {
        props.onRemoveCallback(index)
    }

    // update the validity of the input
    useEffect(() => {
        if (input !== "none" && props.fieldProps.field.value.indexOf(input) === -1) {
            setIsInputValid(true)
        } else {
            setIsInputValid(false)
        }
        // languageNames never changes, much like war
        // eslint-disable-next-line
    }, [ input ])

    const handleInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setInput(e.target.value)
    }

    return <>
        {/* spokenLanguage */}
        <div className="field">
            <label className="label">{ t("doctors:edit.details.form.spokenLanguages.label") }</label>
            <div className="field has-addons">
                <div className="control is-expanded">
                    <span className="select is-fullwidth">
                        <select
                            className={ "input " + ((!isInputValid && input.length === 2) || (props.fieldProps.meta.error && props.fieldProps.meta.touched) ? "is-danger" : "") }
                            name="spokenLanguagesSelect"
                            value={ input }
                            onKeyDown={ (e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault()

                                    if (isInputValid)
                                        addElement()
                                }
                            } }
                            onChange={ handleInputChange }
                            onBlur={ props.fieldProps.field.onBlur }
                        >
                            <option value="none">{ t("common:form.selectALanguageDropdownEntry") }</option>
                            {

                                Object.keys(languages).map((entry, index) => {
                                    // skip existing languages
                                    if (props.fieldProps.field.value.indexOf(entry) !== -1) return null

                                    return <option key={ index } value={ entry }>
                                        { `${ languages[entry][i18n.resolvedLanguage] } (${ languages[entry].native })` }
                                    </option>
                                })
                            }
                        </select>
                    </span>
                </div>
                <div className="control">
                    <button
                        className="button is-primary"
                        type="button"
                        title={ t("doctors:edit.details.form.spokenLanguages.button.add.title") }
                        disabled={ !isInputValid }
                        onClick={ addElement }
                    >
                    <span className="icon is-small">
                        <Icon path={ mdiPlus } size={ 1 } />
                    </span>
                    </button>
                </div>
            </div>
            {
                !isInputValid && input.length === 2
                && <p className="help is-danger">
                    {
                        (() => {
                            return props.fieldProps.field.value.indexOf(input) !== -1
                            ? t("doctors:edit.details.form.spokenLanguages.error.langAlreadyAdded")
                            : t("doctors:edit.details.form.spokenLanguages.error.inputIsNotValid")
                        })()
                    }
                </p>
            }
            {
                props.fieldProps.meta.error && props.fieldProps.meta.touched
                && <p className="help is-danger">
                    { props.fieldProps.meta.error }
                </p>
            }
        </div>

        {/* selectedSpokenLanguages */}
        {
            props.fieldProps.field.value.length > 0
            && <div className="field is-grouped is-grouped-multiline">
                {
                    props.fieldProps.field.value.map((entry, index) => {
                        return (
                            <div key={ `selected-${entry}` } className="control">
                                   <span className="tag is-primary is-small">
                                        { languages[entry][i18n.resolvedLanguage] }
                                       <button
                                           title={ t("doctors:edit.details.form.spokenLanguages.button.remove.title") }
                                           type="button"
                                           className="delete is-small"
                                           onClick={ () => { removeElement(index) } }
                                       />
                                   </span>
                            </div>
                        )
                    })
                }
            </div>
        }
    </>
}

export default LanguageSelect
