import React, { ChangeEvent, FunctionComponent } from "react"
import { FieldHookConfig, useField } from "formik"
import Icon from "@mdi/react"
import { mdiClose, mdiUpload } from "@mdi/js"

import * as colors from "assets/colors"

import "./ImageFileInputField.scss"

interface OtherProps {
    label: string
    fieldSize?: "narrow" | "expanded"
    helpMessage?: string | JSX.Element
    onRemoveFile: () => void
}

/** @Note:
 * In order for this component to work you need to assign a key for this component
 * this key should be a random string and onRemoveFile should just generate a new key
 * thus triggering the rerender of the component and clearing the selected file
 * For yup validation, this field should be mixed() and nullable() so whenever we remove the file,
 * we should change the value of the field to null
 * TL;DR onRemove should:
 * 1. refresh the key used for this component
 * 2. set the field value to null
 **/
const FileInputField: FunctionComponent<OtherProps & FieldHookConfig<string>> = ({ label, fieldSize = "normal", helpMessage, ...props }) => {
    const [ field, meta ] = useField<File | null>(props.name)

    return <>
        <div className={ "field" + (fieldSize === "narrow" ? "is-narrow" : "") }>
            <div className="control is-flex is-justify-content-center">
                <div className={ `file is-primary ${ field.value ? "has-name" : ""}` }>
                    <label className="file-label">
                        <input
                            className="file-input"
                            type="file"
                            name={ field.name }
                            onChange={ props.onChange as (e: ChangeEvent<HTMLInputElement>) => void }
                        />
                        <span className="file-cta">
                            <span className="file-icon">
                                <span className="fa">
                                    <Icon path={ mdiUpload } size={ 1 } />
                                </span>
                            </span>
                            <span className="file-label">
                                { label }
                            </span>
                        </span>
                        {
                            field.value
                            ? <>
                                <span className="file-name has-file-remove">
                                    { field.value!.name }
                                </span>
                            </>
                            : null
                        }
                    </label>
                    {
                        field.value
                        && <>
                            <span className="file-name file-remove">
                                <span
                                    className="icon"
                                    onClick={ (e) => {
                                        e.preventDefault()
                                        props.onRemoveFile()
                                    } }
                                >
                                    <Icon path={ mdiClose } size={ 1 } color={ colors.danger }/>
                                </span>
                            </span>
                        </>
                    }
                </div>
            </div>
            {
                meta.touched && meta.error
                ? <p className="help is-danger has-text-centered">{ meta.error }</p>
                : null
            }
            {
                helpMessage
                ? <p className="help has-text-centered" > { helpMessage } </p>
                : null
            }
        </div>
    </>
}

export default FileInputField
