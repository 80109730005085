import React, { FunctionComponent } from "react"
import { JsonViewer } from "@textea/json-viewer"

interface Props {
    json: any
    onClose: () => void
}

const RawData: FunctionComponent<Props> = (props) => {
    return <>
        <div className="modal is-active">
            <div
                className="modal-background"
                onClick={ props.onClose }
            />
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <JsonViewer
                            value={ props.json }
                            defaultInspectDepth={ 1 }
                        />
                    </div>
                </div>
            </div>
            <button
                className="modal-close is-large"
                aria-label="close"
                onClick={ props.onClose }
            />
        </div>
    </>
}

export default RawData

