import React, { FunctionComponent } from "react"
import * as api from "service/http/api"
import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiPencil, mdiTranslate } from "@mdi/js"
import ImagePreview from "components/common/Form/ImagePreview"
import { TRootStore } from "state/Store"
import { useSelector } from "react-redux"

interface Props {
    tableRowIndex: number
    info: api.doctors.IDoctorSpecialty
    onEdit: () => void
}

const ListRow: FunctionComponent<Props> = (props) => {
    const { i18n } = useTranslation()

    const displayLanguages = useSelector((state: TRootStore) => state.displayLanguages.entities)

    const hasMissingTranslation = () => {
        if (Object.keys(props.info.translations).length < displayLanguages.length)
            return true

        let result = false

        Object.keys(props.info.translations).forEach((key) => {
            if (result) return
            result = props.info.translations[key] === ""
        })

        return result
    }

    return <>
        <tr>
            <td>
                {
                    !hasMissingTranslation()
                    ? props.tableRowIndex
                    : <>
                        <span className="icon is-danger">
                            <Icon path={ mdiTranslate } size={ 0.75 } />
                        </span>
                    </>
                }
            </td>
            <td>
                <div className="is-flex is-justify-content-center">
                    <ImagePreview
                        placeholderType="image"
                        url={ props.info.icon ?? "" }
                        imgAlt="specialty-icon"
                        size="32x32"
                        shape="circle"
                    />
                </div>
            </td>
            <td>
                { props.info.translations[i18n.resolvedLanguage] ?? props.info.translations["en"] }
            </td>
            <td>
                <div className="buttons is-centered">
                    <button className="button is-circular" onClick={ props.onEdit }>
                    <span className="icon is-small">
                        <Icon path={ mdiPencil } size={ 1 } />
                    </span>
                    </button>
                </div>
            </td>
        </tr>
    </>
}

export default ListRow
