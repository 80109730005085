import { DateTime } from "luxon"
import { FunctionComponent } from "react"
import Icon from "@mdi/react"
import { mdiCheck, mdiClose, mdiForum } from "@mdi/js"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { IInstantCallDetails } from "service/http/instantCalls"
import { secondsToTimeString, rateString } from "utils/helpers"
import routes from "routes/routes"
import { EPermissionRoles, hasPermissions } from "utils/auth"

interface Props {
    instantCallTableIndex: number
    instantCallDetails: IInstantCallDetails
    useLocalTime: boolean
    onShowMessages: () => void
}

const InstantCallsTableRow: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common" ])

    return <>
        <tr>
            <td>
                <strong>{ props.instantCallTableIndex + 1 }</strong>
            </td>
            <td>
                <Link
                    to={ {
                        pathname: routes.doctorPage(props.instantCallDetails.doctor.id),
                    } }
                >
                    { `${props.instantCallDetails.doctor.givenName} ${props.instantCallDetails.doctor.familyName}` }
                </Link>
            </td>
            <td>
                <Link
                    to={ {
                        pathname: routes.clientPage(props.instantCallDetails.client.id)
                    } }
                >
                    { `${props.instantCallDetails.client.givenName} ${props.instantCallDetails.client.familyName}` }
                </Link>
            </td>
            <td>{ rateString(props.instantCallDetails.costInUnit, 2) }</td>
            <td>{ rateString(props.instantCallDetails.ratePerMinuteInUnit, 2) }</td>
            <td>{ rateString(props.instantCallDetails.videoRatePerMinuteInUnit, 2) }</td>
            {/*<td>{ "EUR" }</td>*/}
            <td>{ `${props.instantCallDetails.doctorPayoutPercentage ?? 0}%` }</td>
            <td>{ secondsToTimeString(props.instantCallDetails.voiceDurationInSeconds ?? 0) }</td>
            <td>{ secondsToTimeString(props.instantCallDetails.videoDurationInSeconds ?? 0) }</td>
            <td>{ t("common:paymentStatus.status", { context: props.instantCallDetails.paymentStatus }) }</td>
            <td>
                <Icon
                    path={ props.instantCallDetails.doctorReceivedNotification ? mdiCheck : mdiClose }
                    color={ props.instantCallDetails.doctorReceivedNotification ? "green" : "red" }
                    size={ 1 }
                />
            </td>
            <td>
                {
                    t("common:date.dateTimeMedWithSeconds", { date: props.useLocalTime
                        ? DateTime.fromISO(props.instantCallDetails.createdAt).toLocal()
                        : DateTime.fromISO(props.instantCallDetails.createdAt).toUTC()
                    })
                }
            </td>
            <td>
                {
                    props.instantCallDetails.respondedAt
                        ? t("common:date.dateTimeMedWithSeconds", { date: props.useLocalTime
                                ? DateTime.fromISO(props.instantCallDetails.respondedAt).toLocal()
                                : DateTime.fromISO(props.instantCallDetails.respondedAt).toUTC()
                        })
                        : "?"
                }
            </td>
            <td>
                {
                    props.instantCallDetails.endedAt
                        ? t("common:date.dateTimeMedWithSeconds", { date: props.useLocalTime
                                ? DateTime.fromISO(props.instantCallDetails.endedAt).toLocal()
                                : DateTime.fromISO(props.instantCallDetails.endedAt).toUTC()
                        })
                        : "?"
                }
            </td>
            <td>{ t("common:endOfCallRationale.type", { context: props.instantCallDetails.endOfCallRationale }) }</td>
            <td>
                {
                    // managers shouldn't be able to see messages
                    hasPermissions(EPermissionRoles.Admin)
                    && <>
                        <div className="buttons is-centered">
                            <button
                                className="button is-circular"
                                type="button"
                                disabled={ !props.instantCallDetails.messages }
                                onClick={ props.onShowMessages }
                            >
                        <span className="icon is-small">
                            <Icon path={ mdiForum } size={ 1 } />
                        </span>
                            </button>
                        </div>
                    </>
                }
            </td>
        </tr>
    </>
}

export default InstantCallsTableRow
