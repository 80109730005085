import { FunctionComponent, useEffect, useState } from "react"
import { DateTime } from "luxon"
import Icon from "@mdi/react"
import { mdiFilter } from "@mdi/js"
import { useTranslation } from "react-i18next"
import { Form, Formik, FormikProps } from "formik"
import * as yup from "yup"

import { IInstantCallFilters } from "service/http/instantCalls"
import InputField from "components/common/Form/InputField"
import CheckBoxSwitchField from "components/common/Form/CheckBoxSwitchField"

interface IFormField {
    showMonthAndYear: boolean
    month: string
}

interface Props {
    filters: IInstantCallFilters
    onUpdate: (filters: IInstantCallFilters) => void
    onClear: () => void
}

const Filters: FunctionComponent<Props> = (props) => {
    const monthAndYearToFieldValue = (filters: IInstantCallFilters): string => {
        if (filters.year && filters.month)
            return DateTime.fromFormat(`${filters.year}-${filters.month}`, "yyyy-M").toFormat("yyyy-MM").toString()

        return DateTime.now().toFormat("yyyy-MM").toString()
    }

    const { t } = useTranslation([ "common", "instantCalls" ])

    const [ isOpen, setIsOpen ] = useState<boolean>(false)
    const [ initialValues, setInitialValues ] = useState<IFormField>({
        showMonthAndYear: !!(props.filters.month && props.filters.year),
        month: monthAndYearToFieldValue(props.filters)
    })

    useEffect(() => {
        // in case the filters are updated manually in the url
        setInitialValues({
            showMonthAndYear: !!(props.filters.month && props.filters.year),
            month:  monthAndYearToFieldValue(props.filters)
        })
    }, [ props.filters ])

    const handleSubmit = (values: IFormField) => {
        let filters: IInstantCallFilters = {}

        // @NOTE: we can do this for multiple filters in the future
        if (values.showMonthAndYear) {
            let date = DateTime.fromFormat(values.month, "yyyy-MM")

            filters.month = date.month
            filters.year = date.year
        }

        props.onUpdate(filters)
        setIsOpen(false)
    }

    const handleClear = () => {
        props.onClear()
        setIsOpen(false)
    }

    return <>
        <div className={ `dropdown is-right ${isOpen ? 'is-active' : ""}` }>
            <div className="dropdown-trigger">
                <div className="buttons is-centered">
                    <button
                        className="button is-circular"
                        onClick={ () => { setIsOpen(!isOpen) } }
                    >
                    <span className="icon is-small">
                        <Icon path={ mdiFilter } size={ 1 } />
                    </span>
                    </button>
                </div>
            </div>
            <div className="dropdown-menu filters" role="menu">
                <div className="dropdown-content">
                    <Formik
                        enableReinitialize={ true }
                        initialValues={ initialValues }
                        validationSchema={
                            yup.object().shape({
                                showMonthAndYear: yup.boolean(),
                                month: yup
                                    .string()
                                    .when("showMonthAndYear", {
                                        is: true,
                                        then: yup.string().required(t("common:form.fieldIsRequired"))
                                    })
                            })
                        }
                        onSubmit={ handleSubmit }
                    >
                        {
                            ((formikProps: FormikProps<IFormField>) => {
                                return <>
                                    <Form>
                                        <div className="dropdown-item">
                                            <CheckBoxSwitchField
                                                label={ t("instantCalls:table.filters.month.label") }
                                                name="showMonthAndYear"
                                            />
                                            {
                                                formikProps.values.showMonthAndYear
                                                && <InputField
                                                    name="month"
                                                    type="month"
                                                    min="2021-01"
                                                    max={ DateTime.now().toFormat("yyyy-MM").toString() }
                                                />
                                            }

                                        </div> {/* dropdown-item */}
                                        <div className="dropdown-item">
                                            <div className="field is-grouped is-justify-content-space-between">
                                                <button
                                                    className="button"
                                                    type="button"
                                                    onClick={ handleClear }
                                                >
                                                    { t("instantCalls:table.filters.buttons.clear") }
                                                </button>
                                                <button
                                                    className="button is-primary"
                                                    type="submit"
                                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                                >
                                                    { t("instantCalls:table.filters.buttons.apply") }
                                                </button>
                                            </div>
                                        </div> {/* dropdown-item */}
                                    </Form>
                                </>
                            })
                        }
                    </Formik>
                </div> {/* dropdown-content */}
            </div> {/* dropdown-menu */}
        </div> {/* dropdown */}
    </>
}

export default Filters
