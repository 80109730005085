import React, { FunctionComponent } from "react"
import { FieldHookConfig, useField, Field } from "formik"

interface OtherProps {
    label?: string | JSX.Element
    fieldSize?: "narrow" | "expanded"
    helpMessage?: string | JSX.Element
}

const CheckBoxSwitchField: FunctionComponent<OtherProps & FieldHookConfig<string>> = ({ label, fieldSize = "normal", helpMessage, ...props }) => {
    const [ field, meta ] = useField(props.name)

    return <>
        <div className={ "field" + (fieldSize === "narrow" ? "is-narrow" : "") }>
            <div className={
                    "control " + (fieldSize === "expanded" ? "is-expanded"  : "")
                }
            >
                <div className="switch">
                        <Field
                            className="switch__input"
                            id={ field.name }
                            { ...field }
                            { ...props }
                            type="checkbox"
                            checked={ field.value }
                        />
                    {
                        label
                        && <label htmlFor={ field.name } className="switch__label label">
                            { label }
                        </label>
                    }
                </div>
            </div>
            {
                meta.error
                ? <p className="help is-danger">{ meta.error }</p>
                : null
            }
            {
                helpMessage
                ? <p className="help" > { helpMessage } </p>
                : null
            }
        </div>
    </>
}

export default CheckBoxSwitchField
