import _ from "lodash"
import { FunctionComponent, useEffect, useState } from "react"
import Icon from "@mdi/react"
import { mdiAccountPlus } from "@mdi/js"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as doctorApi from "service/http/doctors"
import { noResponseReceivedNotification } from "utils/notifications"
import { handleAxiosError } from "service/service"
import usePagination from "hooks/usePagination"

import ListRow from "components/AdminDashboard/Doctors/List/ListRow"
import Loading from "components/common/Loading/Loading"
import PageSizeSelect from "components/common/Pagination/PageSizeSelect"
import Pagination from "components/common/Pagination/Pagination"
import Add from "components/AdminDashboard/Doctors/List/Add"
import SearchBar from "../../../common/SearchBar/SearchBar"

const List: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "doctors" ])

    // pagination
    const location = useLocation()
    // eslint-disable-next-line
    const [ currentPage, currentPageSize, currentPageCount, currentFilters, changePage, handlePagination ] = usePagination(doctorApi.getDoctorProfilesPage)

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ doctors, setDoctors ] = useState<doctorApi.IDoctor[]>([])
    const [ isAddingDoctor, setIsAddingDoctor ] = useState<boolean>(false)

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)

            try {
                const response = await handlePagination()

                if (response)
                    setDoctors(response)
            } catch (error) {
                // clear the table
                setDoctors([])

                handleAxiosError(error, (e) => {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // if it were a 401 error the user would be redirected
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // Something happened in setting up the request that triggered an Error
                    console.log(error);
                })
            }
        }

        fetch().then(() => {
            setIsLoading(false)
        })

        // t is not relevant as a dependency
        // eslint-disable-next-line
    }, [ location.search ])

    return <>
        <h1 className="title"> { t("doctors:table.title") } </h1>
        <h2 className="subtitle"> { t("doctors:table.subtitle") } </h2>

        <div className="is-flex is-justify-content-space-between is-align-items-flex-end mb-2">
            <SearchBar
                placeholder={ t("doctors:table.searchPlaceholder") }
                searchQuery={ currentFilters?.searchQuery }
                onSubmit={ (searchQuery?: string) => {
                    if (!searchQuery)
                        changePage({ filters: _.omit(currentFilters, "searchQuery")})

                    changePage({
                        filters: {
                            ...currentFilters,
                            searchQuery: searchQuery
                        }
                    })
                } }
            />

            <PageSizeSelect
                currentPageSize={ currentPageSize }
                onChangePage={ (pageSize) => {
                    changePage({
                        pageSize: pageSize
                    })
                } }
                isLoading={ isLoading }
            />
        </div>

        <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
                <thead>
                <tr>
                    <th>#</th>
                    <th>{ t("doctors:table.headers.profilePicture") }</th>
                    <th>{ t("doctors:table.headers.name") }</th>
                    <th>{ t("doctors:table.headers.email") }</th>
                    <th>{ t("doctors:table.headers.rate") }</th>
                    <th>{ t("doctors:table.headers.payoutPercentage") }</th>
                    <th>{ t("doctors:table.headers.accountState") }</th>
                    <th>{ t("doctors:table.headers.pageVisibility") }</th>
                    <th>
                        <div className="buttons is-centered">
                            <button className="button is-secondary is-circular" onClick={ () => { setIsAddingDoctor(true) } }>
                                <span className="icon is-small">
                                    <Icon path={ mdiAccountPlus } size={ 1 } />
                                </span>
                            </button>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    (() => {
                        if (isLoading)
                            return <tr>
                                <td colSpan={ 7 }>
                                    <Loading/>
                                </td>
                            </tr>

                        if (!doctors.length)
                            return <tr>
                                <td colSpan={ 9 }>
                                    { t("doctors:table.noDoctorsFoundMessage") }
                                </td>
                            </tr>

                        return doctors.map((doctor, index) => {
                            return (
                                <ListRow
                                    key={ doctor.id }
                                    tableRowIndex={ ((currentPage - 1) * currentPageSize) + index + 1 }
                                    doctor={ doctor }
                                />
                            )
                        })
                    })()
                }
                </tbody>
            </table>
        </div>
        {
            doctors.length > 0 && !isLoading
            && <Pagination
                currentPage={ currentPage }
                lastPage={ currentPageCount }
                onPageChange={ (page) => {
                    changePage({
                        page: page
                    })
                } }
            />
        }
        {
            isAddingDoctor
            && <Add onClose={ () => { setIsAddingDoctor(false) } }/>
        }
    </>
}

export default List
