import { ChangeEvent, FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"

import { enumToNumberValuesArray } from "utils/helpers"
import { EReportState, IReport, updateReport } from "service/http/reports"
import { notify } from "utils/notifications"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"

interface Props {
    info: IReport
    onReportStateChange: (newState: EReportState) => void
}

const State: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "reports", "modal", "notification" ])
    const setModalOptions = useConfirmModal()

    const [ isUpdating, setIsUpdating ] = useState<boolean>(false)

    const handleStateChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        // for some reason e.target.value is undefined when onConfirm() is called
        // not sure why, so we define it here instead
        const newState = parseInt(e.target.value, 10)

        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t("modal:confirm.reportStateChange.title"),
                content: t("modal:confirm.reportStateChange.message"),
                onConfirm: async () => {
                    setModalOptions({ isOpen: false })
                    setIsUpdating(true)

                    try {
                        // update on the server
                        await updateReport(props.info.id, {
                            state: newState
                        })

                        // update the table info
                        props.onReportStateChange(newState)

                        notify(
                            "success",
                            t("notification:onReportStateChange.success.title"),
                            t("notification:onReportStateChange.success.message")
                        )
                    } catch(e) {
                        notify(
                            "danger",
                            t("notification:onReportStateChange.fail.message"),
                            t("notification:onReportStateChange.fail.message")
                        )
                    }

                    setIsUpdating(false)
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })
    }

    return <>
        <div className="field">
            <div className="control is-expanded">
                <label className="label">{ t("reports:view.state.state.label") }</label>
                <div className={ "select is-fullwidth " + (isUpdating? "is-loading" : "") }>
                    <select
                        name="reportState"
                        disabled={ isUpdating }
                        value={ props.info.reportState }
                        onChange={ handleStateChange }
                    >
                        {
                            enumToNumberValuesArray(EReportState).map((value) => {
                                return <option key={ value } value={ value } >
                                    { t("common:reportState.state", { context: value }) }
                                </option>
                            })
                        }
                    </select>
                </div>
            </div>
        </div>
    </>
}

export default State
