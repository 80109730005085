import { FunctionComponent } from "react"
import { IReport } from "service/http/reports"
import { useTranslation } from "react-i18next"
import NotesForm from "components/AdminDashboard/Reports/View/Details/NotesForm"

interface Props {
    info: IReport
    onUpdateReportNotes: (notes: string) => void
}

const Details: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "reports" ])

    return <>
        <div className="field">
            <div className="control">
                <label className="label">{ t("reports:view.details.labels.userMessage") }</label>
                <textarea className="textarea" readOnly={ true } value={ props.info.userMessage } />
            </div>
        </div>

        <NotesForm info={ props.info } onUpdate={ props.onUpdateReportNotes }/>
    </>
}

export default Details
