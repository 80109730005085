import axios from "axios"
import * as api from "service/http/api"
import { IPagedRequestParams, IPagedRequestResponse } from "service/http/pagination"

const basePath = "/administration/stats"

export enum EGroupByDateType {
    Day,
    Week,
    Month,
    Year
}

export enum EStatsPredefinedDateRange {
    LastWeek,
    LastMonth,
    LastYear,
    AllTime
}

export interface IInstantCallStatsEntry {
    total: number
    answered: number
    notAnswered: number
    rejected: number
    notNotified: number
}

export interface IInstantCallStats {
    notNotifiedToday: boolean
    currentMonth: IInstantCallStatsEntry
    entries: IGroupedByDateInstantCallStats[]
}

export interface IGroupedByDateInstantCallStats {
    day?: number
    month?: number
    year: number
    stats: IInstantCallStatsEntry
}

export interface IDoctorInstantCallStats extends IInstantCallStats {
    doctor: api.doctors.IDoctor,
    notNotifiedToday: boolean
}

// doctor revenue summary
export interface IDoctorRevenueSummaryProfile {
    id: string
    name: string
    primaryEmail: string
}

export interface IDoctorRevenueSummary {
    doctor: IDoctorRevenueSummaryProfile
    manager: IDoctorRevenueSummaryProfile
    totalCalls: number
    totalRevenue: number
    stripeFee: number
    doctorRevenue: number
    managerRevenue: number
    agathaProfit: number
}

// filters
interface IStatsFiltersPredefinedRange {
    range: EStatsPredefinedDateRange
    start?: never
    end?: never
}

interface IStatsFiltersCustomRange {
    range?: never
    start: number // unix timestamp
    end: number // unix timestamp
}

export type TStatsFilters = (IStatsFiltersPredefinedRange | IStatsFiltersCustomRange)

// global stats
interface TGlobalStatsResponse extends IInstantCallStats {}

export const getGlobalStats = async (params: TStatsFilters): Promise<TGlobalStatsResponse> => {
    const response = await axios.get<TGlobalStatsResponse>(`${basePath}/global`, {
        params: params
    })
    return response.data
}

// doctor stats page

interface IGetDoctorStatsPageResponse extends IPagedRequestResponse<IDoctorInstantCallStats> {}

export const getDoctorStatsPage = async (params: IPagedRequestParams<TStatsFilters>): Promise<IGetDoctorStatsPageResponse> => {
    const response = await axios.get<IGetDoctorStatsPageResponse>(`${basePath}/doctors`, {
        params: params
    })
    return response.data
}

// doctor revenue summary
type IGetDoctorRevenueSummaryResponse = IDoctorRevenueSummary[]

export const getDoctorRevenueSummary = async (from: string, to: string): Promise<IGetDoctorRevenueSummaryResponse> => {
    const response = await axios.get<IGetDoctorRevenueSummaryResponse>(`${basePath}/doctors/revenue-summary`, {
        params: {
            start: from,
            end: to
        }
    })
    return response.data
}
