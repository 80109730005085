import { FunctionComponent, useEffect, useRef, useState } from "react"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import Icon from "@mdi/react"
import { mdiAlertCircleOutline, mdiChevronDown, mdiChevronUp } from "@mdi/js"

import * as api from "service/http/api"
import usePagination from "hooks/usePagination"
import { handleAxiosError } from "service/service"
import { noResponseReceivedNotification } from "utils/notifications"

import Loading from "components/common/Loading/Loading"
import Pagination from "components/common/Pagination/Pagination"
import PageSizeSelect from "components/common/Pagination/PageSizeSelect"
import Filters from "components/AdminDashboard/Stats/Doctors/Filters"
import EntryCard from "components/AdminDashboard/Stats/Doctors/EntryCard"
import DoctorRevenueSummary from "components/AdminDashboard/Stats/Doctors/DoctorRevenueSummary"

const DoctorsStats: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "stats", "common" ])

    // pagination
    const location = useLocation()
    // eslint-disable-next-line -- will be used at some point
    const [ currentPage, currentPageSize, currentPageCount, currentFilters, changePage, handlePagination ] = usePagination(api.stats.getDoctorStatsPage, { range: api.stats.EStatsPredefinedDateRange.LastWeek })

    // used to scroll to doctors
    const missingNotificationRef = useRef(new Map<string, HTMLDivElement | null>())

    // region state
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ isShowingSummaryCard, setIsShowingSummaryCard ] = useState<boolean>(false)
    const [ globalStats, setGlobalStats ] = useState<api.stats.IInstantCallStats>({} as api.stats.IInstantCallStats)
    const [ doctorStats, setDoctorStats ] = useState<api.stats.IDoctorInstantCallStats[]>([])
    //endregion

    // mount
    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)

            try {
                const response = await handlePagination()

                if (response)
                {
                    setDoctorStats(response)

                    // if the url is missing pagination this would be called twice
                    // if we do it here it happens only after adding pagination to the url
                    if (currentPage === 1) {
                        const response = await api.stats.getGlobalStats(currentFilters)

                        setGlobalStats(response)
                    }
                }
            } catch (error) {
                handleAxiosError(error, (e) => {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // if it were a 401 error the user would be redirected
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                })
            }
        }

        fetch().then(() => setIsLoading(false))

        // eslint-disable-next-line -- t and current page are not relevant
    }, [ location.search ])

    const getChartTitle = () => {
        if (currentFilters.range !== undefined)
            return t("common:predefinedDateRange.type", { context: currentFilters.range })

        if (currentFilters.start && currentFilters.end)
            return t("common:date.dateMed", { date: DateTime.fromSeconds(currentFilters.start, { zone: "utc" }).toLocal() })
                + " - "
                + t("common:date.dateMed", { date: DateTime.fromSeconds(currentFilters.end, { zone: "utc" }).toLocal() })

        return ""
    }

    // region render
    if (isLoading)
        return <Loading />

    return <>
        <div className="container">
            <h1 className="title">{ t("stats:chart.instantCalls.title") }</h1>
            <h2 className="subtitle">{ t("stats:chart.instantCalls.subtitle") }</h2>

            {/*Generate summary*/}
            <div className="card mb-6">
                <div className="card-header">
                    <p className="card-header-title">
                        { t("stats:reportGeneration.title") }
                    </p>
                    <button
                        className="card-header-icon"
                        onClick={ () => setIsShowingSummaryCard(prevState => !prevState) }
                    >
                        <span className="icon">
                            <Icon path={ isShowingSummaryCard ? mdiChevronUp : mdiChevronDown } size={ 1 } />
                        </span>
                    </button>
                </div>

                {
                    isShowingSummaryCard
                    && <>
                        <div className="columns">
                            <div className="column is-half-desktop">
                                <div className="card-content">
                                    <DoctorRevenueSummary />
                                </div>
                            </div>

                            {/*<div className="column is-half-desktop">*/}
                            {/*    <div className="card-content">*/}
                            {/*        <DoctorBillGenerator />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </>
                }
            </div>

            {
                globalStats.notNotifiedToday
                && <>
                    <div className="notification is-danger">
                        <span className="icon-text">
                            <span className="icon">
                                <Icon path={ mdiAlertCircleOutline } size={ 1 } color={ "white" } />
                            </span>
                            <span>{ t("stats:chart.instantCalls.notNotifiedToday.message") }</span>
                        </span>

                        <p>{ t("stats:chart.instantCalls.notNotifiedToday.onThisPage") }</p>
                        {
                            (() => {
                                if (!doctorStats.find(it => it.notNotifiedToday))
                                    return <p>
                                        { t("stats:chart.instantCalls.notNotifiedToday.none") }
                                    </p>

                                // render button for each un-notified doctor on this page
                                return <>
                                    <div className="buttons mt-2">
                                        {
                                            doctorStats.filter(it => it.notNotifiedToday).map(value => {
                                                return <button
                                                    key={ value.doctor.id }
                                                    className="button is-small is-danger is-light"
                                                    type="button"
                                                    onClick={ () => {
                                                        const ref = missingNotificationRef.current.get(value.doctor.id)

                                                        if (ref)
                                                            ref.scrollIntoView({
                                                                behavior: "smooth"
                                                            })
                                                    } }
                                                >
                                                    { `${value.doctor.givenName} ${value.doctor.familyName}` }
                                                </button>
                                            })
                                        }
                                    </div>
                                </>
                            })()
                        }
                    </div>
                </>
            }

            <div className="columns">
                <div className="column">
                    <div className="field is-horizontal is-pulled-right">
                        <div className="field-body">
                            <div className="field is-flex is-align-items-end">
                                <Filters
                                    filters={ currentFilters }
                                    onUpdate={ (filters) => {
                                        changePage({
                                            filters: filters
                                        })
                                    } }
                                    onClear={ () => {
                                        changePage({
                                            filters: {
                                                range: api.stats.EStatsPredefinedDateRange.LastWeek
                                            }
                                        })

                                    } }
                                />
                            </div>
                            <div className="field">
                                <PageSizeSelect
                                    currentPageSize={ currentPageSize }
                                    onChangePage={ (pageSize) => {
                                        changePage({
                                            pageSize: pageSize
                                        })
                                    } }
                                    isLoading={ isLoading }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                currentPage === 1 && Object.keys(globalStats).length > 0
                && <>
                    <div className="columns">
                        <div className="column">
                            <EntryCard  type="global" stats={ globalStats } chartTitle={ getChartTitle() } />
                        </div>
                    </div>
                </>
            }

            <div className="columns">
                <div className="column">
                    {
                        (() => {
                            if (doctorStats.length === 0)
                                return <>
                                    <p className="has-text-centered">
                                        { t("stats:chart.instantCalls.noDoctors") }
                                    </p>
                                </>

                            const firstCol = doctorStats.filter((entry, index) => index === 0 || index % 2 === 0)
                            const secondCol = doctorStats.filter((entry, index) => index !== 0 && index % 2 === 1)

                            let result: JSX.Element[] = []

                            for (let i = 0; i < firstCol.length; i++) {
                                result.push(
                                    <div className="columns" key={ firstCol[i].doctor.id }>
                                        <div
                                            className="column"
                                            id={ firstCol[i].doctor.id }
                                            ref={ (ref) => missingNotificationRef.current.set(firstCol[i].doctor.id, ref)}
                                        >
                                            <EntryCard type="doctor" stats={ firstCol[i] } chartTitle={ getChartTitle() }/>
                                        </div>

                                        <div
                                            className="column"
                                            id={ secondCol[i]?.doctor.id ?? "" }
                                            ref={ (ref) => {
                                                if (secondCol[i])
                                                    missingNotificationRef.current.set(secondCol[i].doctor.id, ref)
                                            }}
                                        >
                                            {
                                                secondCol[i]
                                                && <EntryCard type="doctor" stats={ secondCol[i] } chartTitle={ getChartTitle() }/>
                                            }
                                        </div>
                                    </div>
                                )
                            }

                            return result
                        })()
                    }
                    {/*pagination*/}
                    {
                        doctorStats.length > 0 && !isLoading
                        && <Pagination
                            currentPage={ currentPage }
                            lastPage={ currentPageCount }
                            onPageChange={ (page) => {
                                changePage({
                                    page: page
                                })
                            } }
                        />
                    }
                </div>
            </div>
        </div>
    </>
    // endregion
}

export default DoctorsStats
