import _ from "lodash"
import { FunctionComponent, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { handleAxiosError } from "service/service"
import { noResponseReceivedNotification } from "utils/notifications"
import usePagination from "hooks/usePagination"
import * as api from "service/http/api"

import Loading from "components/common/Loading/Loading"
import ListRow from "components/AdminDashboard/Clients/List/ListRow"
import PageSizeSelect from "components/common/Pagination/PageSizeSelect"
import Pagination from "components/common/Pagination/Pagination"
import SearchBar from "../../../common/SearchBar/SearchBar"

const List: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "clients", "common" ])

    // pagination
    const location = useLocation()
    // eslint-disable-next-line
    const [ currentPage, currentPageSize, currentPageCount, currentFilters, changePage, handlePagination ] = usePagination(api.clients.getClientsPage)

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ clients, setClients ] = useState<api.clients.IClient[]>([])

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)

            try {
                const response = await handlePagination()

                if (response)
                    setClients(response)
            } catch (error) {
                handleAxiosError(error, (e) => {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // if it were a 401 error the user would be redirected
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                })
            }
        }

        fetch().then(() => {
            setIsLoading(false)
        })

        // t is not relevant as a dependency
        // eslint-disable-next-line
    }, [ location.search ])

    return <>
        <h1 className="title">{ t("clients:table.title") }</h1>
        <h2 className="subtitle">{ t("clients:table.subtitle") }</h2>

        <div className="is-flex is-justify-content-space-between is-align-items-flex-end mb-2">
            <SearchBar
                placeholder={ t("clients:table.searchPlaceholder") }
                searchQuery={ currentFilters?.searchQuery }
                onSubmit={ (searchQuery?: string) => {
                    if (!searchQuery)
                        changePage({ filters: _.omit(currentFilters, "searchQuery")})

                    changePage({
                        filters: {
                            ...currentFilters,
                            searchQuery: searchQuery
                        }
                    })
                } }
            />

            <PageSizeSelect
                currentPageSize={ currentPageSize }
                onChangePage={ (pageSize) => {
                    changePage({
                        pageSize: pageSize
                    })
                } }
                isLoading={ isLoading }
            />
        </div>

        <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
                <thead>
                <tr>
                    <th>#</th>
                    <th>{ t("clients:table.headers.profilePicture") }</th>
                    <th>{ t("clients:table.headers.name") }</th>
                    <th>{ t("clients:table.headers.email") }</th>
                    <th>{ t("clients:table.headers.accountState") }</th>
                    <th>{ t("clients:table.headers.isTestingAccount") }</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {
                    (() => {
                        if (isLoading)
                            return <tr>
                                <td colSpan={ 8 }>
                                    <Loading />
                                </td>
                            </tr>


                        if (!clients.length)
                            return <tr>
                                <td colSpan={ 8 }>{ t("clients:table.noClientsFound") }</td>
                            </tr>


                        return clients.map((client, index) => {
                            return (
                                <ListRow
                                    key={ client.id }
                                    tableRowIndex={ ((currentPage - 1) * currentPageSize) + index }
                                    client={ client }
                                />
                            )
                        })
                    })()
                }
                </tbody>
            </table>
        </div>
        {
            clients.length > 0 && !isLoading
            && <Pagination
                currentPage={ currentPage }
                lastPage={ currentPageCount }
                onPageChange={ (page) => {
                    changePage({
                        page: page
                    })
                } }
            />
        }
    </>
}

export default List
