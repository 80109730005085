import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"

import Loading from "components/common/Loading/Loading"
import Manage from "components/AdminDashboard/Localization/Manage/Manage"
import LocalizationTableRow from "components/AdminDashboard/Localization/LocalizationTableRow"
import { useDispatch, useSelector } from "react-redux"
import { TRootStore } from "state/Store"
import {
    addDisplayLanguages,
    updateDisplayLanguages
} from "state/reducers/DisplayLanguagesReducer"
import { ERequestState } from "state/reducers/RequestTypes"
import { fetchLocalizationStatus } from "state/reducers/LocalizationStatusReducer"

const Localization: FunctionComponent = () => {
    const { t } = useTranslation([ "localization", "notification" ])

    const dispatch = useDispatch()
    const displayLanguagesState = useSelector((state: TRootStore) => state.displayLanguages.state)
    const displayLanguages = useSelector((state: TRootStore) => state.displayLanguages.entities)

    const [ isCreatingLanguage, setIsCreatingLanguage ] =  useState<boolean>(false)
    const [ isEditingDetailsOf, setIsEditingDetailsOf ] =  useState<number | null>(null)

    const add = (language: string) => {
        dispatch(addDisplayLanguages({
            isoCode: language,
            available: false
        }))

        // update the localization status
        dispatch(fetchLocalizationStatus())
    }

    const update = (available: boolean) => {
        dispatch(updateDisplayLanguages({
            ...displayLanguages[isEditingDetailsOf!],
            available: available
        }))
    }

    return <>
        <h1 className="title">{ t("localization:table.title") }</h1>
        <h2 className="subtitle">{ t("localization:table.subtitle") }</h2>

        <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{ t("localization:table.headers.language") }</th>
                    <th>{ t("localization:table.headers.available") }</th>
                    <th>
                        <div className="buttons is-centered">
                            <button className="button is-secondary is-circular" onClick={ () => { setIsCreatingLanguage(true) } }>
                                <span className="icon is-small">
                                    <Icon path={ mdiPlus } size={ 1 } />
                                </span>
                            </button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
            {
                (() => {
                    if (displayLanguagesState === ERequestState.Loading)
                        return <tr>
                            <td colSpan={ 3 }>
                                <Loading />
                            </td>
                        </tr>


                    if (!displayLanguages.length)
                        return <tr>
                            <td colSpan={ 3 }>{ t("localization:table.noLanguagesFound") }</td>
                        </tr>


                    return displayLanguages.map((lang, index) => {
                        return (
                            <LocalizationTableRow
                                key={ lang.isoCode }
                                tableRowIndex={ index + 1 }
                                info={ lang }
                                onEdit={ () => { setIsEditingDetailsOf(index) } }
                            />
                        )
                    })
                })()
            }
            </tbody>
        </table>
        {
            isEditingDetailsOf !== null
            && <Manage
                type="edit"
                info={ displayLanguages[isEditingDetailsOf] }
                onUpdate={ update }
                onClose={ () => { setIsEditingDetailsOf(null) } }
            />
        }
        {
            isCreatingLanguage
            && <Manage
                type="add"
                existingLanguages={ displayLanguages }
                onCreate={ add }
                onClose={ () => { setIsCreatingLanguage(false) } }
            />
        }
    </>
}

export default Localization
