import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import * as yup from "yup"
import { Form as FormikForm, Formik, FormikProps } from "formik"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import * as api from "service/http/api"
import {
    enumToNumberValuesArray,
    getYouTubeVideoIdFromUrl,
    isValidImageFile,
    isValidYTVideoId,
    stringArrayToString
} from "utils/helpers"
import { notify } from "utils/notifications"
import { SUPPORTED_IMAGE_FORMATS } from "service/config/config"

import InputField from "components/common/Form/InputField"
import SelectField from "components/common/Form/SelectField"
import FormikDirtyListener from "components/common/Form/FormikDirtyListener"
import ImagePreview from "components/common/Form/ImagePreview"
import ImageFileInputField from "components/common/Form/ImageFileInputField"
import { TRootStore } from "../../../../../../state/Store"
import languages from "../../../../../../utils/languages"

interface IFormFields {
    imageRequired: boolean // needed to make the file upload required
    type: api.doctors.EMediaContentType
    description: Record<string, string>
    url?: string
    image?: File | null
    [key: string]: any
}

interface NewProps {
    type: "new"
    mediaContentEntry?: never
    onCreate: (entry: api.doctors.TCreateMediaContentParams) => Promise<void>
    onUpdate?: never
}

interface ExistingProps {
    type: "existing"
    mediaContentEntry: api.doctors.IDoctorMediaContent
    onCreate?: never
    onUpdate: (entry: api.doctors.TUpdateMediaContentParams) => Promise<void>
}

type Props = (NewProps | ExistingProps) & {
    onClose: (checkDirty?: boolean) => void
    setIsFormDirty: (value: boolean) => void
}

const Form: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "common", "doctors", "notification" ])
    const displayLanguages = useSelector((state: TRootStore) => state.displayLanguages.entities)

    const [ fileInputKey, setFileInputKey ] = useState<number>(Date.now())

    const [ initialValues, setInitialValues ] = useState<IFormFields>({
        imageRequired:  props.type === "new",
        image:          null,
        url:            props.type === "existing" ? props.mediaContentEntry.url         : "",
        description:    displayLanguages.reduce((acc, curr) => ({
            ...acc,
            [curr.isoCode]: props.mediaContentEntry?.description[curr.isoCode] ?? ""
        }), {}),
        type:           props.type === "existing" ? props.mediaContentEntry.type        : api.doctors.EMediaContentType.Image
    })

    const validationSchemaRef = useRef(yup.object<IFormFields>().shape({
            imageRequired: yup.boolean(),
            image: yup
                .mixed<File>()
                .nullable()
                .when(["imageRequired", "type"], {
                    is: (
                        imageIsRequired: boolean,
                        type: api.doctors.EMediaContentType
                    ) => type === api.doctors.EMediaContentType.Image && imageIsRequired,
                    then: yup
                        .mixed<File>()
                        .required(t("common:form.fieldIsRequired"))
                })
                .test("isFileTypeValid", t("common:form.file.image.error"),
                    value => isValidImageFile(value)
                ),
            url: yup
                .string()
                .url("common:form.URLIsInvalid")
                .when([ "type" ], {
                    is: (type: api.doctors.EMediaContentType) => type !== api.doctors.EMediaContentType.Image,
                    then: yup.string().required(t("common:form.fieldIsRequired"))
                })
                .test('isValidYTVideoId', t("common:form.YTVideoIdIsInvalid"), async (value, ctx) => {
                    if (value && ctx.parent.type !== api.doctors.EMediaContentType.Image) {
                        try {
                            const videoId = getYouTubeVideoIdFromUrl(value)
                            return videoId !== undefined && await isValidYTVideoId(videoId)
                        } catch(e) {
                            return false
                        }
                    }

                    return true
                }),
            description: yup.lazy(obj =>
                yup
                    .object(
                        _.mapValues(obj, (value, key) => yup.string().trim().notRequired())
                )
            ),
            type: yup
                .number()
                .oneOf(enumToNumberValuesArray(api.doctors.EMediaContentType))
                .required(t("common:form.fieldIsRequired")),
        }))

    // update initial values after successful update
    useEffect(() => {
        if (props.type === "existing") {
            setInitialValues({
                imageRequired:  false,
                image:          null,
                url:            props.mediaContentEntry.url,
                description:    displayLanguages.reduce((acc, curr) => ({
                    ...acc,
                    [curr.isoCode]: props.mediaContentEntry?.description[curr.isoCode] ?? ""
                }), {}),
                type:           props.mediaContentEntry.type
            })
        }

        // props.type is not relevant here
        // eslint-disable-next-line
    }, [ props.mediaContentEntry ])

    const handleSubmit = async (values: IFormFields) => {
        values = validationSchemaRef.current.cast(values) as IFormFields

        if (props.type === "new") {
            // create
            let params: api.doctors.TCreateMediaContentParams

            if (values.type === api.doctors.EMediaContentType.Image)
                params = {
                    type: values.type,
                    description: values.description,
                    image: values.image!
                }
            else
                params = {
                    type: values.type,
                    description: values.description,
                    url: values.url!
                }

            try {
                await props.onCreate(params)

                // close pop-up
                props.onClose(false)

                notify(
                    "success",
                    t("notification:onDoctorMediaContentCreated.success.title"),
                    t("notification:onDoctorMediaContentCreated.success.message")
                )
            } catch (e) {
                notify(
                    "danger",
                    t("notification:onDoctorMediaContentCreated.fail.title"),
                    t("notification:onDoctorMediaContentCreated.fail.message")
                )
            }
        } else if (props.type === "existing") {
            // update
            let params = {} as api.doctors.TUpdateMediaContentParams

            Object.keys(_.omit(values, "imageRequired") as api.doctors.TUpdateMediaContentParams).forEach((key) => {
                if (values[key] !== initialValues[key])
                    params[key] = values[key]
            })

            try {
                await props.onUpdate(params)

                // close pop-up
                props.onClose(false)

                notify(
                    "success",
                    t("notification:onDoctorMediaContentUpdated.success.title"),
                    t("notification:onDoctorMediaContentUpdated.success.message")
                )
            } catch (e) {
                notify(
                    "danger",
                    t("notification:onDoctorMediaContentUpdated.fail.title"),
                    t("notification:onDoctorMediaContentUpdated.fail.message")
                )
            }
        }
    }

    return <>
        <Formik
            enableReinitialize={ true }
            initialValues={ initialValues }
            validationSchema={ validationSchemaRef.current }
            onSubmit={ handleSubmit }
        >
            {
                ((formikProps: FormikProps<IFormFields>) => {
                    return <>
                        <FormikDirtyListener onChange={ props.setIsFormDirty } />

                        <FormikForm>
                            {
                                formikProps.values.type === api.doctors.EMediaContentType.Image
                                && <>
                                    <div className="field">
                                        <div className="control">
                                            {
                                                (() => {
                                                    if (!!formikProps.values.image && !formikProps.errors.image)
                                                        return <>
                                                            <ImagePreview
                                                                placeholderType="image"
                                                                file={ formikProps.values.image }
                                                                imgAlt="profile-profilePicture"
                                                                size="auto"
                                                                shape="square"
                                                                maxHeight={ 256 }
                                                            />
                                                        </>

                                                    return <>
                                                        <ImagePreview
                                                            placeholderType="image"
                                                            url={ props.type === "existing" ? props.mediaContentEntry.url : "" }
                                                            imgAlt="profile-profilePicture"
                                                            size="auto"
                                                            shape="square"
                                                            maxHeight={ 256 }
                                                        />
                                                    </>
                                                })()
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            {/*url/image*/}
                            {
                                (() => {
                                    if (formikProps.values.type === api.doctors.EMediaContentType.Image)
                                        return <>
                                            <ImageFileInputField
                                                resetKey={ fileInputKey } // used for resetting the input
                                                label={ t("common:form.file.image.labels.mediaContentImage") }
                                                name="image"
                                                helpMessage={ t("common:form.file.image.help", { fileTypes: stringArrayToString(SUPPORTED_IMAGE_FORMATS) }) }
                                                // crop={ true }
                                                // cropAspectRatio={ 16/9 }
                                                onSaveFile={ (file) => formikProps.setFieldValue("image", file) }
                                                onRemoveFile={ () => {
                                                    setFileInputKey(Date.now())
                                                    formikProps.setFieldValue("image", null)
                                                } }
                                            />
                                        </>

                                    return <>
                                        <InputField
                                            label={ t("doctors:edit.mediaContent.form.url.label") }
                                            name="url"
                                            fieldSize="expanded"
                                            placeholder={ t("doctors:edit.mediaContent.form.url.placeholder") }
                                        />
                                    </>
                                })()
                            }

                            {/*media content type*/}
                            {
                                (() => {
                                    if (props.type === "new")
                                        return (
                                            <SelectField
                                                label={ t("doctors:edit.mediaContent.form.type.label") }
                                                name="type"
                                                fieldSize="expanded"
                                                onChange={ (event: ChangeEvent<HTMLSelectElement>) => {
                                                    formikProps.setFieldValue("type", parseInt(event.target.value, 10))
                                                } }
                                            >
                                                {
                                                    enumToNumberValuesArray(api.doctors.EMediaContentType).map((value) => {
                                                        return <option key={ value } value={ value }>
                                                            { t("common:doctorMediaContentType.type", { context: value }) }
                                                        </option>
                                                    })
                                                }
                                            </SelectField>
                                        )

                                    return (
                                        <>
                                            <div className="field">
                                                <label className="label">{ t("doctors:edit.mediaContent.form.type.label") }</label>
                                                <div className="control is-expanded">
                                                    <input type="text" className="input" readOnly value={ t("common:doctorMediaContentType.type", { context: formikProps.values.type }) }/>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })()
                            }

                            {/*description*/}
                            <div className="field">
                                <label className="label">{ t("doctors:edit.mediaContent.form.description.label") }</label>
                                <div className="control">
                                    {
                                        Object.keys(formikProps.values.description).map((language) => {
                                            return (
                                                <div key={ language } className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">
                                                            { languages[language][i18n.resolvedLanguage] }
                                                        </label>
                                                    </div>
                                                    <div className="field-body">
                                                        <InputField
                                                            name={ `description[${language}]` }
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p className="help">{ t("doctors:edit.mediaContent.form.description.help") } </p>
                            </div>

                            {/*buttons*/}
                            <div className="field is-grouped is-justify-content-space-between">
                                 <div className="control">
                                    <button className="button" type="button" onClick={ () => props.onClose() }>
                                        { t("doctors:edit.mediaContent.form.buttons.close") }
                                    </button>
                                </div>
                                <div className="control">
                                    <button
                                        className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                        type="submit"
                                        disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                    >
                                        { t("doctors:edit.mediaContent.form.buttons.save") }
                                    </button>
                                </div>
                            </div>

                        </FormikForm>
                    </>
                })
            }
        </Formik>
    </>
}

export default Form
