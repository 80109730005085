import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import _ from "lodash"

import * as api from "service/http/api"
import Loading from "components/common/Loading/Loading"
import Manage from "components/AdminDashboard/Doctors/Specialties/Manage/Manage"
import ListRow from "components/AdminDashboard/Doctors/Specialties/List/ListRow"
import { useDispatch, useSelector } from "react-redux"
import { TRootStore } from "state/Store"
import { addDoctorSpecialty, updateDoctorSpecialty } from "state/reducers/DoctorSpecialtiesReducer"
import { ERequestState } from "state/reducers/RequestTypes"
import { fetchLocalizationStatus } from "state/reducers/LocalizationStatusReducer"

const List: FunctionComponent = () => {
    const { t } = useTranslation([ "doctors", "notification" ])

    const [ isCreating, setIsCreating ] = useState<boolean>(false)
    const [ isEditingDetailsOf, setIsEditingDetailsOf ] = useState<number | null>(null)

    const dispatch = useDispatch()
    const doctorSpecialties = useSelector((state: TRootStore) => state.doctorSpecialties)


    const createSpecialty = (specialty: api.doctors.ICreateDoctorSpecialtyResponse) => {
        dispatch(addDoctorSpecialty(specialty))
        setIsCreating(false)

        // update the localization status
        dispatch(fetchLocalizationStatus())
    }

    const updateSpecialty = (specialty: api.doctors.IUpdateDoctorSpecialtyResponse) => {
        let prev = _.cloneDeep(doctorSpecialties.entities[isEditingDetailsOf!])

        Object.keys(specialty).forEach((key) => {
            prev[key] = specialty[key]!
        })

        dispatch(updateDoctorSpecialty(prev))

        // update the localization status
        dispatch(fetchLocalizationStatus())
    }

    return <>
        <h1 className="title">{ t("doctors:specialties.table.title") }</h1>
        <h2 className="subtitle">{ t("doctors:specialties.table.subtitle") }</h2>

        <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
            <thead>
            <tr>
                <th>#</th>
                <th>{ t("doctors:specialties.table.headers.icon") }</th>
                <th>{ t("doctors:specialties.table.headers.specialty") }</th>
                <th>
                    <div className="buttons is-centered">
                        <button className="button is-secondary is-circular" onClick={ () => { setIsCreating(true) } }>
                            <span className="icon is-small">
                                <Icon path={ mdiPlus } size={ 1 } />
                            </span>
                        </button>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            {
                (() => {
                    if (doctorSpecialties.state === ERequestState.Loading)
                        return <tr>
                            <td colSpan={ 4 }>
                                <Loading/>
                            </td>
                        </tr>

                    if (!doctorSpecialties.entities.length)
                        return <tr>
                            <td colSpan={ 4 }>
                                { t("doctors:specialties.table.noSpecialtiesFound") }
                            </td>
                        </tr>

                    return doctorSpecialties.entities.map((specialty, index) => {
                        return <ListRow
                            key={ specialty.id }
                            tableRowIndex={ index + 1 }
                            info={ specialty }
                            onEdit={ () => { setIsEditingDetailsOf(index) } }
                        />
                    })
                })()
            }
            </tbody>
        </table>
        {
            isEditingDetailsOf !== null
            && <Manage
                type="edit"
                info={ doctorSpecialties.entities[isEditingDetailsOf] }
                onUpdate={ updateSpecialty }
                onClose={ () => { setIsEditingDetailsOf(null) } }
            />
        }
        {
            isCreating
            && <Manage
                type="add"
                onCreate={ createSpecialty }
                onClose={ () => { setIsCreating(false) } }
            />
        }
    </>
}

export default List
