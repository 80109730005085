import { mdiDownload, mdiFileDocument } from "@mdi/js"
import Icon from "@mdi/react"
import { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AdminDocument } from "../../../service/http/documents"
import { handleAxiosError } from "../../../service/service"
import { humanFileSize } from "../../../utils/helpers"
import { noResponseReceivedNotification } from "../../../utils/notifications"
import Loading from "../../common/Loading/Loading"
import * as api from "service/http/api"
import { saveAs } from "file-saver"

const DocumentsTableRow: FunctionComponent<{ document: AdminDocument }> = ({ document }) => {
    const handleDownload = async (fileKey: string, fileName: string) => {
        try {
            const response = await api.documents.getDocument(fileKey)
            if (response)
                saveAs(response, fileName)
        }
        catch {

        }
    }

    return (
        <tr key={ document.fileName }>
            <td>
                <span className="icon-text">
                    <span className="icon">
                        <Icon path={ mdiFileDocument } size={ .75 } />
                    </span>
                    { document.fileName }
                </span>
            </td>
            <td>{ humanFileSize(document.fileSize, false, 2) }</td>
            <td>
                <button
                    className="button is-primary is-circular"
                    onClick={ () => handleDownload(document.fileKey, document.fileName) }
                >
                    <span className="icon is-small">
                        <Icon path={ mdiDownload } size={ 1 } />
                    </span>
                </button>
            </td>
        </tr>
    )
}

const Documents: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "documents" ])

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ documents, setDocuments ] = useState<AdminDocument[]>([])

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)

            try {
                const response = await api.documents.getDocuments()
                if (response)
                    setDocuments(response)
            }
            catch (error) {
                setDocuments([])

                handleAxiosError(error, (e) => {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // if it were a 401 error the user would be redirected
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // Something happened in setting up the request that triggered an Error
                    console.log(error);
                })
            }
        }

        fetch().then(() => setIsLoading(false))

        // eslint-disable-next-line
    }, [])

    return (
        <>
            <h1 className="title"> { t("documents:table.title") } </h1>
            <h2 className="subtitle"> { t("documents:table.subtitle") } </h2>

            <div className="table-container">
                <table className="table is-striped is-hoverable is-fullwidth has-text-left is-vcentered">
                    <thead>
                        <tr>
                            <th>{ t("documents:table.headers.fileName") }</th>
                            <th>{ t("documents:table.headers.fileSize") }</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (()=> {
                            if (isLoading)
                                return (
                                    <tr>
                                        <td colSpan={ 3 }>
                                            <Loading />
                                        </td>
                                    </tr>
                                )

                            if (!documents.length)
                                return (
                                    <tr>
                                        <td colSpan={ 3 }>
                                            { t("documents:table.noDocumentsFound") }
                                        </td>
                                    </tr>
                                )

                            return documents.map(it => (
                                <DocumentsTableRow document={ it } />
                            ))
                        })()
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Documents
