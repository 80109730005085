import _ from "lodash"

class UniqueKeys {
    public keys: string[] = []

    public getKey(index: number): string {
        if (!this.keys[index])
            this.setKey(index)

        return this.keys[index]
    }

    public setKey(index: number) {
        if (!this.keys[index])
            this.keys.splice(index, 0, _.uniqueId())
    }

    public addNewEntry() {
        this.keys.splice(0, 0, _.uniqueId())
    }

    public remove(index: number) {
        this.keys.splice(index, 1)
    }
}

export default UniqueKeys
