import { Form, Formik } from "formik"
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import * as api from "service/http/api"
import * as yup from "yup"
import { SUPPORTED_IMAGE_FORMATS } from "../../../../../service/config/config"
import { isValidImageFile, stringArrayToString } from "../../../../../utils/helpers"
import { notify } from "../../../../../utils/notifications"
import FormikDirtyListener from "../../../../common/Form/FormikDirtyListener"
import ImageFileInputField from "../../../../common/Form/ImageFileInputField"
import ImagePreview from "../../../../common/Form/ImagePreview"

interface IFormFields {
    [key:string]: any
    signature: File | null
}

interface Props {
    doctor: api.doctors.IDoctor
    onUpdate: (details: api.doctors.IUpdateDoctorAdditionalDetailsParams) => Promise<void>
    setFormIsDirty: (value: boolean) => void
}

const Signature: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "notification", "doctors" ])

    const [ signaturePicKey, setSignaturePicKey ] = useState<number>(Date.now())

    const [ initialValues, setInitialValues ] = useState<IFormFields>({
        signature: null
    })

    const validationSchemaRef = useRef(
        yup.object<IFormFields>().shape({
            signature: yup
            .mixed<File>()
            .nullable()
            .test("isFileTypeValid", t("common:form.file.image.error"),
                value => isValidImageFile(value)
            ),
        })
    )

    useEffect(() => {
        setInitialValues({
            signature: null
        })
    }, [ props.doctor ])

    const handleSubmit = async (values: IFormFields) => {
        let params: api.doctors.IUpdateDoctorAdditionalDetailsParams = {}

        Object.keys(values).forEach(it => {
            if (values[it] !== initialValues[it])
                params[it as keyof api.doctors.IUpdateDoctorAdditionalDetailsParams] = values[it]
        })

        try {
            await props.onUpdate(params!)

            // reset file input
            setSignaturePicKey(Date.now())

            // notify
            notify(
                "success",
                t("notification:onDoctorUpdate.success.title"),
                t("notification:onDoctorUpdate.success.message", {
                    name: `${props.doctor.givenName} ${props.doctor.familyName}`
                })
            )
        } catch {
            notify(
                "danger",
                t("notification:onDoctorUpdate.fail.title"),
                t("notification:onDoctorUpdate.fail.message", {
                    name: `${props.doctor.givenName} ${props.doctor.familyName}`
                })
            )
        }
    }

    return <>
        <Formik
            enableReinitialize={ true }
            initialValues={ initialValues }
            validationSchema={ validationSchemaRef.current }
            onSubmit={ handleSubmit }
        >
            {
                ((formikProps) => {
                    return (
                        <Form>
                            <FormikDirtyListener onChange={ props.setFormIsDirty } />

                            {/* signature preview*/}
                            <div className="field">
                                <div className="control">
                                    {
                                        (() => {
                                            if (!!formikProps.values.signature && !formikProps.errors.signature)
                                                return <>
                                                    <ImagePreview
                                                        placeholderType="image"
                                                        file={ formikProps.values.signature }
                                                        imgAlt="doctor-signature"
                                                        size="auto"
                                                        shape="square"
                                                        maxHeight={ 256 }
                                                    />
                                                </>

                                            return <>
                                                <ImagePreview
                                                    placeholderType="image"
                                                    url={ props.doctor.signature ?? "" }
                                                    imgAlt="doctor-signature"
                                                    size="auto"
                                                    shape="square"
                                                    maxHeight={ 256 }
                                                />
                                            </>
                                        })()
                                    }
                                </div>
                            </div>

                            {/*upload signature*/}
                            <ImageFileInputField
                                resetKey={ signaturePicKey } // used for resetting the input
                                label={ t("common:form.file.image.labels.signature") }
                                name="signature"
                                helpMessage={ t("common:form.file.image.help", { fileTypes: stringArrayToString(SUPPORTED_IMAGE_FORMATS) }) }
                                // crop={ true }
                                // cropAspectRatio={ 16 / 9 }
                                onSaveFile={ (file) => formikProps.setFieldValue("signature", file) }
                                onRemoveFile={ () => {
                                    setSignaturePicKey(Date.now())
                                    formikProps.setFieldValue("signature", null)
                                } }
                            />

                            {/* form actions */}
                            <div className="field is-grouped is-justify-content-end">
                                <button
                                    className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                    type="submit"
                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                >
                                    { t("doctors:edit.additionalDetails.buttons.submit") }
                                </button>
                            </div>
                        </Form>
                    )
                })
            }
        </Formik>
    </>
}

export default Signature
