/* eslint-disable */
import { FunctionComponent, useEffect, useState } from "react"
import Icon from "@mdi/react"
import { mdiDelete, mdiPencil } from "@mdi/js"

interface Clinic {
    id: number
    name: string
    address: string
}

const Clinics: FunctionComponent = () => {
    const [ clinics, setClinics ] = useState<Clinic[]>([])

    useEffect(() => {
        // fetch clinics
        setClinics([
            {
                id: 1,
                name: "test",
                address: "Str. Strada, nr. 1"
            },
            {
                id: 2,
                name: "testache",
                address: "Str. Strada, nr. 2"
            },
            {
                id: 3,
                name: "testicianu",
                address: "Str. Strada, nr. 3"
            }
        ])
    }, [])

    return <>
        {/*<h1 className="title"> Clinici </h1>*/}
        {/*<h2 className="subtitle"> Gestinoarea clinicilor si a detaliilor acestora </h2>*/}

        {/*<table className="table is-striped">*/}
        {/*    <thead>*/}
        {/*        <tr>*/}
        {/*            <th>#</th>*/}
        {/*            <th>Clinic</th>*/}
        {/*            <th></th>*/}
        {/*        </tr>*/}
        {/*    </thead>*/}
        {/*    <tbody>*/}
        {/*    {*/}
        {/*        clinics.map((clinic, index) => {*/}
        {/*            return <>*/}
        {/*                <tr key={ clinic.id }>*/}
        {/*                    <td>{ index + 1 }</td>*/}
        {/*                    <td>{ clinic.name }</td>*/}
        {/*                    <td>*/}
        {/*                        <button className="button is-circular" >*/}
        {/*                            <span className="icon is-small">*/}
        {/*                                <Icon path={ mdiPencil } size={ 1.2 } />*/}
        {/*                            </span>*/}
        {/*                        </button>*/}
        {/*                        <button className="button is-circular" >*/}
        {/*                            <span className="icon is-small">*/}
        {/*                                <Icon path={ mdiDelete } size={ 1.2 } />*/}
        {/*                            </span>*/}
        {/*                        </button>*/}
        {/*                    </td>*/}
        {/*                </tr>*/}
        {/*            </>*/}
        {/*        })*/}
        {/*    }*/}
        {/*    </tbody>*/}
        {/*</table>*/}
    </>
}

export default Clinics
