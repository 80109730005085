import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import { Formik, Form, FormikProps } from "formik"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { DateTime } from "luxon"

import { notify } from "utils/notifications"
import {
    createNotification,
    ICreateNotificationParams,
    INotification,
    IUpdateNotificationParams,
    updateNotification
} from "service/http/notifications"
import {
    isValidDate,
    dateTimeStringToUtcISOString,
    UtcIsoStringToDateTimeString,
    isValidImageUrl
} from "utils/helpers"
import FormikDirtyListener from "components/common/Form/FormikDirtyListener"
import InputField from "components/common/Form/InputField"
import TextAreaField from "components/common/Form/TextAreaField"
import ImagePreview from "components/common/Form/ImagePreview"

export interface IFormFields {
    title: string
    message: string
    icon: string
    sendSchedule: string
    // pageToRedirectTo: string
    [key: string]: string
}

interface NewProps {
    type: "new"
    details?: never
    onCreateNotification: (notification: ICreateNotificationParams, entityId: string) => void
    onUpdateNotification?: never
}

interface ExistingProps {
    type: "existing"
    details: INotification
    onCreateNotification?: never
    onUpdateNotification: (notification: IUpdateNotificationParams) => void
}

type Props = (NewProps | ExistingProps) & {
    setFormIsDirty: (value: boolean) => void
    onClose: () => void
}

const DetailsForm: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "notification", "modal" ])

    const [ initialValues, setInitialValues ] = useState<IFormFields>({
        title:          props.type === "existing" ? props.details.title : "",
        message:        props.type === "existing" ? props.details.message : "",
        icon:           props.type === "existing" ? props.details.icon : "",
        sendSchedule:   props.type === "existing" ? props.details.sendSchedule : DateTime.now().toUTC().toISO()
        // pageToRedirectTo:   props.type === "existing" ? props.details.pageToRedirectTo : ""
    })

    // update initial values on details updated successfully
    useEffect(() => {
        if (props.type === "existing")
            setInitialValues({
                title:          props.details.title,
                message:        props.details.message,
                icon:           props.details.icon,
                sendSchedule:   props.details.sendSchedule,
                // pageToRedirectTo:   props.details.pageToRedirectTo
            })

        // props.type never changes
        // eslint-disable-next-line
    }, [ props.details ])

    const handleSubmit = async (values: IFormFields) => {
        if (props.type === "new") {
            try {
                let params: ICreateNotificationParams = _.cloneDeep(values)

                const response = await createNotification(params)

                // update the doctors table
                props.onCreateNotification(params, response.id)

                notify(
                    "success",
                    t("notification:onNotificationCreate.success.title"),
                    t("notification:onNotificationCreate.success.message", {
                        title: values.title
                    })
                )
            } catch (e) {
                notify(
                    "success",
                    t("notification:onNotificationCreate.fail.title"),
                    t("notification:onNotificationCreate.fail.message")
                )
            }
        } else {
            let params: IUpdateNotificationParams = {}

            // assign only the modified fields
            Object.keys(values).forEach((key) => {
                if (values[key] !== initialValues[key])
                    params[key] = values[key]
            })

            if (Object.keys(params).length) {
                try {
                    await updateNotification(props.details.id, params)

                    // update the doctors table
                    props.onUpdateNotification(params)

                    // notify
                    notify(
                        "success",
                        t("notification:onNotificationUpdate.success.title"),
                        t("notification:onNotificationUpdate.success.message", {
                            title: values.title
                        })
                    )
                } catch (e) {
                    notify(
                        "danger",
                        t("notification:onNotificationUpdate.fail.title"),
                        t("notification:onNotificationUpdate.fail.message", {
                            title: values.title
                        })
                    )
                }
            }
        }
    }

    return <>
        <Formik
            enableReinitialize={ true }
            initialValues={ initialValues }
            validationSchema={
                yup.object().shape({
                    title: yup
                        .string()
                        .trim()
                        .required(t("common:form.fieldIsRequired")),
                    message: yup
                        .string()
                        .trim()
                        .required(t("common:form.fieldIsRequired")),
                    icon: yup
                        .string()
                        .trim()
                        .test('isImageUrlValid', t("common:form.imageUrlIsInvalid"), async (value) => {
                            if (value)
                                return await isValidImageUrl(value)

                            return true
                        })
                        .required(t("common:form.fieldIsRequired")),
                    sendSchedule: yup
                        .string()
                        .trim()
                        .test('stringIsDateTime', t("common:form.dateTimeIsInvalid"), isValidDate)
                        .required(t("common:form.fieldIsRequired")),
                    // pageToRedirectTo: yup
                    //     .string()
                    //     .trim()
                    //     .required(t("common:form.fieldIsRequired")),
                })
            }
            onSubmit={ handleSubmit }
        >
            {
                ((formikProps: FormikProps<IFormFields>) => {
                    return <>
                        <Form>
                            <FormikDirtyListener onChange={ props.setFormIsDirty } />

                            <div className="field is-narrow">
                                <div className="control">
                                    <ImagePreview
                                        url={ formikProps.values.icon }
                                        placeholderType="image"
                                        size="128x128"
                                        imgAlt="notification-icon"
                                        shape="circle"
                                    />
                                </div>
                            </div>

                            <InputField
                                label={ t("notification:page.form.icon.urlLabel") }
                                name="icon"
                                placeholder={ t("notification:page.form.icon.urlPlaceholder") }
                            />

                            <InputField
                                label={ t("notification:page.form.title.label") }
                                name="title"
                                placeholder={ t("notification:page.form.title.placeholder") }
                            />

                            <TextAreaField
                                label={ t("notification:page.form.message.label") }
                                name="message"
                                placeholder={ t("notification:page.form.message.placeholder") }
                            />

                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <InputField
                                        label={ t("notification:page.form.sendSchedule.label") }
                                        name="sendSchedule"
                                        type="datetime-local"
                                        min={ UtcIsoStringToDateTimeString(DateTime.now().set({ hour: 0, minute: 0 }).toUTC().toISO()) }
                                        value={ UtcIsoStringToDateTimeString(formikProps.values.sendSchedule) }
                                        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                            // the null checks is for pressing the 'clear' button in the date picker
                                            formikProps.setFieldValue("sendSchedule", e.target.value !== "" ? dateTimeStringToUtcISOString(e.target.value) : initialValues.sendSchedule)
                                        } }
                                    />

                                    {/*<InputField*/}
                                    {/*    label={ t("notification:page.form.pageToRedirectTo.label") }*/}
                                    {/*    name="pageToRedirectTo"*/}
                                    {/*    placeholder={ t("notification:page.form.pageToRedirectTo.placeholder") }*/}
                                    {/*/>*/}
                                </div>
                            </div>

                            {/* form actions */}
                            <div className="field is-grouped is-justify-content-space-between">
                                <button className="button" type="button" onClick={ () => { props.onClose() } }>{ t("notification:page.form.buttons.close") }</button>
                                <button
                                    className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                    type="submit"
                                    disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                >{ t("notification:page.form.buttons.submit") }</button>
                            </div>

                        </Form>
                    </>
                })
            }
        </Formik>
    </>
}

export default DetailsForm
