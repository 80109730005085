import { FunctionComponent, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import routes from "routes/routes"
import * as api from "service/http/api"

import Details from "components/AdminDashboard/Campaigns/Page/Details/Details"

interface Props {
    onClose: () => void
}

const Add: FunctionComponent<Props> = (props) => {
    //eslint-disable-next-line
    const { t } = useTranslation([ "modal", "campaigns" ])
    const history = useHistory()

    const setModalOptions = useConfirmModal()

    const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false)

    const handleCreate = useCallback(async (entry: api.campaigns.ICreateCampaignParams) => {
        const response = await api.campaigns.createCampaign(entry)

        history.push(routes.campaignPage(response.id))

        // irrelevant dependency
        // eslint-disable-next-line
    }, [])

    const handleClose = useCallback(() => {
        if (isFormDirty) {
            setModalOptions({
                isOpen: true,
                modalProps: {
                    title: t("modal:discardChanges.title"),
                    content: t("modal:discardChanges.message"),
                    onConfirm: () => {
                        setModalOptions({ isOpen: false })
                        props.onClose()
                    },
                    onCancel: () => {
                        setModalOptions({ isOpen: false })
                    }
                }
            })
        }
        else {
            props.onClose()
        }

        // irrelevant dependency
        //eslint-disable-next-line
    }, [ isFormDirty ])

    return <>
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">
                            { t("campaigns:list.add.title") }
                        </p>
                        <Details
                            type="new"
                            setIsFormDirty={ setIsFormDirty }
                            onCreate={ handleCreate }
                            onClose={ handleClose }
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Add
