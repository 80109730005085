import { FieldHookConfig, useField } from "formik"
import React, { FunctionComponent, ReactNode } from "react"
import MDEditor from "@uiw/react-md-editor"

interface Props {
    label?: ReactNode
    helpMessage?: ReactNode
}

const MarkdownEditor: FunctionComponent<Props & FieldHookConfig<string>> = ({ label, helpMessage, ...props }) => {
    const [ field, meta, helper ] = useField(props.name)

    return <>
        <div className="field">
            <label className="label">{ label }</label>
            <div className="control">
                <MDEditor
                    data-color-mode="light"
                    placeholder={ props.placeholder }
                    value={ field.value }
                    onChange={ (value) => helper.setValue(value) }
                    onBlur={ field.onBlur }
                />
            </div>
            {
                meta.touched && meta.error
                    ? <p className="help is-danger">{ meta.touched && meta.error }</p>
                    : <></>
            }
            {
                helpMessage
                    ? <p className="help" > { helpMessage } </p>
                    : null
            }
        </div>
    </>
}

export default MarkdownEditor
