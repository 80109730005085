import { FunctionComponent } from "react"
import { Field, FieldHookConfig, useField } from "formik"
import Icon from "@mdi/react"
import { mdiAlert } from "@mdi/js"

interface OtherProps {
    label?: string | JSX.Element
    fieldSize?: "narrow" | "expanded"
}

const SelectField: FunctionComponent<OtherProps & FieldHookConfig<string>> = ({ label, fieldSize , ...props }) => {
    const [ field, meta ] = useField(props.name)

    return <>
        <div className={ "field " + (fieldSize === "narrow" ? "is-narrow" : "") }>
            {
                label && <label className="label">{ label }</label>
            }
            <div className={
                "control "
                + (meta.touched && meta.error ? "has-icons-right " : "")
                + (fieldSize === "expanded" ? "is-expanded" : "")
            }>
                <div className={
                    "select "
                    + (meta.touched && meta.error ? "is-danger " : "")
                    + (fieldSize === "expanded" ? "is-fullwidth" : "")
                }>
                    <Field
                        as="select"
                        { ...field }
                        { ...props }
                    >
                        { props.children }
                    </Field>
                </div>
                {
                    meta.touched && meta.error
                    ? <>
                        <span
                            className="icon is-small is-right">
                            <Icon
                                path={ mdiAlert }
                                color={ "#dbdbdb" }
                                size={ 1.0 }/>
                        </span>
                    </>
                    : <></>
                }
            </div>
            {
                meta.touched && meta.error
                ? <p className="help is-danger">{ meta.touched && meta.error }</p>
                : <></>
            }
        </div>
    </>
}

export default SelectField
