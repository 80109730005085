import React, { ChangeEvent, FunctionComponent, useState } from "react"
import { useSelector } from "react-redux"

import * as api from "service/http/api"
import { useTranslation } from "react-i18next"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import languages from "utils/languages"

import Form from "components/AdminDashboard/Doctors/Page/Presentation/Form"
import { TRootStore } from "state/Store"

interface Props {
    presentations: api.doctors.IDoctorPresentation[]
    onCreatePresentation: (entry: api.doctors.ICreatePresentationParams, displayLanguage: string) => Promise<void>
    onUpdatePresentation: (entry: api.doctors.IUpdatePresentationParams, displayLanguage: string) => Promise<void>
    setFormIsDirty: (value: boolean) => void
}

type THandleAction = "CreatePresentation" | "UpdatePresentation" | "CreateMediaContent" | "UpdateMediaContent" | "DeleteMediaContent"

const Presentation: FunctionComponent<Props> = (props) => {
    // region hooks
    const { t, i18n } = useTranslation([ "notification", "doctors", "modal" ])
    const displayLanguages = useSelector((state: TRootStore) => state.displayLanguages.entities)
    const setModalOptions = useConfirmModal()
    // endregion

    // region state
    const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false)
    const [ selectedLanguage, setSelectedLanguage ] = useState(displayLanguages[0]?.isoCode)
    // endregion

    // region handlers
    const handleAction = async (
        action: THandleAction,
        entry?: api.doctors.ICreatePresentationParams
            | api.doctors.IUpdatePresentationParams,
        index?: number
    ) => {
        switch (action) {
            case "CreatePresentation": {
                await props.onCreatePresentation(entry as api.doctors.IDoctorPresentation, selectedLanguage)
                break
            }

            case "UpdatePresentation": {
                await props.onUpdatePresentation(entry as api.doctors.IDoctorPresentation, selectedLanguage)
                break
            }
        }
    }

    const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const newLanguage = event.target.value

        if (isFormDirty) {
            setModalOptions({
                isOpen: true,
                modalProps: {
                    title: t("modal:discardChanges.title"),
                    content: t("modal:discardChanges.message"),
                    onConfirm: () => {
                        setSelectedLanguage(newLanguage)
                        setModalOptions({ isOpen: false })
                    },
                    onCancel: () => {
                        setModalOptions({ isOpen: false })
                    }
                }
            })
        } else {
            setSelectedLanguage(newLanguage)
        }
    }

    const handleFormDirtyChange = (value: boolean) => {
        setIsFormDirty(value)
        props.setFormIsDirty(value)
    }
    // endregion

    // region render
    if (displayLanguages.length === 0)
        return <>
            <div className="field has-text-centered">
                { t("doctors:edit.presentation.noDisplayLanguageFound") }
            </div>
        </>

    return <>
        <div className="field">
            <label className="label">{ t("doctors:edit.presentation.languageSelect.label") }</label>
            <div className="control is-expanded">
                <div className="select is-fullwidth">
                    <select
                        name="selectedLanguage"
                        value={ selectedLanguage }
                        onChange={ handleLanguageChange }
                    >
                        {
                            displayLanguages.map((lang) => {
                                return (
                                    <option key={ lang.isoCode } value={ lang.isoCode } >
                                        { languages[lang.isoCode][i18n.resolvedLanguage] }
                                        {
                                            props.presentations.findIndex(it => it.displayLanguageCode === lang.isoCode) === -1
                                                ? " \u{2757}" // red exclamation mark emoji
                                                : ""
                                        }
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
        </div>

        {
            (() => {
                const entry = props.presentations.find(it => it.displayLanguageCode === selectedLanguage)
                const englishEntry = selectedLanguage === "en"
                    ? entry
                    : props.presentations.find(it => it.displayLanguageCode === "en")

                if (entry) {
                    return <>
                        <Form
                            type="existing"
                            presentation={ entry }
                            language={ selectedLanguage }
                            englishPresentation={ englishEntry }
                            setFormIsDirty={ handleFormDirtyChange }
                            onUpdate={ (entry) => handleAction("UpdatePresentation", entry) }
                        />
                    </>
                }

                return <>
                    <Form
                        type="new"
                        language={ selectedLanguage }
                        englishPresentation={ englishEntry }
                        setFormIsDirty={ handleFormDirtyChange }
                        onCreate={ (entry) => handleAction("CreatePresentation", entry) }
                    />
                </>
            })()
        }
    </>
    // endregion
}

export default Presentation
