import { IPagedRequestParams, IPagedRequestResponse } from "service/http/pagination"
import axios from "axios"
import { IBasicAccountInfo } from "service/http/accounts"

const basePath = "/administration/reports"

export enum EReportState {
    Reported,
    ContactedUser,
    Resolved,
    FalseFlag
}

export interface IReportAccountInfo extends IBasicAccountInfo {
    email: string
}

export interface IReport {
    id: string
    client: IReportAccountInfo
    instantCallId: string
    userMessage: string
    reportState: EReportState
    phoneNumber?: string
    notes: string
    createdAt: string
}

export interface IReportFilters {
    state?: EReportState
    [key: string]: EReportState | undefined
}

// get
interface IGetReportsPageResponse extends IPagedRequestResponse<IReport> {}

export const getReportsPage = async (params: IPagedRequestParams<IReportFilters>): Promise<IGetReportsPageResponse> => {
    const response = await axios.get<IGetReportsPageResponse>(basePath, {
        params: params
    })
    return response.data
}

// update
interface IUpdateReportStateParams {
    state?: EReportState
    notes?: string
}

interface IUpdateReportStateResponse {}

export const updateReport = async (reportId: string, params: IUpdateReportStateParams): Promise<IUpdateReportStateResponse> => {
    const response = await axios.patch<IUpdateReportStateResponse>(`${basePath}/${reportId}`, params)
    return response.data
}
