import { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import usePagination from "hooks/usePagination"
import _ from "lodash"

import { handleAxiosError } from "service/service"
import { noResponseReceivedNotification } from "utils/notifications"
import { EReportState, getReportsPage, IReport } from "service/http/reports"

import PageSizeSelect from "components/common/Pagination/PageSizeSelect"
import Loading from "components/common/Loading/Loading"
import Pagination from "components/common/Pagination/Pagination"
import View from "components/AdminDashboard/Reports/View/View"
import ReportsTableRow from "components/AdminDashboard/Reports/ReportsTableRow"
import Filters from "components/AdminDashboard/Reports/Filters"

const Reports: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "reports", "common" ])

    // pagination
    const location = useLocation()
    // eslint-disable-next-line
    const [ currentPage, currentPageSize, currentPageCount, currentFilters, changePage, handlePagination ] = usePagination(getReportsPage)

    const [ isLoading, setIsLoading ] = useState<boolean>(true)

    const [ reports, setReports ] = useState<IReport[]>([])
    const [ isShowingDetailsOf, setIsShowingDetailsOf ] = useState<number | null>(null)

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)

            try {
                const response = await handlePagination()
                if (response)
                    setReports(response)
            } catch (error) {
                handleAxiosError(error, (e) => {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // if it were a 401 error the user would be redirected
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                })
            }
        }

        fetch().then(() => {
            setIsLoading(false)
        })

        // t is not relevant as a dependency
        // eslint-disable-next-line
    }, [ location.search ])

    const updateReportState  = (newState: EReportState) => {
        let prevState = _.cloneDeep(reports)

        prevState[isShowingDetailsOf!].reportState = newState

        setReports(prevState)
    }

    const updateReportNotes = (notes: string) => {
        let prevState = _.cloneDeep(reports)

        prevState[isShowingDetailsOf!].notes = notes

        setReports(prevState)
    }

    return <>
        <h1 className="title">{ t("reports:table.title") }</h1>
        <h2 className="subtitle">{ t("reports:table.subtitle") }</h2>

        <div className="field is-horizontal is-pulled-right">
            <div className="field-body">
                <div className="field is-flex is-align-items-end">
                    <Filters
                        filters={ currentFilters }
                        onUpdate={ (filters) => {
                            changePage({
                                filters: filters
                            })
                        } }
                        onClear={ () => {
                            changePage({
                                filters: {}
                            })

                        } }
                    />
                </div>
                <div className="field">
                    <PageSizeSelect
                        currentPageSize={ currentPageSize }
                        onChangePage={ (pageSize) => {
                            changePage({
                                pageSize: pageSize
                            })
                        } }
                        isLoading={ isLoading }
                    />
                </div>
            </div>
        </div>

        <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
            <thead>
            <tr>
                <th>#</th>
                <th>{ t("reports:table.headers.client") }</th>
                <th>{ t("reports:table.headers.instantCall") }</th>
                <th>{ t("reports:table.headers.reportState") }</th>
                <th>{ t("reports:table.headers.createdAt") }</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {
                (() => {
                    if (isLoading)
                        return <tr>
                            <td colSpan={ 6 }>
                                <Loading />
                            </td>
                        </tr>


                    if (!reports.length)
                        return <tr>
                            <td colSpan={ 6 }>{ t("reports:table.noReportsFound") }</td>
                        </tr>


                    return reports.map((report, index) => {
                        return (
                            <ReportsTableRow
                                key={ report.id }
                                tableRowIndex={ ((currentPage - 1) * currentPageSize) + index + 1 }
                                info={ report }
                                onView={ () => { setIsShowingDetailsOf(index) }}
                            />
                        )
                    })
                })()
            }
            </tbody>
        </table>
        {
            reports.length > 0 && !isLoading
            && <Pagination
                currentPage={ currentPage }
                lastPage={ currentPageCount }
                onPageChange={ (page) => {
                    changePage({
                        page: page
                    })
                } }
            />
        }
        {
            isShowingDetailsOf !== null
            && <View
                info={ reports[isShowingDetailsOf] }
                onUpdateReportNotes={ updateReportNotes }
                onUpdateReportState={ updateReportState }
                onClose={ () => { setIsShowingDetailsOf(null) } }
            />
        }
    </>
}

export default Reports
