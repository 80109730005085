import { getRefreshEndpoint, refreshAuthToken } from "service/http/accounts"
import jwtDecode, { JwtPayload } from "jwt-decode"

type AgathaJwtPayload = JwtPayload & {
    // "http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid": string
    // "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string
    id: string
    role: string
    scope: string
    loginInstanceId: string
    isTestAccount: string
}

type AgathaDecodedJwt = JwtPayload & {
    accountId: string
    role: string
    scope: string
    loginInstanceId: string
    isTestAccount: string
}

class JWTTokenStorage {
    private accessToken: string | null = null
    private decodedAccessToken: AgathaDecodedJwt | null = null
    private lastUsedRefreshEndpoint: string | null = null
    private isRefreshingToken: boolean = false

    public getAccessToken = (): string => {
        return this.accessToken ?? ""
    }

    private decodeAccessToken = (): void => {
        try {
            const decoded = jwtDecode<AgathaJwtPayload>(this.getAccessToken())

            this.decodedAccessToken = {
                // accountId: decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid"],
                // role: decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
                accountId: decoded.id,
                role: decoded.role,
                scope: decoded.scope,
                loginInstanceId: decoded.loginInstanceId,
                isTestAccount: decoded.isTestAccount,
                exp: decoded.exp,
                iat: decoded.iat,
                iss: decoded.iss,
                aud: decoded.aud
            } as AgathaDecodedJwt
        } catch(e) {
            this.decodedAccessToken = null
        }
    }

    public getDecodedAccessToken = (): AgathaDecodedJwt | null => {
        return this.decodedAccessToken
    }

    private clearDecodedAccessToken = (): void => {
        this.decodedAccessToken = null
    }

    public setAccessToken = (token: string): void => {
        this.accessToken = token
        this.decodeAccessToken()
    }

    public clearAccessToken = (): void  => {
        this.accessToken = null
        this.clearDecodedAccessToken()
    }

    public refreshTokens = async (): Promise<void> => {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true
            this.lastUsedRefreshEndpoint = getRefreshEndpoint()

            const response = await refreshAuthToken()

            this.setAccessToken(response.accessToken)
            this.setRefreshToken(response.refreshToken)
            this.isRefreshingToken = false
        }
    }

    public getLastUsedRefreshEndpoint = () => this.lastUsedRefreshEndpoint

    public getRefreshToken(): string {
        return window.localStorage.getItem('refreshToken') ?? ""
    }

    public setRefreshToken = (token: string): void => {
        window.localStorage.setItem('refreshToken', token)
    }

    public clearRefreshToken = (): void => {
        window.localStorage.removeItem('refreshToken')
    }
}

const JWTTokenStorageInstance = new JWTTokenStorage()
export default JWTTokenStorageInstance
