import React, { FunctionComponent } from "react"
import { Link, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import routes from "routes/routes"
import * as api from "service/http/api"

import Icon from "@mdi/react"
import { mdiDoctor, mdiPencil } from "@mdi/js"
import { IDoctorPageHistoryState } from "components/AdminDashboard/Doctors/Page/Page"

interface Props {
    tableRowIndex: number
    manager: api.managers.IManager
}

const ManagersListRow: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "notification", "managers" ])
    const history = useHistory()

    return <>
        <tr>
            <td> { props.tableRowIndex } </td>
            <td> { props.manager.name } </td>
            <td> { props.manager.primaryEmail } </td>
            <td> { t("common:accountState.state", { context: props.manager.accountState }) } </td>
            <td>
                <div className="buttons is-centered">
                    {/*edit*/}
                    <Link
                        to={ {
                            pathname: routes.managerPage(props.manager.id),
                            state: {
                                from: {
                                    pathname: history.location.pathname,
                                    search: history.location.search
                                }
                            } as IDoctorPageHistoryState
                        } }
                    >
                        <button
                            className="button is-circular"
                            type="button"
                            title={ t("managers:table.buttons.edit.title") }
                        >
                            <span className="icon is-small">
                                <Icon path={ mdiPencil } size={ 1 } />
                            </span>
                        </button>
                    </Link>

                    {/*doctors*/}
                    <Link
                        to={ {
                            pathname: routes.doctors,
                            search: `?&manager-id=${props.manager.id}`
                        } }
                    >
                        <button
                            className="button is-circular"
                            type="button"
                            title={ t("managers:table.buttons.doctorList.title") }
                        >
                            <span className="icon is-small">
                                <Icon path={ mdiDoctor } size={ 1 } />
                            </span>
                        </button>
                    </Link>
                </div>
            </td>
        </tr>
    </>
}

export default ManagersListRow
