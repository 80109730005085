import React, { FunctionComponent, useState } from "react"
import { EReportState, IReport } from "service/http/reports"
import { useTranslation } from "react-i18next"
import Details from "components/AdminDashboard/Reports/View/Details/Details"
import Contact from "components/AdminDashboard/Reports/View/Contact"
import State from "components/AdminDashboard/Reports/View/State"

enum EReportViewDetailsTabs {
    Details,
    Contact,
    State
}

interface Props {
    info: IReport
    onUpdateReportState: (newState: EReportState) => void
    onUpdateReportNotes: (notes: string) => void
    onClose: () => void
}

const View: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "reports" ])

    const [ currentTab, setCurrentTab ] = useState<EReportViewDetailsTabs>(EReportViewDetailsTabs.Details)

    const handleChangeTab = (newTab: EReportViewDetailsTabs) => {
        setCurrentTab(newTab)
    }

    return <>
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-content">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">
                            { t("reports:view.title") }
                        </p>
                        <div className="tabs is-boxed is-centered mb-5">
                            <ul>
                                <li className={ currentTab === EReportViewDetailsTabs.State ? "is-active" : "" }>
                                    {/* eslint-disable-next-line*/}
                                    <a onClick={ () => { handleChangeTab(EReportViewDetailsTabs.State) } }>{ t("reports:view.tabs.state") }</a>
                                </li>
                                <li className={ currentTab === EReportViewDetailsTabs.Details ? "is-active" : "" }>
                                    {/* eslint-disable-next-line*/}
                                    <a onClick={ () => { handleChangeTab(EReportViewDetailsTabs.Details) } }>{ t("reports:view.tabs.details") }</a>
                                </li>
                                <li className={ currentTab === EReportViewDetailsTabs.Contact ? "is-active" : "" }>
                                    {/* eslint-disable-next-line*/}
                                    <a onClick={ () => { handleChangeTab(EReportViewDetailsTabs.Contact) } }>{ t("reports:view.tabs.contact") }</a>
                                </li>
                            </ul>
                        </div>
                        {
                            (() => {
                                switch (currentTab) {
                                    case EReportViewDetailsTabs.Details:
                                        return <Details info={ props.info } onUpdateReportNotes={ props.onUpdateReportNotes }/>

                                    case EReportViewDetailsTabs.Contact:
                                        return <Contact info={ props.info } />

                                    case EReportViewDetailsTabs.State:
                                        return <State info={ props.info } onReportStateChange={ props.onUpdateReportState } />
                                }
                            })()
                        }
                        <div className="field is-grouped mt-3">
                            <button className="button" type="button" onClick={ () => { props.onClose() } }>
                                { t("reports:view.closeButton") }
                            </button>
                        </div>
                    </div> { /* card-content */}
                </div> { /* card */}
            </div> { /* modal-content */}
        </div> { /* modal */}
    </>
}

export default View
