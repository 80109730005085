import React, { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import * as api from "service/http/api"
import { notify } from "utils/notifications"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { EPermissionRoles, hasPermissions } from "utils/auth"
import { enumToNumberValuesArray } from "utils/helpers"

interface Props {
    accountState: api.accounts.EAccountState
    onStateChange: (newState: api.accounts.EAccountState) => Promise<void>
}

const Account: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "notification", "modal", "managers", "common" ])
    const setModalOptions = useConfirmModal()

    const [ isUpdating, setIsUpdating ] = useState<boolean>(false)

    const handleStateChange = async (value: api.accounts.EAccountState) => {
        setModalOptions({
            isOpen: true,
            modalProps: {
                title: t( hasPermissions(EPermissionRoles.Admin)
                          ? "modal:confirm.managerAccountStateChange.admin.title"
                          : "modal:confirm.managerAccountStateChange.manager.title"
                ),
                content: t(hasPermissions(EPermissionRoles.Admin)
                           ? "modal:confirm.managerAccountStateChange.admin.message"
                           : "modal:confirm.managerAccountStateChange.manager.message"
                ),
                onConfirm: async () => {
                    setModalOptions({ isOpen: false })
                    setIsUpdating(true)

                    try {
                        await props.onStateChange(value)

                        notify(
                            "success",
                            t("notification:onManagerAccountStateChange.success.title"),
                            t("notification:onManagerAccountStateChange.success.message")
                        )
                    } catch(e) {
                        notify(
                            "danger",
                            t("notification:onManagerAccountStateChange.fail.title"),
                            t("notification:onManagerAccountStateChange.fail.message")
                        )
                    }

                    setIsUpdating(false)
                },
                onCancel: () => {
                    setModalOptions({ isOpen: false })
                }
            }
        })
    }

    return <>
        {/*account state*/}
        <div className="field">
            <div className="control is-expanded">
                <label className="label">{ t("managers:page.account.form.accountState.label") }</label>

                {/* dropdown for admins*/}
                {
                    hasPermissions(EPermissionRoles.Admin)
                    && <>
                        <div className={ "select is-fullwidth " + (isUpdating ? "is-loading" : "")}>
                            <select
                                name="accountState"
                                disabled={ isUpdating }
                                value={ props.accountState }
                                onChange={ (e) => {
                                    handleStateChange(_.toNumber(e.currentTarget.value))
                                } }
                            >
                                {
                                    enumToNumberValuesArray(api.accounts.EAccountState).map((value) => {
                                        return (
                                            <option
                                                key={ value }
                                                value={ value }
                                            >
                                                { t("common:accountState.state", { context: value }) }
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </>
                }

                {/*button for managers*/}
                {
                    hasPermissions(EPermissionRoles.Manager)
                    && <>
                        <button
                            className={ `button is-primary ${isUpdating ? "is-loading" : ""}` }
                            type="button"
                            onClick={ (e) => {
                                handleStateChange(api.accounts.EAccountState.Suspended)
                            } }
                            disabled={ isUpdating || props.accountState === api.accounts.EAccountState.Suspended }
                        >
                            { t("managers:page.account.form.accountState.button") }
                        </button>
                    </>
                }
            </div>
        </div>
    </>
}

export default Account
