import { FunctionComponent } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { default as DoctorList } from "components/AdminDashboard/Doctors/List/List"
import { default as SpecialtiesList } from "components/AdminDashboard/Doctors/Specialties/List/List"
import Page from "components/AdminDashboard/Doctors/Page/Page"
import Permission from "components/common/Routes/Permission"

import routes from "routes/routes"
import { EPermissionRoles } from "utils/auth"

const Routes: FunctionComponent = () => {
    return <>
        <Switch>
            {/*list*/}
            <Route
                exact
                path={ routes.doctors }
                render={ () => {
                    return <>
                        <Permission
                            logoutOnDenied={ false }
                            redirectTo={ routes.doctors }
                            roles={ [ EPermissionRoles.Admin, EPermissionRoles.Manager ] }
                        >
                            <DoctorList />
                        </Permission>
                    </>
                }}
            />

            {/*specialties*/}
            <Route
                exact
                path={ routes.doctorsSpecialties }
                render={ () => {
                    return <>
                        <Permission
                            logoutOnDenied={ false }
                            redirectTo={ routes.doctors }
                            roles={ [ EPermissionRoles.Admin ] }
                        >
                            <SpecialtiesList />
                        </Permission>
                    </>
                }}
             />

            {/*doctor page*/}
            <Route
                exact
                path={ routes.doctorPage() }
                render={ () => {
                    return <>
                        <Permission
                            logoutOnDenied={ false }
                            redirectTo={ routes.doctors }
                            roles={ [ EPermissionRoles.Admin, EPermissionRoles.Manager ] }
                        >
                            <Page />
                        </Permission>
                    </>
                }}
            />

            {/*redirect*/}
            <Route render={ () => <Redirect to={ routes.doctors } /> }/>
        </Switch>
    </>
}

export default Routes
