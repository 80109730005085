import { FunctionComponent } from "react"
import Icon from "@mdi/react"
import { mdiCheck, mdiClose, mdiEye, mdiPhone } from "@mdi/js"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"

import routes from "routes/routes"
import * as api from "service/http/api"
import { IClientPageHistoryState } from "components/AdminDashboard/Clients/Page/Page"

import ImagePreview from "components/common/Form/ImagePreview"

interface Props {
    tableRowIndex: number
    client: api.clients.IClient
}

const ListRow: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "common", "modal", "notification" ])
    const history = useHistory()

    return <>
        <tr>
            <td>{ props.tableRowIndex + 1 }</td>
            <td>
                <div className="is-flex is-justify-content-center">
                    <ImagePreview
                        placeholderType="account"
                        url={ props.client.profilePicture }
                        imgAlt="profile-picture"
                        size="32x32"
                        shape="circle"
                    />
                </div>
            </td>
            <td>{ `${props.client.givenName} ${props.client.familyName}` }</td>
            <td>{ props.client.primaryEmail }</td>
            <td>
                { t("common:accountState.state", { context: props.client.accountState }) }
            </td>
            <td>
                <Icon
                    path={ props.client.isTestingAccount ? mdiCheck : mdiClose }
                    color={ props.client.isTestingAccount ? "green" : "red" }
                    size={ 1 }
                />
            </td>
            <td>
                <div className="buttons is-centered">
                    <Link
                        to={ {
                            pathname: routes.clientPage(props.client.id),
                            state: {
                                from: {
                                    pathname: history.location.pathname,
                                    search: history.location.search
                                }
                            } as IClientPageHistoryState
                        } }
                    >
                        <button className="button is-circular" type="button">
                            <span className="icon is-small">
                                <Icon path={ mdiEye } size={ 1 } />
                            </span>
                        </button>
                    </Link>

                    <Link
                        to={ {
                            pathname: routes.instantCalls,
                            search: `?&client-id=${props.client.id}`
                        } }
                    >
                        <button className="button is-circular" type="button">
                            <span className="icon is-small">
                                <Icon path={ mdiPhone } size={ 1 } />
                            </span>
                        </button>
                    </Link>
                </div>
            </td>
        </tr>
    </>
}

export default ListRow
