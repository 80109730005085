import React, { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { handleAxiosError } from "service/service"
import { getInstantCallsPage, IInstantCallDetails } from "service/http/instantCalls"
import usePagination from "hooks/usePagination"
import { noResponseReceivedNotification } from "utils/notifications"

import Loading from "components/common/Loading/Loading"
import InstantCallsTableRow from "components/AdminDashboard/InstantCalls/InstantCallsTableRow"
import Messages from "components/AdminDashboard/InstantCalls/Messages"
import Pagination from "components/common/Pagination/Pagination"
import PageSizeSelect from "components/common/Pagination/PageSizeSelect"
import Filters from "components/AdminDashboard/InstantCalls/Filters"

const InstantCalls: FunctionComponent = () => {
    const location = useLocation()
    const { t } = useTranslation([ "notification", "instantCalls" ])

    const [ isLoading, setIsLoading ] = useState<boolean>(true)

    // pagination
    // eslint-disable-next-line
    const [ currentPage, currentPageSize, currentPageCount, currentFilters, changePage, handlePagination ] = usePagination(getInstantCallsPage)

    const [ instantCalls, setInstantCalls ] = useState<IInstantCallDetails[]>([])
    const [ isShowingMessagesOf, setIsShowingMessagesOf ] = useState<number | null>(null)
    const [ useLocalTime, setUseLocalTime ] = useState<boolean>(true)

    // on page or pageSize change
    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)

            try {
                const response = await handlePagination()
                if (response)
                    setInstantCalls(response)
            } catch (error) {
                handleAxiosError(error, (e) => {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // if it were a 401 error the user would be redirected
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                }, (e) => {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                })
            }
        }

        fetch().then(() => {
            setIsLoading(false)
        })

        // t is not relevant as a dependency
        // eslint-disable-next-line
    }, [ location.search ])

    return <>
        <h1 className="title">{ t("instantCalls:table.title") }</h1>
        <h2 className="subtitle">{ t("instantCalls:table.subtitle") }</h2>

        <div className="field is-horizontal is-pulled-right">
            <div className="field-body">
                <div className="field is-flex is-align-items-end">
                    <div className="field is-narrow px-2">
                        <div className="control">
                            <div className="switch">
                                <input
                                    id="useLocalTime"
                                    className="switch__input"
                                    type="checkbox"
                                    checked={ useLocalTime }
                                    onChange={ (e) => {
                                        setUseLocalTime(e.target.checked)
                                    } }
                                />
                                <label htmlFor="useLocalTime" className="switch__label label">
                                    { t("instantCalls:table.filters.localTimeLabel") }
                                </label>
                            </div>
                        </div>
                    </div>

                    <Filters
                        filters={ currentFilters }
                        onUpdate={ (filters) => {
                            changePage({
                                filters: filters
                            })
                        } }
                        onClear={ () => {
                            changePage({
                                filters: {}
                            })

                        } }
                    />
                </div>
                <div className="field">
                    <PageSizeSelect
                        currentPageSize={ currentPageSize }
                        onChangePage={ (pageSize) => {
                            changePage({
                                pageSize: pageSize
                            })
                        } }
                        isLoading={ isLoading }
                    />
                </div>
            </div>
        </div>

        <table className="table is-striped is-hoverable is-fullwidth has-text-centered is-vcentered">
            <thead>
            <tr>
                <th>#</th>
                <th>{ t("instantCalls:table.headers.doctorName") }</th>
                <th>{ t("instantCalls:table.headers.clientName") }</th>
                <th>{ t("instantCalls:table.headers.costInMinutesPerUnit") }</th>
                <th>{ t("instantCalls:table.headers.rateInMinutesPerUnit") }</th>
                <th>{ t("instantCalls:table.headers.videoRateInMinutesPerUnit") }</th>
                {/*<th>{ t("instantCalls:table.headers.currency") }</th>*/}
                <th>{ t("instantCalls:table.headers.doctorPayoutPercentage") }</th>
                <th>{ t("instantCalls:table.headers.voiceDuration") }</th>
                <th>{ t("instantCalls:table.headers.videoDuration") }</th>
                <th>{ t("instantCalls:table.headers.paymentStatus") }</th>
                <th>{ t("instantCalls:table.headers.doctorReceivedNotification") }</th>
                <th>{ t("instantCalls:table.headers.createdAt") }</th>
                <th>{ t("instantCalls:table.headers.respondedAt") }</th>
                <th>{ t("instantCalls:table.headers.endedAt") }</th>
                <th>{ t("instantCalls:table.headers.endOfCallRationale") }</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {
                (() => {
                    if (isLoading)
                        return <tr>
                            <td colSpan={ 15 }>
                                <Loading/>
                            </td>
                        </tr>

                    if (!instantCalls.length)
                        return <tr>
                            <td colSpan={ 15 }>{ t("instantCalls:table.noInstantCallsFound") }</td>
                        </tr>

                    return instantCalls.map((call, index) => {
                        return (
                            <InstantCallsTableRow
                                key={ call.id }
                                instantCallTableIndex={ ((currentPage - 1) * currentPageSize) + index }
                                instantCallDetails={ call }
                                useLocalTime={ useLocalTime }
                                onShowMessages={ () => { setIsShowingMessagesOf(index) } }
                            />
                        )
                    })
                })()
            }
            </tbody>
        </table>
        {
            instantCalls.length > 0 && !isLoading
            && <Pagination
                currentPage={ currentPage }
                lastPage={ currentPageCount }
                onPageChange={ (page) => {
                    changePage({
                        page: page
                    })
                } }
            />
        }
        {
            isShowingMessagesOf !== null
            && <Messages
                instantCallId={ instantCalls[isShowingMessagesOf].id }
                clientInfo={ instantCalls[isShowingMessagesOf].client }
                doctorInfo={ instantCalls[isShowingMessagesOf].doctor }
                onClose={ () => { setIsShowingMessagesOf(null) } }
            />
        }
    </>
}

export default InstantCalls
