import axios from "axios"

import { IPagedRequestParams, IPagedRequestResponse } from "service/http/pagination"

const basePath = "administration/campaigns"

export interface ICampaignCodeClient {
    id: string,
    fullName: string,
    profilePicture: string
}

export interface ICampaignCode {
    code: string
    createdAt: string
    usedAt?: string
    callsMadeCount: number
    campaignId: string
    client?: ICampaignCodeClient
}

interface ICampaignCreatedByAccount {
    id: string
    displayName: string
}

export interface ICampaign {
    id: string
    name: string
    createdAt: string
    startsAt: string
    endsAt: string
    defaultMaxUsagesCount: number
    batchesCount: number
    createdBy: ICampaignCreatedByAccount
    campaignCodeInstances: ICampaignCode[]
    campaignDoctors: string[]
}

export enum ECampaignFilterOrderBy {
    ASCENDING,
    DESCENDING,
    ASCENDING_ACTIVE_FIRST,
    DESCENDING_ACTIVE_FIRST
}

export interface ICampaignFilters {
    orderBy: ECampaignFilterOrderBy
}

//get campaigns list
interface IGetCampaignsPageResponse extends  IPagedRequestResponse<ICampaign> {}
export const getCampaignsPage = async (params: IPagedRequestParams<ICampaignFilters>): Promise<IGetCampaignsPageResponse> => {
    const response = await axios.get<IGetCampaignsPageResponse>(`${basePath}`, {
        params: params
    })
    return response.data
}

// get campaign
interface IGetCampaignResponse extends ICampaign {}

export const getCampaign = async (campaignId: string): Promise<IGetCampaignResponse> => {
    const response = await axios.get<IGetCampaignResponse>(`${basePath}/${campaignId}`)
    return response.data
}

// post campaign
export interface ICreateCampaignParams extends Omit<ICampaign, "id" | "createdAt" | "startsAt" | "batchesCount" | "createdById" | "createdBy" | "campaignCodeInstances" | "campaignDoctors"> {
    startsAt: string | null
    doctors: string[]
    [key: string]: any
}

interface ICreateCampaignResponse {
    id: string
}

export const createCampaign = async (params: ICreateCampaignParams): Promise<ICreateCampaignResponse> => {
    const response = await axios.post<ICreateCampaignResponse>(`${basePath}`, params)
    return response.data
}

// patch campaign
export interface IUpdateCampaignParams extends Partial<ICreateCampaignParams> {
    [key: string]: any
}

export const updateCampaign = async (campaignId: string, params: IUpdateCampaignParams) => {
    const response = await axios.patch(`${basePath}/${campaignId}`, params)
    return response.data
}

// generate codes
export interface IPostCampaignCodesParams {
    amount: number
}

type IGenerateCampaignCodesResponse = ICampaignCode[]

export const generateCampaignCodes = async (campaignId: string, params: IPostCampaignCodesParams): Promise<IGenerateCampaignCodesResponse> => {
    const response = await axios.post(`${basePath}/${campaignId}/codes`, params)
    return response.data
}
