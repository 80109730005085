import { Form, Formik } from "formik"
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import * as api from "service/http/api"
import { TRootStore } from "state/Store"
import * as yup from "yup"
import { ERequestState } from "../../../../../state/reducers/RequestTypes"
import languages from "../../../../../utils/languages"
import { notify } from "../../../../../utils/notifications"
import FormikDirtyListener from "../../../../common/Form/FormikDirtyListener"
import InputField from "../../../../common/Form/InputField"
import Loading from "../../../../common/Loading/Loading"

import "./Quote.scss"

interface IFormFields {
    [key:string]: any
    quote?: Record<string, string>
}

interface Props {
    doctor: api.doctors.IDoctor
    onUpdate: (details: api.doctors.IUpdateDoctorAdditionalDetailsParams) => Promise<void>
    setFormIsDirty: (value: boolean) => void
}

const Quote: FunctionComponent<Props> = (props) => {
    const { t, i18n } = useTranslation([ "common", "notification", "doctors" ])
    const displayLanguages = useSelector((state: TRootStore) => state.displayLanguages)

    const [ initialValues, setInitialValues ] = useState<IFormFields>({
        quote: props.doctor.quote ?? {},
    })

    const validationSchemaRef = useRef(
        yup.object<IFormFields>().shape({
            quote: yup.lazy(obj => {
                let entries: Record<string, yup.StringSchema>
                displayLanguages.entities.forEach((value) => {
                        entries = {
                            ...entries,
                            [value.isoCode]: yup.string().notRequired()
                        }
                    }
                )

                return yup.object().shape(entries!)
            })
        })
    )

    useEffect(() => {
        setInitialValues({
            quote: props.doctor.quote ?? {},
        })
    }, [ props.doctor ])

    const handleSubmit = async (values: IFormFields) => {
        let params: api.doctors.IUpdateDoctorAdditionalDetailsParams = {}

        Object.keys(values).forEach(it => {
            if (values[it] !== initialValues[it])
                params[it as keyof api.doctors.IUpdateDoctorAdditionalDetailsParams] = values[it]
        })

        try {
            await props.onUpdate(params!)

            // notify
            notify(
                "success",
                t("notification:onDoctorUpdate.success.title"),
                t("notification:onDoctorUpdate.success.message", {
                    name: `${props.doctor.givenName} ${props.doctor.familyName}`
                })
            )
        } catch {
            notify(
                "danger",
                t("notification:onDoctorUpdate.fail.title"),
                t("notification:onDoctorUpdate.fail.message", {
                    name: `${props.doctor.givenName} ${props.doctor.familyName}`
                })
            )
        }
    }

    if (displayLanguages.state === ERequestState.Loading)
        return <Loading />

    return <>
        <div className="quote">
            <Formik
                enableReinitialize={ true }
                initialValues={ initialValues }
                validationSchema={ validationSchemaRef.current }
                onSubmit={ handleSubmit }
            >
                {
                    ((formikProps) => {
                        return (
                            <Form>
                                <FormikDirtyListener onChange={ props.setFormIsDirty } />

                                <div className="field">
                                    <div className="label">
                                        { t("doctors:edit.additionalDetails.quote.label") }
                                    </div>

                                    <div className="control">
                                        <div className="quote-container">
                                            {
                                                displayLanguages.entities.map((language) => {
                                                    return (
                                                        <div key={ language.isoCode } className="field is-horizontal">
                                                            <div className="field-label is-normal">
                                                                <label className="label">
                                                                    { languages[language.isoCode][i18n.resolvedLanguage] }
                                                                </label>
                                                            </div>
                                                            <div className="field-body">
                                                                <InputField
                                                                    name={ `quote[${language.isoCode}]` }
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* form actions */}
                                <div className="field is-grouped is-justify-content-end">
                                    <button
                                        className={ "button is-primary " + (formikProps.isSubmitting ? "is-loading" : "") }
                                        type="submit"
                                        disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                    >
                                        { t("doctors:edit.additionalDetails.buttons.submit") }
                                    </button>
                                </div>
                            </Form>
                        )
                    })
                }
            </Formik>
        </div>
    </>
}

export default Quote
