import { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik, FormikHelpers, FormikProps } from "formik"
import * as yup from "yup"
import { mdiClose, mdiContentSave, mdiPencil } from "@mdi/js"
import Icon from "@mdi/react"

import TextAreaField from "components/common/Form/TextAreaField"
import { IReport, updateReport } from "service/http/reports"
import { notify } from "utils/notifications"

interface IFormValues {
    notes: string
}

interface Props {
    info: IReport
    onUpdate: (notes: string) => void
}

const NotesForm: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation([ "reports", "common", "notification" ])

    const [ isEditing, setIsEditing ] = useState<boolean>(false)
    const [ initialValues, setInitialValues ] = useState<IFormValues>({
        notes: props.info.notes ?? ""
    })

    // update initial values
    useEffect(() => {
        setInitialValues({
            notes: props.info.notes
        })
    }, [ props.info.notes ])

    const handleSubmit = async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        try {
            await updateReport(props.info.id, {
                notes: values.notes
            })

            props.onUpdate(values.notes)

            notify(
                "success",
                t("notification:onReportNotesChange.success.title"),
                t("notification:onReportNotesChange.success.message")
            )
        } catch (e) {
            notify(
                "danger",
                t("notification:onReportNotesChange.fail.title"),
                t("notification:onReportNotesChange.fail.message")
            )
        }
    }

    return <>
        <Formik
            enableReinitialize={ true }
            initialValues={ initialValues }
            validationSchema={
                yup.object().shape({
                    notes: yup.lazy((value) => {
                        if (isEditing)
                            return yup.string().required(t("common:form.fieldIsRequired"))

                        return yup.string()
                    })
                })
            }
            onSubmit={ handleSubmit }
        >
            {
                (formikProps: FormikProps<IFormValues>) => {
                    return <>
                        <Form>
                            <div className="field">
                                <div className="control">
                                    <label className="label">
                                        { t("reports:view.details.form.notes.label") }
                                    </label>
                                    <div className="field is-horizontal">
                                        <div className="field-body">
                                            <TextAreaField
                                                name="notes"
                                                placeholder={ t("reports:view.details.form.notes.placeholder") }
                                                readOnly={ !isEditing }
                                            />

                                            <div className="field is-narrow is-grouped is-align-items-center">
                                                {
                                                    isEditing
                                                    && <>
                                                        <div className="control">
                                                            <button
                                                                className={ `button is-primary is-circular ${ formikProps.isSubmitting ? "is-loading" : ""}` }
                                                                disabled={ !formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting }
                                                                type="submit"
                                                            >
                                                        <span className="icon is-small">
                                                            <Icon path={ mdiContentSave } size={ 1 } />
                                                        </span>
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                                <div className="control">
                                                    <button
                                                        className={ `button is-circular ${ isEditing ? "is-danger" : ""}` }
                                                        disabled={ formikProps.isSubmitting }
                                                        type="button"
                                                        onClick={ () => {
                                                            if (isEditing)
                                                                formikProps.setFieldValue("notes", initialValues.notes)

                                                            setIsEditing(!isEditing)
                                                        } }
                                                    >
                                                <span className="icon is-small">
                                                    <Icon path={ isEditing ? mdiClose : mdiPencil } size={ 1 } />
                                                </span>
                                                    </button>
                                                </div> {/* control */}
                                            </div> {/* field */}
                                        </div> {/* field-body */}
                                    </div> {/* field is-horizontal */}
                                </div>
                            </div> {/* field */}
                        </Form>
                    </>
                }
            }
        </Formik>
    </>
}

export default NotesForm
