import List from "components/AdminDashboard/Campaigns/List/List"
import Page from "components/AdminDashboard/Campaigns/Page/Page"
import { FunctionComponent } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import routes from "routes/routes"

const Routes: FunctionComponent = () => {
    return  <>
        <Switch>
            <Route exact path={ routes.campaigns } component={ List } />
            <Route exact path={ routes.campaignPage() } component={ Page } />
            <Route render={ () => <Redirect to={ routes.campaigns } /> }/>
        </Switch>
    </>
}

export default Routes
