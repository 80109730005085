import { FunctionComponent } from "react"
import { Redirect, useHistory } from "react-router-dom"

import * as api from "service/http/api"
import { EPermissionRoles, hasPermissions } from "utils/auth"
import JWTTokenStorageInstance from "service/JWTTokenStorage"

interface LogoutProps {
    logoutOnDenied: true
    redirectTo?: never
}

interface RedirectProps {
    logoutOnDenied: false
    redirectTo: string
}

type Props = (LogoutProps | RedirectProps) & {
    roles: EPermissionRoles[]
}

const Permission: FunctionComponent<Props> = (props) => {
    const history = useHistory()

    return <>
        {
            (() => {
                // should be able to enter the dashboard if the access token doesn't exist yet
                // the user will be redirected to login if the refresh token is expired
                const permission =
                    !JWTTokenStorageInstance.getAccessToken()
                    || hasPermissions(props.roles)

                if (permission)
                    return <>
                        { props.children }
                    </>
                else {
                    if (props.logoutOnDenied) {
                        api.accounts.logout(history)
                        return <></>
                    }

                    return <Redirect to={ props.redirectTo }/>
                }
            })()
        }
    </>
}

export default Permission
