import { FunctionComponent, useEffect } from "react"
import { useFormikContext } from "formik"

interface Props {
    onChange: (value: boolean) => void
}


const FormikDirtyListener: FunctionComponent<Props> = (props) => {
    const { dirty } = useFormikContext()

    useEffect(() => {
        props.onChange(dirty)
    }, [dirty, props ])

    return <></>
}

export default FormikDirtyListener
