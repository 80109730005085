import {
    mdiAccount,
    mdiAccountSupervisor,
    mdiAlert,
    mdiBell,
    mdiChartLine,
    mdiDoctor, mdiFileDocument, mdiFrequentlyAskedQuestions,
    mdiHospitalBuilding,
    mdiPhone,
    mdiTag,
    mdiTranslate
} from "@mdi/js"
import { default as CampaignRoutes } from "components/AdminDashboard/Campaigns/Routes"
import { default as ClientRoutes } from "components/AdminDashboard/Clients/Routes"

// Routes components
import { default as DoctorRoutes } from "components/AdminDashboard/Doctors/Routes"
import InstantCalls from "components/AdminDashboard/InstantCalls/InstantCalls"
import Localization from "components/AdminDashboard/Localization/Localization"
import { default as ManagerRoutes } from "components/AdminDashboard/Managers/Routes"
import Notifications from "components/AdminDashboard/MarketingNotifications/Notifications"
import Reports from "components/AdminDashboard/Reports/Reports"
import Stats from "components/AdminDashboard/Stats/Stats"
import Loading from "components/common/Loading/Loading"
import Permission from "components/common/Routes/Permission"
import { FunctionComponent, Suspense, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, Route, Switch } from "react-router-dom"

import routes from "routes/routes"
import { fetchDisplayLanguages } from "state/reducers/DisplayLanguagesReducer"
import { fetchDoctorSpecialties } from "state/reducers/DoctorSpecialtiesReducer"
import { fetchLocalizationStatus } from "state/reducers/LocalizationStatusReducer"
import { TRootStore } from "state/Store"
import { EPermissionRoles, hasPermissions } from "utils/auth"

// components
import Navbar, { INavbarEntry } from "../common/Navbar/Navbar"
import Clinics from "./Clinics/Clinics"
import Documentation from "./Documentation/Documentation"
import Documents from "./Documents/Documents"

const AdminDashboard: FunctionComponent = () => {
    const { t, i18n } = useTranslation([ "common", "notification" ])

    const dispatch = useDispatch()
    const localizationStatus = useSelector((state: TRootStore) => state.localizationStatus.status)

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ navbarEntries, setNavbarEntries ] = useState<INavbarEntry[]>([])

    // we do this so the list gets updated if the language changes
    useEffect(() => {
        setNavbarEntries([
            {
                title: t("common:navbar.labels.stats"),
                path: routes.stats,
                icon: mdiChartLine,
                disabled: !hasPermissions([EPermissionRoles.Admin, EPermissionRoles.Manager])
            },
            {
                title: t("common:navbar.labels.clinics"),
                icon: mdiHospitalBuilding,
                disabled: true,
                links: [
                    {
                        path: routes.clinics,
                        title: t("common:navbar.links.manage"),
                        disabled: false
                    }
                ]
            },
            {
                title: t("common:navbar.labels.managers"),
                path: routes.managers,
                icon: mdiAccountSupervisor,
                disabled: !hasPermissions([EPermissionRoles.Admin, EPermissionRoles.Manager]),
            },
            {
                title: t("common:navbar.labels.doctors"),
                path: routes.doctors,
                icon: mdiDoctor,
                hasMissingTranslations: localizationStatus.doctors,
                disabled: !hasPermissions([EPermissionRoles.Admin, EPermissionRoles.Manager]),
                links: [
                    {
                        title: t("common:navbar.links.specialties"),
                        path: routes.doctorsSpecialties,
                        disabled: !hasPermissions([EPermissionRoles.Admin]),
                        hasMissingTranslations: localizationStatus.specialties
                    }
                ]
            },
            {
                title: t("common:navbar.labels.clients"),
                path: routes.clients,
                icon: mdiAccount,
                disabled: !hasPermissions(EPermissionRoles.Admin)
            },
            {
                title: t("common:navbar.labels.instantCalls"),
                path: routes.instantCalls,
                icon: mdiPhone,
                disabled: !hasPermissions([EPermissionRoles.Admin, EPermissionRoles.Manager])
            },
            {
                title: t("common:navbar.labels.campaigns"),
                path: routes.campaigns,
                icon: mdiTag,
                disabled: !hasPermissions([ EPermissionRoles.Admin ])
            },
            {
                title: t("common:navbar.labels.notifications"),
                path: routes.notifications,
                icon: mdiBell,
                disabled: !hasPermissions(EPermissionRoles.Admin)
            },
            {
                title: t("common:navbar.labels.reports"),
                path: routes.reports,
                icon: mdiAlert,
                disabled: !hasPermissions(EPermissionRoles.Admin)
            },
            {
                title: t("common:navbar.labels.localization"),
                path: routes.localizationLanguages,
                icon: mdiTranslate,
                disabled: !hasPermissions(EPermissionRoles.Admin)
            },
            {
                title: t("common:navbar.labels.documents"),
                path: routes.documents,
                icon: mdiFileDocument,
                disabled: !hasPermissions([ EPermissionRoles.Admin, EPermissionRoles.Manager])
            },
            {
                title: t("common:navbar.labels.documentation"),
                path: routes.documentation,
                icon: mdiFrequentlyAskedQuestions,
                disabled: !hasPermissions([ EPermissionRoles.Admin, EPermissionRoles.Manager])
            }
        ])

        // no need for path or t as a dependency
        // eslint-disable-next-line
    }, [ i18n.resolvedLanguage, localizationStatus ])

    // fetch global state
    useEffect(() => {

        const fetch = async () => {
            // async chaining just because we have to await for each of them
            // if they are all done async, then the refresh jwt endpoint gets called a bunch of times,
            // resulting in random logouts
            await Promise.all([
                dispatch(fetchDisplayLanguages())
            ])
            await Promise.all([
                dispatch(fetchDoctorSpecialties())
            ])
            await Promise.all([
                dispatch(fetchLocalizationStatus())
            ])
        }

        fetch().then(() => setIsLoading(false))
        // dispatch is not a relevant dependency
        // eslint-disable-next-line
    }, [])

    if (isLoading)
        return <Loading />

    return <>
        <div className="columns is-desktop is-gapless">
            <div className="column is-narrow">
                <Suspense fallback={ <Loading /> }>
                    <Navbar entries={ navbarEntries }/>
                </Suspense>
            </div>
            <div className="column" style={ { overflowX: "auto" } }>
                <div className="section">
                    <Suspense fallback={ <Loading /> }>
                        <Switch>
                            {/*home*/}
                            <Route
                                exact
                                path={ routes.stats }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Manager, EPermissionRoles.Admin ] }
                                        >
                                            <Stats />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*clinics*/}
                            <Route
                                exact
                                path={ routes.clinics }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Admin ] }
                                        >
                                            <Clinics />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*instant calls*/}
                            <Route
                                exact
                                path={ routes.instantCalls }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Manager, EPermissionRoles.Admin ] }
                                        >
                                            <InstantCalls />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*notifications*/}
                            <Route
                                exact
                                path={ routes.notifications }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Admin ] }
                                        >
                                            <Notifications />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*localization*/}
                            <Route
                                exact
                                path={ routes.localizationLanguages }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Admin ] }
                                        >
                                            <Localization />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*reports*/}
                            <Route
                                exact
                                path={ routes.reports }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Admin ] }
                                        >
                                            <Reports />
                                        </Permission>
                                    </>
                                }}
                            />


                            {/* not exact because it has sub-routes */}

                            {/*doctors*/}
                            <Route
                                path={ routes.doctors }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Admin, EPermissionRoles.Manager ] }
                                        >
                                            <DoctorRoutes />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*campaigns*/}
                            <Route
                                path={ routes.campaigns }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.campaigns }
                                            roles={ [ EPermissionRoles.Admin ] }
                                        >
                                            <CampaignRoutes />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*clients*/}
                            <Route
                                path={ routes.clients }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Admin ] }
                                        >
                                            <ClientRoutes />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*managers*/}
                            <Route
                                path={ routes.managers }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Admin, EPermissionRoles.Manager ] }
                                        >
                                            <ManagerRoutes />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*documents*/}
                            <Route
                                path={ routes.documents }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Admin, EPermissionRoles.Manager ] }
                                        >
                                            <Documents />
                                        </Permission>
                                    </>
                                }}
                            />

                            {/*documentation*/}
                            <Route
                                path={ routes.documentation }
                                render={ () => {
                                    return <>
                                        <Permission
                                            logoutOnDenied={ false }
                                            redirectTo={ routes.doctors }
                                            roles={ [ EPermissionRoles.Admin, EPermissionRoles.Manager ] }
                                        >
                                            <Documentation />
                                        </Permission>
                                    </>
                                }}
                            />

                            <Route>
                                <Redirect to={ routes.doctors } />
                            </Route>
                        </Switch>
                    </Suspense>
                </div>
            </div>
        </div>
    </>
}

export default AdminDashboard
