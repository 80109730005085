import React, { FunctionComponent } from "react"
import { Field, FieldHookConfig, useField } from "formik"
import Icon from "@mdi/react"
import { mdiAlert } from "@mdi/js"

interface OtherProps {
    label?: string | JSX.Element
    helpMessage?: string | JSX.Element
}

const TextAreaField: FunctionComponent<OtherProps & FieldHookConfig<string>> = ({ label, helpMessage, ...props }) => {
    const [ field, meta ] = useField(props.name)

    return <>
        <div className="field">
            { label && <label className="label">{ label }</label> }
            <div className={ "control is-expanded " + (meta.touched && meta.error ? "has-icons-right" : "") }>
                <Field
                    className={ "textarea " + (meta.touched && meta.error ? "is-danger" : "") }
                    as="textarea"
                    { ...field }
                    { ...props }
                />
                {
                    meta.touched && meta.error
                    ? <>
                        <span
                            className="icon is-small is-right">
                            <Icon
                                path={ mdiAlert }
                                color={ "#dbdbdb" }
                                size={ 1.0 }/>
                        </span>
                    </>
                    : <></>
                }
            </div>
            {
                meta.touched && meta.error
                ? <p className="help is-danger">{ meta.touched && meta.error }</p>
                : <></>
            }
            {
                helpMessage
                ? <p className="help" > { helpMessage } </p>
                : null
            }
        </div>
    </>
}

export default TextAreaField
