import Codes from "components/AdminDashboard/Campaigns/Page/Codes/Codes"
import { DateTime } from "luxon"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { mdiArrowLeft } from "@mdi/js"
import Icon from "@mdi/react"

import Details from "components/AdminDashboard/Campaigns/Page/Details/Details"
import useConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import Loading from "components/common/Loading/Loading"
import * as api from "service/http/api"
import { handleAxiosError } from "service/service"
import routes from "routes/routes"
import { noResponseReceivedNotification } from "utils/notifications"

export interface ICampaignPageHistoryState {
    from?: {
        pathname: string
        search: string
    }
}

export interface ICampaignPageParams {
    campaignId: string
}

const Page: FunctionComponent = () => {
    const { t } = useTranslation([ "notification", "modal", "campaigns" ])
    const { campaignId } = useParams<ICampaignPageParams>()
    const history = useHistory<ICampaignPageHistoryState>()

    const setModalOptions = useConfirmModal()

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ campaign, setCampaign ] = useState<api.campaigns.ICampaign>({} as api.campaigns.ICampaign)
    const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false)

    // region mount
    useEffect(() => {
        const fetch = async () => {
            try {
                const doctor = await api.campaigns.getCampaign(campaignId)
                setCampaign(doctor)
            } catch (error) {
                handleAxiosError(
                    error,
                    (e) => {
                        noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                        history.replace(routes.campaigns)
                    }, (e) => {
                        // no connection or smth
                        noResponseReceivedNotification(t("notification:noResponseReceived.title"), t("notification:noResponseReceived.message"))
                    }, (e) => {
                        // this my bad
                        // console.log('Error', e)
                    })
            }
        }

        fetch().then(() => setIsLoading(false))

        // irrelevant dependencies
        // eslint-disable-next-line
    }, [])
    // endregion

    // region handlers
    const handleGoBack = () => {
        const goBack = () => {
            const state = history.location.state

            if (state && state.from && state.from.pathname === routes.campaigns)
                history.goBack()
            else
                history.push(routes.campaigns)
        }

        if (isFormDirty) {
            setModalOptions({
                isOpen: true,
                modalProps: {
                    title: t("modal:discardChanges.title"),
                    content: t("modal:discardChanges.message"),
                    onConfirm: () => {
                        setModalOptions({ isOpen: false })
                        goBack()
                    },
                    onCancel: () => {
                        setModalOptions({ isOpen: false })
                    }
                }
            })
        } else {
            goBack()
        }
    }

    const updateCampaignDetails = useCallback(async (entry: api.campaigns.IUpdateCampaignParams) => {
        await api.campaigns.updateCampaign(campaignId, entry)

        setCampaign(newState => ({ ...newState, ...entry as api.campaigns.ICampaign }))
    }, [ campaignId ])

    const generateCodes = useCallback(async (amount: number) => {
        const response = await api.campaigns.generateCampaignCodes(campaignId, { amount: amount })

        setCampaign(newState => ({
            ...newState,
            campaignCodeInstances: [
                ...newState.campaignCodeInstances,
                ...response
            ]
        }))
    }, [ campaignId ])
    // end region

    // region render
    if (isLoading)
        return <Loading />

    return <>
        <div className="container">

            {/*back button*/}
            <div className="columns">
                <div className="column">
                    <button
                        className="button is-circular"
                        onClick={ handleGoBack }
                    >
                        <span className="icon is-small">
                            <Icon path={ mdiArrowLeft } size={ 1 } />
                        </span>
                    </button>
                </div>
            </div>

            {/*content*/}
            <div className="columns">
                {/*used for centering*/}
                <div className="column is-hidden-touch"></div>

                <div className="column is-narrow-desktop is-full-tablet">

                    {/*details*/}
                    <div className="card">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("campaigns:page.titles.details") }
                            </div>
                            <Details
                                type="existing"
                                details={ campaign }
                                onUpdate={ updateCampaignDetails }
                                setIsFormDirty={ setIsFormDirty }
                                hasCampaignEnded={ DateTime.fromISO(campaign.endsAt) < DateTime.now().toUTC() }
                            />
                        </div>
                    </div>
                </div>

                {/*used for centering*/}
                <div className="column is-hidden-touch"></div>
            </div>

            <div className="columns">
                <div className="column">
                    {/*code*/}
                    <div className="card">
                        <div className="card-content">
                            <div className="title has-text-centered">
                                { t("campaigns:page.titles.codes") }
                            </div>
                            <Codes
                                codes={ campaign.campaignCodeInstances }
                                campaignName={ campaign.name }
                                onGenerateCodes={ generateCodes }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    // endregion
}

export default Page
