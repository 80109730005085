import { DateTime } from "luxon"
import _ from "lodash"
import { SUPPORTED_IMAGE_FORMATS } from "service/config/config"

interface EnumArrayEntry {
    key: string
    value: string | number
}

// Turn enum into array
export const enumToArray = (myEnum: any): EnumArrayEntry[] => {
    return Object.keys(myEnum)
        .filter((value) => {
            return isNaN(Number(value))
        })
        .map((key) => {
            return {
                key: key,
                value: myEnum[key]
            }
        });
}

export const enumToNumberValuesArray = (myEnum: any): number[] => {
    const enumArray = enumToArray(myEnum)

    return enumArray
        .filter((value) => {
            return !isNaN(Number(value.value))
        })
        .map((value) => {
            return value.value as number
    })
}

export const enumToStringValuesArray = (myEnum: any): string[] => {
    const enumArray = enumToArray(myEnum)

    return enumArray
        .filter((value) => {
            return isNaN(Number(value.value))
        })
        .map((value) => {
            return value.value as string
        })
}

// check if string is a valid date
export const isValidDate = (value: string | undefined): boolean => {

    return !!value && (new Date(value!)).toString() !== "Invalid Date"
}

// time string
export const timeStringToUtcIsoString = (time: string, timeZone: string = "utc") => {
    return DateTime.fromFormat(time, 'T', { zone: timeZone }).toUTC().toISO()
}

export const UtcIsoStringToTimeString = (isoString: string, timeZone: string = "utc") => {
    return DateTime.fromISO(isoString, { zone: timeZone} ).toFormat('T')
}

// year string
export const yearStringToUtcISOString = (time: string) => {
    return DateTime.fromFormat(time, 'y', { zone: "utc" }).toUTC().toISO()
}

export const UtcIsoStringToYearString = (isoString: string) => {
    return DateTime.fromISO(isoString, { zone: "utc" }).year
}

// datetime string (without seconds)
export const dateTimeStringToUtcISOString = (time: string, timeZone: string = "utc") => {
    return DateTime.fromFormat(time, "yyyy-MM-dd'T'HH:mm", { zone: timeZone }).toUTC().toISO()
}

export const UtcIsoStringToDateTimeString = (isoString: string, timeZone: string = "utc") => {
    return DateTime.fromISO(isoString, { zone: timeZone}).toFormat("yyyy-MM-dd'T'HH:mm")
}

// seconds to time string
// return format: "21m 30s"
export const secondsToTimeString = (seconds: number): string => {
    const min = _.floor(seconds / 60)
    const sec = seconds - min * 60

    return `${min > 0 ? `${min}m` : ""} ${sec}s`
}

// url validation
export const isValidImage = async (url: string, timeout: number = 5000, isYTThumbnail: boolean = false): Promise<boolean> => {
    return new Promise(function (resolve, reject) {
        const img = new Image()

        img.onerror = img.onabort = function () {
            clearTimeout(timer)
            resolve(false)
        }

        img.onload = function () {
            clearTimeout(timer)

            // the yt thumbnail placeholder has a width of 120
            if (isYTThumbnail && img.width === 120)
                resolve(false)
            else
                resolve(true)
        }

        let timer = setTimeout(function () {
            // reset .src to invalid URL, so it stops previous
            // loading, but doesn't trigger new load
            img.src = "//!!!!/test.jpg"
            resolve(false)
        }, timeout)

        img.src = url
    })
}

function isValidUrl(string: string): boolean {
    try {
        const url = new URL(string)
        return url.protocol === "http:" || url.protocol === "https:"
    } catch (e) {
        return false;
    }
}

export const isValidImageUrl = async (string: string): Promise<boolean> => {
    // case for uploaded files
    // remove 'blob:' before validating
    if (string && string.indexOf("blob:") === 0)
        return true

    return isValidUrl(string) && await isValidImage(string)
}

export const getYouTubeVideoThumbnail = (url: string): string => {
    const ytId = getYouTubeVideoIdFromUrl(url)
    return `https://img.youtube.com/vi/${ytId}/maxresdefault.jpg`
}

export const getYouTubeVideoIdFromUrl = (url: string): string | undefined => {
    const regex = /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i
    const match = url.match(regex)
    return match ? match[7] : undefined
}

export const isValidYTVideoId = async (ytId: string): Promise<boolean> => {
    const url = "https://img.youtube.com/vi/" + ytId + "/hqdefault.jpg"

    return await isValidImage(url, 5000, true)
}

// numbers
export const rateString = (number: number, decimals: number): string => {
    const str = number.toString().split('.')

    // return the integer
    if (decimals <= 0) return `${str[0]}€`

    // build the decimals string
    let strD = str[1] || ""

    // if it has enough decimals already
    if (strD.length > decimals) return `${str[0]}.${strD.substring(0, decimals)}€`

    // if not, fill with '0'
    while (strD.length < decimals) strD += '0'

    return `${str[0]}.${strD}€`
}

// strings
export const stringArrayToString = (array: string[]): string => {
    let result = ""

    for (let i in array)
        result += `.${array[i]}, `

    return result.slice(0, result.length - 2)
}

// file
export const isFileEmpty = (file: File): boolean => {
    if (!file) return true

    return !(file.name && file.size > 0)
}

export const isValidImageFile = (file?: File | null) => {
    if (!file) return true

    // in case the file doesn't contain the ext or the browser doesn't recognize the type
    // best I can do, probably it's redundant and type was enough
    const extType = (file as File).type.split("/").pop()
    const extName = (file as File).type.split(".").pop()

    return (!!extType && SUPPORTED_IMAGE_FORMATS.includes(extType))
        || (!!extName && SUPPORTED_IMAGE_FORMATS.includes(extName))
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si= false, dp= 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}
