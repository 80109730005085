import React, { FunctionComponent } from "react"
import { FieldHookConfig, useField, Field } from "formik"
import Icon from "@mdi/react"
import { mdiAlert } from "@mdi/js"

interface OtherProps {
    label?: string | JSX.Element
    isStatic?: boolean
    isReadOnly?: boolean
    fieldSize?: "narrow" | "expanded"
    helpMessage?: string | JSX.Element
}

const InputField: FunctionComponent<OtherProps & FieldHookConfig<string>> = ({ label, isStatic = false, isReadOnly = false , fieldSize = "normal", helpMessage, ...props }) => {
    const [ field, meta ] = useField(props.name)

    return <>
        <div className={ "field" + (fieldSize === "narrow" ? "is-narrow" : "") }>
            {
                label && <label className="label">{ label }</label>
            }
            <div className={
                "control "
                + (meta.touched && meta.error ? "has-icons-right " : "")
                + (fieldSize === "expanded" ? "is-expanded"  : "")
            }>
                <Field
                    className={ `input ${meta.touched && meta.error ? "is-danger" : ""} ${isStatic ? "is-static" : ""}` }
                    { ...field }
                    { ...props }
                    readOnly={ isStatic || isReadOnly }
                />
                {
                    meta.touched && meta.error
                    ? <span  className="icon is-small is-right">
                        <Icon path={ mdiAlert } color={ "#dbdbdb" } size={ 1.0 }/>
                    </span>
                    : <></>
                }
            </div>
            {
                meta.touched && meta.error
                ? <p className="help is-danger">{ meta.error }</p>
                : null
            }
            {
                helpMessage
                ? <p className="help" > { helpMessage } </p>
                : null
            }
        </div>
    </>
}

export default InputField
